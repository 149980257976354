import { useState } from 'react';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const useDrawerToggle = () => {
  const [drawerAnchor, setDrawerAnchor] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    setDrawerAnchor({ ...drawerAnchor, [anchor]: open });
  };

  return {
    drawerAnchor,
    toggleDrawer,
  };
};

export default useDrawerToggle;
