type SearchIconSvgProps = {
  color?: string
}

export const SearchIconSvg = ({ color = '#fff' }: SearchIconSvgProps) => {
  return (
    <svg width="18" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 .25C3.71979.25.25 3.71979.25 8c0 4.2802 3.46979 7.75 7.75 7.75 1.61351 0 3.1118-.4931 4.3526-1.3368l4.1171 4.1172 1.0607-1.0607-4.0201-4.02C14.895 12.0497 15.75 10.1247 15.75 8 15.75 3.71979 12.2802.25 8 .25ZM1.75 8c0-3.45178 2.79822-6.25 6.25-6.25 3.4518 0 6.25 2.79822 6.25 6.25 0 3.4518-2.7982 6.25-6.25 6.25-3.45178 0-6.25-2.7982-6.25-6.25Z"
        fill={color}
      />
    </svg>
  )
};
