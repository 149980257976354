export const italianToFrench = (face: string, suit: string) => {
    const itaSuits = ['b', 'c', 'd', 's'];
    const freSuits = ['s', 'h', 'd', 'c'];

    const itaFaces = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
    const freFaces = ['1', '2', '3', '4', '5', '6', '7', 'j', 'q', 'k'];

    return {
        face: freFaces[itaFaces.indexOf(face)],
        suit: freSuits[itaSuits.indexOf(suit)]
    }
}