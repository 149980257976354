import { TournamentStateEnum } from '@quanticogames/gameclient-core';
import { HeadCell } from 'types/commonTable';

export enum TournamentStateLabels {
    PROGRESS = 'progress',
    TERMINATED = 'terminated',
    CANCELED = 'canceled',
    ARCHIVED = 'archived',
    SIGNINGUP = 'signing',
}

export const TOURNAMENT_STATES_TITLES = {
    [TournamentStateEnum.open]: {
        title: 'Open',
        color: '#00BE2A',
        background: '#F4F8F9'
    },
    [TournamentStateEnum.inprogress]: {
        title: 'In Progress',
        color: '#06F',
        background: '#F4F8F9'
    },
    [TournamentStateEnum.end]: {
        title: 'End',
        color: '#E20613',
        background: '#F4F8F9'
    },
    [TournamentStateEnum.terminated]: {
        title: 'Terminated',
        color: '#8E8E8E',
        background: '#CBCBCB'
    },
    [TournamentStateEnum.canceled]: {
        title: 'Canceled',
        color: '#E20613',
        background: '#F4F8F9'
    },
    [TournamentStateEnum.closed]: {
        title: 'Closed',
        color: '#E20613',
        background: '#F4F8F9'
    },
    [TournamentStateEnum.scheduled]: {
        title: 'Scheduled',
        color: '#237893',
        background: '#F4F8F9'
    }
};

export const TOURNAMENT_HEAD_DATA: HeadCell[] = [
    {
        id: 'favourite',
        label: '',
        sortable: false,
        filter: false
    },
    {
        id: 'description',
        label: 'Description',
        sortable: false,
        filter: true
    },
    // {
    //     id: 'prize',
    //     label: 'Prize',
    //     sortable: true,
    //     filter: false
    // },
    {
        id: 'badge',
        label: 'Badge',
        sortable: false,
        filter: false
    },
    {
        id: 'cost',
        label: 'Cost',
        sortable: true,
        filter: false
    },
    {
        id: 'players',
        label: 'Players',
        sortable: true,
        filter: false
    },
    {
        id: 'state',
        label: 'State',
        sortable: false,
        filter: true,
    },
    {
        id: 'actions',
        label: 'Actions',
        sortable: false,
        align: 'center',
    },
];

export const SCHEDULED_HEAD_DATA: HeadCell[] = [
    {
        id: 'data',
        label: 'Data',
        sortable: false
    },
    {
        id: 'description',
        label: 'Description',
        sortable: true
    },
    {
        id: 'badge',
        label: 'Badge',
        sortable: false
    },
    {
        id: 'cost',
        label: 'Cost',
        sortable: true
    },
    {
        id: 'players',
        label: 'Players',
        sortable: false
    },
    {
        id: 'state',
        label: 'State',
        sortable: true
    },
    {
        id: 'actions',
        label: 'Actions',
        sortable: false,
        align: 'center',
    },
];
