import { selectSessionInfo } from "@quanticogames/gameclient-core";
import moment from "moment";
import { useSelector } from "react-redux";

const ConvertBanner = () => {
    const sessionInfo: any = useSelector(selectSessionInfo);
    const dataBanner = sessionInfo?.banners;

    const banner = dataBanner?.map((e) => {
        return {
            ...e,
            startTime: e?.isDefault === true ? '' : e?.startTime,
            endTime: e?.isDefault === true ? '' : e?.endTime
        }
    }).filter(e => e?.campaignName)

    const defaultBanner = banner?.length > 0 ? banner?.filter((e) => e.isDefault) : [];
    const defaultDesktopBanner = defaultBanner?.length > 0 && JSON.parse(defaultBanner[0]?.desktopBannerUrl || '""');
    const defaultDesktopUrl = defaultBanner?.length > 0 && JSON.parse(defaultBanner[0]?.desktopEmbededBannerUrl || '""');
    const slideDesktopDefault =
        defaultBanner?.length > 0 &&
        defaultDesktopBanner?.map((item: any, index: number) => {
            return {
                desktopBanner: item,
                desktopEmbededBannerUrl: defaultDesktopUrl[index],
            };
        });

    const defaultMobileBanner = defaultBanner?.length > 0 && JSON.parse(defaultBanner[0]?.mobileBannerUrl || '""');
    const defaultMobileUrl = defaultBanner?.length > 0 && JSON.parse(defaultBanner[0]?.mobileEmbededBannerUrl || '""');
    const slideMobileDefault =
        defaultBanner?.length > 0 &&
        defaultMobileBanner?.map((item: any, index: number) => {
            return {
                mobileBanner: item,
                mobileEmbededBannerUrl: defaultMobileUrl[index],
            };
        });

    const activeBanner = banner?.filter((e) => {
        const currentTime = moment();
        const startTime = moment(e?.startTime);
        const endTime = moment(e?.endTime);
        const isCurrentVsTime = currentTime.isSameOrAfter(startTime, 'date') && currentTime.isSameOrBefore(endTime, 'date');
        return currentTime.isBetween(startTime, endTime) || isCurrentVsTime;
    });
    const removeDefautl = activeBanner?.filter((e) => !e?.isDefault);

    const activeDesktopBanner = activeBanner?.length > 0 && JSON.parse(removeDefautl[0]?.desktopBannerUrl || '""');
    const activeDesktopUrl = activeBanner?.length > 0 && JSON.parse(removeDefautl[0]?.desktopEmbededBannerUrl || '""');
    const slideDesktopActive =
    activeDesktopBanner?.length > 0 && Array.isArray(activeDesktopBanner) &&
        activeDesktopBanner?.map((item: any, index: number) => {
            return {
                desktopBanner: item,
                desktopEmbededBannerUrl: activeDesktopUrl[index],
            };
        });

    const activeMobileBanner = activeBanner?.length > 0 && JSON.parse(activeBanner[0]?.mobileBannerUrl || '""');
    const activeMobileUrl = activeBanner?.length > 0 && JSON.parse(activeBanner[0]?.mobileEmbededBannerUrl || '""');
    const slideMobileActive =
        activeBanner?.length > 0 &&
        activeMobileBanner?.map((item: any, index: number) => {
            return {
                mobileBanner: item,
                mobileEmbededBannerUrl: activeMobileUrl[index],
            };
        });

    return {slideDesktopDefault, slideMobileDefault, slideDesktopActive, slideMobileActive, activeBanner, banner}
}
export default ConvertBanner;