import React, { useCallback, useState } from 'react';
import {
  Avatar,
  Box,
  Pagination,
  styled,
  TableCell,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Participant,
  Tournament,
  TournamentStateEnum,
  selectCurrentUser,
  User,
  ParticipantStatusEnum,
  selectActiveTournament,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import IconLoading from 'assets/icons/Loading';
import TableComponent from 'shared/components/tables';
import { FlexBox } from 'shared/Flex';

type ParticipantsTableProps = {
  data?: Tournament | null;
  listParticipant?: Participant[];
};

const PaginationWrapper = styled(Box)(() => ({
  paddingTop: '28px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  borderTop: '1px solid #F0F1F3',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#0B0E11',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const ParticipantsTable: React.FC<ParticipantsTableProps> = ({ listParticipant }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const tournament: Tournament = useSelector(selectActiveTournament);
  const currentUser: User = useSelector(selectCurrentUser);
  const [page, setPage] = useState<number>(1);

  const isMatchInprocess =
    tournament?.state === TournamentStateEnum.inprogress ||
    tournament?.state === TournamentStateEnum.end ||
    tournament?.state === TournamentStateEnum.terminated;

  const HEAD_DATA_PARTICIPANTS = [
    {
      id: 'position',
      label: 'No.',
      align: 'center',
    },
    {
      id: 'user',
      label: 'User',
      align: 'left'
    },
  ];

  const renderPlayerInfo = useCallback(
    (player: Participant, index: number, isMatchInprocess: boolean) => {
      return (
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <Avatar
              sx={{
                borderColor: 'transparent',
                marginRight: '8px',
                width: '20px',
                height: '20px',
              }}
              src={
                isMatchInprocess || player.userId === currentUser.id
                  ? `/images/avatar/${player.avatar}`
                  : `/images/avatar/0`
              }
              key={player.id}
            />
            {isMatchInprocess || player.userId === currentUser.id ? player?.userName || currentUser?.userName : 'Guest ' + index}
          </Box>
        </TableCell>
      );
    },
    [tournament]
  );

  let sortParticipants = JSON.parse(JSON.stringify(listParticipant || []));

  sortParticipants.sort((a, b) => {
    if (a.status == ParticipantStatusEnum.Eliminated) return -1;
    if (a.status == ParticipantStatusEnum.Active) return 1;
    return 0;
  });

  return (
    <>
      <TableComponent columns={HEAD_DATA_PARTICIPANTS} className='paticipant-table'>
        {sortParticipants.slice((page - 1) * 10, page * 10).map((player, idx) => (
          <TableRow key={`participant-table-item-${player?.id}`}>
            {player?.id ? (
              <>
                <TableCell
                  width="50"
                  style={{textAlign: 'center'}}
                >
                  {idx + 1 + 10 * (page - 1)}
                </TableCell>
                {renderPlayerInfo(player, idx, isMatchInprocess)}
              </>
            ) : <TableCell colSpan={2}>
                <FlexBox><IconLoading color="" /></FlexBox>
              </TableCell>}
          </TableRow>
        ))}
      </TableComponent>
      {isDesktop && tournament?.participants?.length && tournament?.participants?.length >= 9 && (
        <PaginationWrapper>
          <StyledPagination
            onChange={(e, page) => setPage(page)}
            count={Math.ceil(listParticipant.length / 10)}
            hidePrevButton
            hideNextButton
          />
        </PaginationWrapper>
      )}
    </>
  );
};