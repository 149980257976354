import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const SetteEMezzoIlMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Sette e mezzo - Il mazzo di carte </h2>
            <h2 className="titolo2">Il mazzo di carte </h2>
            <p className="paragraph">Si gioca con un mazzo di 40 carte italiane tradizionali. </p>
            <p className="paragraph">I giocatori possono selezionare il tipo di carte (napoletane, piacentine).</p>
            <p className="paragraph">E' anche possibile selezionare le carte francesi senza i Jolly, gli 8, i nove e i dieci. </p>
            <p className="paragraph">La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
            <table className="bordi" border={1} cellPadding="5" width="221">
                <tbody>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">denari </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">quadri </p></td>
                        <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♦ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">coppe </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">cuori </p></td>
                        <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♥ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">bastoni </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">fiori </p></td>
                        <td className="bordi" width="43"><p className="paragraph">♣ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">spade </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">picche </p></td>
                        <td className="bordi" width="43"><p className="paragraph">♠ </p></td>
                    </tr>
                </tbody>
            </table>
            <h2 className="titolo2">Il valore delle carte </h2>
            <p className="paragraph">
                Il re di denari (quadri) è la matta e può assumere uno qualsiasi dei seguenti
                valori a scelta del giocatore che la posiede: ½, 1 , 2, 3, 4, 5, 6, 7
            </p>
            <p className="paragraph">Attenzione: se si sta con la matta il suo valore è ½, quindi, possedendo la matta, è necessario tirare almeno una carta. </p>
            <p className="paragraph">Il sistema attribuirà automaticamente alla matta il valore massimo. </p>
            <p className="paragraph">· Le figure (fante, cavallo, re) valgono ½ punto. </p>
            <p className="paragraph">· Tutte le altre carte valgono il loro valore facciale. </p>
        </ContentTabs >
    );
};

export const SetteEMezzoSvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Sette e mezzo - Svolgimento del gioco </h2>
            <h2 className="titolo2">Svolgimento del gioco </h2>
            <p className="paragraph">
                Nella variante piatto il banchiere stabilisce il rischio massimo e ha la
                possibilità di vincere una somma assai maggiore (al limite potrebbe raddoppiare
                il piatto ad ogni puntata).
            </p>
            <p className="paragraph">
                Nella variante classica, invece, il banco, deve essere in grado di pagare la
                possibile perdita massima che è pari a: numero giocatori di punta x puntata
                massima (senza considerare eventuali pagamenti doppi).
            </p>
            <p className="paragraph">
                Il banco deve quindi avere un grosso credito a disposizione per fare il banco
                e può vincere al massimo la somma che può perdere (se vincesse contro tutti in
                presenza di tutte puntate massime).
            </p>
            <p className="paragraph">
                Per le caratteristiche sopra illustrate la variante piatto è più adatta al
                gioco on line. Per questo motivo le regole base verranno illustrate per questa
                variante salvo poi specificare le differenze della variante classica.
            </p>
            <p className="paragraph">I tornei di Sette e mezzo saranno proposti solo nella variante piatto. </p>
            <p className="paragraph">
                Durante i tornei di sette e mezzo, se nello stesso giro due giocatori sono
                eliminati dallo stesso banchiere, si classifica prima colui il quale, prima di
                essere eliminato, aveva il numero maggiore di chips.
            </p>
            <h2 className="titolo2">Designazione del banchiere</h2>
            <p className="paragraph">
                Il "<strong>banchiere"</strong> (o più semplicemente "banco") è il giocatore
                che da le carte, gli altri giocatori giocano contro il banchiere e sono detti <strong>giocatori di punta</strong> (o più semplicemente "punta").
            </p>
            <p className="paragraph">
                Il banchiere viene selezionato tra i giocatori seduti al tavolo che non sono
                in stato di sit out con le seguenti regole:
            </p>
            <p className="paragraph">a inizio partita: </p>
            <p className="paragraph">
                viene data una carta per volta a tutti i giocatori, partendo da un giocatore
                stabilito casualmente. Il banchiere designato è il giocatore che riceve la
                matta";
            </p>
            <p className="paragraph">durante la partita: </p>
            <p className="paragraph">
                al termine di un giro di banco, il banco spetta al giocatore alla destra del
                banchiere con un'unica eccezione: se il giocatore a cui spetterebbe il banco si
                è appena seduto al tavolo, cioè se non ha ancora effettuato almeno una puntata,
                il banco passa al giocatore successivo;
            </p>
            <p className="paragraph">alla ripresa di una partita interrotta per mancanza del numero minimo di giocatori: </p>
            <ul itemType="disc" className="paragraph">
                <li>il banco spetta al primo giocatore ad essere di turno tra quelli che erano presenti al momento dell'interruzione, </li>
                <li>se nessun giocatore presente al momento dell'interruzione è ancora al tavolo, il banco viene stabilito come a inizio partita. </li>
            </ul>
            <h2 className="titolo2">Accettazione del banco </h2>
            <p className="paragraph">
                Per accettare il banco il giocatore deve indicare l'importo del "piatto".
                Tale importo deve essere almeno uguale all'importo minimo previsto, con un'unica
                eccezione: se un giocatore ha un credito residuo inferiore alla posta minima può
                fare il banco con tutto il credito residuo ("all in").
            </p>
            <h2 className="titolo2">Rifiuto del banco</h2>
            <p className="paragraph">
                Nei tornei il banco non può essere rifiutato: se il giocatore a cui
                spetterebbe il banco è in sit out al momento della puntata o se va in sit out
                perché non comunica l'accettazione nel tempo massimo stabilito perde senza
                giocare un importo pari al <u>piatto minimo /2</u> o, se non avesse a
                disposizione tale importo, tutto il suo credito residuo. Questo importo viene
                suddiviso tra tutti i giocatori seduti al tavolo.
            </p>
            <p className="paragraph">Se il sit out si verifica dopo aver puntato, il sistema giocherà "alla regola" per il banco. </p>
            <h2 className="titolo2">I giocatori di punta</h2>
            <p className="paragraph">Nei tornei tutte le punte sono obbligate a giocare piazzando almeno la puntata minima. </p>
            <p className="paragraph">Se la punta si trova in sit out al momento della puntata perde automaticamente (senza giocare) la metà della puntata minima (che va al banco);</p>
            <p className="paragraph">Se il sit out si verifica dopo aver puntato il sistema giocherà "alla regola" per la punta. </p>
            <h2 className="titolo2">Distribuzione delle carte - le decisioni della punta e del banco</h2>
            <p className="paragraph">Dopo il mescolamento delle carte, viene data una carta coperta al primo giocatore di punta alla destra del banchiere e una carta coperta al banchiere.</p>
            <p className="paragraph">Il giocatore di punta, dopo aver preso visione della sua carta, può decidere se: </p>
            <p className="paragraph">· tirare: nel qual caso gli verrà data una carta scoperta, oppure </p>
            <p className="paragraph">· stare: nel qual caso il gioco passerà al banco. </p>
            <p className="paragraph">Se il giocatore di punta tira e sballa (cioè totalizza un punteggio superiore a 7 ½) perde senza che il banco debba giocare. </p>
            <p className="paragraph">
                Il banchiere, dopo aver scoperto la sua carta, deve a sua volta decidere se
                stare o tirare con l'obiettivo di uguagliare o superare il punteggio della punta
                di cui conosce le eventuali carte tirate ma non la carta iniziale che rimane
                coperta.
            </p>
            <h2 className="titolo2">Chi vince tra banchiere e giocatore di punta</h2>
            <p className="paragraph">Il vincitore tra il banchiere e il giocatore di punta è cosi stabilito: </p>
            <ul itemType="disc" className="paragraph">
                <li> se il giocatore di punta sballa vince il banchiere, altrimenti </li>
                <li>se il banchiere sballa vince la punta, altrimenti </li>
                <li>vince chi ha il punteggio più alto, </li>
                <li>
                    a parità di punteggio vince sempre il banchiere senza eccezioni (anche se
                    la punta ha realizzato un Sette e Mezzo con sole due carte e anche se queste due
                    carte sono la matta e il sette di denari e il banco realizza un Sette e Mezzo
                    con più carte).
                </li>
            </ul>
            <h2 className="titolo2">Pagamento delle vincite</h2>
            <p className="paragraph">Il giocatore di punta incassa subito la vincita prelevandola dal piatto </p>
            <p className="paragraph">Il banco incassa il piatto residuo dopo aver giocato con l'ultimo giocatore di punta. </p>
            <h2 className="titolo2">Mescolamento delle carte</h2>
            <p className="paragraph">Le carte vengono mischiate nei seguenti casi: </p>
            <ul itemType="disc" className="paragraph">
                <li>a inizio partita per decidere il primo giocatore di banco, </li>
                <li>ad ogni inizio banco </li>
            </ul>
            <p className="paragraph">se durante il banco esce la matta, le carte vengono mescolate al termine della mano. </p>
        </ContentTabs >
    );
};

export const SetteEMezzoComeSiGioca = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Sette e mezzo - come si gioca</h2>
            <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
            <p className="paragraph">Saranno sempre visualizzati solo e tutti i tasti per comunicare le alternative tra cui il giocatore può scegliere. </p>
            <p className="paragraph">Elenchiamo I tasti delle varie situazioni di gioco, precisando che al giocatore appariranno solo i tasti tra cui può effettivamente scegliere. </p>
            <p className="paragraph">· Accettazione banco "Selezione importo del piatto o della puntata massima", "Accetto". L'importo del banco va impostato prima di cliccare su "Accetto" </p>
            <p className="paragraph">· Accettazione punta "Gioco" , "Non gioco" </p>
            <p className="paragraph">· Scelta importo puntata "Selezione Importo puntata" , "Sto", "Tiro" <br /> L'importo della puntata va impostato prima di cliccare su "Sto" o "tiro" </p>
            <p className="paragraph">· Scelta di gioco dopo aver già comunicato la puntata : "Sto", "Tiro" </p>
            <p className="paragraph">· Scelta di gioco del banco: "Sto", "Tiro" </p>
            <p className="paragraph">· Premove: In alcuni casi il giocatore potrà impostare la sua decisione ancor prima che tocchi a lui. </p>
        </ContentTabs >
    );
};

export const SetteEMezzoRegoleOpzionali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Sette e mezzo - Regole opzionali</h2>
            <h2 className="titolo2">Variante "classica" </h2>
            <p className="paragraph">Il banchiere gioca contro tutti i giocatori di punta contemporaneamente. </p>
            <p className="paragraph">Ciascun giocatore può puntare un importo compreso tra la puntata minima e la puntata massima determinate dalle regole specifiche del tavolo. </p>
            <p className="paragraph">Lo svolgimento del gioco nella variante classica si differenzia dalla variante piatto per le seguenti regole: </p>
            <p className="paragraph">
                Per accettare il banco il giocatore deve indicare l'importo della puntata
                massima che accetta (contemporaneamente viene visualizzato il rischio di perdita
                massima). Tale importo deve essere almeno uguale all'importo minimo previsto,
                con un'unica eccezione: se un giocatore non ha a disposizione un credito residuo
                tale da poter impostare una puntata massima almeno uguale al minimo previsto,
                può fare il banco mettendo nel piatto tutto il suo credito ("all in"): in questo
                caso le prime punte sono obbligate a giocare la puntata minima fino a copertura
                totale dell'importo coperto dal banco (non è possibile puntare una cifra
                maggiore della puntata minima)
            </p>
            <p className="paragraph">Se il banco è in sit out al momento della puntata il banco paga il doppio della puntata minima a tutti i giocatori e perde automaticamente. </p>
            <p className="paragraph">
                Nella variante classica il banchiere distribuisce una carta coperta a tutti i
                giocatori di punta e una carta coperta a se stesso. Giocano prima tutti i
                giocatori di punta e poi gioca il giocatore di banco.
            </p>
            <p className="paragraph">Tutte le punte sono obbligate a mettere almeno la puntata minima con due eccezioni: </p>
            <p className="paragraph">· Se il banco è in Sit Out le punte si spartiscono la perdita automatica del banco </p>
            <p className="paragraph">
                · Se il banco è in "All in" le prime punte sono obbligate a giocare la
                puntata minima fino a copertura totale dell'importo coperto dal banco (non è
                possibile puntare una cifra maggiore della puntata minima)
            </p>
            <p className="paragraph">
                In condizioni normali i giocatori possono scegliere tra la puntata minima
                (obbligatoria) e la puntata massima stabilita dal banco.
            </p>
            <p className="paragraph">
                Viene raffrontato il punteggio di ciascun giocatore di punta con quello del
                banchiere. Le regole per determinare chi vince sono le stesse della variante
                piatto (per limitare il rischio del banco non sono quindi previsti pagamenti
                doppi in caso di "sette e mezzo legittimo e reale del giocatore di punta").
            </p>
            <p className="paragraph">Al termine del banco vengono pagate vincite e perdite. </p>
            <p className="paragraph">Le carte vengono mischiate a inizio banco (non vengono quindi rimischiate se esce la matta). </p>
            <p className="paragraph">Tutte le altre regole della variante piatto restano invariate, incluso il passaggio del banco ad ogni fine banco. </p>
        </ContentTabs >
    );
};

export const SetteEMezzoOpzioniDiGiocoAutomatico = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Sette e mezzo - Opzioni di gioco automatico</h2>
            <h2 className="titolo2">Opzioni di gioco automatico</h2>
            <p className="paragraph">Il giocatore può impostare le seguenti regole di gioco automatico in caso di sit out: </p>
            <p className="paragraph">· se sono di punta sto a partire da: 3/4/5/6/6,5 default = 4,5 </p>
            <p className="paragraph">· se sono di banco, se la punta è stata, sto a partire da: 3/4/5/6/6,5 default = 4,5 </p>
            <p className="paragraph">
                non è, invece, possibile impostare quando stare se la punta ha tirato: il
                banco sta sempre quando ha superato di ½ punto la somma delle carte viste della
                punta.
            </p>
            <p className="paragraph">
                Le opzioni di gioco automatico possono essere impostate dalla lobby,
                cliccando su "il mio profilo" e poi su "opzioni", oppure sul tavolo di gioco
                cliccando su "opzioni".
            </p>
            <p className="paragraph">
                Modifiche alle opzioni di gioco automatico non sono prese in considerazione
                sul tavolo nel quale il giocatore sta giocando. Le modifiche saranno operative
                dalla prossima entrata in un tavolo.
            </p>
        </ContentTabs >
    );
};
export const SetteEMezzoRegoleDeiTavoliCash = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Sette e mezzo - Regole specifiche dei tavoli cash </h2>
            <h2 className="titolo2">Quota della raccolta destinata a montepremi</h2>
            <p className="paragraph">
                Al montepremi di ogni singola mano è destinata una percentuale della somma
                delle puntate dei giocatori, variabile sulla base della singola sessione di
                gioco, ma in tutti i casi non inferiore al 90%.
            </p>
            <p className="paragraph">
                L'importo del montepremi di una singola mano è sempre derivabile dalla somma
                delle puntate diminuite del margine lordo del concessionario (rake).
            </p>
            <p className="paragraph">
                Il rake massimo applicato al tavolo è chiaramente indicato nel pannello
                informativo presente sulla destra della lobby e sul tavolo di gioco; in caso di
                vincita, i giocatori di punta pagano il rake che è calcolato come segue:
            </p>
            <p className="paragraph">vincita *2 *% rake </p>
            <p className="paragraph">Esempio: </p>
            <ul itemType="disc" className="paragraph">
                <li>Vincita € 10 </li>
                <li>Rake 5% </li>
            </ul>
            <p className="paragraph">(€ 10 * 2) *0,05 = € 1 </p>
            <p className="paragraph">
                I giocatori di banco pagano il rake sulle vincite conseguite con le seguenti
                modalità, diversificate a seconda della tipologia di partita:
            </p>
            <h2 className="titolo2">Nella variante classica : </h2>
            <p className="paragraph">
                Il rake viene calcolato dopo che il banchiere ha pagato o incassato le somme
                puntate dai giocatori di punta; se il saldo tra quanto incassato e quanto pagato
                è negativo, nulla spetta al concessionario in termini di rake, altrimenti il
                rake di spettanza è pari a:
            </p>
            <p className="paragraph">saldo * 2* rake </p>
            <p className="paragraph">Es. </p>
            <p className="paragraph">· Saldo alla fine delle puntate = € 20 </p>
            <ul itemType="disc" className="paragraph">
                <li>Rake 5% </li>
                <li>€20 *2 * 0,05 = € 2 </li>
            </ul>
            <h2 className="titolo2">Nella variante piatto: </h2>
            <p className="paragraph">
                Il rake viene pagato al concessionario ogni volta che il banco vince; in
                questo caso il rake è pari a:
            </p>
            <p className="paragraph">vincita *2 *% rake </p>
            <p className="paragraph">Es. </p>
            <ul itemType="disc" className="paragraph">
                <li>Vincita € 10 </li>
                <li>Rake 5% </li>
            </ul>
            <ul itemType="disc" className="paragraph">
                <li>(€ 10 * 2) *0,05 = € 1 </li>
            </ul>
            <h2 className="titolo2">Rebuy</h2>
            <p className="paragraph">
                Se durante una partita un giocatore volesse aumentare il suo credito al
                tavolo può farlo cliccando sulla propria postazione: facendo questo gli apparirà
                l'apposito pannello con cui selezionare l'importo da versare.
            </p>
            <p className="paragraph">
                Effettuato il versamento al giocatore comparirà il biglietto AAMS relativo
                alla transazione effettuata.
            </p>
            <p className="paragraph">
                Se il versamento venisse effettuato durante una mano in corso, il credito al
                tavolo sarà aggiornato e disponibile solo alla fine della mano stessa.
            </p>
            <h2 className="titolo2">Abbandono automatico nei tavoli cash </h2>
            <p className="paragraph">Rammentiamo che un giocatore va in stato di sitout nei seguenti casi: </p>
            <p className="paragraph">· per sua scelta, cliccando su "sitout", </p>
            <p className="paragraph">· perché non comunica la sua decisione entro il tempo massimo. </p>
            <p className="paragraph">
                Un giocatore presente al tavolo può uscire dallo stato di sit out cliccando
                sul tasto "sono tornato", oppure automaticamente nel caso di rientro nel tavolo.
            </p>
            <p className="paragraph">
                Se un giocatore salta tre turni di banco consecutivi, propri o altrui, (sia
                perché in sit out, sia perché rifiuta) è fatto alzare automaticamente dal tavolo
                con accredito del suo credito residuo sul suo conto di gioco.
            </p>
            <p className="paragraph"></p>
            <h2 className="titolo2">Tasti e metodi per comunicare le proprie decisioni:</h2>
            <p className="paragraph">
                Elenchiamo I tasti delle varie situazioni di gioco, precisando che al
                giocatore appariranno solo i tasti tra cui può effettivamente scegliere.
            </p>
            <p className="paragraph">
                · Accettazione banco <br />
                Selezione importo del piatto o della puntata massima, "Rifiuto". <br />
                L'importo del banco va impostato prima di cliccare sul pulsante per accettare.
            </p>
            <p className="paragraph">
                · Scelta importo puntata <br />
                Selezione Importo puntata , "Sto", "Tiro" <br />
                L'importo della puntata va impostato prima di cliccare su "Sto" o "tiro"
            </p>
            <p className="paragraph">· Scelta di gioco dopo aver già comunicato la puntata "Sto", "Tiro" </p>
            <p className="paragraph">· Scelta di gioco del banco "Sto", "Tiro" </p>
            <h2 className="titolo2">Regole specifiche per la variante "piatto"</h2>
            <p className="paragraph"><strong>Designazione del banchiere durante la partita:</strong></p>
            <p className="paragraph">al termine di un giro di banco, il banco spetta al giocatore alla destra del banchiere.</p>
            <p className="paragraph"><strong>Obbligo a giocare il banco:</strong></p>
            <p className="paragraph">
                Nei tavoli cash il banco può essere rifiutato liberamente senza alcuna
                perdita. Per le possibili conseguenze al fine della permanenza al tavolo vedere
                paragrafo "Abbandono automatico". Se un giocatore non accetta il banco per
                qualsiasi motivo, il banco passa al giocatore alla sua destra e cosi via.
            </p>
            <p className="paragraph"><strong>I giocatori di punta:</strong></p>
            <p className="paragraph">Può partecipare al banco solo chi è già presente all'inizio del banco medesimo.</p>
            <p className="paragraph">
                Il giocatore non ha mai l'obbligo a giocare di punta; tuttavia, poiché le
                carte vengono automaticamente distribuite a tutti i giocatori non in sitout, per
                non giocare di punta è necessario mettersi in sitout prima di ricevere le carte.
                Si specifica che la richiesta di mettersi in sitout, inviata dal client deve
                arrivare al server prima che il server abbia già distribuito le carte. Se nessun
                giocatore di punta decidesse di partecipare al banco dopo 3 minuti di inattività
                di gioco tutti i giocatori in sitout saranno fatti alzare dal tavolo.
            </p>
            <p className="paragraph">
                Il giocatore che decida di partecipare al banco potrà scegliere di puntare un
                importo compreso tra la puntata minima e la puntata massima oppure andare "All
                In" nel caso abbia a disposizione un ammontare di denaro inferiore alla puntata
                minima
            </p>
            <p className="paragraph"><strong>Pagamento delle vincite:</strong></p>
            <p className="paragraph">
                Il giocatore di punta incassa subito la vincita prelevandola dal piatto,
                previa detrazione della percentuale di competenza del Concessionario.
            </p>
            <p className="paragraph">
                In caso di vittoria del banco la somma puntata dal giocatore di punta viene
                aggiunta al piatto al netto della percentuale di spettanza del concessionario.
                Il banco incassa il piatto residuo dopo aver giocato con l'ultimo giocatore di
                punta.
            </p>
            <p className="paragraph">&nbsp;</p>
            <h2 className="titolo2">Regole specifiche per la variante "classica"</h2>
            <p className="paragraph"><strong>Il banco si forma nel seguente modo:</strong></p>
            <p className="paragraph">
                A condizioni normali il banco minimo è uguale a: banco minimo unitario *
                numero di giocatori. Nel caso in cui il banchiere non disponga di sufficiente
                credito per rispettare quanto previsto al punto precedente il banchiere va "All
                in" e la puntata massima è pari all'importo a disposizione del banco diviso per
                il numero dei giocatori; nel caso in cui dalla divisione risultassero dei resti
                questi andranno sulla puntata del primo giocatore.
            </p>
            <p className="paragraph">Al termine del banco vengono pagate vincite e perdite. </p>
            <p className="paragraph"><strong>I giocatori di punta:</strong></p>
            <p className="paragraph">
                Può partecipare al banco solo chi è già presente all'inizio del banco
                medesimo.Il giocatore non ha mai l'obbligo a giocare di punta; tuttavia, poiché
                le carte vengono automaticamente distribuite a tutti i giocatori non in sitout,
                per non giocare di punta è necessario mettersi in sitout entro la fine della
                mano precedente. Si specifica che la richiesta di mettersi in sitout, inviata
                dal client deve arrivare al server prima che il server abbia già concluso la
                mano. Se nessun giocatore di punta decidesse di partecipare al banco dopo 3
                minuti di inattività di gioco tutti i giocatori in sitout saranno fatti alzare
                dal tavolo. Il giocatore che decida di partecipare al banco potrà scegliere di
                puntare un importo compreso tra la puntata minima e la puntata massima oppure
                andare "All In" nel caso abbia a disposizione un ammontare di denaro inferiore
                alla puntata minima.
            </p>
            <p className="paragraph"><strong>Pagamento delle vincite:</strong></p>
            <p className="paragraph">
                Il giocatore di punta incassa subito la vincita prelevandola dal piatto,
                previa detrazione della percentuale di competenza del Concessionario.
            </p>
            <p className="paragraph">
                Il banco paga l'eventuale perdita complessiva o incassa l'eventuale vincita
                al netto della percentuale di spettanza del concessionario, tale percentuale è
                calcolata sulla differenza tra quanto vinto e quanto perso. Se questa differenza
                è negativa non viene trattenuta alcuna percentuale.
            </p>

        </ContentTabs>
    );
};
