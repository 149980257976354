import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { SxProps, TableCell, TableRow, styled } from '@mui/material';
import { CashTable, Game, GameCode, GameHelper, Sound, SoundManager, User, cashTableActions, selectActiveGame, tournamentActions } from '@quanticogames/gameclient-core';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import ButtonComponent from 'shared/components/button';
import StarIconActive from 'assets/icons/StarIconActive';
import StarIcon from 'assets/icons/StarIcon';
import { BADGES_COLLECTION } from 'constants/badges';
import { FlexBox } from 'shared/Flex';
import { StateLabel } from 'pages/tournament/component/tournament-state';
import UserGroup from 'assets/icons/UserGroup';
import { useIsMobileLandscape } from 'hooks/useIsMobileLandscape';

type CashGameTableItemMobileProps = {
  data?: (CashTable & { cost: number }) | null;
  onSelect?: (...agrs: any[]) => void | any;
  index: number;
  //   onAction: (action: string, id: number, index?: number) => void;
};

export const CashGameTableItemMobile = ({ data, onSelect, index }: CashGameTableItemMobileProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const game: Game = useSelector(selectActiveGame);
  const isMobile = GameHelper.isMobile();
  const isMobileLandscape = useIsMobileLandscape();
  const id = data?.id;
  const [favoriteList, setFavoriteList] = useState<number[]>(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    return storedFavorites ? JSON.parse(storedFavorites) : [];
  });

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    if (storedFavorites) {
      setFavoriteList(JSON.parse(storedFavorites));
    }
  }, []);

  const handleActionFavourite = (id: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const isFavorited = favoriteList.includes(id);
    if (isFavorited) {
      const updatedFavorites = favoriteList.filter((itemId) => itemId !== id);
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favoriteList, id];
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    }
  };

  const renderFavoriteIcon = (id: number) => {
    const isFavorited = favoriteList.includes(id);
    return isFavorited ? <StarIconActive /> : <StarIcon />;
  };

  const handleTournamentsDetail = () => {
    SoundManager.play(Sound.clickButton);
    dispatch(tournamentActions.tournamentsDetail(id));
    onSelect(data.id, index);
  };

  const handleOpenTable = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
      e.stopPropagation();
      if (!game) return;
      dispatch(
        cashTableActions.openTable({
          id,
          gameCode: game.code,
        })
      );
    },
    [game]
  );

  return (
    <FlexBox alignItems="center" className="tournament-mobile-item" onClick={handleTournamentsDetail}>
      <div className="tournament-mobile-favourite" onClick={(e: any) => handleActionFavourite(data.id, e)}>
        {renderFavoriteIcon(data.id)}
      </div>
      <FlexBox gap={isMobileLandscape ? 15 : 6}
        flexDirection={isMobileLandscape ? 'row' : 'column'}
        alignItems={isMobileLandscape ? 'center' : 'flex-start'}
        width="100%" className={`tournament-mobile-content flex-1 ${isMobileLandscape ? 'tournament-mobile-content-landscape' : ''}`}>
        {isMobileLandscape ? (
          <>
            <FlexBox flexDirection="column" className="flex-1" gap={3}>
              <div className="tournament-des">
                <div className="tournament-des-content">
                  <p className="tournament-des-title">
                    {data.title} - Cash
                  </p>
                </div>
              </div>

              <FlexBox gap={10} alignItems="center" className="tournament-mobile-line">
                <div className="tournament-mobile-badge">
                  <div className="badge-list">{BADGES_COLLECTION['Cash_Table']?.icon}</div>
                </div>
                <FlexBox alignItems="center" gap={5} className="tournament-mobile-player">
                  <UserGroup />
                  {`${data.activeCount}/${data.numberOfSeats}`}
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flexDirection="column" width="120px" className="tournament-mobile-line">
              <div className="tournament-mobile-code">
                <span className="label">{t('Cost')}: </span>
                {`€ ${formatMoney(convertCentToEuro(data.invitation))}`}
              </div>
            </FlexBox>

            <FlexBox flexDirection="column" width="120px">
              <ButtonComponent
                onClick={(e) => handleOpenTable(e, data.id)}
                variant="outlined"
                title={t('Play')}
                className="lobby-button"
                rouned="large"
                size="large"
                fullWidth
              />
            </FlexBox>
          </>
        ) : (
          <>
            <FlexBox
              alignItems="center"
              width="100%"
              gap={10}
              justifyContent="space-between"
              className="tournament-mobile-line"
            >
              <div className="tournament-des">
                <div className="tournament-des-content">
                  <p className="tournament-des-title">
                    {data.title} - Cash
                  </p>
                </div>
              </div>

              <ButtonComponent
                onClick={(e) => handleOpenTable(e, data.id)}
                variant="outlined"
                title={t('Play')}
                className="lobby-button"
                rouned="large"
                size="large"
                fullWidth
              />
            </FlexBox>
            <FlexBox alignItems="center" width="100%" justifyContent="space-between" className="tournament-mobile-line">
              <div className="tournament-mobile-code">
                <span className="label">{t('Cost')}: </span>
                {`€ ${formatMoney(convertCentToEuro(game.code === GameCode.SetteEMezzo ? data?.cost : data.invitation))}`}
              </div>
              <div className="tournament-mobile-badge">
                <div className="badge-list">{BADGES_COLLECTION['Cash_Table']?.icon}</div>
              </div>
              <FlexBox alignItems="center" gap={5} className="tournament-mobile-player">
                <UserGroup />
                {`${data.activeCount}/${data.numberOfSeats}`}
              </FlexBox>
            </FlexBox>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};
