import React from 'react';
import { styled, SxProps } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ClassificheInterface } from 'types/classifiche-type';

type ClassificheItemProps = {
  data: ClassificheInterface;
};

type CELL = {
  name: string;
  render?: (data: ClassificheInterface, cell: string, t?: TFunction) => string | JSX.Element;
  sx?: SxProps;
};

const StyledTableRow = styled('tr')``;

const StyledTableCell = styled('td')``;

export const ClassificheItem: React.FC<ClassificheItemProps> = ({
  data
}): JSX.Element => {
  const { t } = useTranslation();

  function formatNumber(number) {
    return new Intl.NumberFormat().format(number);
  }
  
  const CELLS: CELL[] = [
    {
      name: 'position',
      sx: {textAlign: 'center', width: 70},
      render: (data, cell, t) => {
        return (
          <span className="leader-board-no">{data.position}</span>
        );
      },
    },
    {
      name: 'userName',
      sx: {textAlign: 'center', width:'auto'},
      render: (data, cell, t) => {
        return (
          <span className="leader-board-userName">{data.userName}</span>
        );
      },
    },
    
    {
      name: 'point',
      sx: {textAlign: 'center', width: 120},
      render: (data, cell, t) => {
        return (
          <span className="leader-board-punti">{formatNumber(data.point)}</span>
        );
      },
    },
    {
      name: 'play',
      sx: {textAlign: 'center', width: 120},
      render: (data, cell, t) => {
        return (
            <span className="">{formatNumber(data.play)}</span>
        );
      },
    },
    {
      name: 'won',
      sx: {textAlign: 'center', width: 120},
      render: (data, cell, t): JSX.Element => {
        return <span className="leader-board-vinte">{formatNumber(data.won)}</span>;
      },
    },
    {
      name: 'avgPoint',
      sx: {textAlign: 'center', width: 120},
      render: (data, cell, t): JSX.Element => (
        <span className="leader-board-mediaPunti">{formatNumber(data.avgPoint)}</span>
      ),
    },
    {
      name: 'wonPercentage',
      sx: {textAlign: 'center', width: 120},
      render: (data, cell, t): JSX.Element => (
        <span className="leader-board-percentVinte">{formatNumber(data.wonPercentage)}%</span>
      ),
    },
  ];

  return (
    <>
      <StyledTableRow>
        {CELLS.map((cell) => (
          <StyledTableCell key={cell.name} sx={cell.sx}>
            {cell.render ? cell.render(data, cell.name, t) : data[cell.name]}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </>
  );
};
