import { LIST_GAME } from "constants/common";

export const detectGameTranslationNamespace = (gameCode: string) => {
    // scopa variant use the same key
    switch (gameCode) {
        case LIST_GAME.ASSOPIGLIATUTTO:
        case LIST_GAME.BRISCOLA:
        case LIST_GAME.CIRULLA:
        case LIST_GAME.SCOPA:
            return "scopa";
        case LIST_GAME.BESTIA:
            return "bestia";
        case LIST_GAME.BURRACO:
            return "burraco";
        case LIST_GAME.ITALIANPOKER:
            return "itPoker";
        case LIST_GAME.PEPPA:
            return "peppa";
        case LIST_GAME.SCALA40:
            return "scala40";
        case LIST_GAME.SCOPONE:
            return "scopone";
        case LIST_GAME.SETTEEMEZZO:
            return "setteEMezzo";
        case LIST_GAME.SOLITARIO:
            return "solitario";
        case LIST_GAME.TELESINA:
            return "telesina";
        case LIST_GAME.TRESETTE:
            return "tresette";
        default:
            return "";
    }
}

export const getPrefixForGame = (gameCode: string) => {
    switch (gameCode) {
        case LIST_GAME.ASSOPIGLIATUTTO:
            return 'di';
        case LIST_GAME.SCOPONE:
            return 'dello';
        case LIST_GAME.SCALA40:
        case LIST_GAME.BRISCOLA:
            return 'della';
        case LIST_GAME.SETTEEMEZZO:
        case LIST_GAME.SOLITARIO:
        case LIST_GAME.TRESETTE:
        case LIST_GAME.BURRACO:
        case LIST_GAME.ITALIANPOKER:
            return 'del';
        case LIST_GAME.SCOPA:
        case LIST_GAME.CIRULLA:
        case LIST_GAME.BESTIA:
        case LIST_GAME.TELESINA:
        case LIST_GAME.PEPPA:
            return 'della';
        default:
            return 'di';
    }
}

export const getGameNameByCode = (gameCode: string) => {
    switch (gameCode) {
        case LIST_GAME.ASSOPIGLIATUTTO:
            return "Asso Piglia Tutto";
        case LIST_GAME.BESTIA:
            return "Bestia";
        case LIST_GAME.BRISCOLA:
            return "Briscola";
        case LIST_GAME.BURRACO:
            return "Burraco";
        case LIST_GAME.CIRULLA:
            return "Cirulla";
        case LIST_GAME.ITALIANPOKER:
            return "Poker 5 carte";
        case LIST_GAME.PEPPA:
            return "Peppa";
        case LIST_GAME.SCALA40:
            return "Scala 40";
        case LIST_GAME.SCOPA:
            return "Scopa";
        case LIST_GAME.SCOPONE:
            return "Scopone";
        case LIST_GAME.SETTEEMEZZO:
            return "Sette E Mezzo";
        case LIST_GAME.SOLITARIO:
            return "Solitario";
        case LIST_GAME.TELESINA:
            return "Telesina";
        case LIST_GAME.TRESETTE:
            return "Tresette";
        default:
            return "";
    }
}
