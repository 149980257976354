import moment from 'moment';
import { useEffect, useState } from 'react';
import TabsRegulation from 'shared/Regulation/components/TabsRegulation';
import ScheduledList from 'shared/Regulation/scheduled';
import { getWeekSchedule } from 'shared/Regulation/scheduled/service';

const InformationScheduled = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        const res = await getWeekSchedule();
        if (res.length) setData(res.sort((a: any, b: any) => moment(a.startingTime).diff(b.startingTime)));
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const items = [
        {
            key: 'Total',
            label: `TUTTI`,
            children: <ScheduledList data={data} loading={loading} />,
        },
        {
            key: 'AssoPigliaTutto',
            label: `ASSO PIGLIA TUTTO`,
            children: <ScheduledList data={data} loading={loading} gameCode='AssoPigliaTutto' gameName='Asso Piglia Tutto' />,
        },
        {
            key: 'Bestia',
            label: `BESTIA`,
            children: <ScheduledList data={data} loading={loading} gameCode='Bestia' gameName='Bestia' />,
        },
        {
            key: 'Briscola',
            label: `BRISCOLA`,
            children: <ScheduledList data={data} loading={loading} gameCode='Briscola' gameName='Briscola' />,
        },
        {
            key: 'Burraco',
            label: `BURRACO`,
            children: <ScheduledList data={data} loading={loading} gameCode='Burraco' gameName='Burraco' />,
        },
        {
            key: 'Cirulla',
            label: `CIRULLA`,
            children: <ScheduledList data={data} loading={loading} gameCode='Cirulla' gameName='Cirulla' />,
        },
        {
            key: 'ItalianPoker',
            label: `POKER 5 CARTE`,
            children: <ScheduledList data={data} loading={loading} gameCode='ItalianPoker' gameName='Italian Poker' />,
        },
        {
            key: 'Peppa',
            label: `PEPPA`,
            children: <ScheduledList data={data} loading={loading} gameCode='Peppa' gameName='Peppa' />,
        },
        {
            key: 'Scala40',
            label: `SCALA 40`,
            children: <ScheduledList data={data} loading={loading} gameCode='Scala40' gameName='Scala 40' />,
        },
        {
            key: 'Scopa',
            label: `SCOPA`,
            children: <ScheduledList data={data} loading={loading} gameCode='Scopa' gameName='Scopa' />,
        },
        {
            key: 'SetteEMezzo',
            label: `SETTE E MEZZO`,
            children: <ScheduledList data={data} loading={loading} gameCode='SetteEMezzo' gameName='Sette E Mezzo' />,
        },
        {
            key: 'Telesina',
            label: `TELESINA`,
            children: <ScheduledList data={data} loading={loading} gameCode='Telesina' gameName='Telesina' />,
        },
        {
            key: 'Tresette',
            label: `TRESETTE`,
            children: <ScheduledList data={data} loading={loading} gameCode='Tresette' gameName='Tresette' />,
        },
        {
            key: 'Scopone',
            label: `SCOPONE`,
            children: <ScheduledList data={data} loading={loading} gameCode='Scopone' gameName='Scopone' />,
        },
        {
            key: 'Solitario',
            label: `SOLITARIO`,
            children: <ScheduledList data={data} loading={loading} gameCode='Solitario' gameName='Solitario' />,
        },
    ];

    useEffect(() => {
        document.title = "PALINSESTO";
    }, []);

    return (
        <TabsRegulation items={items} />
    )
}
export default InformationScheduled;
