import { styled, IconButton, SxProps, Theme } from '@mui/material';
import React from 'react';
import { CheckIconSvg } from '../../../assets/icons/CheckIconSvg';

const StyledIconButton = styled(IconButton)(() => ({
  width: '24px',
  height: '24px',
  padding: 0,
}));

type ButtonType = {
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const CheckButton = ({ sx, onClick }: ButtonType) => (
  <StyledIconButton sx={sx} onClick={onClick}>
    <CheckIconSvg />
  </StyledIconButton>
);
