import './index.scss';
import {
  Sound,
  SoundManager,
  Tournament,
  tournamentActions,
  gameActions,
  selectSessionInfo,
  SessionInfo,
  selectGameState,
  selectRecommendTours,
  selectRecommendTourFullSubcribe,
  Game,
  selectGames,
} from '@quanticogames/gameclient-core';
import SliderTableItem from 'shared/components/slider-table/component/slider-table-item';
import { Container, keyframes } from '@mui/material';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useEffect, useState } from 'react';
import { SideDrawer } from 'shared/components/side-drawer';
import useDrawerToggle from 'hooks/useDrawerToggle';
import { DRAWER_POSITIONING } from 'types/rightSideDrawer';
import { useDispatch, useSelector } from 'react-redux';
import ModalConfirmSubscribe from 'pages/tournament/modal/modal-confirm-subscribe';
import { useTranslation } from 'react-i18next';
import { ExpandRight } from 'assets/icons/ExpandRight';
import TournamentDetail from 'pages/tournament/component/detail/tournament-info-container';
import { FlexBox } from 'shared/Flex';
import { mapStyleGame } from 'utils/styleGame';
import styled from '@emotion/styled';

type SliderTableProps = {
  id?: string;
};

const SliderTable = ({ id }: SliderTableProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { drawerAnchor, toggleDrawer } = useDrawerToggle();
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
  const gameState = useSelector(selectGameState);
  const recommendTours: Tournament[] = useSelector(selectRecommendTours);
  const recommendTourFullSubcribe: Tournament = useSelector(selectRecommendTourFullSubcribe);
  const [recommendToursShow, setRecommendToursShow] = useState<Tournament[]>([]);
  const [tourId, setTourId] = useState<number>();
  const [infoTournamentSubcribe, setInfoTournamentSubcribe] = useState({});
  const [isOpenModalConfirmSubscribe, setIsOpenModalConfirmSubscribe] = useState<boolean>(false);
  const [tokenCode, setGetTokenCode] = useState<string>('');
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const games: Game[] = useSelector(selectGames);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  useEffect(() => {
    if (gameState?.connected) dispatch(gameActions.joinTournamentRecommend({}));
  }, [gameState?.connected]);

  const confirmToken = (tokenCode) => {
    setGetTokenCode(tokenCode);
  };

  const handleOpenRightDrawer = () => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
  };

  const handleCloseRightDrawer = () => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
  };


  useEffect(() => {
    if (sessionInfo?.sessionId) {
      dispatch(tournamentActions.loadRecommendTournaments({}));
    };
  }, [sessionInfo?.sessionId]);

  const handleOpenDetail = async (id: number) => {
    setTourId(id);
    SoundManager.play(Sound.clickButton);
    handleOpenRightDrawer();
  };

  const gameCode = games?.find(g => g.id === recommendTourFullSubcribe?.gameId)?.code

  const onAction = (action: string, id: number) => {
    switch (action) {
      case 'subscribe':
        return subscribe(id);
      case 'unsubscribe':
        return unsubscribe(id);
      case 'play':
        return play(id);
    }
  };

  const play = async (id: number) => {
    SoundManager.play(Sound.clickButton);
    localStorage.setItem('lobbyUrl', window.location.pathname);
    dispatch(tournamentActions.play(id));
  };

  const unsubscribe = (tournamentId: number) => {
    SoundManager.play(Sound.clickUnsubscribe);
    dispatch(tournamentActions.unsubscribeTournament({ tournamentId }));
  };

  const subscribe = (data) => {
    SoundManager.play(Sound.clickSubscribe);
    setInfoTournamentSubcribe(data);
    const tournamentId = data?.id;
    if (data?.cost > 0 || (data?.cost === 0 && data?.hasPassword === true)) {
      setTimeout(() => {
        setIsOpenModalConfirmSubscribe(true);
      }, 100);
    } else {
      dispatch(tournamentActions.subscribeTournament({ tournamentId, tokenCode: tokenCode || null }));
    }
  };

  useEffect(() => {
    const index = recommendToursShow.findIndex((item) => item.id === recommendTourFullSubcribe?.id);
    if (index !== -1) {
      setShouldAnimate(true);
      setTimeout(() => {
        setRecommendToursShow(recommendTours);
      }, 500)
    } else {
      setRecommendToursShow(recommendTours);
    }
  }, [recommendTours])

  useEffect(() => {
    if (recommendTourFullSubcribe?.id) {
      const timer = setTimeout(() => {
        const targetSlide = document.querySelector(`.swiper-slide:nth-child(${recommendToursShow.findIndex(item => item.id === recommendTourFullSubcribe.id) + 1})`);

        if (targetSlide) {
          const rect = targetSlide.getBoundingClientRect();

          setPosition({
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
          });
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [recommendTourFullSubcribe, recommendToursShow]);


  const slideAndFadeOut = keyframes`
    0% {
      transform: translateY(${position?.top}px);
      opacity: 1;
    }
    50% {
      transform: translateY(${position?.top + 75}px); 
      opacity: 0.5;
    }
    100% {
      transform: translateY(${position?.top + 150}px);
      opacity: 0;
    }
`;

  const AnimatedFlexBox = styled(FlexBox)`
    animation-delay: 0.5s;
    animation-name: ${slideAndFadeOut};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
`;

  useEffect(() => {
    if (recommendTourFullSubcribe?.id) {
      setShouldAnimate(true);
      const timer = setTimeout(() => {
        setShouldAnimate(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [recommendTourFullSubcribe]);

  return (
    <div className="slider-table-container" id={id}>
      {isOpenModalConfirmSubscribe && (
        <ModalConfirmSubscribe
          isOpen={isOpenModalConfirmSubscribe}
          data={infoTournamentSubcribe}
          onCloseModal={() => setIsOpenModalConfirmSubscribe(false)}
          confirmToken={confirmToken}
        />
      )}

      {shouldAnimate && (
        <AnimatedFlexBox
          className={`tournament-animation slider-${gameCode}`}
          style={{
            top: position.top,
            left: position.left,
            width: position.width,
            height: position.height,
            backgroundColor: mapStyleGame(gameCode)?.background,
            color: mapStyleGame(gameCode)?.color,
          }}
          gap={10}
          alignItems="center"
          justifyContent="center"
        >
          <img src='/images/common/accept-icon.svg' className='w-full' />
          Di partenza
        </AnimatedFlexBox>
      )}

      <Container sx={{ display: 'flex', alignItems: 'flex-end', height: '100%' }}>
        <Swiper
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={2.3}
          speed={1000}
          autoplay={{ delay: 7000 }}
          loop={false}
          initialSlide={0}
          slidesPerGroup={1}
          navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
          breakpoints={{
            600: {
              slidesPerView: 3.3,
            },
            768: {
              slidesPerView: 4.2,
            },
            1024: {
              slidesPerView: 4.3,
            },
            1170: {
              slidesPerView: 5.3,
            },
          }}
        >
          {recommendToursShow?.length > 0 &&
            recommendToursShow.map((item, index) => (
              <SwiperSlide
                key={index}
                onClick={() => handleOpenDetail(item?.id)}
                className={`${item.id === recommendTourFullSubcribe?.id ? 'tournament-full' : ''}`}
              >
                <SliderTableItem game={item} />
              </SwiperSlide>
            ))}
          <div className="swiper-button-next">
            <ExpandRight />
          </div>
          <div className="swiper-button-prev">
            <ExpandRight />
          </div>
        </Swiper>

        <SideDrawer
          anchor="right"
          open={drawerAnchor.right}
          onClose={handleCloseRightDrawer}
          showHeader={true}
          titleHeader={t('Tournament information')}
          showButtonBack={false}
          className="tournament-info-drawer"
        >
          <TournamentDetail onClose={handleCloseRightDrawer} onAction={onAction} id={tourId} />
        </SideDrawer>
      </Container>
    </div>
  );
};
export default SliderTable;
