export type HeadCell = {
  id: string;
  label?: string;
  sortable?: boolean;
  align?: "center" | "left" | "inherit" | "right" | "justify";
  filter?: boolean
};

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}
