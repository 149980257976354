import CoinIcon from 'assets/images/icons/coin.png';
import AddIcon from 'assets/images/icons/add.png';
import { FlexBox } from 'shared/Flex';
import { Image } from 'shared/Image';
import { BalanceInfo, selectBalanceInfo } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import ButtonComponent from 'shared/components/button';
import { Plus } from 'assets/icons/Plus';

const UserCurrency = () => {
  const balanceInfo = useSelector(selectBalanceInfo) as BalanceInfo;
  const totalBalance = formatMoney(convertCentToEuro(Number(balanceInfo?.balance) + balanceInfo?.bonusBalance));
  return (
    <FlexBox className="user-currency-container" justifyContent={'center'} alignItems={'center'}>
      <FlexBox className='currency' justifyContent={'space-between'} alignItems="center">
        <Image src={CoinIcon} alt="money" width={19} height={19} />
        <p className='balance'>€ {totalBalance}</p>
      </FlexBox>
      <ButtonComponent
        size="small"
        icon={<Plus />}
        variant="text"
        className="header-balance-btn"
        sx={{
          padding: 0,
          minWidth: 0
        }}
      />
    </FlexBox>
  );
};

export default UserCurrency;
