import { Box, styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Game, selectActiveGame } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));

const Image = styled('img')(() => ({
  width: '48px',
  height: '48px',
  borderRadius: '6px',
}));

export const GameInfoBlock = () => {
  const { t } = useTranslation();
  const game: Game = useSelector(selectActiveGame);
  return (
    <StyledBox>
      {game && (
        <>
          <Image src={`/images/common/logo/logo-game-${game.code || 'Scopa'}-no-bg.png`} alt={game.name} width={'48px'} height={'48px'} />
          <Box alignItems="center">
            <Typography className='font-raleway' variant="h2" color="#fff">
              {t(game.name)}
            </Typography>
            {/* <Typography variant="details" color="#fff">
              Some small description
            </Typography> */}
          </Box>
        </>
      )}
    </StyledBox>
  )
};
