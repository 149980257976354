import { useMemo } from "react";
import { Paper } from "@mui/material";
import TableComponent from "shared/components/tables";
import TableNoData from "shared/components/tables/component/table-nodata";
import ContentTabs from "../components/ContentTabs";
import { TableItem } from "./TableItem";
import { SCHEDULED_TOUR_HEAD_DATA, ScheduledTour } from "./type";
import "./index.scss";
import { t } from "i18next";
import Loading from "shared/components/loading";

type ScheduledListProps = {
  data?: ScheduledTour[];
  gameCode?: string;
  gameName?: string;
  loading?: boolean;
}

const ScheduledList = ({ data, gameCode, gameName, loading }: ScheduledListProps) => {
  const list = useMemo(() => {
    if (gameCode) return data.filter(item => item.gameCode === gameCode);
    return data;
  }, [data, gameCode]);

  const columns = useMemo(() => {
    if (gameCode) return SCHEDULED_TOUR_HEAD_DATA.slice(0, -1);
    return SCHEDULED_TOUR_HEAD_DATA;
  }, [gameCode]);

  return (
    <ContentTabs>
      <h2 className="titolomenu uppercase !border-none !min-h-[auto]">
        {gameCode ? `${gameName}: ` : t("All")} {t("Tournaments of the week")}
      </h2>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableComponent columns={columns} className="scheduled-table-container">
          {loading ? (
            <tr className="relative !bg-transparent">
              <td colSpan={columns.length} style={{ border: 'none', height: 300 }}>
                <Loading className="loading-tournament absolute w-full min-h-[300px]" />
              </td>
            </tr>
          ) : (
            <>
              {list.length > 0 ? list.map((item, index) => (
                <TableItem
                  key={`cassifiche-${index}`}
                  data={item}
                  gameCode={gameCode}
                />
              )) : <tr>
                <td colSpan={columns.length} style={{ border: "none" }}>
                  <TableNoData />
                </td>
              </tr>}
            </>

          )}
        </TableComponent>
      </Paper>
    </ContentTabs>
  )
}

export default ScheduledList;