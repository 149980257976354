import React from 'react';
import {
  Accordion,
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { FlexBox } from 'shared/Flex';

type FilterOption = {
  id: number;
  label: string;
  costRange?: { costFrom: number; costTo: number };
  objective?: string;
  objectiveValue?: number;
  matchType?: number;
  timeSpeed?: number;
  type?: string;
};

type FilterItemProps = {
  id: string | number;
  title: string;
  options: FilterOption[];
  values: number[];
  onChange?: (name) => void;
  onSelect?: () => void;
  data?: any,
  className?: string
};

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#0B0E11',
  lineHeight: '17px',
  [theme.breakpoints.up('md')]: {
    lineHeight: '24px',
  },
}));

const StyledCheckbox = styled(Checkbox)(() => ({}));

export const FilterItem: React.FC<FilterItemProps> = ({
  id,
  title,
  options,
  values,
  onChange,
  onSelect,
  className
}) => {
  const { t } = useTranslation();

  const handleChecked = (id) => {
    let newValues = [];
    const isChecked = values.includes(id);
    if (isChecked) {
      newValues = values.filter(item => item !== id)
    } else {
      newValues = [...values, id].sort((a, b) => a - b)
    }
    onChange(newValues);
  }

  return <Box className={`filter-item ${className || ''}`}>
  <Typography
    className="filter-item-label"
    sx={{
      fontSize: '12px',
      color: '#9EA8B4',
      fontWeight: 600,
    }}
  >
    {title}
  </Typography>
  <FlexBox className="filter-item-input" flexWrap="wrap">
    {options.map((option) => (
      <FormControlLabel
        key={`filter-checkbox-${title}-${option.id}`}
        label={<Label>{t(option.label)}</Label>}
        control={<StyledCheckbox />}
        onChange={() => handleChecked(option.id)}
        checked={values.includes(option.id)}
        onSelect={onSelect}
        className={`filter-item-label ${values.includes(option.id) ? 'checked' : ''}`}
      />
    ))}
  </FlexBox>
</Box>
};
