import { Box, List, ListItem, Stack, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectActiveTournament, Tournament } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { BADGES_COLLECTION } from 'constants/badges';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import moment from 'moment';
import { StateLabel } from 'pages/tournament/component/tournament-state';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
  height: 30,
  marginTop: 5,
}));

const ListTitle = styled('p')(() => ({}));

const ListValue = styled('p')(() => ({}));

export const InfoList: React.FC = () => {
  const { t } = useTranslation();
  const tournament: Tournament = useSelector(selectActiveTournament);
  const startingTime = tournament && tournament?.startingTime;
  
  const closeTime = tournament && tournament?.closingTime;
  const renderDateAnnounce = () => {
    return moment.utc(startingTime).local().format('DD/MM/YYYY HH:mm')
  };

  const getNameSpeed = () => {
    switch (tournament?.speed) {
      case 0:
        return t('Slow');
      case 1:
        return t('Normal');
      case 2:
        return t('Fast');
      case 3:
        return t('Turbo');
      default:
        return '';
    }
  };

  return (
    <List className="tournament-info-list">
      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Tournament ID')}</ListTitle>
        <ListValue className="tournament-info-value" id='tournament-info-id'>{tournament?.id}</ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Cost')}</ListTitle>
        <ListValue className="tournament-info-value">
          {tournament?.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(tournament?.cost || 0))}`}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Players')}</ListTitle>
        <ListValue className="tournament-info-value" id='tournament-info-players'>
          {tournament?.registered} / {tournament?.maxPlayers || 2}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('State')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-state">
          <StateLabel
            color={TOURNAMENT_STATES_TITLES[tournament?.state]?.color}
            background={TOURNAMENT_STATES_TITLES[tournament?.state]?.background}
          >
            {t(TOURNAMENT_STATES_TITLES[tournament?.state]?.title)}
          </StateLabel>
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Speed')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-speed">{getNameSpeed()}</ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Playing time')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-turn-time">
          {new String(Math.round((tournament?.playingTurnTime / 60) * 2) / 2 || 0)} {t('minutes')}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Match time')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-time">
          {new String(Math.round((tournament?.matchTime / 60) * 2) / 2 || 0)} {t('minutes')}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Badge')}</ListTitle>
        <Box className="tournament-info-value">
          <div className="badge-list">
            {tournament?.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
            {tournament?.structure === 'MT' && tournament?.matchType === 0 && tournament?.type !== 'Scheduled' ? (
              <div className="badge-icon">{`${tournament?.maxPlayers}P`}</div>
            ) : (
              <>{BADGES_COLLECTION[tournament?.matchType]?.icon}</>
            )}
            {tournament?.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
            {BADGES_COLLECTION[tournament?.type]?.icon}
          </div>
        </Box>
      </StyledListItem>

      {tournament?.type === 'Scheduled' && (
        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t('Start in')}</ListTitle>
          <ListValue className="tournament-info-value">{renderDateAnnounce()}</ListValue>
        </StyledListItem>
      )}
    </List>
  );
};
