import { Box, styled } from '@mui/material';

interface BoxOwnProps extends React.HTMLAttributes<HTMLDivElement> {
    justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    gap?: number;
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'stretch';
}

export const FlexBox = styled(Box)<BoxOwnProps>(({ theme, justifyContent, alignItems,  gap }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'center',
    alignItems: alignItems || 'flex-start',
    gap: gap || 0,
}));
