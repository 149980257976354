import React, { ReactNode } from 'react';
import { Box, Button, styled, SxProps, Theme, Typography } from '@mui/material';
import { COLORS } from '../../../constants/colors';

export const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  color: ${COLORS.text_color};
  width: 24px;
  height: 24px;
  margin-right: 16px;
  min-width: 24px;
  padding: 0;

  .MuiTypography-root {
    margin-left: 16px;
  }
`;

type ButtonType = {
  title?: string | number;
  variant?: 'contained' | 'text' | 'outlined';
  sx?: SxProps<Theme>;
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const BackButton: React.FC<ButtonType> = ({
  title,
  variant = 'text',
  sx,
  icon,
  onClick,
}: ButtonType) => (
  <Box sx={{ display: 'flex' }} className="button-text">
    <StyledButton sx={sx} variant={variant} onClick={onClick}>
      {icon}
    </StyledButton>
    <Typography variant="h4">{title}</Typography>
  </Box>
);
