import { TournamentFilter, tournamentActions } from '@quanticogames/gameclient-core';
import { PATH_ROUTES } from 'constants/routes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CASH_TABLE_FILTER,
  TOURNAMENT_FILTERS,
  TOURNAMENT_ITALIAN_POKER_FILTERS,
  TOURNAMENT_STATUS_FILTER,
} from 'shared/Filters/FilterConstants';
import { FilterItem } from 'shared/Filters/FilterItem';
import ModalComponent from 'shared/components/modal';

type FilterMobileCashTableProps = {
  open: boolean;
  onClose: () => void;
};

const FilterMobileCashTable = ({ open, onClose }: FilterMobileCashTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState<any>({});
  const [initialFilterList, setInitialFilterList] = useState<any>({});
  const cashTableFilter = CASH_TABLE_FILTER

  const filterData = (name: string, values: any[]) => {
    const updatedFilterOptions: any = { ...filterList };
    const options = cashTableFilter[name].options.filter(o => values.includes(o.id));

    switch (name) {
      case 'cost':
        updatedFilterOptions.costLabels = options.map(o => o.label);
        updatedFilterOptions.costRanges = options.map(o => o.costRange);
        break;

      case 'maxPlayers':
        updatedFilterOptions.maxPlayersLabels = options.map(o => o.label);
        updatedFilterOptions.maxPlayers = options.map(o => o.maxPlayers);
        break;
    }

    setFilterList(updatedFilterOptions);
  };

 

  const handleApply = () => {
    dispatch(tournamentActions.updateFilters(filterList));
    onClose();
  };

  useEffect(() => {
    setInitialFilterList(filterList);
  }, [open]);

  const onCancelFilter = () => {
    setFilterList(initialFilterList);
    onClose();
  };

  const calculateFilterValue = (name: string) => {
    const labels: string[] = filterList[`${name}Labels`] || [];
    const options = cashTableFilter[name].options.filter((o) => labels.includes(o.label));
    return options.map((o) => o.id); // return id list
  };

  return (
    <ModalComponent
      open={open}
      className="filter-mobile-modal"
      title="Filter"
      onClose={onCancelFilter}
      typeModal="filter"
      onCancel={onCancelFilter}
      onOk={handleApply}
      textOk={t('Apply')}
      textCancel={t('Cancel')}
      showFooter={true}
      size="small"
    >
      <div className="filter-mobile-list">
        <div className="filter-mobile-item">
          <p className="filter-mobile-label">{t('Description')}</p>
          {(Object.entries(cashTableFilter) || []).map(([name, filter]) => {
            return (
              <FilterItem
                key={`filter-item-${name}`}
                id={name}
                title={t(filter.title)}
                options={filter.options}
                values={calculateFilterValue(name)}
                onChange={(values) => filterData(name, values)}
              />
            );
          })}
        </div>
      </div>
    </ModalComponent>
  );
};
export default FilterMobileCashTable;
