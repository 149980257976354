import { Box, Collapse, TableCell, TableRow, styled } from "@mui/material";
import ProfileCollapseDetail from "layouts/profiles/components/profile-collapse/profile-collapse-detail";
import React, { useState } from "react";
import { FlexBox } from "shared/Flex";
import TableComponent from "shared/components/tables";
import WarningIcon from '@mui/icons-material/Warning';
import IconLoading from "assets/icons/Loading";
import ButtonComponent from "shared/components/button";
import { useTranslation } from "react-i18next";

type ProfileCollapseProps = {
    columns: any[],
    data?: any[],
    totalItem?: number,
    onClickContent?: () => void,
    isLoading?: boolean,
    onLoadMore?: ()=> void
}

const LoadingBox = styled(FlexBox)(() => ({
    height: '200px',
    width: '100%',
    alignItems:'center',
    justifyContent:'center'
  }))

const TdCollapse = styled(TableCell)(() => ({
    padding: '10px 12px !important',
    background: '#E9ECED'
  }))
  
  export const NoData = styled(FlexBox)(() => ({
    justifyContent: 'center',
    fontWeight: 500,
    width: '100%',
    height: 200,
    alignItems:'center',
    flexDirection: 'column',
    gap: '10px',
  }))

const ProfileCollapse = ({columns, data, onClickContent, totalItem, isLoading, onLoadMore}: ProfileCollapseProps) => {
    const [openRowIndex, setOpenRowIndex] = useState<number | null>(null);
    const { t } = useTranslation();

    const handleRowClick = (index: number) => {
        setOpenRowIndex(openRowIndex === index ? null : index);
    };
    
    return(
        <>
            <TableComponent columns={columns} className="profile-collapse-table">
                {isLoading ? (
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            <LoadingBox className="loading-box"><IconLoading color="currentColor" /></LoadingBox>
                        </TableCell>
                    </TableRow>
                ) : (<>
                    {data.length > 0 ? (
                        data.map((row, index) => (
                        <React.Fragment key={index}>
                            <TableRow onClick={() => handleRowClick(index)} style={{cursor: 'pointer'}}>
                                {columns?.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id} className={column.classNames}>
                                            {column.render ? column.render(value) : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            {openRowIndex === index && (
                            <TableRow className="table-row-collapse">
                                <TdCollapse colSpan={columns.length}>
                                    <Collapse in={true}>
                                        <ProfileCollapseDetail data={row?.detail} onClickContent={onClickContent} />
                                    </Collapse>
                                </TdCollapse>
                            </TableRow>
                            )}
                        </React.Fragment>
                        ))
                    ) : (
                        <TableRow>
                        <TableCell colSpan={columns.length}>
                            <NoData>
                            <WarningIcon />
                            <span>{t('No data')}</span>
                            </NoData>
                        </TableCell>
                        </TableRow>
                    )}</>
                )}
            </TableComponent>
            {(totalItem !== data.length && data.length > 0) && <Box sx={{ p: '13px', textAlign: 'center' }}>
                { isLoading ? (
                    <span className='flex justify-center'><IconLoading /></span>
                ) : (
                    <ButtonComponent
                        title={t('Show more')}
                        variant='contained'
                        fullWidth
                        onClick={onLoadMore}
                        className="show-more"
                    />
                )
                }
            </Box>}
        </>
    )
}
export default ProfileCollapse