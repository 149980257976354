type SectionSidebarProps = {
    children: React.ReactNode;
    className?: string
};

const SectionSidebar = ({ children, className }: SectionSidebarProps) => {
    return (
        <div className={`sidebar-item py-[8px] ${className}`} style={{ borderBottom: '1px solid #F0F1F3' }}>
            {children}
        </div>
    );
};
export default SectionSidebar;
