import { Avatar } from "@mui/material";
import { Game, RankingUser, SessionInfo, User, axiosClient, selectActiveGame, selectCurrentUser, selectSessionInfo, userApi } from "@quanticogames/gameclient-core";
import useDrawerToggle from "hooks/useDrawerToggle";
import Profile from "layouts/profiles";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SideDrawer } from "shared/components/side-drawer";
import { DRAWER_POSITIONING } from "types/rightSideDrawer";
import Loading from 'shared/components/loading';

const HeaderUser = () => {
    const { drawerAnchor, toggleDrawer } = useDrawerToggle();
    const currentUser: User = useSelector(selectCurrentUser);
    const game: Game = useSelector(selectActiveGame);
    const [rankingUser, setRankingUser] = useState<RankingUser>();
    const [loading, setLoading] = useState<boolean>(true);
    const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;

    const fetchData = async (gameId) => {
        try {
            const response = await userApi.getRankingUser(gameId);
            setRankingUser(response);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (sessionInfo?.sessionId) {
            fetchData(game?.id ?? 1);
        }
    }, [sessionInfo?.sessionId]);
    
    const handleOpenRightDrawer = () => {
        toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
    }; 

    const handleCloseRightDrawer = () => {
        toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
      };

    return (
        <>
            <div id="header-user" className="header-user" onClick={handleOpenRightDrawer}>
                <Avatar
                    className={`header-avartar ranking-vip-${rankingUser?.vipLevel}`}
                    alt={currentUser?.userName}
                    src={`/images/avatar/${currentUser?.avatar}` || currentUser?.userName}
                />
            </div>
            <SideDrawer
                open={drawerAnchor.right}
                isBackdropVisible={false}
                onClose={handleCloseRightDrawer}
                titleHeader='User Infomation'
                showHeader={true}
                showButtonBack={false}
                anchor="right"
                className="profile-container-drawer"
            >
                {loading ? <Loading className="loading-tournament absolute w-full min-h-[300px]" /> : (
                    <Profile onClose={handleCloseRightDrawer} rankingUser={rankingUser}/>
                )}
            </SideDrawer>
        </>
    )
}
export default HeaderUser