import {
  createTheme as createMuiTheme,
  ThemeOptions,
} from '@mui/material/styles';
import typography from './typography';
import breakpoints from './breakpoints';
import { palette } from './palette';

const createTheme = (props: ThemeOptions) =>
  createMuiTheme({
    spacing: 4,
    breakpoints,
    typography,
    palette,
    ...props,
  });

// Create a theme instance.
const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          details: 'div',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          padding: '12px 16px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root' : {
            boxShadow: 'none'
          },
          '&.Mui-disabled': {
            backgroundColor: '#E9ECED',
            color: '#8E8E8E',
            border: '1px solid #E1E1E1',
            pointerEvents: 'none',
            '&:hover': {
              backgroundColor: 'inherit !important', 
            },
          },
          '&.MuiButton-colorInherit': {
            color: '#8E8E8E',
            border: '1px solid #8E8E8E'
          }
        },
      },
    },
  },
});

export default theme;
