import { Container } from '@mui/material';
import { GameHelper, darkMode, selectCasino } from '@quanticogames/gameclient-core';
import clsx from 'clsx';
import { SKIN } from 'constants/skin';
import LayoutPage from 'layouts/layout-page';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { FlexBox } from 'shared/Flex';

const RegulationPage = () => {
  const casino = (useSelector(selectCasino) as string) || 'quantico';
  const isDarkMode = useSelector(darkMode);
  const isMobile = GameHelper.isMobile();

  const className = clsx(
    'root-container',
    'container-regulation',
    isMobile ? 'mobile' : 'desktop',
    isDarkMode ? 'dark-mode' : casino,
  );

  return (
    <>
    <div className={className}>
      <div className="header-box">
        <div className="header-top-container">
          <Container className="h-full !flex items-center justify-between">
            <div className="header-top-main">
              <FlexBox className="max-h-[40px] max-w-[200px]" justifyContent={'center'}>
                <img src={`/images/logo/skin/logo-${SKIN[casino].logoHeader}.webp`} className="h-full" />
              </FlexBox>
            </div>
          </Container>
        </div>
      </div>

      <div className="main-container">
        {isMobile ? (<Outlet />) : (
          <Container className="h-full" sx={{ py: 3 }}>
            <Outlet />
          </Container>
        )}
      </div>
    </div>
    </>
  )
};
export default RegulationPage
