import { Game, TournamentFilter, selectActiveGame, selectFilterOptions, tournamentActions } from "@quanticogames/gameclient-core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TOURNAMENT_FILTERS, TOURNAMENT_ITALIAN_POKER_FILTERS, TOURNAMENT_STATUS_FILTER } from "shared/Filters/FilterConstants";
import { FilterItem } from "shared/Filters/FilterItem";
import ModalComponent from "shared/components/modal";

type FilterMobileProps = {
    open: boolean,
    onClose: () => void
}

const FilterMobile = ({ open, onClose }: FilterMobileProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updatedFilterOptions: any = {} as TournamentFilter;
    const activeGameId = JSON.parse(localStorage.getItem('activeGame'))?.id;
    const filterOptions = useSelector(selectFilterOptions);
    const [filterList, setFilterList] = useState<any>({});
    const activeGame: Game = useSelector(selectActiveGame);
    const [oldGameCode, setOldGameCode] = useState<string>(null);
    

    const descriptionFilter = activeGameId === 3 ? TOURNAMENT_ITALIAN_POKER_FILTERS : TOURNAMENT_FILTERS;
    const tournamentFilters = activeGameId === 3 ? {...TOURNAMENT_ITALIAN_POKER_FILTERS, ...TOURNAMENT_STATUS_FILTER} : {...TOURNAMENT_FILTERS, ...TOURNAMENT_STATUS_FILTER};

    const filterData = (name: string, values: any[]) => {
        const options = tournamentFilters[name].options.filter(o => values.includes(o.id));
        switch (name) {
        case 'cost':
            updatedFilterOptions.costLabels = options.map(o => o.label);
            updatedFilterOptions.costRanges = options.map(o => o.costRange);
            break;

        case 'objective':
            updatedFilterOptions.objectiveLabels = options.map(o => o.label);
            updatedFilterOptions.objectives = options.map(o => o.objective);
            updatedFilterOptions.objectiveValues = options.map(o => o.objectiveValue);
            break;

        case 'matchType':
            updatedFilterOptions.matchTypeLabels = options.map(o => o.label);
            updatedFilterOptions.matchTypes = options.map(o => o.matchType);
            break;

        case 'timeSpeed':
            updatedFilterOptions.timeSpeedLabels = options.map(o => o.label);
            updatedFilterOptions.timeSpeeds = options.map(o => o.timeSpeed);
            break;

        case 'maxPlayers':
            updatedFilterOptions.maxPlayersLabels = options.map(o => o.label);
            updatedFilterOptions.maxPlayers = options.map(o => o.maxPlayers);
            break;

        case 'status':
            updatedFilterOptions.statusLabels = options.map(o => o.label)
            updatedFilterOptions.status = options.map(o => o.status)
            break;
        }

        setFilterList({ ...filterList, ...updatedFilterOptions })
    }

    const handleApply = () => {
        dispatch(tournamentActions.updateFilters(filterList))
        onClose();
    }

   

    const onCancelFilter = () => {
        setFilterList(filterOptions);
        onClose()
    }

    const calculateFilterValue = (name: string) => {
        const labels: string[] = filterList[`${name}Labels`] || [];
        const options = tournamentFilters[name].options.filter(o => labels.includes(o.label));
        return options.map(o => o.id); // return id list
    }

    useEffect(() => {
        
        
        setOldGameCode(activeGame?.code)
        if (oldGameCode === null || activeGame?.code === oldGameCode) return;
        dispatch(tournamentActions.resetFilters(null))
    }, [activeGame]);
    
    useEffect(() => {
        
        if(JSON.stringify(filterOptions) === JSON.stringify(filterList)) return;
        setFilterList(filterOptions)
     }, [filterOptions]);

    return (
        <ModalComponent
            open={open}
            className="filter-mobile-modal"
            title="Filter"
            onClose={onCancelFilter}
            typeModal='filter'
            onCancel={onCancelFilter}
            onOk={handleApply}
            textOk={t('Apply')}
            textCancel={t('Cancel')}
            showFooter={true}
            size='small'
        >
            <div className="filter-mobile-list">
                <div className="filter-mobile-item">
                    <p className="filter-mobile-label">{t('Description')}</p>
                    {(Object.entries(descriptionFilter) || []).map(([name, filter]: any) => {
                        return (
                            <FilterItem
                                key={`filter-item-${name}`}
                                id={name}
                                title={t(filter.title)}
                                options={filter.options}
                                values={calculateFilterValue(name)}
                                onChange={(values) => filterData(name, values)}
                                className={`filter-${name}`}
                            />
                        )
                    })}
                </div>

                <div className="filter-mobile-item">
                    <p className="filter-mobile-label">{t('Status')}</p>
                    {(Object.entries(TOURNAMENT_STATUS_FILTER) || []).map(([name, filter]: any) => {
                        return (
                            <FilterItem
                                key={`filter-item-${name}`}
                                id={name}
                                title={t(filter.title)}
                                options={filter.options}
                                values={calculateFilterValue(name)}
                                onChange={(values) => filterData(name, values)}
                                className={`filter-${name}`}
                            />
                        )
                    })}
                </div>
            </div>
        </ModalComponent>
    )
}
export default FilterMobile;
