export const HeightTabs = () => {
    const heightWindown = window.innerHeight;
    let heightHeaderTop;
    let regulationTopHeight;

    const headerTop = document.querySelector('.header-box') as HTMLElement;
    if (headerTop) {
        heightHeaderTop = headerTop.offsetHeight;
    }

    const regulationTop = document.querySelector('.regulation-top') as HTMLElement;
    if (regulationTop) {
        regulationTopHeight = regulationTop.offsetHeight;
    }
    const heightContentTabs = heightWindown - heightHeaderTop - regulationTopHeight;
    return heightContentTabs
}