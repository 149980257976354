import { useEffect } from 'react';
import TabsRegulation from 'shared/Regulation/components/TabsRegulation';
import TutorialTab from 'shared/Regulation/components/TutorialTab';
import { AssoPigliaTuttoComeSiGioca, AssoPigliaTuttoIlMazzoDiCarte, AssoPigliaTuttoPenalita, AssoPigliaTuttoRegoleOpzionali, AssoPigliaTuttoSvolgimentoDelGioco, AssoPigliaTuttoTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataAssoPigliaTutto';
import { BestiaComeSiGioca, BestiaIlMazzoDiCarte, BestiaRegoleDeiTavoliCash, BestiaRegoleOpzionali, BestiaSvolgimentoDelGioco } from 'shared/Regulation/data/DataBestia';
import { BriscolaComeSiGioca, BriscolaDifferenzaPuntiNeiCash, BriscolaIlMazzoDiCarte, BriscolaPenalita, BriscolaRegoleOpzionali, BriscolaSvolgimentoDelGioco, BriscolaTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataBriscola';
import { BurracoComeSiGioca, BurracoDifferenzaPuntiNeiCash, BurracoIlMazzoDiCarte, BurracoPenalita, BurracoRegoleOpzionali, BurracoSvolgimentoDelGioco, BurracoTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataBurraco';
import { CirullaComeSiGioca, CirullaIlMazzoDiCarte, CirullaOpzioniDiGiocoAutomatico, CirullaPenalita, CirullaRegoleOpzionali, CirullaSvolgimentoDelGioco, CirullaTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataCirulla';
import { ItalianPokerComeSiGioca, ItalianPokerIlMazzoDiCarte, ItalianPokerOpzioniDiGiocoAutomatico, ItalianPokerRegoleDeiTavoliCash, ItalianPokerRegoleOpzionali, ItalianPokerSvolgimentoDelGioco } from 'shared/Regulation/data/DataIntalianPoker';
import { PeppaComeSiGioca, PeppaIlMazzoDiCarte, PeppaOpzioniDiGiocoAutomatico, PeppaRegoleOpzionali, PeppaSvolgimentoDelGioco, PeppaTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataPeppa';
import { Scala40ComeSiGioca, Scala40DifferenzaPuntiNeiCash, Scala40IlMazzoDiCarte, Scala40Penalita, Scala40RegoleOpzionali, Scala40SvolgimentoDelGioco, Scala40TabellaDeiVictoryPoints } from 'shared/Regulation/data/DataScala40';
import { ScopaComeSiGioca, ScopaIlMazzoDiCarte, ScopaPenalita, ScopaRegoleOpzionali, ScopaSvolgimentoDelGioco, ScopaTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataScopa';
import { GameProgressScopone, HowToPlayScopone, PenaltyScopone, TheDeckCardScopone } from 'shared/Regulation/data/DataScopone';
import { SetteEMezzoComeSiGioca, SetteEMezzoIlMazzoDiCarte, SetteEMezzoOpzioniDiGiocoAutomatico, SetteEMezzoRegoleDeiTavoliCash, SetteEMezzoRegoleOpzionali, SetteEMezzoSvolgimentoDelGioco } from 'shared/Regulation/data/DataSetteEMezzo';
import { SolitarioComeSiGioca, SolitariollMazzoDiCarte, SolitarioSvolgimentoDelGioco } from 'shared/Regulation/data/DataSolitario';
import { TelesinaComeSiGioca, TelesinaIlMazzoDiCarte, TelesinaOpzioniDiGiocoAutomatico, TelesinaRegoleDeiTavoliCash, TelesinaRegoleOpzionali, TelesinaSvolgimentoDelGioco } from 'shared/Regulation/data/DataTelesina';
import { TresetteComeSiGioca, TresetteIlMazzoDiCarte, TresetteOpzioniDiGiocoAutomatico, TresettePenalita, TresetteRegoleOpzionali, TresetteSvolgimentoDelGioco, TresetteTabellaDeiVictoryPoints } from 'shared/Regulation/data/DataTresette';
import { LIST_GAME } from 'constants/common';

const InformationGame = () => {
    const items = [
        {
            key: 'AssoPigliaTutto',
            label: `ASSO PIGLIA TUTTO`,
            subTabs: [
                {
                    key: 'AssoPigliaTutto.1',
                    label: `Il mazzo di carte`,
                    children: <AssoPigliaTuttoIlMazzoDiCarte />,
                },
                {
                    key: 'AssoPigliaTutto.2',
                    label: `Svolgimento del gioco`,
                    children: <AssoPigliaTuttoSvolgimentoDelGioco />,
                },
                {
                    key: 'AssoPigliaTutto.3',
                    label: `Come si gioca`,
                    children: <AssoPigliaTuttoComeSiGioca />,
                },
                {
                    key: 'AssoPigliaTutto.4',
                    label: `Penalità`,
                    children: <AssoPigliaTuttoPenalita />,
                },
                {
                    key: 'AssoPigliaTutto.5',
                    label: `Regole opzionali`,
                    children: <AssoPigliaTuttoRegoleOpzionali />,
                },
                {
                    key: 'AssoPigliaTutto.6',
                    label: `Tabella dei Victory Points`,
                    children: <AssoPigliaTuttoTabellaDeiVictoryPoints />,
                },
                {
                    key: 'AssoPigliaTutto.7',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.ASSOPIGLIATUTTO} />,
                },
            ],
        },
        {
            key: 'Bestia',
            label: `BESTIA`,
            subTabs: [
                {
                    key: 'Bestia.1',
                    label: `Il mazzo di carte`,
                    children: <BestiaIlMazzoDiCarte />,
                },
                {
                    key: 'Bestia.2',
                    label: `Svolgimento del gioco`,
                    children: <BestiaSvolgimentoDelGioco />,
                },
                {
                    key: 'Bestia.3',
                    label: `Come si gioca`,
                    children: <BestiaComeSiGioca />,
                },
                {
                    key: 'Bestia.4',
                    label: `Regole opzionali`,
                    children: <BestiaRegoleOpzionali />,
                },
                {
                    key: 'Bestia.6',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.BESTIA} />,
                },
            ],
        },
        {
            key: 'Briscola',
            label: `BRISCOLA`,
            subTabs: [
                {
                    key: 'Briscola.1',
                    label: `Il mazzo di carte`,
                    children: <BriscolaIlMazzoDiCarte />,
                },
                {
                    key: 'Briscola.2',
                    label: `Svolgimento del gioco`,
                    children: <BriscolaSvolgimentoDelGioco />,
                },
                {
                    key: 'Briscola.3',
                    label: `Come si gioca`,
                    children: <BriscolaComeSiGioca />,
                },
                {
                    key: 'Briscola.4',
                    label: `Penalità`,
                    children: <BriscolaPenalita />,
                },
                {
                    key: 'Briscola.5',
                    label: `Regole opzionali`,
                    children: <BriscolaRegoleOpzionali />,
                },
                {
                    key: 'Briscola.6',
                    label: `Differenza punti nei cash`,
                    children: <BriscolaDifferenzaPuntiNeiCash />,
                },
                {
                    key: 'Briscola.7',
                    label: `Tabella dei Victory Points`,
                    children: <BriscolaTabellaDeiVictoryPoints />,
                },
                {
                    key: 'Briscola.8',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.BRISCOLA} />,
                },
            ]
        },
        {
            key: 'Burraco',
            label: `BURRACO`,
            subTabs: [
                {
                    key: 'Burraco.1',
                    label: `Il mazzo di carte`,
                    children: <BurracoIlMazzoDiCarte />,
                },
                {
                    key: 'Burraco.2',
                    label: `Svolgimento del gioco`,
                    children: <BurracoSvolgimentoDelGioco />,
                },
                {
                    key: 'Burraco.3',
                    label: `Come si gioca`,
                    children: <BurracoComeSiGioca />,
                },
                {
                    key: 'Burraco.4',
                    label: `Penalità`,
                    children: <BurracoPenalita />,
                },
                {
                    key: 'Burraco.5',
                    label: `Regole opzionali`,
                    children: <BurracoRegoleOpzionali />,
                },
                {
                    key: 'Burraco.6',
                    label: `Differenza punti nei cash`,
                    children: <BurracoDifferenzaPuntiNeiCash />,
                },
                {
                    key: 'Burraco.7',
                    label: `Tabella dei Victory Points`,
                    children: <BurracoTabellaDeiVictoryPoints />,
                },
                {
                    key: 'Burraco.8',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.BURRACO} />,
                },
            ]
        },
        {
            key: 'Cirulla',
            label: `CIRULLA`,
            subTabs: [
                {
                    key: 'Cirulla.1',
                    label: `Il mazzo di carte`,
                    children: <CirullaIlMazzoDiCarte />,
                },
                {
                    key: 'Cirulla.2',
                    label: `Svolgimento del gioco`,
                    children: <CirullaSvolgimentoDelGioco />,
                },
                {
                    key: 'Cirulla.3',
                    label: `Come si gioca`,
                    children: <CirullaComeSiGioca />,
                },
                {
                    key: 'Cirulla.4',
                    label: `Penalità`,
                    children: <CirullaPenalita />,
                },
                {
                    key: 'Cirulla.5',
                    label: `Regole opzionali`,
                    children: <CirullaRegoleOpzionali />,
                },
                {
                    key: 'Cirulla.6',
                    label: `Opzioni di gioco automatico `,
                    children: <CirullaOpzioniDiGiocoAutomatico />,
                },
                {
                    key: 'Cirulla.7',
                    label: `Tabella dei Victory Points`,
                    children: <CirullaTabellaDeiVictoryPoints />,
                },
                {
                    key: 'Cirulla.8',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.CIRULLA} />,
                },
            ]
        },
        {
            key: 'ItalianPoker',
            label: `POKER 5 CARTE`,
            subTabs: [
                {
                    key: 'ItalianPoker.1',
                    label: `Il mazzo di carte`,
                    children: <ItalianPokerIlMazzoDiCarte />,
                },
                {
                    key: 'ItalianPoker.2',
                    label: `Svolgimento del gioco`,
                    children: <ItalianPokerSvolgimentoDelGioco />,
                },
                {
                    key: 'ItalianPoker.3',
                    label: `Come si gioca`,
                    children: <ItalianPokerComeSiGioca />,
                },
                {
                    key: 'ItalianPoker.4',
                    label: `Regole opzionali`,
                    children: <ItalianPokerRegoleOpzionali />,
                },
                {
                    key: 'ItalianPoker.5',
                    label: `Opzioni di gioco automatico `,
                    children: <ItalianPokerOpzioniDiGiocoAutomatico />,
                },
                {
                    key: 'ItalianPoker.6',
                    label: `Regole dei tavoli cash`,
                    children: <ItalianPokerRegoleDeiTavoliCash />,
                },
                {
                    key: 'ItalianPoker.7',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.ITALIANPOKER} />,
                },
            ],
        },
        {
            key: 'Peppa',
            label: `PEPPA`,
            subTabs: [
                {
                    key: 'Peppa.1',
                    label: `Il mazzo di carte`,
                    children: <PeppaIlMazzoDiCarte />,
                },
                {
                    key: 'Peppa.2',
                    label: `Svolgimento del gioco`,
                    children: <PeppaSvolgimentoDelGioco />,
                },
                {
                    key: 'Peppa.3',
                    label: `Come si gioca`,
                    children: <PeppaComeSiGioca />,
                },
                {
                    key: 'Peppa.4',
                    label: `Regole opzionali`,
                    children: <PeppaRegoleOpzionali />,
                },
                {
                    key: 'Peppa.5',
                    label: `Opzioni di gioco automatico `,
                    children: <PeppaOpzioniDiGiocoAutomatico />,
                },
                {
                    key: 'Peppa.6',
                    label: `Tabella dei Victory Points`,
                    children: <PeppaTabellaDeiVictoryPoints />,
                },
                {
                    key: 'Peppa.7',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.PEPPA} />,
                },
            ]
        },
        {
            key: 'Scala40',
            label: `SCALA 40`,
            subTabs: [
                {
                    key: 'Scala40.1',
                    label: `Il mazzo di carte`,
                    children: <Scala40IlMazzoDiCarte />,
                },
                {
                    key: 'Scala40.2',
                    label: `Svolgimento del gioco`,
                    children: <Scala40SvolgimentoDelGioco />,
                },
                {
                    key: 'Scala40.3',
                    label: `Come si gioca`,
                    children: <Scala40ComeSiGioca />,
                },
                {
                    key: 'Scala40.4',
                    label: `Penalità`,
                    children: <Scala40Penalita />,
                },
                {
                    key: 'Scala40.5',
                    label: `Regole opzionali`,
                    children: <Scala40RegoleOpzionali />,
                },
                {
                    key: 'Scala40.6',
                    label: `Differenza punti nei cash`,
                    children: <Scala40DifferenzaPuntiNeiCash />,
                },
                {
                    key: 'Scala40.7',
                    label: `Tabella dei Victory Points`,
                    children: <Scala40TabellaDeiVictoryPoints />,
                },
                {
                    key: 'Scala40.8',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.SCALA40} />,
                },
            ]
        },
        {
            key: 'Scopa',
            label: `SCOPA`,
            subTabs: [
                {
                    key: 'Scopa.1',
                    label: `Il mazzo di carte`,
                    children: <ScopaIlMazzoDiCarte />,
                },
                {
                    key: 'Scopa.2',
                    label: `Svolgimento del gioco`,
                    children: <ScopaSvolgimentoDelGioco />,
                },
                {
                    key: 'Scopa.3',
                    label: `Come si gioca`,
                    children: <ScopaComeSiGioca />,
                },
                {
                    key: 'Scopa.4',
                    label: `Penalità`,
                    children: <ScopaPenalita />,
                },
                {
                    key: 'Scopa.5',
                    label: `Regole opzionali`,
                    children: <ScopaRegoleOpzionali />,
                },
                {
                    key: 'Scopa.6',
                    label: `Tabella dei Victory Points`,
                    children: <ScopaTabellaDeiVictoryPoints />,
                },
                {
                    key: 'Scopa.7',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.SCOPA} />,
                },
            ]
        },
        {
            key: 'SetteEMezzo',
            label: `SETTE E MEZZO`,
            subTabs: [
                {
                    key: 'SetteEMezzo.1',
                    label: `Il mazzo di carte`,
                    children: <SetteEMezzoIlMazzoDiCarte />,
                },
                {
                    key: 'SetteEMezzo.2',
                    label: `Svolgimento del gioco`,
                    children: <SetteEMezzoSvolgimentoDelGioco />,
                },
                {
                    key: 'SetteEMezzo.3',
                    label: `Come si gioca`,
                    children: <SetteEMezzoComeSiGioca />,
                },
                {
                    key: 'SetteEMezzo.4',
                    label: `Regole opzionali`,
                    children: <SetteEMezzoRegoleOpzionali />,
                },
                {
                    key: 'SetteEMezzo.5',
                    label: `Opzioni di gioco automatico `,
                    children: <SetteEMezzoOpzioniDiGiocoAutomatico />,
                },
                {
                    key: 'SetteEMezzo.6',
                    label: `Regole dei tavoli cash`,
                    children: <SetteEMezzoRegoleDeiTavoliCash />,
                },
                {
                    key: 'SetteEMezzo.7',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.SETTEEMEZZO} />,
                },
            ],
        },
        {
            key: 'Telesina',
            label: `TELESINA`,
            subTabs: [
                {
                    key: 'Telesina.1',
                    label: `Il mazzo di carte`,
                    children: <TelesinaIlMazzoDiCarte />,
                },
                {
                    key: 'Telesina.2',
                    label: `Svolgimento del gioco`,
                    children: <TelesinaSvolgimentoDelGioco />,
                },
                {
                    key: 'Telesina.3',
                    label: `Come si gioca`,
                    children: <TelesinaComeSiGioca />,
                },
                {
                    key: 'Telesina.4',
                    label: `Regole opzionali`,
                    children: <TelesinaRegoleOpzionali />,
                },
                {
                    key: 'Telesina.5',
                    label: `Opzioni di gioco automatico `,
                    children: <TelesinaOpzioniDiGiocoAutomatico />,
                },
                {
                    key: 'Telesina.6',
                    label: `Regole dei tavoli cash`,
                    children: <TelesinaRegoleDeiTavoliCash />,
                },
                {
                    key: 'Telesina.7',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.TELESINA} />,
                },
            ],
        },
        {
            key: 'Tresette',
            label: `TRESETTE`,
            subTabs: [
                {
                    key: 'Tresette.1',
                    label: `Il mazzo di carte`,
                    children: <TresetteIlMazzoDiCarte />,
                },
                {
                    key: 'Tresette.2',
                    label: `Svolgimento del gioco`,
                    children: <TresetteSvolgimentoDelGioco />,
                },
                {
                    key: 'Tresette.3',
                    label: `Come si gioca`,
                    children: <TresetteComeSiGioca />,
                },
                {
                    key: 'Tresette.4',
                    label: `Penalità`,
                    children: <TresettePenalita />,
                },
                {
                    key: 'Tresette.5',
                    label: `Regole opzionali`,
                    children: <TresetteRegoleOpzionali />,
                },
                {
                    key: 'Tresette.6',
                    label: `Opzioni di gioco automatico `,
                    children: <TresetteOpzioniDiGiocoAutomatico />,
                },
                {
                    key: 'Tresette.7',
                    label: `Tabella dei Victory Points`,
                    children: <TresetteTabellaDeiVictoryPoints />,
                },
                {
                    key: 'Tresette.8',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.TRESETTE} />,
                },
            ]
        },
        {
            key: 'Scopone',
            label: `SCOPONE`,
            subTabs: [
                {
                    key: 'Scopone.1',
                    label: `Il mazzo di carte`,
                    children: <TheDeckCardScopone />,
                },
                {
                    key: 'Scopone.2',
                    label: `Svolgimento del gioco`,
                    children: <GameProgressScopone />,
                },
                {
                    key: 'Scopone.3',
                    label: `Come si gioca`,
                    children: <HowToPlayScopone />,
                },
                {
                    key: 'Scopone.4',
                    label: `Penalità`,
                    children: <PenaltyScopone />,
                },
                {
                    key: 'Scopone.5',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.SCOPONE} />,
                },
            ],
        },
        {
            key: 'Solitario',
            label: `SOLITARIO`,
            subTabs: [
                {
                    key: 'Solitario.1',
                    label: `Il mazzo di carte`,
                    children: <SolitariollMazzoDiCarte />,
                },
                {
                    key: 'Solitario.2',
                    label: `Svolgimento del gioco`,
                    children: <SolitarioSvolgimentoDelGioco />,
                },
                {
                    key: 'Solitario.3',
                    label: `Come si gioca`,
                    children: <SolitarioComeSiGioca />,
                },
                {
                    key: 'Solitario.4',
                    label: `Tutorial`,
                    children: <TutorialTab gameCode={LIST_GAME.SOLITARIO} />,
                },
            ],
        },
    ];

    useEffect(() => {
        document.title = "REGOLE DEI GIOCHI";
    }, []);

    return (
        <TabsRegulation items={items} />
    )
}
export default InformationGame
