import { useEffect, useState } from "react"
import { HeightTabs } from "shared/Regulation/components/HeightTabs";

type ContentTabsProps = {
    children: any
}

const ContentTabs = ({ children }: ContentTabsProps) => {
    const [heightTabs, setHeightTabs] = useState<any>(0)
    useEffect(() => {
        setHeightTabs(HeightTabs())
    }, [])

    const styleContentTabs = {
        maxHeight: heightTabs - 50
    }

    return (
        <div className="overflow-auto rule-game" style={styleContentTabs}>
            <div className="pr-[10px]">
                {children}
            </div>
        </div>
    )
}
export default ContentTabs