const UserGroup = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <circle cx="9.99996" cy="7.50002" r="3.33333" fill="currentColor"/>
            <ellipse cx="14.1666" cy="7.5" rx="2.5" ry="2.5" fill="currentColor"/>
            <circle cx="5.83337" cy="7.5" r="2.5" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.6404 15H16.3951C16.9867 15 17.4407 14.4889 17.2837 13.9185C16.944 12.6839 16.1106 10.8333 14.1668 10.8333C13.4284 10.8333 12.8502 11.1004 12.3984 11.4993C13.6549 12.3144 14.3045 13.7418 14.6404 15Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.60166 11.4993C7.14992 11.1004 6.57175 10.8333 5.83335 10.8333C3.88955 10.8333 3.05612 12.6839 2.71639 13.9185C2.55943 14.4889 3.01337 15 3.60499 15H5.35964C5.69555 13.7418 6.3452 12.3144 7.60166 11.4993Z" fill="currentColor"/>
            <path d="M10 11.6667C12.9747 11.6667 13.8257 14.2151 14.0692 15.6732C14.1601 16.2179 13.719 16.6667 13.1667 16.6667H6.83337C6.28109 16.6667 5.83998 16.2179 5.93093 15.6732C6.17439 14.2151 7.02538 11.6667 10 11.6667Z" fill="currentColor"/>
        </svg>
    )
}
export default UserGroup