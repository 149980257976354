import React, { Fragment } from 'react';
import { Box, styled, SxProps, TableCell, TableRow } from '@mui/material';
import { Warning } from '../../../types/warning';
import { COLORS } from '../../../constants/colors';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type CELL = {
  name: string;
  render?: (data: Warning, cell: string, t: TFunction) => string | JSX.Element;
  sx?: SxProps;
};

const CELLS: CELL[] = [
  {
    name: 'game',
    render: (data, cell, t) => {
      const games = data[cell];
      return (
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            width: '122px',
          }}
        >
          {games.map((item) => (
            <Fragment key={`warning-item-cell-game-${item.id}`}>
              <Box sx={{ height: '20px' }}>{item.icon}</Box>
              <Box
                sx={{
                  // marginLeft: '8px',
                  whiteSpace: 'nowrap',
                  height: '17px',
                }}
              >
                {t(item.name)}
              </Box>
            </Fragment>
          ))}
        </Box>
      );
    },
  },
  {
    name: 'warning',
    render: (data, cell, t) => {
      const warnings = data[cell];
      return (
        <Box
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            width: '60px',
            height: '20px',
          }}
        >
          {warnings.map((item) => (
            <Fragment key={`warning-item-cell-warning-${item.id}`}>
              <Box sx={{ height: '14px' }}>{item.icon}</Box>
              <Box
                sx={{
                  marginLeft: '8px',
                  whiteSpace: 'nowrap',
                }}
              >
                {t(item.name)}
              </Box>
            </Fragment>
          ))}
        </Box>
      );
    },
  },
  {
    name: 'date',
  },
];
const StyledTableRow = styled(TableRow)`
  border-radius: 8px;

  &:nth-of-type(even) {
    background: #f7f7f9;
  }
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  color: ${COLORS.text_color};
  &:last-of-type {
    text-align: right;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    @media (orientation: landscape) {
      padding: 0 0 16px;
      background-color: #fff;
    }
  }
`;

export const WarningItem = ({ data }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledTableRow>
      {CELLS.map((cell) => (
        <StyledTableCell
          key={`warning-item-${data.id}-cell-${cell.name}`}
          sx={cell.sx}
        >
          {cell.render ? cell.render(data, cell.name, t) : data[cell.name]}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  )
};
