import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const PeppaIlMazzoDiCarte = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole di Peppa - Il mazzo di carte </h2>
      <h2 className="titolo2">Il mazzo di carte </h2>
      <p className="paragraph">Si gioca con un mazzo di carte francesi Dal Negro per un totale di 52 carte.</p>
    </ContentTabs>
  );
};

export const PeppaSvolgimentoDelGioco = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole di Peppa  - Svolgimento del gioco </h2>
      <p className="paragraph"> Scopo del gioco è quello di non prendere nè la Peppa (donna di picche) nè le carte di cuori. </p>
      <h2 className="titolo2">Designazione del mazziere</h2>
      <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. </p>
      <p className="paragraph">Quando il gioco ha inizio, il mazziere viene stabilito casualmente dal sistema. </p>
      <p className="paragraph">
        Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra
        stessi giocatori, il mazziere gira in senso antiorario e cioè il prossimo
        mazziere sarà quello seduto alla destra del mazziere precedente.
      </p>
      <h2 className="titolo2">Distribuzione delle carte</h2>
      <p className="paragraph">Il sistema mescola le carte e:<br />vengono distribuite 13 carte a ciascun giocatore. </p>
      <h2 className="titolo2">Passaggio delle carte</h2>
      <p className="paragraph">Dopo aver visionato le carte, ciascun giocatore ne passa tre al suo avversario di destra; tutti devono effettuare questo passaggio contemporaneamente. </p>
      <h2 className="titolo2">Il gioco della carta</h2>
      <p className="paragraph">
        Il giocatore seduto alla destra del mazziere gioca una carta a sua scelta.
        Tutti gli altri giocatori, a turno e in senso antiorario, hanno l'obbligo di porre sul tavolo una carta dello stesso seme mostrato dal primo giocatore di mano.
        Se non si hanno carte di quel seme si può giocare una carta che si desidera. <br />
        Giocate le quattro carte, queste vengono prese dal giocatore che ha giocato la carta più alta del seme di mano.<br />
        Chi, non avendone di quel seme, avesse giocato una carta di un altro seme, non prenderà&nbsp; quella mano, neppure se il valore della carta fosse più alto.
        Il gioco passa ora a chi ha fatto la presa, che giocherà una nuova carta a sua scelta ed il gioco procede così di presa in presa fino all' esaurimento delle carte in mano ai giocatori
      </p>
      <h2 className="titolo2">Punteggi</h2>
      <p className="paragraph">Finita la smazzata, ciascun giocatore conta i punti che ha preso, secondo la seguente tabella: : </p>
      <ul itemType="disc" className="paragraph">
        <li>Donna di picche (Peppa): 13 punti  </li>
        <li>Asso di cuori: 5 punti </li>
        <li>Re di cuori: 4 punti  </li>
        <li>Donna di cuori: 3 punti  </li>
        <li>Fante di cuori: 2 punti </li>
        <li>Ciascuna altra carta di cuori: 1 punto </li>
        <li>Le altre carte: 0 punti  </li>
      </ul>
      <p className="paragraph">Prendere tutti i cuori e la Donna di picche (cappotto): 0 punti (ma 36 agli altri giocatori)  </p>
      <h2 className="titolo2">Conclusione della partita</h2>
      <p className="paragraph">Le partite si concludono a seconda della tipologia di partita giocata: </p>
      <p className="paragraph"><strong>Partite a smazzate</strong> </p>
      <p className="paragraph">
        Si stabilisce il numero di smazzate di cui è composta la partita.
        Vince chi fa meno punti al termine delle smazzate previste.
      </p>
      <p className="paragraph"><strong>Partite a punti</strong> </p>
      <p className="paragraph">
        Si stabilisce il totale dei punti massimi che un giocatore può raggiungere.
        Al raggiungimento di un giocatore dei punti massimi stabiliti si conclude la partita.
        Vince chi ha totalizzato meno punti degli avversari.<br />
        In entrambi i casi la classifica verrà stabilita partendo da chi ha realizzato il minor numero di punti, che vince la partita, fino a quello che ne ha realizzati di più, che sarà ultimo in classifica.
      </p>
      <h2 className="titolo2">Parità</h2>
      <p className="paragraph">
        In caso di parità tra due o più giocatori, si guarda l'ultima presa fatta da ciascuno.<br />
        Il giocatore che ha effettuato la sua ultima presa prima degli altri, vince.<br /><br />
        Es.<br />
        Giocatore A e giocatore B finiscono il torneo in parità.<br />
        Il giocatore A ha effettuato la sua ultima presa alla mossa 80, il giocatore B ha effettuato la sua ultima presa alla mossa 85, vince il giocatore A, in quanto la sua ultima presa è avvenuta prima di quella del suo avversario.
      </p>
      <p className="paragraph">Nel caso in cui non siano state fatte prese tra i giocatori in parità&nbsp;, vincerà il giocatore che ha giocato per primo nella prima mano dell'ultima smazzata giocata.</p>
    </ContentTabs>
  );
};

export const PeppaComeSiGioca = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole di Peppa - Come si gioca</h2>
      <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
      <p className="paragraph">Per giocare una carta, quando è il proprio turno si può: </p>
      <ul itemType="disc" className="paragraph">
        <li>Fare un "doppio clic" sulla carta, oppure </li>
        <li>Selezionare una carta (cioè cliccarla) e poi cliccare sulla carta semitrasparente che appare sul tavolo </li>
        <li>Selezionare una carta e, tenendo premuto il tasto sinistro del mouse, trascinarla sulla carta semitrasparente che appare sul tavolo </li>
      </ul>
      <p className="paragraph">Per selezionare le carte da passare ad inizio mano si clicca su una carta alla volta che si "alza" rispetto alle altre</p>
      <p className="paragraph">Per deselezionare una carta si clicca sulla carta che si abbassa tornando nella posizione originale.  </p>
      <p className="paragraph">Per passare le carte selezionate si clicca su "CONFERMA" in basso a destra. </p>
      <p className="paragraph">Per deselezionare una carta è sufficiente cliccarla.</p>
      <h2 className="titolo2">Gioco Automatico</h2>
      <p className="paragraph">Non è consentito abbandonare la partita, infatti il sistema continuerà&nbsp; a giocare in modo automatico come segue: </p>
      <ul itemType="disc" className="paragraph">
        <li>Durante la fase di passaggio delle carte,il sistema passerà&nbsp; automaticamente le ultime 3 carte ricevute. </li>
        <li>Durante la fase di gioco della carta, il sistema giocherà&nbsp; automaticamente una delle carte consentite secondo le regole del gioco</li>
      </ul>
    </ContentTabs>
  );
};

export const PeppaRegoleOpzionali = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole di Peppa - Regole opzionali</h2>
      <h2 className="titolo2">Hearts</h2>
      <p className="paragraph">
        Il giocatore che ha il 2 di fiori inizia il 1° turno.
        I giocatori seguenti (in senso orario) devono rispondere al seme giocato dal primo giocatore,se un giocatore non ha carte per rispondere al seme, deve giocare un'altra carta qualsiasi.
        Nel primo turno non è consentito giocare una carta del seme di cuori o la Regina di picche.
        Carte con il seme di cuori potranno essere giocate solo dopo il primo turno e dopo che un giocatore ha usato una carta di cuori.
      </p>
    </ContentTabs>
  );
};

export const PeppaOpzioniDiGiocoAutomatico = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole di Peppa - Opzioni di gioco automatico</h2>
      <h2 className="titolo2">Opzioni di gioco automatico </h2>
      <ul itemType="disc" className="paragraph">Non è consentito abbandonare la partita, infatti il sistema continuerà&nbsp; a giocare in modo automatico come segue:<p></p>
        <ul>
          <li>Durante la fase di passaggio delle carte,il sistema  passerà&nbsp; automaticamente le ultime 3 carte ricevute.</li>
          <li>Durante la fase di gioco della carta, il sistema giocherà&nbsp;  automaticamente una delle carte consentite secondo le regole del gioco.</li>
        </ul>
      </ul>
    </ContentTabs>
  );
};

export const PeppaTabellaDeiVictoryPoints = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole di Peppa - Tabella dei Victory Points</h2>
      <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
      <p className="paragraph">Le partite possono prevedere 1 vincente per tavolo o 2 vincenti per tavolo, e in base alla classifica finale i VP distribuiti sono:</p>
      <table border={1} cellSpacing="0" cellPadding="0" width="402">
        <tbody>
          <tr>
            <td className="bordi" width="196"><p><strong>RISULTATO</strong></p></td>
            <td className="bordi" width="103"><p><strong>VP</strong><strong> 1 vincente</strong></p></td>
            <td className="bordi" width="103" valign="top"><p><strong>VP 2 vincenti</strong></p></td>
          </tr>
          <tr>
            <td className="bordi" width="196"><p>1º classificato</p></td>
            <td className="bordi" width="103"><p>12 </p></td>
            <td className="bordi" width="103" valign="top"><p>10</p></td>
          </tr>
          <tr>
            <td className="bordi" width="196"><p>2º classificato </p></td>
            <td className="bordi" width="103"><p>6</p></td>
            <td className="bordi" width="103" valign="top"><p>6</p></td>
          </tr>
          <tr>
            <td className="bordi" width="196"><p>3º classificato</p></td>
            <td className="bordi" width="103"><p>2 </p></td>
            <td className="bordi" width="103" valign="top"><p>4</p></td>
          </tr>
          <tr>
            <td className="bordi" width="196"><p>4º classificato</p></td>
            <td className="bordi" width="103"><p>0</p></td>
            <td className="bordi" width="103" valign="top"><p>0</p></td>
          </tr>
        </tbody>
      </table>
    </ContentTabs>
  );
};
