import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContentTabs from "shared/Regulation/components/ContentTabs";
import { ArrowForwardIos } from '@mui/icons-material';
import { getGameNameByCode, getPrefixForGame } from "i18n/i18helper";
import { useNavigate } from "react-router";
import { GameHelper } from "@quanticogames/gameclient-core";

const TutorialTab = ({ gameCode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        if (GameHelper.isMobile()) {
            navigate(`/tutorial/${gameCode}`);
            return;
        }
        window.open(
            `/tutorial/${gameCode}`,
            `tutorial${gameCode}`,
            "width=" + screen.availWidth + ",height=" + screen.availHeight
        );
        let tutorialWindows: string[] = localStorage['tutorialWindows'] && localStorage['tutorialWindows'].split(',') || [];
        tutorialWindows.push(gameCode);
        localStorage.setItem('tutorialWindows', tutorialWindows.join(','));
    }

    const gameName = getGameNameByCode(gameCode);
    const welcomeGameName = `${getPrefixForGame(gameCode)} ${gameName}`;

    return (
        <ContentTabs>
            <h2 className="titolomenu">{gameName} - {t("Tutorial")}</h2>
            <h2 className="titolo2">{t("tutorial.welcome", { gameName: welcomeGameName })}</h2>
            <p className="paragraph">{t("tutorial.description", { gameName })}</p>
            <div className="tutorial">
                <div className="game">{t("Tutorial")}/{gameName}</div>
                <div className="title">{t("tutorial.title", { gameName })}</div>
                <Button className="start-btn" onClick={handleClick}>
                    {t("tutorial.btn")}
                    <ArrowForwardIos color={"#ffffff" as any} fontSize="small" />
                </Button>
            </div>
        </ContentTabs>
    );
};

export default TutorialTab;
