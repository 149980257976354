import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SessionInfo, selectSessionInfo } from "@quanticogames/gameclient-core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ModalLogGame = () => {
    const { t } = useTranslation();
    const [isOpenModalLogGame, setIsOpenModalLogGame] = useState<boolean>(false);
    const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;

    useEffect(() => {
        if (!sessionInfo) {
            return
        }
        setIsOpenModalLogGame(sessionInfo?.isBanned ?? false);
    }, [sessionInfo])

    return (
        <Dialog
            open={isOpenModalLogGame}
            className="font-raleway"
            sx={{
                border: "1px solid white",
                borderRadius: '8px',
            }}
            
        >
            <DialogTitle
                className="font-raleway"
                sx={{
                    background: '#0E345E',
                    color: '#fff',
                    padding: '10px 20px',
                    height: '45px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                }}
            >
                <div className='flex justify-center items-center max-h-0 uppercase'>{t("Notification")}</div>
            </DialogTitle>
            <DialogContent
                sx={{
                    width: "400px",
                    textAlign: 'center',
                    fontSize: '16px',
                }}
            >
                    <p className='mt-7 font-medium'>{t("You are suspended from the platform, please contact your operator for more info")}</p>
            </DialogContent>
        </Dialog>
    )
}
export default ModalLogGame
