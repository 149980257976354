import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material'
import { ButtonCustom } from '@quanticogames/gameclient-components'
import { BoardGameContext, Game, TableSettings, getCardTypeOption, selectActiveGame, useTableSettings } from '@quanticogames/gameclient-core'
import { Select } from 'antd'
import { CloseIconSvg } from 'assets/icons/CloseIconSvg'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

type ModalOptionsProps = {
    onClose?: (value?: string) => void;
    openModalOptions?: boolean
};

const ModalOptions = ({ onClose, openModalOptions }: ModalOptionsProps) => {
    const { t, i18n } = useTranslation();
    const { tableSettings, updateTableSettings } = useTableSettings();
    const { boardGame } = useContext(BoardGameContext);
    const game: Game = useSelector(selectActiveGame);

    const cardTypeList = (boardGame.cardTypeList || [])
        .map(c => ({ value: c.type, label: c.name }));

    const [cardType, setCardType] = useState(getCardTypeOption(game?.code) || cardTypeList[0].value);

    const onApplySettingAllTable = () => {
        const options = {
            ...tableSettings,
        } as TableSettings;
        options[`${game.code}`] = {
            cardType: cardType,
        };
        updateTableSettings(options);
        onClose();
    }

    const updateCardType = (type) => {
        setCardType(type.toString());
    }

    const options = useMemo(() => {
        return [{ value: 'Classic', label: t('Classic') }];
    }, [i18n.language]);

    return (
        <>
            <Dialog
                open={openModalOptions}
                sx={{
                    borderRadius: '10px',
                }}
                PaperProps={{
                    sx: {
                        maxWidth: '600px !important',
                        alignSelf: 'center',
                    },
                }}
                className='modal-full font-raleway'
                onClose={onClose}
            >
                <DialogTitle
                    sx={{
                        background: '#0E345E',
                        color: '#fff',
                        padding: '10px 20px',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontWeight: 400,
                    }}
                >
                    <div className='flex justify-center items-center ml-12 flex-1 uppercase font-raleway text-[20px] font-medium'>
                        {t('Options')}
                    </div>
                    <div onClick={() => onClose()} className='flex justify-end items-end cursor-pointer ml-3 text-[#fff]'>
                        <CloseIconSvg />
                    </div>
                </DialogTitle>
                <DialogContent
                    sx={{
                        fontSize: '15px',
                        marginTop: '15px',
                    }}
                >
                    <div className='flex gap-[10px] options-list flex-wrap'>
                        <div className='options-item bg-[#f1f2fb] w-full'>
                            <div className='options-title uppercase text-[#fff] bg-[#3F51B5] flex items-center justify-center h-[30px]'>{t('Personalize')}</div>
                            <div className='options-content min-h-[150px]'>
                                <Grid container spacing={2} className='options-main px-[10px] py-[15px]'>
                                    <Grid item xs={6} className='font-medium'>
                                        <label className='mb-[5px] inline-block'>{t('Card option')}:</label>
                                        <Select
                                            // defaultValue="Select"
                                            style={{ width: '100%' }}
                                            options={cardTypeList}
                                            value={cardType}
                                            onChange={(item) => updateCardType(item)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className='font-medium'>
                                        <label className='mb-[5px] inline-block'>{t('Table theme')}:</label>
                                        <Select
                                            defaultValue="Classic"
                                            style={{ width: '100%' }}
                                            options={options}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className='font-medium'>
                                        <label className='mb-[5px] inline-block'>{t('Card back')}:</label>
                                        <Select
                                            defaultValue="Classic"
                                            style={{ width: '100%' }}
                                            options={options}
                                        />
                                    </Grid>
                                    <Grid item xs={6} className='font-medium'>
                                        <label className='mb-[5px] inline-block'>{t('Cloth')}:</label>
                                        <Select
                                            defaultValue="Classic"
                                            style={{ width: '100%' }}
                                            options={options}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-wrap items-center justify-center gap-[10px] mt-[15px] options-btn-group'>
                        <ButtonCustom type='warning' text={t('Cancel')} onClickButton={() => onClose()} className='!h-[40px] !text-[14px] lg:!min-w-[180px] !rounded-[4px] max-w-[100%] w-full lg:w-[auto]' />
                        {/* <ButtonCustom type='primary-alt' text={t('Apply to this table')} onClickButton={onApplySettingCurrentTable} className='!h-[40px] !text-[14px] lg:!min-w-[180px] !rounded-[4px] max-w-[100%] w-full lg:w-[auto]' /> */}
                        <ButtonCustom type='primary-alt' text={t('Apply to all tables')} onClickButton={onApplySettingAllTable} className='!h-[40px] !text-[14px] lg:!min-w-[180px] !rounded-[4px] max-w-[100%] w-full lg:w-[auto]' />
                    </div>
                </DialogContent>
            </Dialog >
        </>
    )
}

export default ModalOptions