import ContentTabs from "shared/Regulation/components/ContentTabs"

export const BurracoIlMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Il mazzo di carte </h2>
            <h2 className="titolo2">Il mazzo di carte </h2>
            <p className="paragraph">Il Burraco si gioca con due mazzi di carte francesi inclusi i jolly per un totale di 108 carte. </p>
            <p className="paragraph">I giocatori possono selezionare il tipo di carte (francesi standard, francesi Dal Negro). </p>
        </ContentTabs>
    );
};

export const BurracoSvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Svolgimento del gioco </h2>
            <h2 className="titolo2">Vengono dette: </h2>
            <p className="paragraph"><strong>giocata</strong> : la carta mostrata scoperta sul tavolo da un giocatore; </p>
            <p className="paragraph"><strong>mano</strong> :la fase di una <em>smazzata</em> in cui tutti i giocatori al tavolo effettuano una <em>giocata</em>; </p>
            <p className="paragraph"><strong>smazzata</strong> : la fase di gioco che inizia con la distribuzione delle carte e si conclude con il conteggio dei punti; </p>
            <p className="paragraph"><strong>partita</strong> : la fase di gioco composta da una o più <em> smazzate.</em> </p>
            <p className="paragraph">&nbsp;</p>
            <h2 className="titolo2">Designazione del mazziere</h2>
            <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. </p>
            <p className="paragraph">Quando il gioco ha inizio, il mazziere viene stabilito casualmente dal sistema. </p>
            <p className="paragraph">
                Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra
                stessi giocatori, il mazziere gira in senso orario e cioè il prossimo
                mazziere sarà quello seduto alla sinistra del mazziere precedente.
            </p>
            <h2 className="titolo2">Distribuzione delle carte</h2>
            <p className="paragraph">Il sistema mescola le carte e: </p>
            <p className="paragraph">da 11 ad ogni giocatore distribuendole una alla volta, in senso orario, partendo dal giocatore alla sinistra del mazziere; </p>
            <p className="paragraph">scopre una carta in mezzo al tavolo (il monte scarti iniziale); </p>
            <p className="paragraph">forma i pozzetti (ovvero due mazzetti da 11 carte ciascuno) prelevando una carta alla volta da fondo mazzo; </p>
            <p className="paragraph">posiziona le rimanenti carte coperte a centro tavolo (il tallone). </p>
            <h2 className="titolo2">Scelte del giocatore di mano</h2>
            <p className="paragraph">Il giocatore seduto alla sinistra del mazziere è il <em>giocatore di mano</em>, cioè il primo a dover effettuare la <em>giocata.</em> </p>
            <p className="paragraph">Dopo che ogni giocatore ha effettuato la giocata,la mano passa al giocatore successivo in senso orario. </p>
            <p className="paragraph"><strong>Ogni giocatore di mano deve per prima cosa decidere se:</strong> </p>
            <p className="paragraph"><strong>pescare</strong> , cioè prelevare la prima carta del tallone, o </p>
            <p className="paragraph">
                <strong>raccogliere</strong> , cioè prelevare tutte le carte del monte scarti
                (non si può raccogliere solo l'ultima carta del monte scarti, bisogna
                raccoglierle tutte).
            </p>
            <p className="paragraph">Dopo aver pescato o raccolto il giocatore può: </p>
            <p className="paragraph">
                <strong>aprire dei nuovi giochi o può allungare i giochi già aperti</strong>
                da lui o dal proprio compagno, nel rispetto delle regole sotto enunciate ( con "
                aprire nuovi giochi " si intende disporre sul tavolo, scoperte, alcune delle
                carte che il giocatore ha in mano), oppure
            </p>
            <p className="paragraph">
                per terminare il suo turno e passare la mano al giocatore alla sua sinistra
                deve <strong>scartare una carta</strong> (la carta scartata non può essere
                l'unica carta raccolta a meno di non averne l'altra uguale in mano).
            </p>
            <h2 className="titolo2">Regole per aprire nuovi giochi </h2>
            <p className="paragraph">
                I giochi aperti devono essere formati da almeno 3 carte in sequenza dello
                stesso seme (scale) o da 3 carte dello stesso valore anche dello stesso seme
                (combinazioni).
            </p>
            <p className="paragraph">Esempio di scala: 5♣, 6♣, 7♣, 8♣ </p>
            <p className="paragraph">Esempio di combinazione: <span className="testorosso">7♥</span>, <span className="testorosso">7♥</span>, <span className="testorosso">7♦</span>, 7♣, </p>
            <p className="paragraph">Non si possono appoggiare, separatamente, combinazioni della stessa carta.</p>
            <p className="paragraph">Nelle scale l'asso può essere posto prima del 2 o dopo il K ma non possono essere appoggiate scale K-A-2-3. </p>
            <p className="paragraph">I Jolly e i "2" sono dette "matte". </p>
            <p className="paragraph">
                Le matte possono assumere il valore di qualsiasi carta. Un gioco può
                contenere al massimo una sola matta. Non si possono quindi, in particolare,
                appoggiare giochi formati da sole matte.
            </p>
            <p className="paragraph">
                I "2" (che sono detti anche "pinelle") non sono però considerati matte se
                stanno nella loro posizione naturale (ad esempio nella sequenza <span className="testorosso">A♥</span>, <span className="testorosso">2♥</span>, <span className="testorosso">3♥</span>, <span className="testorosso">4♥</span>,
                il <span className="testorosso">2♥</span> non è considerato matta).
            </p>
            <h2 className="titolo2">Regole per allungare i giochi esistenti </h2>
            <p className="paragraph">
                Si possono aggiungere delle carte ai giochi della propria Linea ("allungare
                un gioco") se il gioco risultante rispetta le regole previste per l'apertura di
                nuovi giochi tenendo presente che, nelle sequenze, le matte possono essere
                sostituite solo d alla carta della quale fanno le veci. Ad esempio non si può
                aggiungere il 2♣ alla sequenza <span className="testorosso">3♥</span>, <span className="testorosso">2♥</span>, <span className="testorosso">5♥</span>, anche se la sequenza risultante <span className="testorosso">2♥</span>,
                <span className="testorosso">3♥</span>, 2♣, <span className="testorosso">5♥</span> sarebbe valida, perché il <span className="testorosso">2♥</span> puo essere sostituito solo dal <span className="testorosso">4♥</span>.
            </p>
            <p className="paragraph">
                Si possono allungare solamente i giochi aperti dalla propria Linea. Non si
                possono allungare i giochi aperti dalla Linea avversaria.
            </p>
            <p className="paragraph">
                I giochi aperti dalla propria linea sono visualizzati nella parte bassa del
                tavolo, quelli aperti dalla linea avversaria nella parte alta del tavolo.
            </p>
            <h2 className="titolo2">Burrachi </h2>
            <p className="paragraph">Viene detto Burraco un gioco composto da almeno 7 carte. </p>
            <p className="paragraph">Si possono fare tre tipi di burraco: </p>
            <p className="paragraph">burraco pulito: non contiene matte; </p>
            <p className="paragraph">
                burraco semi pulito: contiene una matta ma è composto da una sequenza di
                almeno sette carte pulite senza matte o da una combinazione di almeno 8 carte
                inclusa la matta;
            </p>
            <p className="paragraph">burraco sporco: contiene una matta e non è un burraco semipulito. </p>
            <h2 className="titolo2">Presa del pozzetto </h2>
            <p className="paragraph">Per prendere il pozzetto bisogna esaurire una prima volta le carte in mano. Si possono esaurire le carte in mano in due modi: </p>
            <p className="paragraph">
                scartando una carta (pozzetto con scarto). In questo caso il proprio turno
                termina e il pozzetto potrà essere giocato solo al giro successivo (si può anche
                scartare una matta);
            </p>
            <p className="paragraph">senza scartare (pozzetto in diretta). In questo caso il giocatore può giocare subito il pozzetto. </p>
            <p className="paragraph">Ogni Linea può prendere un solo pozzetto. </p>
            <h2 className="titolo2">Chiusura della smazzata </h2>
            <p className="paragraph">
                Per chiudere la smazzata bisogna esaurire le proprie carte in mano una
                seconda volta. Non si può chiudere se non si è fatto almeno un burraco. Inoltre
                per chiudere bisogna sempre scartare una carta e questa carta non deve essere
                una matta.
            </p>
            <h2 className="titolo2">Esaurimento delle carte del tallone </h2>
            <p className="paragraph">
                Una smazzata ha termine anche quando nel tallone rimangono solo due carte. Il
                giocatore che scarta quando rimangono due carte è l'ultimo a giocare.
            </p>
            <h2 className="titolo2">Punteggi </h2>
            <p className="paragraph">Alla fine di ogni smazzata sono calcolati i punteggi di ciascuna linea come segue: </p>
            <p className="paragraph">
                Si sommano tutti i punti delle carte appoggiate dalla linea e si detrae la
                somma di tutti i punti delle carte rimaste in mano ai componenti della linea.
            </p>
            <p className="paragraph">Il valore delle carte è il seguente: </p>
            <p className="paragraph">3,4,5,6,7.................... punti 5 </p>
            <p className="paragraph">8,9,10,J,Q,K.............. punti 10 </p>
            <p className="paragraph">Asso.......................... punti 15 </p>
            <p className="paragraph">Pinelle (i "2").............. punti 20 </p>
            <p className="paragraph">Jolly........................... punti 30; </p>
            <p className="paragraph">si sommano 200 punti per ogni Burraco pulito; </p>
            <p className="paragraph">si sommano 150 punti per ogni Burraco semi pulito; </p>
            <p className="paragraph">si sommano 100 punti per ogni Burraco sporco; </p>
            <p className="paragraph">si sommano 100 punti se la linea ha chiuso; </p>
            <p className="paragraph">si detraggono 100 punti se la linea non ha preso il pozzetto; </p>
            <p className="paragraph">si sommano i punti realizzati nelle smazzate precedenti. </p>
            <h2 className="titolo2">Conclusione della partita</h2>
            <h2 className="titolo2">Partite a smazzate </h2>
            <p className="paragraph">Si stabilisce il numero di smazzate di cui è composta la partita. </p>
            <p className="paragraph">Vince la partita chi fa più punti al termine delle smazzate previste, </p>
            <p className="paragraph">
                Solo nei tornei a eliminazione diretta (KO), in caso di parità di punti,
                <strong><u>perde</u></strong> chi ha fatto l'ultima mossa dell'ultima smazzata.
            </p>
            <h2 className="titolo2">Partite a punti </h2>
            <p className="paragraph">Si stabilisce il totale dei punti che un giocatore o una coppia deve raggiungere per aggiudicarsi la partita. </p>
            <p className="paragraph">Vince la partita chi, avendo superato il punteggio previsto, ha più punti dell'avversario, </p>
            <p className="paragraph">
                Solo nei tornei a eliminazione diretta (KO), in caso di parità di punti,
                <strong><u>perde</u></strong> chi ha fatto l'ultima mossa dell'ultima smazzata.
            </p>
        </ContentTabs>
    );
};

export const BurracoComeSiGioca = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Come si gioca</h2>
            <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
            <p className="paragraph">Il giocatore di mano : </p>
            <p className="paragraph"><strong>per pescare</strong> deve cliccare sul tallone (la carta coperta a centro tavolo); </p>
            <p className="paragraph">
                <strong>per raccogliere</strong> deve cliccare sugli scarti o, in assenza di
                scarti, sulla carta semitrasparente posta accanto al tallone;
            </p>
            <p className="paragraph">
                <strong>per aprire un nuovo gioco</strong> deve cliccare sulle carte che
                desidera appoggiare (le carte si alzano leggermente) e poi deve cliccare sulla
                carta semitrasparente inizialmente a sinistra, sotto i pozzetti;
            </p>
            <p className="paragraph">
                <strong>per allungare un gioco esistente</strong> deve cliccare sulle carte
                che desidera legare e poi deve cliccare su quel gioco;
            </p>
            <p className="paragraph">
                <strong>per scartare</strong> deve cliccare sulla carta e poi deve cliccare
                sugli scarti o, in assenza di scarti, sulla carta semitrasparente posta accanto
                al tallone.
            </p>
            <p className="paragraph">Inoltre il giocatore, in qualsiasi momento della partita: </p>
            <p className="paragraph">
                per ordinare le carte che ha in mano può cliccare su uno dei due tasti in
                basso a sinistra, il primo tasto ordina le carte per seme, il secondo tasto
                ordinerà le carte per valore con le matte a sinistra; per ordinare le carte a
                suo piacimento deve cliccare su una carta e, tenendola premuta, la deve
                trascinare nella posizione desiderata.
            </p>
        </ContentTabs>
    );
};

export const BurracoPenalita = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Penalità</h2>
            <h2 className="titolo2">Penalità in caso di tempo scaduto o abbandono </h2>
            <p className="paragraph">
                Se un giocatore fa scadere il tempo massimo o il timeout o clicca sul tasto
                "abbandona" la partita ha termine e il giocatore <strong>perde la partita</strong>
                e la differenza punti è cosi stabilita:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>si stabilisce il "punteggio base" cioè il punteggio di ciascun giocatore al momento della fine della partita, </li>
                <li>si stabilisce una "penalità" pari a 350 punti, </li>
                <li>si fanno i conti con punteggio base + penalità, </li>
                <li>
                    se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il
                    timeout, la differenza punti è uguale a zero (ma la partita è comunque persa
                    da chi ha fatto scadere il tempo).
                </li>
            </ul>
            <p className="paragraph">
                Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o
                abbandona farà 0 VP e la linea avversaria farà minimo 14 VP. I MP di entrambe le
                linee saranno calcolati dai VP in base ai corrispondenti minimi previsti dalla
                tabella dei VP.
            </p>
            <p className="paragraph"><em>Si sottolinea che quest'ultima regola sul timeout e abbandono è valida solo per determinare i MP e i VP per le classifiche dei tornei a girone e i livelli di abilità in caso di timeout e abbandono , ma non per determinare la differenza punti nei tavoli cash che vengono sempre determinati come descritto nei <a href="generali.php?skin=SNAI&amp;mobile=&amp;argomento=cashgenerico&amp;capitolo=tavoliturni">regolamenti generali</a> anche in caso di timeout e abbandono.</em> </p>
        </ContentTabs>
    );
};

export const BurracoRegoleOpzionali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Regole opzionali </h2>
            <h2 className="titolo2">Partite a coppie a carte scoperte</h2>
            <p className="paragraph">Saranno proposte partite a coppie nelle quali i compagni, senza essere visti dagli avversari, giocano a carte scoperte. </p>
        </ContentTabs>
    );
};

export const BurracoDifferenzaPuntiNeiCash = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Differenza punti nei tavoli cash </h2>
            <h2 className="titolo2">Differenza punti nei tavoli cash </h2>
            <p className="paragraph">
                Nel Burraco, ai fini del calcolo dell'importo vinto o perso, la differenza
                punti è stabilita dividendo per 100 l'effettiva differenza punti e poi
                arrotondando il risultato ( se &gt; 0,50 all'unità superiore, se &lt;=0,50 all'unità
                inferiore).
            </p>
            <p className="paragraph">Esempio: </p>
            <p className="paragraph">Gioc A totalizza 2235 punti </p>
            <p className="paragraph">Gioc B totalizza 1860 punti </p>
            <p className="paragraph">Differenza punti effettiva = 2235 - 1860 = 375 punti </p>
            <p className="paragraph">Differenza punti = 375 / 100 = 3,75 =&gt; 4 punti </p>
            <p className="paragraph">Se la differenza punti effettiva fosse stata di 3,50 punti, l'arrotondamento avrebbe portato la differenza punti = 3. </p>
        </ContentTabs>
    );
};

export const BurracoTabellaDeiVictoryPoints = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Burraco - Tabella dei Victory Points</h2>
            <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
            <table className="bordi" border={1} cellPadding="5" cellSpacing="5" width="299">
                <tbody>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph"><strong>RISULTATO</strong> </p></td>
                        <td className="bordi" width="37"><p className="paragraph"><strong>VP</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Vittoria </p></td>
                        <td className="bordi" width="37"><p className="paragraph">20 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Pareggio (ove previsto) </p></td>
                        <td className="bordi" width="37"><p className="paragraph">10 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Sconfitta </p></td>
                        <td className="bordi" width="37"><p className="paragraph">0 </p></td>
                    </tr>
                </tbody>
            </table>
        </ContentTabs>
    );
};
