import { ReactNode } from "react";

type LineIconTextProps = {
    icon?: React.ReactNode;
    text?: string;
    link?: string;
    arrowRight?: boolean;
    handleClick?: () => void
    children?: ReactNode
};

const LineIconText = ({ icon, text, link, arrowRight, handleClick, children }: LineIconTextProps) => {
    return (
        <a href={link} className="flex items-center justify-between py-[16px] px-[24px] link-hover sidebar-link cursor-pointer" onClick={handleClick} target="_blank">
            <span className="flex items-center">
                {icon && <span className="icon w-[24px] mr-[16px] h-[24px] flex items-center justify-center">{icon}</span>}
                {text && <span className="text-[#0B0E11]">{text}</span>}
            </span>
            {arrowRight && (
                <span className="arrow w-[24px] h-[24px] flex items-center justify-center">
                    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 17L9 9L0.999999 1" stroke="#C2CDDB" strokeWidth="1.5" />
                    </svg>
                </span>
            )}
            {children}
        </a>
    );
};
export default LineIconText;
