/* eslint-disable */

import {
  BalanceInfo,
  RankingUser,
  SessionInfo,
  TournamentStateEnum,
  User,
  convertCentToEuro,
  formatMoney,
  selectBalance,
  selectBalanceInfo,
  selectCurrentUser,
  selectSessionInfo,
} from '@quanticogames/gameclient-core';
import { useAppSelector } from 'app/hooks';
import ProfileAvatar from 'layouts/profiles/components/profile-avatar';
import moment from 'moment';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';
import { StateLabel } from 'pages/tournament/component/tournament-state';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FlexBox } from 'shared/Flex';

type ProfileUserProps = {
  rankingUser?: RankingUser;
}

const ProfileUser = ({ rankingUser }: ProfileUserProps) => {
  const { t } = useTranslation();
  const balance = useAppSelector(selectBalance);
  const balanceFormat = formatMoney(convertCentToEuro(Number(balance)));
  const balanceInfo = useSelector(selectBalanceInfo) as BalanceInfo;
  const balanceBonusFormat = formatMoney(convertCentToEuro(Number(balanceInfo?.bonusBalance)));
  const totalBalance = formatMoney(convertCentToEuro(Number(balance) + Number(balanceInfo?.bonusBalance)));
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;

  return (
    <>
      <div className="profile-user-container">
        <ProfileAvatar showIconEdit={true} rankingUser={rankingUser} />
        <FlexBox className="profile-user-rate hidden" alignItems="center">
          <div className="profile-user-item">
            <p className="profile-user-label">{t('Win rate')}</p>
            <FlexBox className="profile-user-number" alignItems="center" justifyContent="center">
              80,65%
            </FlexBox>
          </div>

          <div className="profile-user-item">
            <p className="profile-user-label">{t('Hand plays')}</p>
            <FlexBox className="profile-user-number" alignItems="center" justifyContent="center">
              80,65%
            </FlexBox>
          </div>

          <div className="profile-user-item">
            <p className="profile-user-label">{t('Biggest win')}</p>
            <FlexBox className="profile-user-number" alignItems="center" justifyContent="center">
              80,65%
            </FlexBox>
          </div>
        </FlexBox>
      </div>

      <ul className="profile-user-info-list">
        <FlexBox flexDirection="column" className="profile-user-info-column">
          <li className="profile-user-info-item">
            <span className="profile-user-info-lable">{t('Balance')}</span>
            <span className="profile-user-info-txt success">€ {totalBalance}</span>
          </li>
          <li className="profile-user-info-item">
            <span className="profile-user-info-lable">{t('Credit')}</span>
            <span className="profile-user-info-txt success">€ {balanceFormat}</span>
          </li>
          <li className="profile-user-info-item">
            <span className="profile-user-info-lable">{t('Bonus')}</span>
            <a className="profile-user-info-txt success">€ {balanceBonusFormat}</a>
          </li>
          <li className="profile-user-info-item">
            <span className="profile-user-info-lable">{t('Account Status')}</span>
            <span className="profile-user-info-txt">
              <StateLabel
                color={TOURNAMENT_STATES_TITLES[TournamentStateEnum.open]?.color}
                background={TOURNAMENT_STATES_TITLES[TournamentStateEnum.open]?.background}
              >
                Attivo
              </StateLabel>
            </span>
          </li>
          {/* </FlexBox> */}
          {/* <FlexBox flexDirection="column" className="profile-user-info-column"> */}
          <li className="profile-user-info-item">
            <span className="profile-user-info-lable">{t('Last Login')}</span>
            <span className="profile-user-info-txt">{moment(sessionInfo?.lastLogin).format('DD/MM/YYYY')}</span>
          </li>
          <li className="profile-user-info-item hidden">
            <span className="profile-user-info-lable">{t('Warning score')}</span>
            <span className="profile-user-info-txt warning">Red (20 pt)</span>
          </li>
          <li className="profile-user-info-item hidden">
            <span className="profile-user-info-lable">{t('Last warning')}</span>
            <span className="profile-user-info-txt">{moment(sessionInfo?.lastLogin).format('DD MMM YYYY')}</span>
          </li>
          <li className="profile-user-info-item hidden">
            <span className="profile-user-info-lable">{t('Tax code')}</span>
            <span className="profile-user-info-txt">87294673</span>
          </li>
        </FlexBox>
      </ul>
    </>
  );
};
export default ProfileUser;
