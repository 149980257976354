import React, { useEffect, useMemo } from 'react';
import { Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { CashTable, IForceLeaveData, User, cashTableActions, selectActiveTableId, selectCashTable, selectCashTableForceLeave, selectCurrentUser } from '@quanticogames/gameclient-core';
import { StyledAnnounce, StyledButtonCustom, StyledContent } from './styled';
import { useTranslation } from 'react-i18next';

const ModalForceLeave: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tableId: number = useSelector(selectActiveTableId);
  const forceLeave: IForceLeaveData = useSelector(selectCashTableForceLeave(tableId));
  const currentUser: User = useSelector(selectCurrentUser);


  
  const open = useMemo(() => {
    if (forceLeave?.ids?.length) return forceLeave?.ids?.includes(currentUser?.id);
    return false;
  }, [forceLeave, currentUser]);

  const onCloseModal = () => {
    dispatch(cashTableActions.resetForceLeave({ tableId }));
  };

  return (
    <>
      <Dialog open={open} className='font-raleway modal-confirm-subcribe'>
        <StyledContent>
          <StyledAnnounce sx={{ padding: '16px'}}>
            <p>{t('Force leave notice', { round: forceLeave?.round || 0 })}</p>
          </StyledAnnounce>
          <StyledButtonCustom
            type='primary-alt'
            text='Chiudi'
            onClickButton={onCloseModal}
          />
        </StyledContent>
      </Dialog>
    </>
  );
};

export default ModalForceLeave;
