import React, { ReactNode } from 'react';
import { Button, styled, SxProps } from '@mui/material';

export const ButtonStyled = styled(Button)`
  border-radius: 50%;
  background-color: transparent;
  width: 24px;
  height: 24px;
  min-width: 24px;
  padding: 0;
`;

type ButtonType = {
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  sx?: SxProps;
};

export const InfoButton = ({ icon, onClick, sx }: ButtonType) => (
  <ButtonStyled onClick={onClick} sx={sx}>
    {icon}
  </ButtonStyled>
);
