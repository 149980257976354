import React, { ReactNode } from 'react';
import { Button, styled, SxProps, Theme, Typography } from '@mui/material';
import { COLORS } from '../../../constants/colors';

export const StyledButton = styled(Button)`
  width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: ${COLORS.text_color};

  .MuiTypography-root {
    margin-left: 16px;
    line-height: 17px;
    font-weight: 400;
    font-size: 0.875rem;
  }
  .MuiButton-endIcon {
    position: absolute;
    right: 16px;
  }
`;

type ButtonType = {
  title?: string | number;
  variant?: 'contained' | 'text' | 'outlined';
  sx?: SxProps<Theme>;
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  endIcon?: ReactNode;
};

export const LineButton: React.FC<ButtonType> = ({
  title,
  variant = 'text',
  sx,
  icon,
  onClick,
  endIcon,
}: ButtonType) => (
  <StyledButton endIcon={endIcon} sx={sx} variant={variant} onClick={onClick}>
    {icon}
    <Typography>{title}</Typography>
    {/* <ArrowRightIconSvg /> */}
  </StyledButton>
);
