import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CashTableFilter, Game, cashTableActions, selectActiveGame } from '@quanticogames/gameclient-core';
import LayoutPage from 'layouts/layout-page';
import './index.scss';
import TableCash from 'pages/cash-table/component/table';

export const CashTablePage: React.FC = () => {
    const dispatch = useDispatch();
    const activeGame: Game = useSelector(selectActiveGame);

    useEffect(() => {
        if (activeGame?.id) {
            dispatch(cashTableActions.loadCashTables({
                gameId: activeGame?.id,
            } as CashTableFilter));
        };
    }, [activeGame?.id]);

    return (
        <LayoutPage>
            <div className="tournament-container">
                <TableCash />
            </div>
        </LayoutPage>
    );
};
