import { GameHelper } from '@quanticogames/gameclient-core';
import { useEffect, useState } from 'react';

const getOrientation = () => GameHelper.isLandscape() && GameHelper.isMobile();

export const useIsMobileLandscape = () => {
  const [isMobileLandscape, setIsMobileLandscape] = useState<boolean>(getOrientation());
  
  const updateOrientation = event => {
    setIsMobileLandscape(getOrientation())
  }

  useEffect(() => {
    window.addEventListener(
      'resize',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'resize',
        updateOrientation
      )
    }
  }, [])



  return isMobileLandscape;
};
