import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const TelesinaIlMazzoDiCarte = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Telesina - Il mazzo di carte </h2>
      <h2 className="titolo2">Il mazzo di carte </h2>
      <p className="paragraph">La telesina si gioca con un massimo di 6 giocatori al tavolo </p>
      <p className="paragraph">Si gioca con un mazzo di carte <em>francesi </em>senza i <em>jolly</em>. </p>
      <p className="paragraph">La carta più bassa del mazzo è stabilita sottraendo al numero 11, il numero dei giocatori. </p>
      <p className="paragraph">Ad esempio, con 4 giocatori, la carta più bassa è il 7 (11 - 4). </p>
      <p className="paragraph">Si può quindi giocare con un numero di carte compreso tra: </p>
      <p className="paragraph">· 24 carte, dal 9 all'asso, nel caso di 2 giocatori </p>
      <p className="paragraph">· 40 carte, dal 5 all'asso, nel caso di 6 giocatori </p>
      <p className="paragraph">Per dirimere casi di assoluta parità, i semi hanno un valore decrescente secondo la regola "Come Quando Fuori Piove" (Cuori, Quadri, Fiori, Picche). </p>
      <h2 className="titolo2">Il valore delle carte </h2>
      <p className="paragraph">Il valore decrescente delle carte è il seguente: </p>
      <p className="paragraph">A, K, Q, J, 10, 9 e, se usate, 8, 7, 6, 5. </p>
      <p className="paragraph">
        l'asso è la carta con valore più alto, ma anche con valore minimo in caso
        venga utilizzata per chiudere una scala, ponendola al di sotto della carta con
        il numero più basso in gioco.
      </p>
    </ContentTabs>
  );
};

export const TelesinaSvolgimentoDelGioco = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Telesina - Svolgimento del gioco</h2>
      <h2 className="titolo2">I giocatori che hanno diritto a partecipare alla mano </h2>
      <p className="paragraph">I giocatori che hanno diritto a partecipare a una smazzata vengono detti "giocatori attivi" e sono determinati come segue. </p>
      <p className="paragraph">Nei tornei vengono eliminati i giocatori che rimangono senza chips alla fine della mano. </p>
      <p className="paragraph">
        Nei cash games i giocatori che hanno diritto a partecipare alla mano sono
        quelli che hanno un credito sufficiente(secondo le regole del tavolo specifico)
        e non si trovano in stato di sitout.
      </p>
      <h2 className="titolo2">Il Mazziere, l'invito, il piatto, i piatti secondari e la dotazione di chips iniziale. </h2>
      <p className="paragraph">Il <strong><em>mazziere</em></strong> è il giocatore che distribuisce le carte nella mano, in senso orario. </p>
      <p className="paragraph">Nella prima mano di una partita, il primo mazziere viene designato tramite un sorteggio. </p>
      <p className="paragraph">L'<strong><em>invito</em></strong> è l'importo che i giocatori devono versare nel piatto per partecipare alla mano. </p>
      <p className="paragraph">Il <strong><em>piatto</em></strong> è la somma delle varie puntate nella mano. </p>
      <p className="paragraph">
        Nel caso in cui un giocatore punti tutto il suo credito, e altri giocatori
        coinvolti nella mano continuano a puntare ulteriori somme queste verranno messe
        in <strong><em>piatti secondari </em></strong>ai quali partecipa solo chi ha
        contribuito con somme di chips.
      </p>
      <p className="paragraph">La <strong><em>dotazione di chips</em></strong> <strong><em>iniziale</em></strong>, in un torneo, è uguale per tutti i partecipanti. </p>
      <p className="paragraph">In una partita cash game la dotazione di chips iniziale è pari all'importo che un giocatore porta al tavolo, ed è delimitata dai limiti del tavolo stesso.</p>
      <p className="paragraph">il gioco si svolge nelle seguenti fasi: </p>
      <ul itemType="disc" className="paragraph">
        <li>Invito </li>
        <li>Distribuzione </li>
        <li>Girata vele </li>
        <li>Puntata </li>
        <li>Fase finale: esibizione punteggio e assegnazione piatti </li>
      </ul>
      <h2 className="titolo2">Invito</h2>
      <p className="paragraph">
        In un torneo l'invito viene prelevato automaticamente a tutti i giocatori
        attivi presenti al tavolo inclusi i giocatori in sitout. I giocatori in sitout
        non partecipano alle fasi successive, perdendo automaticamente l'invito che va
        nel piatto.
      </p>
      <p className="paragraph">
        In una partita cash game, invece, viene richiesto ai giocatori attivi di
        mettere l'invito o andare in sitout non partecipando alla mano. Tramite le
        opzioni è possibile impostare l'invito automatico per i cash games.
      </p>
      <h2 className="titolo2">Distribuzione delle carte</h2>
      <p className="paragraph">
        La distribuzione avviene sempre a partire dal primo giocatore alla sinistra
        del mazziere. In una mano ci possono essere fino a 4 giri di distribuzione.
      </p>
      <p className="paragraph">
        Nel primo giro di distribuzione che avviene dopo l'invito, vengono
        distribuite 2 carte a ciascun giocatore. La prima sarà coperta e la seconda
        scoperta. Nel secondo, nel terzo e nel quarto giro viene distribuita una carta
        scoperta. In tutto un giocatore può avere fino ad un massimo di 5 carte.
      </p>
      <p className="paragraph">Al termine di una fase di distribuzione si passa alla fase di puntata. </p>
      <h2 className="titolo2">Girata vele</h2>
      <p className="paragraph">
        Dopo la fase di puntata a seguito della distribuzione della quinta carta,
        qualora siano state disposte sul tavolo delle vele si passa alla fase di girata vele.
      </p>
      <p className="paragraph">In base ad ogni variante di vele previste è possibile che vengano girate una o più vele contemporaneamente. </p>
      <p className="paragraph">Al termine della girata vele si passa nuovamente alla fase di puntata. </p>
      <h2 className="titolo2">Puntata</h2>
      <p className="paragraph">
        In questa fase il primo giocatore a parlare è colui che mostra il miglior
        punteggio considerando le sue carte scoperte e le eventuali combinazioni di vele.
      </p>
      <p className="paragraph">In questa fase di puntata le azioni disponibili sono: </p>
      <ul itemType="disc" className="paragraph">
        <li>
          <strong>Passo</strong> - equivale ad uscire dalla mano e a rinunciare al
          piatto. Nel caso in cui un giocatore decida di passare senza che ci siano
          state fatte delle puntate , gli viene richiesto di confermare la scelta.
        </li>
        <li>
          <strong>Busso</strong> - si passa la parola al giocatore successivo.
          <br />
          Nel caso in cui tutti giocatori rimasti in gioco bussino si procede alla
          fase successiva. Quest'azione è disponibile solo se nella fase incorso non è
          stata fatta alcuna puntata.
        </li>
        <li>
          <strong>Punto - </strong>viene puntata una somma pari all'importo
          selezionato. Gli altri giocatori rimasti in gioco possono adeguarsi, oppure
          rilanciare. Dopo la prima puntata non è più possibile "puntare" ma solo
          "rilanciare" e "contro rilanciare", "passare" o "vedere".
        </li>
        <li>
          <strong>Rilancio</strong> - viene puntata una somma superiore all'ultima
          puntata effettuata sia puntata normale, sia rilancio che controrilancio. Il
          rilancio apre a nuovi contro rilanci solo se maggiore o uguale del doppio
          dell'ultima puntata effettuata. Quest'azione è disponibile solo a seguito di
          una puntata.
        </li>
        <li>
          <strong>Vedo - </strong>equivale ad adeguarsi all'ultima puntata
          effettuata. Quest'azione è disponibile solo a seguito di una puntata o di un
          rilancio.
        </li>
      </ul>
      <p className="paragraph">
        Se in una delle fasi di gioco un giocatore punta e non viene seguito da
        nessun altro giocatore, egli vince la mano, e senza dover continuare nelle varie
        fasi di gioco si aggiudica il piatto.
      </p>
      <p className="paragraph">
        Nel caso un giocatore finisca le chips a disposizione, durante una fase di
        puntata (incluso l'invito) , rimane comunque attivo fino alla fine della mano,
        partecipando però solo al piatto che si è creato sino a quel momento.
      </p>
      <p className="paragraph">
        Se tale situazione si verifica con solo un altro giocatore ancora in gioco
        non ci saranno più altre puntate, e si procede alla distribuzione di tutte le 5
        carte e alla girata delle vele se presenti fino alla determinazione del
        vincitore della mano.
      </p>
      <p className="paragraph">
        Se ciò accade con altri giocatori ancora in gioco, essi potranno continuare
        il regolare svolgimento della mano e delle puntate, creando un altro piatto
        aggiuntivo, a cui partecipano solo loro, denominato <strong>piatto secondario</strong>.
      </p>
      <h2 className="titolo2">Fase finale: esibizione punteggio e assegnazione piatti</h2>
      <p className="paragraph">
        Al termine dell'ultima fase di puntata se è rimasto in gioco un solo
        giocatore questo incassa tutto il piatto e non è obbligato a mostrare il proprio
        punteggio. Gli verrà richiesto di mostrare le sue carte o di nasconderle.
        Qualora non decida entro il tempo limite le carte verranno automaticamente
        nascoste.
      </p>
      <p className="paragraph">
        Qualora ci sia più di un giocatore rimasto in gioco, a partire dal giocatore
        che fatto l'ultimo rilancio, mostrano automaticamente le carte i giocatori che
        hanno un punteggio superiore al miglior punteggio mostrato. Inoltre mostrano
        automaticamente le carte i giocatori che durante la mano hanno rilanciato
        puntando tutto il loro credito. Agli altri giocatori che non ricadono nei casi
        precedenti viene richiesto di mostrare o nascondere le proprie carte.
      </p>
      <p className="paragraph">
        Terminata la fase di esibizione delle carte si passa all'assegnazione del
        piatto e dei piatti secondari.
      </p>
      <p className="paragraph">
        Il giocatore con la mano vincente(secondo i punteggi descritti nel paragrafo
        <strong>Punteggi</strong>) si aggiudica il piatto. Nel caso ci siano un piatto
        principale e uno o più piatti secondari, si stabilisce per ogni piatto il
        giocatore vincente tra quelli che hanno diritto all'assegnazione dello stesso.
      </p>
      <h2 className="titolo2">Punteggi </h2>
      <p className="paragraph">Valgono le seguenti regole generali: </p>
      <p className="paragraph">· l'ordine decrescente del valore delle carte è: A - K - Q - J - 10 - 9 e se usate 8 - 7 - 6 - 5 </p>
      <p className="paragraph">· l'ordine di valore decrescente dei semi è <span className="testorosso">♥ cuori</span> -
        <span className="testorosso">♦ quadri</span> - ♣ fiori - ♠
        picche: questa regola è conosciuta anche come la regola del Come Quando Fuori
        Piove.
      </p>
      <p className="paragraph">
        · le combinazioni vengono valutate prima in base alla categoria, e poi in
        base al valore e ai semi delle carte.
      </p>
      <p className="paragraph">Le combinazioni possibili in ordine di valore crescente sono: </p>
      <p className="paragraph"><strong>Carta Alta</strong></p>
      <p className="paragraph">es: <span className="testorosso">A♥</span> K♠ 10♠ <span className="testorosso">8♦</span> 7♣ </p>
      <p className="paragraph">Questo è la combinazione di minor valore realizzabile. </p>
      <p className="paragraph">È superiore la combinazione che presenta fra le proprie carte quella di valore maggiore. </p>
      <p className="paragraph">In caso di parità, è superiore quella che presenta come seconda carta quella di valore maggiore e così di seguito. </p>
      <p className="paragraph">
        In caso di ulteriore parità, a partire dalla carta di maggior valore sino a
        quella di minor valore si confrontano i semi con la regola Come Quando Fuori
        Piove.
      </p>
      <p className="paragraph"><strong>Coppia</strong></p>
      <p className="paragraph">es: K♣ <span className="testorosso">J♥</span> 10♠ <span className="testorosso">10♥ 7♦ </span></p>
      <p className="paragraph">La coppia presenta due carte dello stesso valore. </p>
      <p className="paragraph">
        In caso di parità di coppie si valutano le tre carte residue applicando la
        stessa regola della Carta Alta (quindi prima i valori e poi i semi).
      </p>
      <p className="paragraph"><strong>Doppia coppia</strong> </p>
      <p className="paragraph">es: Q♣ Q♠ <span className="testorosso">9♦</span> 9♣ <span className="testorosso">8♥ </span> </p>
      <p className="paragraph">Due coppie. </p>
      <p className="paragraph">È superiore il giocatore che ha la coppia di valore maggiore </p>
      <p className="paragraph">In caso di parità si guarda la seconda coppia </p>
      <p className="paragraph">In caso di ulteriore parità si guarda il valore della quinta carta. </p>
      <p className="paragraph">In caso le quinte carte mostrino lo stesso valore, conta il seme della quinta carta. </p>
      <p className="paragraph"><strong>Tris</strong> </p>
      <p className="paragraph">es: <span className="testorosso">A♦ J♥</span> J♣ J♠ 8♠ </p>
      <p className="paragraph">Tre carte dello stesso valore. </p>
      <p className="paragraph">In caso di parità di tris si applica la regola della carta alta considerando le due carte residue. </p>
      <p className="paragraph"><strong>Scala</strong> </p>
      <p className="paragraph">es: K♣ <span className="testorosso">Q♥</span> J♠ <span className="testorosso">10♦ 9♥</span> </p>
      <p className="paragraph">Sequenza di carte in ordine di valore, appartenenti a 2 o più semi. </p>
      <p className="paragraph">
        Fra due scale è superiore quella che comprende la carta di valore più alto.
        In caso di ulteriore parità si guardano i semi a partire dalla carta più alta
        rispettando l'ordine Cuori, Quadri, Fiori, Picche.
      </p>
      <p className="paragraph">NB: <em>L'asso può aprire la scala se posto dopo la carta di valore più basso tra quelle del mazzo </em></p>
      <p className="paragraph">
        (<span className="testorosso">10♦ 9♦</span> 8♠ 7♣
        <span className="testorosso">A♥</span> se i giocatori sono 4).</p><p className="paragraph">
        Ugualmente può 	chiudere la scala ponendolo prima del K (A♠
        <span className="testorosso">K♦</span> Q♣ <span className="testorosso">J♥ 10♦</span> ).  Nel
        primo caso la scala sarà definita "minima", nel secondo "massima"; le scale che
        non comprendono l'asso sono definite "medie".
      </p>
      <p className="paragraph"><strong>Full</strong> </p>
      <p className="paragraph">es: <span className="testorosso">J♥ J♦</span> J♣ 6♠ 6♣ </p>
      <p className="paragraph">Tre carte dello stesso valore e una coppia. </p>
      <p className="paragraph">In caso di parità di tris si guarda il valore della coppia. </p>
      <p className="paragraph"><strong>Colore</strong> </p>
      <p className="paragraph">es: <span className="testorosso">A♦ K♦ J♦ 9♦ 8♦ </span> </p>
      <p className="paragraph">
        Cinque carte dello stesso seme, non in scala. Fra due colori è superiore
        quello con il seme più alto secondo la regola Come Quando Fuori Piove. Es.
        <span className="testorosso"> K♥ Q♥ 10♥ 9♥ 8♥</span> batte A♠ K♠ J♠ 9♠ 8♠ .
      </p>
      <p className="paragraph">In caso di parità di seme a partire dalla carta più alta si confrontano i valori delle 5 carte. </p>
      <p className="paragraph"><strong>Poker</strong> </p>
      <p className="paragraph">es: <span className="testorosso">Q♥ Q♦</span> Q♣ Q♠ 7♣ </p>
      <p className="paragraph">Quattro carte dello stesso valore. </p>
      <p className="paragraph">
        In caso di parità si guarda il valore della quinta carta e in caso di
        ulteriore parità si guarda il seme della quinta carta
      </p>
      <p className="paragraph"><strong>Scala reale</strong> </p>
      <p className="paragraph">es: K♠ Q♠ J♠ 10♠ 9♠ </p>
      <p className="paragraph">Scala con cinque carte dello stesso seme. </p>
      <p className="paragraph">In caso di parità della carta di maggior valore si guarda il seme. </p>
      <p className="paragraph">
        NB.1: <em>Nel caso in cui, grazie alle vele presenti lo stesso giocatore
          realizzi 2 scale reali, ai fini del confronto con i punteggi degli altri
          giocatori vale sempre la scala reale superiore per seme e poi per valore. </em>
      </p>
      <p className="paragraph">
        NB.2: <em>La scala reale massima di cuori (scala reale che termina con l'asso
          di cuori) è il punto più forte nel poker, ma non garantisce la certezza assoluta
          della vittoria. Infatti, nel caso rarissimo ma non impossibile di due o più
          scale reali, la scala minima di picche è superiore alla massima di cuori. </em>
      </p>
      <h2 className="titolo2">Sit-out nella Telesina </h2>
      <p className="paragraph">Rammentiamo che un giocatore va in stato di sitout nei seguenti casi: </p>
      <ul itemType="disc" className="paragraph">
        <li>per sua scelta, cliccando su "sitout", </li>
        <li>perché in una fase di puntata non comunica la sua decisione entro il <em>tempo massimo,</em> </li>
        <li>· perché, all'inizio di una mano (solo tavoli cash), il suo credito residuo è inferiore al <em>credito residuo minimo.</em></li>

      </ul>
      <p className="paragraph">
        Un giocatore presente al tavolo può uscire dallo stato di sitout cliccando
        sul tasto "sono tornato", oppure automaticamente nel caso di rientro nel tavolo.
      </p>
      <p className="paragraph">
        Se un giocatore si trova in sitout durante la mano a cui sta partecipando, e
        successivamente deve prendere una decisione in una fase di puntata:
      </p>
      <p className="paragraph">
        · se è consentito "bussare", bussa automaticamente rimane in gioco anche se
        in stato di sitout
      </p>
      <p className="paragraph">
        · se invece è necessario mettere dei soldi nel piatto per continuare a
        partecipare al gioco, perde il diritto di partecipare al piatto e passa la mano.
      </p>
      <p className="paragraph">
        Unica eccezione è il caso in cui un giocatore sitout abbia già puntato tutte
        le sue chips nella mano in corso. In questo caso partecipa al piatto principale
        ed eventuali piatti secondari come se fosse normalmente in gioco.
      </p>
      <h2 className="titolo2">Tempo di giocata e Time Bank </h2>
      <p className="paragraph">
        Il tempo di giocata, ovvero in cui il giocatore può decidere la propria
        mossa, in un tavolo normale è di 30 secondi.
      </p>
      <p className="paragraph">
        Se il giocatore non gioca entro questo tempo prefissato, il sistema prenderà
        automaticamente la decisione al posto del giocatore. Se inoltre questo avviene
        durante una fase di puntata va in sitout e:
      </p>
      <p className="paragraph">
        · se è consentito "bussare", bussa automaticamente rimane in gioco anche se
        in stato di sitout
      </p>
      <p className="paragraph">
        · se invece è necessario mettere dei soldi nel piatto per continuare a
        partecipare al gioco, perde il diritto di partecipare al piatto e passa la mano.
        In questo caso, se previsto dal tavolo è possibile usufruire di una scorta di
        ulteriori secondi, detta "time bank", per prendere la propria decisione nella
        fase di puntata. Il "time bank" non si rigenera ogni volta che viene utilizzato,
        e se usato più volte ricomincia sempre dalla scorta di secondi rimasti.
      </p>
    </ContentTabs>
  );
};

export const TelesinaComeSiGioca = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Telesina - Come si gioca</h2>
      <h2 className="titolo2">Invito (solo cash games)</h2>
      <p className="paragraph">Nella fase di invito sono visualizzati due tasti: </p>
      <ul itemType="disc" className="paragraph">
        <li><strong>SITOUT</strong> : non si partecipa alla mano e si va sitout </li>
        <li><strong>INVITO</strong> : mettendo l'invito si partecipa alla mano </li>
      </ul>
      <h2 className="titolo2">Scelta Variante</h2>
      <p className="paragraph">In unico pannello è possibile decidere le 2 varianti che seguono: </p>
      <p className="paragraph">
        Se la <strong>Variante vele</strong> è libera si visualizzano dei riquadri da
        selezionare per decidere la variante vele desiderata.
      </p>
      <p className="paragraph">
        Se la variante <strong>Distribuzione carta coperta </strong>è libera si
        visualizzano tre tasti :
      </p>
      <ul itemType="disc" className="paragraph">
        <li><strong>Mai</strong>: le carte vengono distribuite nel modo classico(la prima coperta e le seguenti scoperte) </li>
        <li><strong>Solo al primo giro</strong>: solo al primo giro vengono date due carte coperte </li>
        <li><strong>Sempre</strong>: le carte vengono sempre distribuite coperte</li>
      </ul>
      <p className="paragraph">Per confermare le selezioni precedenti è presente il tasto "conferma". </p>
      <p className="paragraph"><strong>Scelta carta da scoprire</strong> </p>
      <p className="paragraph">
        Viene visualizzato un pannello che riporta le due carte coperte. Si clicca su
        una delle due e poi si clicca sul tasto "conferma" per comunicare la scelta.
      </p>
      <h2 className="titolo2">Puntata</h2>
      <p className="paragraph">
        durante la fase di puntata il giocatore di turno visualizzerà i tasti per
        decidere quale azione effettuare tra le seguenti:
      </p>
      <ul itemType="disc" className="paragraph">
        <li>
          <strong>PASSO</strong> - equivale ad uscire dalla mano e a rinunciare al
          piatto. Nel caso in cui un giocatore decida di passare senza che ci siano
          state fatte delle puntate , gli viene richiesto di confermare la scelta.
        </li>
        <li>
          <strong>BUSSO</strong> - si passa la parola al giocatore successivo. Nel
          caso in cui tutti giocatori rimasti in gioco bussino si procede alla fase
          successiva. Quest'azione è disponibile solo se nella fase incorso non è
          stata fatta alcuna puntata.
        </li>
        <li>
          <strong>PUNTO - </strong>viene puntata una somma pari all'importo
          selezionato. Gli altri giocatori rimasti in gioco possono adeguarsi, oppure
          rilanciare. Dopo la prima puntata non è più possibile "puntare" ma solo
          "rilanciare" e "contro rilanciare", "passare" o "vedere".
        </li>
        <li>
          <strong>RILANCIO</strong> - viene puntata una somma superiore all'ultima
          puntata effettuata sia puntata normale, sia rilancio che controrilancio. Il
          rilancio apre a nuovi contro rilanci solo se maggiore o uguale del doppio
          dell'ultima puntata effettuata. Quest'azione è disponibile solo a seguito di
          una puntata.
        </li>
        <li>
          <strong>VEDO - </strong>equivale ad adeguarsi all'ultima puntata
          effettuata. Quest'azione è disponibile solo a seguito di una puntata o di un
          rilancio.
        </li>
      </ul>
      <p className="paragraph">
        <strong>NB.1</strong> : per i tasti PUNTO, RILANCIO, VEDO, qualora l'importo
        associato fosse pari al credito disponibile il testo diventa "RESTO".
      </p>
      <p className="paragraph">
        <strong>NB.2</strong> : qualora sia consentito puntare o rilanciare è
        presente un riquadro sulla destra dove si può decidere l'importo da associare
        alla decisione. L'importo è possibile digitarlo nell'apposito riquadro oppure si
        può stabilire utilizzando i tasti di trascinamento o moltiplicazione.
      </p>
      <h2 className="titolo2">Esibizione punteggio</h2>
      <p className="paragraph">
        Nella fase finale è possibile che vanga richiesto, se si desidera mostrare la
        propria carta scoperta rivelando il proprio punteggio. Vengono visualizzati i
        seguenti tasti:
      </p>
      <ul itemType="disc" className="paragraph">
        <li><strong>MOSTRO</strong> : la carta viene mostrata agli altri giocatori</li>
        <li><strong>NASCONDO</strong> : la carta non viene mostrata </li>
      </ul>
    </ContentTabs>
  );
};

export const TelesinaRegoleOpzionali = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Telesina - Regole opzionali</h2>
      <h2 className="titolo2">Regole opzionali</h2>
      <p className="paragraph">
        In questo capitolo saranno descritte alcune regole del Poker 5 carte che vengono
        spesse utilizzate nelle partite dal vivo e che sono state definite "opzionali"
        perché nel sito saranno proposte partite con e senza queste regole.
      </p>
      <h2 className="titolo2">Vele</h2>
      <p className="paragraph">
        All'inizio della mano dopo la distribuzione delle prime due carte si
        dispongono sul tavolo un numero di vele stabilito dalle regole del tavolo.
      </p>
      <p className="paragraph">
        Ogni variante di vele è caratterizzata da un numero un numero di <strong>
        girate, </strong>ciascuna delle quali è seguita da un ulteriore fase di puntata,
        e da <strong>combinazioni </strong>di vele che contribuiscono a migliorare il
        punteggio di ogni giocatore. AI fini del punteggio si possono utilizzare le vele
        comuni ad un sola combinazione.
      </p>
      <p className="paragraph">
        Ad esempio, supponendo che una variante di vele sia formata da 2
        combinazioni, la prima dalle vele A,E,B e la seconda dalle vele C,E,D ogni
        giocatore per migliorare il proprio punteggio potrà usare solo vele della
        combinazione A,E,B o solo vele della combinazione C,E,D ma mai mischiare le vele
        tra due combinazioni (ad esempio la vela A e la vela C).
      </p>
      <p className="paragraph">All'interno della combinazione si possono utilizzare quante vele si vogliono da zero a tutte.</p>
      <p className="paragraph">
        Al termine della fase di puntata, che segue la distribuzione della quinta
        carta, le vele vengono girate nelle modalità di seguito descritte:
      </p>
      <p className="paragraph"><em><u>Libera</u></em> : all'inizio della mano il mazziere sceglie una delle varianti sotto descritte </p>
      <p className="paragraph"><em><u>Nessuna vela</u></em> : si passa direttamente alla fase finale </p>
      <p className="paragraph"><em><u>Una vela</u></em> : disposta al centro del tavolo si effettua l'unica girata della vela A </p>
      <p className="paragraph"><img height="89" src="/images/regulation/poker/1vela.jpg" width="120" /></p>
      <p className="paragraph"><em><u>Due vele</u></em> : disposte orizzontalmente sul tavolo, si effettuano 2 girate: prima A e poi B. </p>
      <p className="paragraph">Le due vele formano una combinazione. </p>
      <p className="paragraph"><img height="88" src="/images/regulation/poker/2vele.jpg" width="118" /></p>
      <p className="paragraph"><em><u>Tre vele</u></em> : disposte orizzontalmente si effettuano 3 girate: prima A, poi C e infine B. </p>
      <p className="paragraph">Le tre vele formano una combinazione </p>
      <p className="paragraph"><img height="89" src="/images/regulation/poker/3vele.jpg" width="121" /></p>
      <p className="paragraph"><em><u>Croce</u></em> : 5 vele disposte sul tavolo in due file incrociate.</p>
      <p className="paragraph">Si effettuano 3 girate: prima A - C, poi B - D, e infine E. </p>
      <p className="paragraph"><img height="89" src="/images/regulation/poker/croce.jpg" width="121" /></p>
      <p className="paragraph">Le combinazioni in questo caso sono 2: </p>
      <p className="paragraph"><img height="90" src="/images/regulation/poker/croceH.jpg" width="121" /><img height="89" src="/images/regulation/poker/croceV.jpg" width="120" />&nbsp;&nbsp;</p>
      <p className="paragraph"><em><u>H</u></em> : 7 vele disposte a formare visivamente la lettera "H". </p>
      <p className="paragraph"><img height="89" src="/images/regulation/poker/H.jpg" width="121" /></p>
      <p className="paragraph">Si effettuano 4 girate: prima C - G, poi B - F, poi A - E, e infine D. </p>
      <p className="paragraph">
        <img height="89" src="/images/regulation/poker/H2bottom.jpg" width="121" />
        <img height="88" src="/images/regulation/poker/Horizontal3.jpg" width="119" />
        <img height="89" src="/images/regulation/poker/H2top.jpg" width="120" />
      </p>
      <p className="paragraph">Le combinazioni possibili sono 7: </p>
      <p className="paragraph">
        <img height="90" src="/images/regulation/poker/H3left.jpg" width="121" />
        <img height="90" src="/images/regulation/poker/H3right.jpg" width="122" />
        <img height="90" src="/images/regulation/poker/Hdiagonall.jpg" width="121" />
        <img height="90" src="/images/regulation/poker/Hdiagonalr.jpg" width="121" />
      </p>
      <p className="paragraph"><em><u>Piramide</u></em> : 7 vele disposte a "V" capovolta. </p>
      <p className="paragraph">Le girate sono 4: prima A - G, poi B - F, poi C - E, e infine D. </p>
      <p className="paragraph"><img height="89" src="/images/regulation/poker/piramide.jpg" width="121" /></p>
      <p className="paragraph">Le combinazioni possibili sono due: </p>
      <p className="paragraph">
        <img height="88" src="/images/regulation/poker/piramideL.jpg" width="118" />
        <img height="88" src="/images/regulation/poker/piramideR.jpg" width="119" />
      </p>
      <p className="paragraph"><em><u>Ascensore</u></em> : 9 vele </p>
      <p className="paragraph">Le girate sono 5 : prima D - I, poi C - H , poi B - G, poi A - F, e infine E</p>
      <p className="paragraph"><img height="90" src="/images/regulation/poker/Ascensore1.jpg" width="121" /></p>
      <p className="paragraph">Le combinazioni sono 4 e la vela centrale (detta "ascensore") è presente in tutte e 4 le combinazioni: </p>
      <p className="paragraph">
        <img height="90" src="/images/regulation/poker/Ascensore2.jpg" width="121" />
        <img height="88" src="/images/regulation/poker/Ascensore3.jpg" width="119" />
        <img height="89" src="/images/regulation/poker/Ascensore4.jpg" width="120" />
        <img height="89" src="/images/regulation/poker/ascensore5.jpg" width="121" />
      </p>
      <h2 className="titolo2">Distribuzione carta coperta</h2>
      <p className="paragraph">
        In base alle regole del tavolo è possibile che le carte che normalmente
        vengono distribuite già scoperte vengano distribuite coperte per poi far
        scegliere a ciascun giocatore quale delle due girare definitivamente. Le
        varianti possibili sono :
      </p>
      <p className="paragraph">· Libera: all'inizio della mano il mazziere sceglie una delle varianti sotto descritte </p>
      <p className="paragraph">· Mai: le carte vengono distribuite nel modo classico: la prima coperta e le seguneti scoperte </p>
      <p className="paragraph">
        · Solo al primo giro: solo al primo giro di distribuzione vengono date due
        carte coperte, nelle distribuzioni successive le carte vengono sempre
        distribuite scoperte
      </p>
      <p className="paragraph">
        · Sempre: le carte vengono distribuite sempre coperte ed ogni giocatore avrà
        la possibilità di tenere coperta la carta che preferisce
      </p>
      <h2 className="titolo2">Limiti di puntata</h2>
      <p className="paragraph">Le regole del tavolo possono prevedere i seguenti limiti di puntata </p>
      <p className="paragraph">
        · Nessuno (NL): la puntata è libera fino al massimo del proprio credito, in
        questo caso la puntata si definisce "RESTO"
      </p>
      <p className="paragraph">
        · Piatto (PL): la puntata massima è pari al piatto attuale + eventuali
        puntate della fase in corso + l'importo per vedere l'ultima puntata. <br />
        Ad esempio, nel piatto ci sono 100 chips e due giocatori prima del giocatore di
        turno hanno puntato 20 chips ciascuno (il primo ha puntato e il secondo ha
        visto). Il giocatore di turno può puntare fino ad un massimo di 160 chips
        (100+20+20+20 = 160)
      </p>
      <p className="paragraph">· Fisso (FL): la puntata e il rilancio hanno un importo fisso pari all'invito messo nella mano. </p>
    </ContentTabs>
  );
};

export const TelesinaOpzioniDiGiocoAutomatico = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Telesina - Opzioni di gioco automatico</h2>
      <h2 className="titolo2">Opzioni di gioco automatico</h2>
      <p className="paragraph">Il giocatore può impostare le seguenti regole di gioco automatico: </p>
      <ul itemType="disc" className="paragraph">
        <li><strong>Variante vele preferita.</strong> Il valore predefinito è "nessuna preferenza"</li>
        <li><strong>Utilizzare sempre la variante vele preferita.</strong>Se attivato ogni volta che si è mazziere verranno distribuite le vele secondo quanto impostato al punto precedente. Di default l'opzione è disabilitata</li>
        <li><strong>Variante di distribuzione preferita.</strong> Il valore predefinito è "nessuna preferenza"</li>
        <li><strong>Utilizzare sempre la variante distribuzione preferita.</strong>Se attivato ogni	volta che si è mazziere verranno distribuite le carte secondo quanto impostato al punto precedente. Di default il l'opzione è disabilitata</li>
        <li>
          <strong>Mostra / nascondi la tua mano.</strong>Le scelte possibili sono:
          <ul itemType="circle">
            <li><strong>Chiedimelo sempre:</strong>verrà sempre richiesto al giocatore di effettuare una scelta</li>
            <li><strong>Nascondo sempre:</strong>le carte verranno sempre nascoste automaticamente se permesso dal gioco e dalla situazione al tavolo</li>
            <li><strong>Mostro sempre:</strong>le carte verranno sempre mostrate.</li>
          </ul>
        </li>
        <li>
          <strong>Invito automatico(solo cash games):</strong> se attivato ogni volta che si deve mettere l'invito verrà messo automaticamente dal sistema. Di default l'opzione è disabilitata<br />
          Il valore predefinito è <strong>Chiedimelo sempre</strong>
        </li>
      </ul>
    </ContentTabs>
  );
};

export const TelesinaRegoleDeiTavoliCash = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Telesina - Regole specifiche dei tavoli cash </h2>
      <h2 className="titolo2">Regole specifiche dei tavoli cash </h2>
      <p className="paragraph">
        Al montepremi di ogni singola mano è destinata una percentuale della somma
        delle puntate dei giocatori, variabile sulla base della singola sessione di
        gioco, ma in tutti i casi non inferiore al 90%.
      </p>
      <p className="paragraph">
        L'importo del montepremi di una singola mano è sempre derivabile dalla somma
        delle puntate diminuite del margine lordo del concessionario (rake).
      </p>
      <p className="paragraph">
        Il rake massimo applicato al tavolo ("cap") è chiaramente indicato nel
        pannello informativo presente sulla destra della lobby e sul tavolo di gioco.
      </p>
      <h2 className="titolo2">La vincita del giocatore con il punteggio migliore </h2>
      <p className="paragraph">Il rake che viene prelevato da ogni piatto è calcolato come segue: </p>
      <p className="paragraph">Piatto * %rake </p>
      <p className="paragraph">esempio con rake al 5% </p>
      <p className="paragraph">3 giocatori mettono ciascuno €10 nel piatto </p>
      <p className="paragraph">Piatto = €30 </p>
      <p className="paragraph">Rake = €30 * 5% = €1,5 </p>
      <p className="paragraph">sempre seguendo l'esempio se il cap fosse pari a €1 </p>
      <p className="paragraph">il rake prelevato sarebbe pari al cap quindi a €1 </p>
      <p className="paragraph">la vincita del giocatore con il punteggio migliore sarebbe quindi pari a €29 = €30 - €1 </p>
      <h2 className="titolo2">Abbandono automatico nei tavoli cash </h2>
      <p className="paragraph">
        Se un giocatore salta dieci turni di gioco consecutivi perchè in sitout, è
        fatto alzare automaticamente dal tavolo con accredito del suo credito residuo
        sul suo conto di gioco.
      </p>
      <p className="paragraph">
        Nei tavoli cash in caso di sit-out il giocatore ha un tempo massimo di 10
        minuti per rientrare in gioco. Passati i 10 minuti viene fatto alzare dal tavolo
        e il credito al tavolo viene accreditato sul conto di gioco.
      </p>
    </ContentTabs>
  );
};