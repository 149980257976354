import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'shared/components/button';
import ModalComponent from 'shared/components/modal';
import './index.scss';
import { selectSessionInfo, SessionInfo } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';

type ModalComponenProps = {
  open: boolean;
  onClose?: () => void;
};

const RedirectLoginModal = (props: ModalComponenProps) => {
    const { t } = useTranslation('common');
    const { open, onClose } = props;
    const [timer, setTimer] = useState(Number(process.env.REACT_APP_REDIRECT_LOGIN_TIMEOUT) || 30);
    const timerId = useRef(null);
    const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
    
    useEffect(() => {
        if(open) {
            timerId.current = setInterval(() => {
                setTimer(prev => {
                    if (prev === 0) {
                        // Clear the interval when the timer reaches 0 or less
                        if (timerId.current) {
                            clearInterval(timerId.current);
                        }
                        
                        onConfirmRedirect()
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        
        return () => {
            clearInterval(timerId.current)
            timerId.current = null;
        };
    }, [open])    

    const onConfirmRedirect = () => {
        onClose()
        localStorage.removeItem("token")        
        const casinoUrl = sessionInfo?.backUrl || "";
        window.location.href = casinoUrl;
      }


    return (
        <ModalComponent
            className="redirect-login-modal-container"
            open={open}
            title={``}
            showIconClose={false}
        >
            <p><span>{t('Authentication failed, you will automatically redirect to login page after')}:</span> <strong>{timer}</strong>s</p>
            <div style={{ marginTop: "8px" }}>
                <ButtonComponent
                    variant="contained"
                    onClick={onConfirmRedirect}
                    size={"medium"}
                    className="modal-button"
                    title={t("Redirect now")}
                    color={'primary'}
                />
            </div>
        </ModalComponent>
    );
};

export default RedirectLoginModal;
