export function mapGameIdToGame(id: number) {
    switch (id) {
        case 3:
            return 'ItalianPoker';
        case 4:
            return 'Scopa';
        case 5:
            return 'Burraco';
        case 6:
            return 'Scala40';
        case 7:
            return 'Briscola';
        case 8:
            return 'SetteEMezzo';
        case 9:
            return 'Tresette';
        case 10:
            return 'AssoPigliaTutto';
        case 11:
            return 'Cirulla';
        case 12:
            return 'Bestia';
        case 13:
            return 'Scopone';
        case 14:
            return 'Solitario';
        case 15:
            return 'Telesina';
        case 16:
            return "Peppa";
    }
}

export function mapGameIdToGameName(id: number) {
    switch (id) {
        case 3:
            return 'Italian Poker';
        case 4:
            return 'Scopa';
        case 5:
            return 'Burraco';
        case 6:
            return 'Scala 40';
        case 7:
            return 'Briscola';
        case 8:
            return 'Sette E Mezzo';
        case 9:
            return 'Tresette';
        case 10:
            return 'Asso Piglia Tutto';
        case 11:
            return 'Cirulla';
        case 12:
            return 'Bestia';
        case 13:
            return 'Scopone';
        case 14:
            return 'Solitario';
        case 15:
            return 'Telesina';
        case 16:
            return "Peppa";
    }
}
