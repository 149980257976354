import { useTranslation } from 'react-i18next';
import ModalComponent from 'shared/components/modal';


const PassModal = ({ isOpen, onClose, content }) => {
  const { t } = useTranslation();

  return (
    <ModalComponent 
      open={isOpen} 
      onClose={onClose} 
      className="modal-main"
      size='small'
      title={t("Notification")}
    >
      <p>{content}</p>
    </ModalComponent>
  );
};

export default PassModal;
