import React from 'react';
import { List, ListItem, styled } from '@mui/material';
import { SyncIconSvg } from '../../assets/icons/SyncIconSvg';
import { MiniFAButton } from '../index';
import { COLORS } from '../../constants/colors';

const ACTIONS = [
  {
    id: 1,
    title: 'Action 1',
  },
  {
    id: 2,
    title: 'Action 2',
  },
  {
    id: 3,
    title: 'Action 3',
  },
  {
    id: 4,
    title: 'Action 4',
  },
];

const Text = styled('p')(({ theme }) => ({
  color: '#fff',
  fontSize: '0.75rem',
  lineHeight: '20px',
  fontWeight: 400,
  [theme.breakpoints.up('md')]: {
    color: '#000',
    marginLeft: '16px',
    fontSize: '0.875rem',
    fontFamily: 'Inter',
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  width: '120px',
  padding: 0,
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '60%',
    right: '24px',
    transform: 'translateY(-50%)',
    'li:first-of-type': {
      transform: 'translate(72px, 27px)',
    },
    'li:last-of-type': {
      transform: 'translate(72px, -27px)',
    },
    '@media (orientation: landscape)': {
      top: '50%',
    },
  },
  [theme.breakpoints.up('md')]: {
    position: 'static',
    width: '170px',
  },
}));

const ActionListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  width: '48px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    padding: '10px 46px 10px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '12px 0',
  },
  '&:hover': {
    button: {
      backgroundColor: COLORS.base_light,
      boxShadow: '0px 3px 12px rgba(36, 222, 219, 0.46)',
    },
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    width: 'auto',
  },
}));

export const ActionsList = () => (
  <StyledList>
    {ACTIONS.map((item) => (
      <ActionListItem key={`actions-list-${item.id}`}>
        <MiniFAButton icon={<SyncIconSvg />} />
        <Text>{item.title}</Text>
      </ActionListItem>
    ))}
  </StyledList>
);
