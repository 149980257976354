import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const ScopaIlMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scopa - Il mazzo di carte</h2>
            <h2 className="titolo2">Il mazzo di carte </h2>
            <p className="paragraph">Si gioca con un mazzo di 40 carte italiane tradizionali. </p>
            <p className="paragraph">I giocatori possono selezionare il tipo di carte (napoletane, piacentine…).</p>
            <p className="paragraph">E' anche possibile selezionare le carte francesi senza i Jolly, gli 8, i nove e i dieci. </p>
            <p className="paragraph">La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
            <table className="bordi" border={1} cellPadding="5" width="221">
                <tbody>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">denari </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">quadri </p></td>
                        <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♦ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">coppe </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">cuori </p></td>
                        <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♥ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">bastoni </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">fiori </p></td>
                        <td className="bordi" width="43"><p className="paragraph">♣ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">spade </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">picche </p></td>
                        <td className="bordi" width="43"><p className="paragraph">♠ </p></td>
                    </tr>
                </tbody>
            </table>
            <h2 className="titolo2">Il valore delle carte</h2>
            <table border={1} cellPadding="0" cellSpacing="0" width="180">
                <tbody>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>Re (K)</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>10</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>Cavallo (Q)</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>9</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>Fante (J)</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>8</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>7</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>7</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>6</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>6</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>5</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>5</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>4</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>4</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>3</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>3</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>2</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>2</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="104"><p className="paragraph"><strong>Asso</strong> </p></td>
                        <td className="bordi" width="76"><p className="paragraph"><strong>1</strong> </p></td>
                    </tr>
                </tbody>
            </table>
        </ContentTabs>
    );
};

export const ScopaSvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scopa - Svolgimento del gioco </h2>
            <h2 className="titolo2">Designazione del mazziere</h2>
            <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzionedelle carte. </p>
            <p className="paragraph">Quando il gioco ha inizio, il mazziere viene stabilito casualmente dalsistema. </p>
            <p className="paragraph">
                Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra
                stessi giocatori, il mazziere gira in senso antiorario e cioè il prossimo
                mazziere sarà quello seduto alla destra del mazziere precedente.
            </p>
            <h2 className="titolo2">Distribuzione delle carte</h2>
            <p className="paragraph">
                Il sistema mescola le carte e ne da 3 ad ogni giocatore distribuendole una
                alla volta, in senso antiorario, partendo dal giocatore alla destra del
                mazziere.
            </p>
            <p className="paragraph">
                Quindi vengono poste 4 carte scoperte in tavola: se almeno 3 delle 4 carte
                iniziali sul tavolo sono Re, la mano non viene giocata e si effettua una nuova
                distribuzione delle carte poiché non sarebbe possibile fare alcuna scopa.
            </p>
            <h2 className="titolo2">Il gioco della carta</h2>
            <p className="paragraph">
                Il giocatore seduto alla destra del mazziere è il <em>giocatore di mano</em>,
                cioè il <u>primo</u> a dover effettuare la <em>giocata.</em>
            </p>
            <p className="paragraph">Dopo che ogni giocatore ha effettuato la giocata,la mano passa al giocatore successivo in senso antiorario. </p>
            <p className="paragraph">Ogni giocatore di mano deve giocare una carta tenendo presente che:</p>
            <p className="paragraph"><strong>a) può prendere la carta in tavola che ha lo stesso valore della carta da lui giocata,</strong> </p>
            <p className="paragraph"><strong>b) può prendere le carte in tavola la cui somma è uguale al valore della carta da lui giocata, </strong></p>
            <p className="paragraph">
                Se vi sono diverse combinazioni di carte che il giocatore può prendere
                giocando la sua carta, si è obbligati a prendere la carta in tavola dello stesso
                valore. Fatta salva la regola poc'anzi enunciata, se vi sono più combinazioni
                composte da un diverso numero di carte, il giocatore può scegliere quella
                combinazione che più gli conviene.
            </p>
            <p className="paragraph">
                Per esempio: se vi sono sul tavolo un tre, un cinque e un fante (valore 8),
                il giocatore giocando un fante (valore 8) può prendere solo il fante; se in
                tavola ci sono un asso (valore 1), un tre, un quattro e un cinque, il giocatore,
                giocando un cavallo (valore 9) può scegliere se prendere asso, tre e cinque
                oppure quattro e cinque a suo piacimento.
            </p>
            <p className="paragraph">Se il giocatore può fare almeno un tipo di presa è costretto a prendere e non può quindi scartare la carta. </p>
            <p className="paragraph"><strong>Se un giocatore prende tutte le carte in tavola "fa scopa" ad eccezione che ciò avvenga nell'ultima giocata dell'ultima mano di una smazzata.</strong></p>
            <h2 className="titolo2">Punteggi </h2>
            <p className="paragraph">Al termine di ogni smazzata si esaminano le carte prese da ciascun giocatore o coppia e le scope girate e si calcolano i punteggi come segue: </p>
            <table className="bordi" border={1} cellPadding="5" width="500">
                <tbody>
                    <tr>
                        <td className="bordi" width="121"><p className="paragraph"><em>Scopa</em> </p></td>
                        <td className="bordi" width="397"><p className="paragraph">ogni scopa vale 1 punto, </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="121"><p className="paragraph"><em>Sette bello</em> </p></td>
                        <td className="bordi" width="397"><p className="paragraph">il sette di denari (o di quadri) vale un 1 punto, </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="121"><p className="paragraph"><em>Primiera</em> </p></td>
                        <td className="bordi" width="397"><p className="paragraph">vale 1 punto, </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="121"><p className="paragraph"><em>Denari</em> </p></td>
                        <td className="bordi" width="397"><p className="paragraph">6 o più carte di denari (o quadri) valgono 1 punto </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="121"><p className="paragraph"><em>Carte</em> </p></td>
                        <td className="bordi" width="397"><p className="paragraph">21 o più carte valgono 1 punto. </p></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph">Ai fini della primiera, il valore delle carte è il seguente: </p>
            <table border={1} cellPadding="0" width="457">
                <tbody>
                    <tr>
                        <td className="bordi" width="161"><p className="paragraph">CARTA </p></td>
                        <td className="bordi" width="290"><p className="paragraph">VALORE </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="161"><p className="paragraph">7 </p></td>
                        <td className="bordi" width="290"><p className="paragraph">21 punti </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="161"><p className="paragraph">6 </p></td>
                        <td className="bordi" width="290"><p className="paragraph">18 punti </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="161"><p className="paragraph">Asso </p></td>
                        <td className="bordi" width="290"><p className="paragraph">16 punti </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="161"><p className="paragraph">5, 4, 3, 2 </p></td>
                        <td className="bordi" width="290"><p className="paragraph">rispettivamente 15,14,13,12 punti </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="161"><p className="paragraph">Re, cavallo e fante </p></td>
                        <td className="bordi" width="290"><p className="paragraph">10 punti </p></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph">Si aggiudica la primiera il giocatore o coppia che mostra le 4 carte, una per ogni seme, la cui somma di valori è maggiore. </p>
            <h2 className="titolo2">Conclusione della partita</h2>
            <p className="paragraph">Le partite si concludono a seconda della tipologia di partita giocata: </p>
            <p className="paragraph">· Se è stato stabilito il numero di smazzate di cui è composta la partita vince la partita chi fa più punti al termine delle smazzate previste. </p>
            <p className="paragraph">
                · Se è stato stabilito il totale dei punti che un giocatore o una coppia deve
                raggiungere per aggiudicarsi la partita vince la partita chi, avendo superato il
                punteggio previsto, ha più punti dell'avversario.
            </p>
            <h2 className="titolo2">Parità</h2>
            <p className="paragraph">
                Nei tornei a eliminazione diretta (KO), se la partita finisse in parità,
                verrà giocata un'altra smazzata ("1° spareggio") e si aggiudicherà l'incontro
                chi farà più punti.
            </p>
            <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("2° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
            <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("3° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
            <p className="paragraph">Poiché non è ammissibile un gioco ad oltranza, viene stabilita la seguente regola: </p>
            <p className="paragraph">se anche il 3° spareggio finisse in parità, perde il giocatore che ha fatto l'ultima presa nel 3° spareggio, anche senza fare punti. </p>
        </ContentTabs>
    );
};

export const ScopaComeSiGioca = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scopa - Come si gioca</h2>
            <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
            <p className="paragraph">Per giocare una carta, quando è il proprio turno si può: </p>
            <p className="paragraph"><strong>Selezionare le carte ad una ad una</strong> </p>
            <p className="paragraph">
                Per effettuare una presa si può selezionare la carta in mano e le carte in
                tavola che si desidera prendere ad una ad una. Appena verrà completata una presa
                questa verrà effettuata.
            </p>
            <p className="paragraph">Se si seleziona una carta in mano che non consente prese apparirà sul tavolo una carta semitrasparente, cliccatela per scartare la carta. </p>
            <p className="paragraph"><strong>Effettuare una presa trascinando la carta sul tavolo</strong> </p>
            <p className="paragraph">Se si trascina una carta sul tavolo verrà evidenziata l'unica presa possibile o, se non ci sono prese possibili apparirà sul tavolo una carta semitrasparente.</p>
            <p className="paragraph">Per effettuare la presa evidenziata è sufficiente rilasciare la carta. </p>
            <p className="paragraph">Se non si vuole effettuare la presa evidenziata, si deve riposizionare la carta tra quelle in mano e poi rilasciarla. </p>
            <p className="paragraph">Può succedere che sia possibile effettuare più prese, in questo caso bisognerà spostarsi su una carta in tavola che consente una sola presa. </p>
            <p className="paragraph">
                Ad esempio, se in tavola fossero presenti 5 ,5, 3, 2, giocando un 10 e
                posizionandosi su un 2, il sistema non può sapere quali dei due 5 si desidera
                prendere. In questo caso è necessario spostare la carta un 5 in modo che sia
                possibile comporre una sola presa.
            </p>
            <p className="paragraph">
                Può anche succedere che non sia in alcun modo possibile individuare una sola
                presa, in questo caso non resta che selezionare ad una ad una le carte come
                sotto descritto.
            </p>
            <p className="paragraph"><strong>Doppio clic su una carta in mano</strong> </p>
            <p className="paragraph">
                Il doppio clic su una carta in mano consente di scartare subito quella carta
                senza far prese. Se la carta selezionata col doppio clic consentisse possibili
                prese apparirà un messaggio di errore.
            </p>
            <p className="paragraph">Si precisa che: </p>
            <p className="paragraph">· per trascinare una carta bisogna cliccarla col pulsante sinistro del mouse e spostarsi dove si desidera tenendo il pulsante premuto, </p>
            <p className="paragraph">· dopo aver trascinato una carta, per rilasciarla è sufficiente smettere di tener premuto il pulsante sinistro del mouse, </p>
            <p className="paragraph">· per selezionare una carta è sufficiente cliccarci sopra col pulsante sinistro del mouse, </p>
            <p className="paragraph">· per deselezionare una carta già selezionata è sufficiente cliccarci sopra col pulsante sinistro del mouse. </p>
            <p className="paragraph">
                Si precisa che hanno valore solo le decisioni comunicate con questi tasti.
                Qualsiasi decisione di gioco comunicata in chat, oltre a non avere alcun valore,
                è severamente vietata.
            </p>
        </ContentTabs>
    );
};

export const ScopaPenalita = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scopa - Penalità</h2>
            <h2 className="titolo2">Penalità in caso di tempo scaduto o abbandono </h2>
            <p className="paragraph">
                Se giocatore fa scadere il tempo partita o il timeout o clicca sul tasto
                "abbandona" la partita ha termine e il giocatore <strong>perde la partita</strong> e la differenza punti è cosi stabilita:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>si assegnano tutti i punti ancora non aggiudicati all'avversario con l'aggiunta di una scopa , </li>
                <li>
                    se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il
                    timeout, la differenza punti è uguale a zero (ma la partita è comunque persa
                    da chi ha fatto scadere il tempo).
                </li>
            </ul>
            <p className="paragraph">Calcolo dei VP e MP in caso di Timeout o abbandono </p>
            <p className="paragraph">
                Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o
                abbandona farà 0 VP e la linea avversaria farà minimo 14 VP. I MP di entrambe le
                linee saranno calcolati dai VP in base ai corrispondenti minimi previsti dalla
                tabella dei VP.
            </p>
            <p className="paragraph"><em>Si sottolinea che quest'ultima regola sul timeout e abbandono è valida solo per determinare i MP e i VP per le classifiche dei tornei a girone e i livelli di abilità in caso di timeout e abbandono , ma non per determinare la differenza punti nei tavoli cash che vengono sempre determinati come descritto nei <a href="generali.php?skin=SNAI&amp;mobile=&amp;argomento=cashgenerico&amp;capitolo=tavoliturni">regolamenti generali</a> anche in caso di timeout e abbandono.</em> </p>
        </ContentTabs>
    );
};

export const ScopaRegoleOpzionali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scopa - Regole opzionali </h2>
            <h2 className="titolo2">Partite a "carte viste"</h2>
            <p className="paragraph">Saranno mostrate le carte viste nelle precedenti mani della smazzata; </p>
            <p className="paragraph">Nelle partite testa a testa a carte viste, l'ultima mano della smazzata si gioca a carte scoperte. </p>
            <h2 className="titolo2">Partite a coppie a "carte scoperte"</h2>
            <p className="paragraph">Saranno proposte partite a coppie nelle quali i compagni, senza essere visti dagli avversari, giocano a carte scoperte. </p>
        </ContentTabs>
    );
};

export const ScopaTabellaDeiVictoryPoints = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scopa - Tabella dei Victory Points</h2>
            <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
            <table className="bordi" border={1} cellPadding="5" cellSpacing="5" width="299">
                <tbody>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph"><strong>RISULTATO</strong> </p></td>
                        <td className="bordi" width="37"><p className="paragraph"><strong>VP</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Vittoria </p></td>
                        <td className="bordi" width="37"><p className="paragraph">20 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Pareggio (ove previsto) </p></td>
                        <td className="bordi" width="37"><p className="paragraph">10 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Sconfitta </p></td>
                        <td className="bordi" width="37"><p className="paragraph">0 </p></td>
                    </tr>
                </tbody>
            </table>
        </ContentTabs>
    );
};
