import { TooltipPosition } from 'intro.js/src/core/steps';

interface IntroStep {
    element: string;
    intro: string;
    position: TooltipPosition;
}

export const introStep = [
    {
        element: '.tournament-table',
        intro: 'Welcome to the lobby',
        position: 'top',
    },
    {
        element: '.nav-link-game-mode',
        intro: 'Switch between game modes',
        position: 'bottom',
    },
    {
        element: '.header-balance',
        intro: 'User credit balance',
        position: 'bottom',
    },
    {
        element: '.change-game-button',
        intro: 'Click here to change the game',
        position: 'bottom',
    },
    {
        element: '.type-Normal',
        intro: 'Tournament table information',
        position: 'top-left-aligned',
    },
    {
        element: '.subscribe-button',
        intro: 'Click here to register for the table',
        position: 'top',
    },
    {
        element: '.button-action',
        intro: 'Click here to see all your current tables',
        position: 'top',
    },
    {
        element: '.header-user',
        intro: 'Player information and settings',
        position: 'bottom',
    },
    {
        element: '.profile-header-info-container',
        intro: 'Balance information',
        position: 'bottom',
    },
    {
        element: '.top-up-button',
        intro: 'Wallet balance user',
        position: 'bottom',
    },
    {
        element: '.profile-menu-settings',
        intro: 'User profile information',
        position: 'bottom',
    },
    {
        element: '.profile-menu-history',
        intro: 'The match history',
        position: 'bottom',
    },
    {
        element: '.profile-menu-token',
        intro: 'User token list',
        position: 'bottom',
    },
    // {
    //     element: '.profile-menu-warning',
    //     intro: 'Warning section',
    //     position: 'bottom',
    // },
    
   
] as IntroStep[];



export const introStepMobile = [
    {
        element: '.tournament-table',
        intro: 'Welcome to the lobby',
        position: 'top',
    },
    {
        element: '.nav-link-game-mode-mobile',
        intro: 'Switch between game modes',
        position: 'bottom',
    },
    {
        element: '.header-balance',
        intro: 'User credit balance',
        position: 'bottom',
    },
    {
        element: '.change-game-button',
        intro: 'Click here to change the game',
        position: 'bottom',
    },
    {
        element: '.type-Normal',
        intro: 'Tournament table information',
        position: 'bottom',
    },
    {
        element: '.subscribe-button',
        intro: 'Click here to register for the table',
        position: "left",
    },
    {
        element: '.button-action-icon',
        intro: 'Click here to see all your current tables',
        position: 'top',
    },
    {
        element: '.header-user',
        intro: 'Player information and settings',
        position: 'bottom',
    },
    {
        element: '.profile-header-info-container',
        intro: 'Balance information',
        position: 'bottom',
    },
    {
        element: '.top-up-button',
        intro: 'Wallet balance user',
        position: 'bottom',
    },
    {
        element: '.profile-menu-settings',
        intro: 'User profile information',
        position: 'bottom',
    },
    {
        element: '.profile-menu-history',
        intro: 'The match history',
        position: 'bottom',
    },
    {
        element: '.profile-menu-token',
        intro: 'User token list',
        position: 'bottom',
    },
    // {
    //     element: '.profile-menu-warning',
    //     intro: 'Warning section',
    //     position: 'bottom',
    // },


] as IntroStep[];