import { useEffect, useRef, useState } from "react";
import IconLoading from "assets/icons/Loading";
type LoadingProps = {
    color?: string,
    background?: string,
    className?: string,
    inGame?: boolean;
}

const Loading = ({ color, background = "#123335", className, inGame = false }: LoadingProps) => {
    const [width, setWidth] = useState(10);
    const elemRef = useRef(null);

    useEffect(() => {
        let i = 0;
        let id;

        const frame = () => {
            const elem = elemRef.current;
            if (elem) {
                if (width >= 99) {
                    clearInterval(id);
                    i = 0;
                } else {
                    setWidth((prevWidth) => prevWidth + 1);
                    elem.style.width = `${width}%`;
                    elem.innerText = `${width}%`;
                }
            }
        };

        if (i === 0) {
            i = 1;
            id = setInterval(frame, 10);
        }

        return () => clearInterval(id);
    }, [width]);

    return (
        <div className={`loader-container z-[1200] fixed top-0 left-0 w-[100vw] bottom-0 bg-[${background}] ${className ? className : ''}`}>
            <div className="loader absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                {inGame ?
                    <div className="loading-game">
                        <div className="loading-game-content">
                            <div className="loading-inprogress-bar" id="myProgress">
                                <div className="loading-inprogress-bar-child" id="myBar" ref={elemRef} style={{ width: `${width}%` }}>{`${width}%`}</div>
                            </div>
                        </div>
                    </div>
                    :
                    <span className="w-[50px]">
                        <IconLoading color={`${color}`} />
                    </span>
                }
            </div>
        </div>
    );
};
export default Loading;