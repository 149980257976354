import { selectLogoutNotification } from "@quanticogames/gameclient-core"
import { mobileAppAgent } from "constants/common";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"
import ButtonComponent from "shared/components/button";
import ModalComponent from "shared/components/modal";


export const EndSession = () => {
    const { t } = useTranslation();
    const isForceLogout = useSelector(selectLogoutNotification) as boolean;
    const refresh = () => window.location.reload();
    const isMobileApp = navigator.userAgent === mobileAppAgent;
    return (
        <>
            <ModalComponent
                open={isForceLogout}
                title={t("Notification")}
                showIconClose={false}
                size="small"
                sx={{ zIndex: 100000010 }}
            >   
                <p style={{
                    marginBottom: '10px'
                }}>{t('Your account has been logged in from somewhere else')}!</p>
                {isMobileApp && <ButtonComponent
                    variant="contained"
                    onClick={() => refresh()}
                    size={'medium'}
                    className="modal-button"
                    title={t("Reconnect")}
                    color={'primary'}

                />}
            </ModalComponent>
        </>
    )
}
