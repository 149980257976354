import ContentTabs from "shared/Regulation/components/ContentTabs"

export const BriscolaIlMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Il mazzo di carte </h2>
            <h2 className="titolo2">Il mazzo di carte </h2>
            <p className="paragraph">Si gioca con un mazzo di 40 carte italiane tradizionali. </p>
            <p className="paragraph">I giocatori possono selezionare il tipo di carte (napoletane, piacentine…).</p>
            <p className="paragraph">E' anche possibile selezionare le carte francesi senza i Jolly, gli 8, i nove e i dieci. </p>
            <p className="paragraph">La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
            <table className="bordi" border={1} cellPadding="5" width="221">
                <tbody>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">denari </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">quadri </p></td>
                        <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♦ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">coppe </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">cuori </p></td>
                        <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♥ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">bastoni </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">fiori </p></td>
                        <td className="bordi" width="43"><p className="paragraph">♣ </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="72"><p className="paragraph">spade </p></td>
                        <td className="bordi" width="22"><p className="paragraph">= </p></td>
                        <td className="bordi" width="74"><p className="paragraph">picche </p></td>
                        <td className="bordi" width="43"><p className="paragraph">♠ </p></td>
                    </tr>
                </tbody>
            </table>
            <h2 className="titolo2">Il valore delle carte e la briscola</h2>
            <p className="paragraph">Ai fini delle prese, Il valore delle carte nella briscola è il seguente: </p>
            <ul itemType="disc" className="paragraph">
                <li>dall'alto al basso: Asso, tre, re, cavallo, fante, 7, 6, 5, 4, 2; </li>
                <li>
                    viene stabilito un seme dominante detto <strong>"briscola"</strong>: le
                    carte del seme di briscola hanno valore maggiore di quelle di tutti gli
                    altri semi.
                </li>
            </ul>
        </ContentTabs>
    );
};

export const BriscolaSvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Svolgimento del gioco </h2>
            <h2 className="titolo2">Vengono dette:</h2>
            <p className="paragraph"><strong>giocata</strong> : la carta mostrata scoperta sul tavolo da un giocatore; </p>
            <p className="paragraph"><strong>mano: </strong>la fase di una <em>smazzata</em> in cui tutti i giocatori al tavolo effettuano una <em>giocata</em>; </p>
            <p className="paragraph"><strong>smazzata</strong> : la fase di gioco che inizia con la distribuzione delle carte e si conclude con il conteggio dei punti; </p>
            <p className="paragraph"><strong>partita</strong> : la fase di gioco composta da una o più <em> smazzate.</em> </p>
            <h2 className="titolo2">Designazione del mazziere </h2>
            <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. </p>
            <p className="paragraph">Quando il gioco ha inizio, il mazziere viene stabilito casualmente dal sistema. </p>
            <p className="paragraph">
                Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra
                stessi giocatori, il mazziere gira in senso antiorario e cioè il prossimo
                mazziere sarà quello seduto alla destra del mazziere precedente.
            </p>
            <h2 className="titolo2">Distribuzione delle carte e determinazione della briscola</h2>
            <p className="paragraph">
                Il sistema mescola le carte e ne da 3 ad ogni giocatore distribuendole una
                alla volta, in senso antiorario, partendo dal giocatore alla destra del mazziere.
            </p>
            <p className="paragraph">
                Quindi lascia una carta scoperta sul tavolo, coprendola parzialmente con il
                mazzo, in modo che rimanga visibile a tutti per l'intero gioco: questa carta
                segnerà il seme di <strong>briscola</strong> e sarà l'ultima carta ad essere
                pescata.
            </p>
            <h2 className="titolo2">Gioco della carta</h2>
            <p className="paragraph">
                Inizia a giocare Il primo giocatore attivo alla destra del mazziere (" <em>
                    giocatore di mano") </em>. Nelle mani successive alla prima, il <em>giocatore di mano</em> è quello che ha fatto la presa.
            </p>
            <p className="paragraph">Ogni giocatore, partendo dal <em>giocatore di mano</em> e proseguendo in senso antiorario, pone sul tavolo una carta scoperta. </p>
            <p className="paragraph">La carta giocata dal giocatore di mano determina il "seme di mano". </p>
            <p className="paragraph">Si aggiudica la mano chi gioca la briscola più alta o, se nessuno gioca una briscola, che gioca la carta più alta nel "seme di mano". </p>
            <p className="paragraph">
                Si sottolinea che non vi è mai l'obbligo a giocare una carta ma, a differenza
                di altri giochi, il giocatore può liberamente scegliere quale carta giocare tra
                quelle in suo possesso
            </p>
            <p className="paragraph">
                Il giocatore che si aggiudica la mano prende tutte le carte poste sul tavolo;
                in seguito sarà il primo a prendere la prima carta dal mazzo, seguito da tutti
                gli altri sempre in senso antiorario e sarà il primo ad aprire la mano
                successiva e quindi a decidere il nuovo seme di mano.
            </p>
            <h2 className="titolo2">Conclusione della smazzata</h2>
            <p className="paragraph">
                La smazzata consiste in un avvicendamento di diverse <em>mani</em> che si
                concludono con l'esaurimento del mazzo, all'esaurimento del quale si ha l' <em>ultima mano</em>
                la quale è giocoforza costituita da tre prese che avvengono
                senza pescare altre carte, in quanto queste sono finite.
            </p>
            <p className="paragraph">
                L'ultima mano è solitamente la mano più importante e spesso determinante per
                l'esito della partita, poiché in essa tendono a concentrarsi il maggior numero
                di punti.
            </p>
            <h2 className="titolo2">Punteggi </h2>
            <p className="paragraph">Il punteggio delle carte è il seguente: </p>
            <table className="bordi" border={1} cellPadding="5" width="645">
                <tbody>
                    <tr>
                        <td className="bordi" width="43"></td>
                        <td className="bordi" colSpan={2} width="88" align="center"><p className="paragraph">Carichi </p></td>
                        <td className="bordi" colSpan={3} width="172" align="center"><p className="paragraph">Figure </p></td>
                        <td className="bordi" colSpan={5} width="136" align="center"><p className="paragraph">Lisci </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="43"><p className="paragraph">Carte </p></td>
                        <td className="bordi" width="58"><p className="paragraph"><em>Asso (A)</em> </p></td>
                        <td className="bordi" width="58"><p className="paragraph"><em>3</em> </p></td>
                        <td className="bordi" width="58"><p className="paragraph"><em>Re (K)</em> </p></td>
                        <td className="bordi" width="58"><p className="paragraph"><em>Cavallo</em> / <em>Donna (Q)</em> </p></td>
                        <td className="bordi" width="58"><p className="paragraph"><em>Fante (J)</em> </p></td>
                        <td className="bordi" width="27"><p className="paragraph"><em>7</em> </p></td>
                        <td className="bordi" width="28"><p className="paragraph"><em>6</em> </p></td>
                        <td className="bordi" width="28"><p className="paragraph"><em>5</em> </p></td>
                        <td className="bordi" width="28"><p className="paragraph"><em>4</em> </p></td>
                        <td className="bordi" width="28"><p className="paragraph"><em>2</em> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="43"><p className="paragraph">Punti </p></td>
                        <td className="bordi" width="58"><p className="paragraph">11 </p></td>
                        <td className="bordi" width="58"><p className="paragraph">10 </p></td>
                        <td className="bordi" width="58"><p className="paragraph">4 </p></td>
                        <td className="bordi" width="58"><p className="paragraph">3 </p></td>
                        <td className="bordi" width="58"><p className="paragraph">2 </p></td>
                        <td className="bordi" width="27"><p className="paragraph">0 </p></td>
                        <td className="bordi" width="28"><p className="paragraph">0 </p></td>
                        <td className="bordi" width="28"><p className="paragraph">0 </p></td>
                        <td className="bordi" width="28"><p className="paragraph">0 </p></td>
                        <td className="bordi" width="28"><p className="paragraph">0 </p></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph">In questo modo i punti in totale sono: </p>
            <p className="paragraph">
                4 <em>Assi</em> + 4 <em>Tre</em> + 4 <em>K</em> + 4 <em>Q</em> + 4 <em>J</em>
                = 4 <strong>·</strong> 11 + 4 <strong>·</strong> 10 + 4 <strong>·</strong> 4 + 4
                <strong>·</strong> 3 + 4 <strong>·</strong> 2 = 120
            </p>
            <p className="paragraph">Ne consegue che totalizzando 61 punti si ha la certezza di fare più punti dell'avversario e quindi di vincere la smazzata. </p>
            <p className="paragraph">Se entrambi i giocatori totalizzano 60 punti la smazzata finisce in parità. </p>
            <h2 className="titolo2">Conclusione della partita</h2>
            <p className="paragraph">Ai fini della loro conclusione, le partite possono essere di due tipi: </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>Partite a punti <br />
                </strong>
                    Nelle partite a punti vince il giocatore o la coppia che supera la
                    soglia dei 60 punti. Nelle partite con il cosidetto "<strong>Vinco subito</strong>"
                    in qualsiasi momento della partita, il <em>giocatore di mano</em> (cioè
                    quello che ha appena <em>fatto la presa</em>)
                    <strong>pone automaticamente fine alla partita,</strong> <strong>vincendola</strong> non appena ha
                    totalizzato un punteggio almeno pari a 61 punti. <br />
                </li>
                <li>
                    <strong>Partite a smazzate</strong> <br />
                    Si stabilisce il numero di smazzate di cui è composta la partita: vince la
                    singola smazzata il giocatore o la coppia che ha totalizzato un punteggio
                    almeno pari a 61 punti. Nelle partite con il cosidetto "<strong>Vinco subito</strong>"
                    in qualsiasi momento della partita, il<em>giocatore di mano</em> (cioè
                    quello che ha appena <em>fatto la presa</em>) 
                    <strong>pone automaticamente fine alla partita,</strong> <strong>vincendola</strong> non appena ha
                    totalizzato un punteggio almeno pari a 61 punti. <br />
                    Vince la partita chi si aggiudica la maggioranza delle smazzate (es. in una
                    partita a tre smazzate vince la partita chi se ne aggiudica due). Per questo
                    motivo il numero di smazzate deve sempre essere dispari. <br />
                </li>
            </ul>
            <h2 className="titolo2">Parità</h2>
            <p className="paragraph">
                Nei tornei a eliminazione diretta (KO), se la partita finisse in parità,
                verrà giocata un'altra smazzata ("1° spareggio") e si aggiudicherà l'incontro
                chi farà più punti.
            </p>
            <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("2° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
            <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("3° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
            <p className="paragraph">Poiché non è ammissibile un gioco ad oltranza, viene stabilita la seguente regola: </p>
            <p className="paragraph">se anche il 3° spareggio finisse in parità, perde il giocatore che ha fatto l'ultima presa nel 3° spareggio, anche senza fare punti. </p>
            <p className="paragraph">&nbsp;</p>
        </ContentTabs>
    );
};

export const BriscolaComeSiGioca = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Come si gioca</h2>
            <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
            <p className="paragraph">Per giocare una carta si può: </p>
            <ul itemType="disc" className="paragraph">
                <li>fare un "doppio clic" sulla carta, oppure </li>
                <li>selezionare una carta (cioè cliccarla) e poi cliccare sulla carta semitrasparente che appare sul tavolo </li>
                <li>Selezionare una carta e, tenendo premuto il tasto sinistro del mouse, trascinarla sulla carta semitrasparente che appare sul tavolo </li>
            </ul>
            <p className="paragraph">Per deselezionare una carta è sufficiente cliccarla. </p>
        </ContentTabs>
    );
};

export const BriscolaPenalita = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Penalità</h2>
            <h2 className="titolo2">Penalità in caso di tempo scaduto o abbandono </h2>
            <p className="paragraph">
                Se giocatore fa scadere il tempo partita o il timeout o clicca sul tasto
                "abbandona" la partita ha termine e il giocatore <strong>perde la partita</strong>
                e la differenza punti è cosi stabilita:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>si assegnano tutti o punti ancora non aggiudicati all'avversario fino a un massimo di 20 punti, </li>
                <li>
                    se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il
                    timeout, la differenza punti è uguale a zero (ma la partita è comunque persa
                    da chi ha fatto scadere il tempo).
                </li>
            </ul>
            <p className="paragraph">
                Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o
                abbandona farà 0 VP e la linea avversaria farà minimo 14 VP. I MP di entrambe le
                linee saranno calcolati dai VP in base ai corrispondenti minimi previsti dalla
                tabella dei VP.
            </p>
            <p className="paragraph"><em>Si sottolinea che quest'ultima regola sul timeout e abbandono è valida solo per determinare i MP e i VP per le classifiche dei tornei a girone e i livelli di abilità in caso di timeout e abbandono , ma non per determinare la differenza punti nei tavoli cash che vengono sempre determinati come descritto nei <a href="generali.php?skin=SNAI&amp;mobile=&amp;argomento=cashgenerico&amp;capitolo=tavoliturni">regolamenti generali</a> anche in caso di timeout e abbandono.</em> </p>
        </ContentTabs>
    );
};

export const BriscolaRegoleOpzionali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Regole opzionali </h2>
            <h2 className="titolo2">Partite a "carte viste"</h2>
            <p className="paragraph">Saranno mostrate le carte viste nelle precedenti mani della smazzata. </p>
            <p className="paragraph">Nelle partite testa a testa a carte viste, l'ultima mano della smazzata si gioca a carte scoperte. </p>
            <h2 className="titolo2">Partite a coppie a "carte scoperte"</h2>
            <p className="paragraph">Saranno proposte partite a coppie nelle quali i compagni, senza essere visti dagli avversari, giocano a carte scoperte. </p>
        </ContentTabs>
    );
};

export const BriscolaDifferenzaPuntiNeiCash = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Differenza punti nei tavoli cash </h2>
            <h2 className="titolo2">Differenza punti nei tavoli cash </h2>
            <p className="paragraph">
                Nella Briscola, ai fini del calcolo dell'importo vinto o perso, la differenza
                punti è stabilita dividendo per 10 l'effettiva differenza punti (su tutte le
                smazzate giocate) e poi arrotondando il risultato ( se &gt; 0,50 all'unità
                superiore, se &lt;= 0,50 all'unità inferiore)
            </p>
            <p className="paragraph">Esempio: </p>
            <ul itemType="disc" className="paragraph">
                <li>Giocatore A totalizza 93 punti </li>
                <li>Giocatore B totalizza 27 punti </li>
                <li>Differenza punti effettiva = 93 - 27 = 66 punti </li>
                <li>Differenza punti = 66 / 10 = 6, 6 per arrotondamento = 7 punti </li>
            </ul>
            <p className="paragraph">Se la differenza punti effettiva fosse stata di 6,50 punti, l'arrotondamento avrebbe portato la differenza punti = 6. </p>
        </ContentTabs>
    );
};

export const BriscolaTabellaDeiVictoryPoints = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Briscola - Tabella dei Victory Points</h2>
            <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
            <table className="bordi" border={1} cellPadding="5" cellSpacing="5" width="299">
                <tbody>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph"><strong>RISULTATO</strong> </p></td>
                        <td className="bordi" width="37"><p className="paragraph"><strong>VP</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Vittoria </p></td>
                        <td className="bordi" width="37"><p className="paragraph">20 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Pareggio (ove previsto) </p></td>
                        <td className="bordi" width="37"><p className="paragraph">10 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Sconfitta </p></td>
                        <td className="bordi" width="37"><p className="paragraph">0 </p></td>
                    </tr>
                </tbody>
            </table>
        </ContentTabs>
    );
};
