import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectConfigState, SoundManager, soundManager, useCheckLobbyPage, dismissToasts, Sound, configActions, GameHelper } from '@quanticogames/gameclient-core';
import { useDispatch } from 'react-redux';
import { mobileAppAgent } from 'constants/common';


const useSound = () => {
    const configState = useSelector(selectConfigState);
    const isLobby = useCheckLobbyPage();
    const dispatch = useDispatch();

    const isMobileDevice = GameHelper.isMobile();
    const [appState, setAppState] = React.useState('active'); // active, background, inactive
    useEffect(() => {
        SoundManager.muteVolume(!configState?.soundEffect);
    }, [configState?.soundEffect]);

    useEffect(() => {
        soundManager.setVolume(1);
    }, [configState?.soundVolume]);

    useEffect(() => {
        if (isLobby) {
            soundManager.play(Sound.backgroundLobby.toString());
            dismissToasts();
        } else {
            soundManager.stop(Sound.backgroundLobby.toString());
        }
    }, [isLobby]);


    const updateStorageConfigs = () => {
        const storedSoundEffect = localStorage.getItem('soundEffect');
        const storedChatEnabled = localStorage.getItem('chatEnabled');
        const storedChatTableEnabled = localStorage.getItem('chatTableEnabled');

        if (storedSoundEffect !== null) {
            const newIsSound = !(storedSoundEffect === 'false');
            dispatch(configActions.toggleSound(newIsSound));
        };

        if (storedChatEnabled !== null) {
            const newChatEnabled = !(storedChatEnabled === 'false');
            dispatch(configActions.toggleChat(newChatEnabled));
        };

        if (storedChatTableEnabled !== null) {
            const newChatTableEnabled = !(storedChatTableEnabled === 'false');
            dispatch(configActions.toggleChatTable(newChatTableEnabled));
        };
    };

    useEffect(() => {
        updateStorageConfigs();
        window.addEventListener('storage', updateStorageConfigs);
        return (() => {
            window.removeEventListener('storage', updateStorageConfigs);
        });
    }, []);

    const onFocus = () => {
       
        if (!isMobileDevice) return;
        soundManager.setVolume(1);
        soundManager.play(Sound.backgroundLobby.toString());
    };

    const onBlur = () => {
        if (!isMobileDevice) return;
        soundManager.stop(Sound.backgroundLobby.toString());
        soundManager.setVolume(0);
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        onFocus();
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    useEffect(() => {
        const isMobileApp = navigator.userAgent === mobileAppAgent;
        if (isMobileApp) {
            const msgHandler = function (event) {
                const data = event.data;
                if (data?.type === 'appState') setAppState(data.payload);
            }
            window.addEventListener('message', msgHandler);
            return () => window.removeEventListener('message', msgHandler);
        }
    }, []);

    useEffect(() => {
        if (appState === 'background') {
            soundManager.stop(Sound.backgroundLobby.toString());
            soundManager.setVolume(0);
        }
        if (appState === 'active') {
            soundManager.setVolume(1);
            soundManager.play(Sound.backgroundLobby.toString());
        }
    }, [appState]);
};

export default useSound;
