import React, { useEffect, useState } from 'react';
import { Box, Pagination, styled, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { CommonTable } from '../../../../shared/CommonTable';
import {
  PricePoolDistribution,
  selectActiveTournament,
  selectTournaments,
  Tournament,
  tournamentApi,
  TournamentStateEnum,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { useDispatch } from 'react-redux';
import TableComponent from 'shared/components/tables';

const HEAD_DATA_PRIZE_POOL = [
  {
    id: 'position',
    label: 'Pos.',
    align: 'center'
  },
  {
    id: 'prize',
    label: 'Prize',
  },
];


const StyledTableCell = styled(TableCell)(() => ({}));

const PaginationWrapper = styled(Box)(() => ({
  paddingTop: '28px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#0B0E11',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

type PrizePoolTableProps = {
  subscribeSimulation?: boolean;
  prizeInProgress: any;
  prizeDistribution: PricePoolDistribution[];
};

export const PrizePoolTable = ({
  prizeInProgress,
  prizeDistribution,
}: PrizePoolTableProps) => {
  const tournament: Tournament = useSelector(selectActiveTournament);
  const [prizeDistribute, setPrizeDistribute] = useState<number[]>([]);
  const [subcribeNumber, setSubcribeNumber] = useState<number>(tournament?.minPlayers);
  const [page, setPage] = useState<number>(1);
  const itemPerPage = 10;

  const isInGame =
    tournament?.state === TournamentStateEnum.inprogress ||
    tournament?.state === TournamentStateEnum.end ||
    tournament?.state === TournamentStateEnum.terminated;

  const subcribeSimulationList = Array.from(new Set(prizeDistribution?.map((entry) => entry?.participants)).values());

  useEffect(() => {
    if (isInGame) {
      if (prizeInProgress) {
        handleSubscribeSimulation(tournament?.participants?.length);
      }
      return;
    }
    if (prizeDistribution) {
      handleSubscribeSimulation(subcribeNumber);
    }
  }, [prizeDistribution, prizeInProgress, isInGame]);
  

  const handleSubscribeSimulation = (e: number) => {
    setSubcribeNumber(e);
    setPage(1);

    let number: number;

    if (e >= subcribeSimulationList[subcribeSimulationList.length - 1]) {
      number = subcribeSimulationList[subcribeSimulationList.length - 1];
    } else {
      for (let i = 0; i < subcribeSimulationList.length - 1; i++) {
        if (e >= subcribeSimulationList[i] && e < subcribeSimulationList[i + 1]) {
          number = subcribeSimulationList[i];
        }
      }
    }

    const prizeDistributeArr = [];
    prizeDistribution
      .filter((item) => item.participants === number)
      .forEach((item) => {
        const a = item.toPos - item.fromPos;
        if (a !== 0) {
          for (let index = 0; index <= a; index++) {
            prizeDistributeArr.push(item.rate);
          }
        } else {
          prizeDistributeArr.push(item.rate);
        }
      });

    setPrizeDistribute(prizeDistributeArr);
  };

  let subcribeNumberArr: Array<number>;
  (subcribeNumberArr = []).length = itemPerPage;
  subcribeNumberArr.fill(0);
  const subscribeOption = [];
  for (let index = tournament?.minPlayers; index <= tournament?.maxPlayers; index++) {
    subscribeOption.push(index);
  }

  return (
    <>
      {tournament?.type === 'Scheduled' && !isInGame && (
        <div className="flex justify-end mb-[10px] items-center pr-[5px]">
          <div className="mr-[10px]">Iscriviti alla simulazione</div>
          <select
            className="w-[80px] h-[30px] rounded-[4px] border-[1px] border-[#D9D9D9]"
            onChange={(e) => handleSubscribeSimulation(Number(e.target.value))}
          >
            {subscribeOption?.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      )}

      <TableComponent columns={HEAD_DATA_PRIZE_POOL}>
        {subcribeNumberArr.map((item, index) => {
          const totalPize = !tournament?.freeroll ? (((tournament?.cost - tournament?.fee) * subcribeNumber) > tournament?.guaranteedValue ? ((tournament?.cost - tournament?.fee) * subcribeNumber ) : tournament?.guaranteedValue) : tournament?.rewardValue;
          return (
            <TableRow key={`prize-pool-table-item-${index}`}>
              {index + itemPerPage * (page - 1) < subcribeNumber && (
                <>
                  <StyledTableCell width="60px" style={{textAlign:'center'}}>
                    {index + 1 + itemPerPage * (page - 1)}°
                  </StyledTableCell>
                  <StyledTableCell>
                    {index >= prizeDistribute?.length || index + itemPerPage * (page - 1) >= prizeDistribute?.length
                      ? '-'
                      : !isInGame
                      ? `€ ${formatMoney(
                          convertCentToEuro(Math.floor(prizeDistribute[index + itemPerPage * (page - 1)] * totalPize))
                        )}`
                      : `€ ${formatMoney(convertCentToEuro(prizeInProgress[index + itemPerPage * (page - 1)]?.amount))}`}
                  </StyledTableCell>
                </>
              )}
            </TableRow>
          );
        })}
      </TableComponent>

      <PaginationWrapper>
        <StyledPagination
          onChange={(e, page) => setPage(page)}
          count={Math.ceil(subcribeNumber / 10)}
          hidePrevButton
          hideNextButton
        />
      </PaginationWrapper>
    </>
  );
};