import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import ModalComponent from 'shared/components/modal';
import { INACTIVE_DETECT_TIME } from 'utils/constants';
import { useSelector } from "react-redux"
import { selectLogoutNotification } from "@quanticogames/gameclient-core"

const InactiveNotice: React.FC = () => {
    const { t } = useTranslation('common');
    const [isIdle, setIsIdle] = useState(false);
    const storedTimeoutDuration = localStorage.getItem('inactiveTimeout') ? Number(localStorage.getItem('inactiveTimeout')) : null;
    const [timeoutDuration] = useState((storedTimeoutDuration || INACTIVE_DETECT_TIME) * 1000);
    const isForceLogout = useSelector(selectLogoutNotification) as boolean;

    useEffect(() => {
        if(isForceLogout) {
            setIsIdle(false);
        }
     }, [isForceLogout]);

    const { message } = useIdleTimer({
        onIdle: () => setIsIdle(true),
        onMessage: (msg: any) => msg === 'reload' && setIsIdle(false),
        timeout: timeoutDuration,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        events: [
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'visibilitychange',
        ],
    });

    useEffect(() => {
        message('reload', false);
    }, []);

    const refresh = () => window.location.reload();

    return (
        <ModalComponent
            sx={{ zIndex: 100000010 }}
            open={isIdle}
            title={t('Notification')}
            showIconClose={false}
            showFooter
            onOk={refresh}
            textOk={t('Reconnect')}
        >
            <Typography>{t('Session terminated')}</Typography>
            <Typography>{t('Click button to refresh')}</Typography>
        </ModalComponent>
    );
};

export default InactiveNotice;
