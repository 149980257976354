import { Badge, Box, IconButton, IconButtonProps, styled, useMediaQuery, useTheme } from "@mui/material";
import { Game, GameRound, Sound, SoundManager, User, matchActions, selectActiveGame, selectActiveMatchId, selectCurrentUser, selectMatchState, selectRounds, soundManager, MatchState, gameActions, isPopup, GameHelper, chatActions } from "@quanticogames/gameclient-core";
import { CloseIconSvg } from "assets/icons/CloseIconSvg";
import { NotificationIcon } from "assets/icons/NotificationIcon";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import './index.scss';
import MatchSwitcherDesktop from "shared/components/match-switcher/component/match-switcher-desktop";
import MatchSwitcherMobile from "shared/components/match-switcher/component/match-switcher-mobile";
import { useIsMobileLandscape } from "hooks/useIsMobileLandscape";
interface StyledIconButtonProps extends IconButtonProps {
    animate?: boolean;
  }
  
  interface BoxProps {
    islobby?: string;
  }

const ButtonWrapper = styled(Box)<BoxProps>(() => ({
    position: 'fixed',
    left: 16,
    zIndex: 1200,
    display: 'block',
  }));
  
  const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(({ theme }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    '&.notifying': {
      animation: 'bg-color 4s linear infinite',
      animationDelay: '10s',
      path: {
        animation: 'icon-color 4s linear infinite',
        animationDelay: '10s',
      },
    },
    '@keyframes bg-color': {
      '0%': {
        backgroundColor: theme.palette.primary.main,
      },
      '50%': {
        backgroundColor: '#ffffff',
      },
      '100%': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '@keyframes icon-color': {
      '0%': { fill: '#ffffff' },
      '50%': { fill: theme.palette.primary.main },
      '100%': { fill: '#fff' },
    },
  
    '&:hover': {
      backgroundColor: theme.palette.white.main,
      path: {
        fill: theme.palette.primary.main,
      },
    },
    '&.close': {
        backgroundColor: "#fff",
        opacity: 1,
        path: {
          fill: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            path: {
                fill: '#fff',
            }
        }
      },
  }));
  
  const StyledBadge = styled(Badge)(() => ({
    width: '100%',
    height: '100%',
    '.MuiBadge-badge': {
      width: 16,
      height: 16,
      border: '2px solid #fff',
      borderRadius: '50%',
    },
    '&.hide': {
      '.MuiBadge-badge': {
        display: 'none',
      },
    },
  }));

const MatchSwitcher = () => {
  const dispatch = useDispatch();
  const activeGame: Game = useSelector(selectActiveGame);
  const matchState: MatchState = useSelector(selectMatchState);
  const isOpened = matchState.isOpenSwitcher;
  const [showRinging, setShowRinging] = useState<boolean>(false);
  const rounds: GameRound[] = useSelector(selectRounds);
  const currentUser: User = useSelector(selectCurrentUser);
  const activeMatchId = useSelector(selectActiveMatchId);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'match-switch' : undefined;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileLandscape = useIsMobileLandscape();

  const onClose = () => {
    setAnchorEl(null);
    dispatch(matchActions.updateSwitcher(false));
  };

    const inTurnMatchIds = rounds
        .filter((round) => {
            return !round.sitout && round.activeInteraction?.playerId == currentUser.id && activeMatchId != round.matchId;
        })
        .map((round) => round.matchId)
        .sort();

    const [checkOpenFirst, setCheckOpenFirst] = useState<boolean>(inTurnMatchIds?.join().length === 0);

    useEffect(() => {
        if (inTurnMatchIds.join()) {
            setShowRinging(true);
            soundManager.play(Sound.notification.toString());
            if (!isOpened) setCheckOpenFirst(false);
        } else {
            setShowRinging(false);
            soundManager.stop(Sound.notification.toString());
            setCheckOpenFirst(true);
        };
    }, [inTurnMatchIds.join()]);

    const onClick = (event) => {
        SoundManager.play(Sound.clickButton);
        if (!checkOpenFirst) setCheckOpenFirst(true);
        if (isOpened) {
            setShowRinging(false);
            setCheckOpenFirst(true);
            if(!isMobile && !isMobileLandscape) setAnchorEl(event.currentTarget);
        } else {
          if(!isMobile || !isMobileLandscape) setAnchorEl(null);
        }
        dispatch(matchActions.updateSwitcher(!isOpened));
        dispatch(chatActions.updateOpenDrawer(false));
    };

    return(
      <>
        <ButtonWrapper 
          className={`button-action button-action-${activeGame?.code}`}
          ref={(elm: any) => setAnchorEl(elm)}
          onClick={onClick}
          aria-describedby={id}
        >
          <StyledBadge
            className={`${checkOpenFirst ? 'hide' : ''}`}
            color="error"
            overlap="circular"
            variant="dot"
            sx={{ width: '100%', height: '100%' }}
          >
            <StyledIconButton
              className={`button-action-icon button-icon ${isOpened ? 'close' : ''}
              ${isOpened || !showRinging ? '' : 'notifying'}`}
              color="primary"
            >
              {isOpened ? <CloseIconSvg />  : (
                <div className={isOpened || !showRinging ? '' : 'ringing'}>
                  <NotificationIcon />
                </div>
              )}
            </StyledIconButton>
          </StyledBadge>
        </ButtonWrapper>

        {isMobile || isMobileLandscape ? (
          <MatchSwitcherMobile isOpened={isOpened} onClose={onClose}/>
        ): (
          <MatchSwitcherDesktop isOpened={isOpened} onClose={onClose} />
        )}
      </>
    )
}
export default MatchSwitcher