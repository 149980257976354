import { useTranslation } from "react-i18next";
import ModalComponent from "shared/components/modal";

const ModalLogChat = ({isOpen, onClose}) => {
    const { t } = useTranslation();

    return (
        <ModalComponent open={isOpen} onClose={onClose} title={t("Notification")} size="small">
            <p className='mt-7 font-medium'>{t("You are banned from chat")}</p>
        </ModalComponent>
    )
}
export default ModalLogChat
