import { useTranslation } from "react-i18next";

const TableNoData = () => {
    const { t } = useTranslation();
    return (
        <div className="nodata-container">
            <div className="nodata-header">
                <img src="/images/common/nodata.svg" alt="" width="" height=""/>
            </div>
             <div className="nodata-content">
                <h2 className="nodata-content-title">Ops!</h2>
                <p>Non ci sono eventi disponibili.</p>
                <p>Se hai attivato i filtri puoi resettarli,</p>
                <p> oppure prova a cambiare sezione.</p>
            </div>
        </div>
    )
}
export default TableNoData