import React, { useEffect } from 'react';
import LayoutPage from 'layouts/layout-page';
import { TournamentTable } from 'pages/tournament/component/list/tournament-table';
import { TOURNAMENT_TYPE } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import {
  Game,
  TournamentFilter,
  selectActiveGame,
  selectReloadTournaments,
  tournamentActions,
} from '@quanticogames/gameclient-core';

interface TournamentPageProps {
  type?: TOURNAMENT_TYPE;
  types?: string[];
}

export const TournamentPage: React.FC<TournamentPageProps> = ({ type, types }) => {
  const dispatch = useDispatch();
  const activeGame: Game = useSelector(selectActiveGame);
  const reloadTournaments: boolean = useSelector(selectReloadTournaments);
  const typeNew = type === TOURNAMENT_TYPE.CRAZY_PRIZE || type === TOURNAMENT_TYPE.TREBLE
  useEffect(() => {
    if (activeGame?.id) {
      dispatch(
        tournamentActions.loadTournaments({
          gameId: activeGame?.id,
          isOnlyInSubcription: false,
          ...(type === TOURNAMENT_TYPE.SIT_AND_GO ? { types } : { type }),
        } as any)
      );
    }
  }, [activeGame?.id, type]);

  useEffect(() => {
    if (reloadTournaments && activeGame?.id) {
      dispatch(
        tournamentActions.loadTournaments({
          gameId: activeGame?.id,
          isOnlyInSubcription: false,
          ...(type === TOURNAMENT_TYPE.SIT_AND_GO ? { types } : { type }),
        } as any)
      );
      dispatch(tournamentActions.reloadTournaments({ state: false }));
    }
  }, [reloadTournaments]);

  const scaleScreenTournament = () => {
    setTimeout(() => {
      const wrapperEl = document.querySelector('.header-tabs-container') as HTMLElement;
      const tournamentContainer = document.querySelector('.tournament-container') as HTMLElement;
      const tournamentType = document.querySelector('.tournament-type-container') as HTMLElement;
      const wW = wrapperEl?.clientWidth;
        const elW = 1176;
      if (tournamentType && wW > 700) {
        const scaleW = (wW - 8) / elW;
        tournamentType.style.transform = `scale(${(scaleW).toFixed(3)})`;
        tournamentType.style.transformOrigin = "top left";
      } else if (tournamentContainer) {
        tournamentContainer.style.transform = '';
        tournamentContainer.style.transformOrigin = "top center";
      }
    }, 100);
  };

  useEffect(() => {
    scaleScreenTournament();

    const handleResize = () => {
      scaleScreenTournament();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.location.href]);

  return (
    <LayoutPage>
      <div className={`tournament-container tournament-${type} ${typeNew ? 'tournament-type-container' : ''}`}>
        <TournamentTable type={type} types={types} />
      </div>
    </LayoutPage>
  );
};
