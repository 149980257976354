import { describeScopaMove, describeTelesinaMove, describeBuracoMove, describeScala40Move, describeTresetteMove, describeItPokerMove, describeBestiaMove, describeCirullaMove, describeScoponeMove, describeSetteEMezzoMove, describePeppaMove, describePokerCashMove, describeSolitarioMove, describeTelesinaCashMove, describeBriscolaMove, describeAssoPigliaTuttoMove} from './require';

export interface ComponentTypeMap {
    [key: string]: Function;
}

// Define game component types and their corresponding React components
export const componentTypes: ComponentTypeMap = {
    Scopa: describeScopaMove,
    ItalianPoker: describeItPokerMove,
    Burraco: describeBuracoMove,
    Scala40: describeScala40Move,
    Briscola: describeBriscolaMove,
    AssoPigliaTutto: describeAssoPigliaTuttoMove,
    Bestia: describeBestiaMove,
    Tresette: describeTresetteMove,
    Cirulla: describeCirullaMove,
    Telesina: describeTelesinaMove,
    TelesinaCash: describeTelesinaCashMove,
    Scopone: describeScoponeMove,
    SetteEMezzo: describeSetteEMezzoMove,
    SetteEMezzoCash: describeSetteEMezzoMove,
    Solitario: describeSolitarioMove,
    ItalianPokerCash: describePokerCashMove,
    Peppa: describePeppaMove,
};
