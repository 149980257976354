import { Tournament } from "@quanticogames/gameclient-core";
import { HeadCell } from "types/commonTable";

export interface ScheduledTour extends Tournament {
    gameCode?: string;
    [key: string]: any;
}

interface ScheduledHeadCell extends HeadCell {
    minWidth?: number;
    maxWidth?: number;
}

export const SCHEDULED_TOUR_HEAD_DATA: ScheduledHeadCell[] = [
    {
        id: 'day',
        label: 'TimeDay',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'time',
        label: 'TimeHour',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'description',
        label: 'Description',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'guaranteed',
        label: 'Guaranteed',
        sortable: false,
        filter: false,
        align: 'center',
        maxWidth: 120,
    },
    {
        id: 'cost',
        label: 'Cost',
        sortable: false,
        filter: false,
        align: 'center',
        maxWidth: 80,
    },
    {
        id: 'subscribers',
        label: 'Min/Max subscribers',
        sortable: false,
        filter: false,
        align: 'center',
        maxWidth: 120,
    },
    {
        id: 'game',
        label: 'GameName',
        sortable: false,
        align: 'center',
        maxWidth: 80,
    },
];
