import './index.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useCallback } from 'react';
import { GameHelper } from '@quanticogames/gameclient-core';
import ConvertBanner from 'shared/components/banner/convert-banner';

const Banner = () => {
    const { 
        slideDesktopDefault, 
        slideMobileDefault, 
        slideDesktopActive, 
        slideMobileActive , 
        activeBanner,
        banner
    } = ConvertBanner();
    const isMobile = GameHelper.isMobile();

    const renderBanner: any = useCallback(() => {
      if (activeBanner.length > 0) {
        if (isMobile) {
          return slideMobileActive?.length > 0
            ? slideMobileActive?.map((e: any, id: number) => {
                return (
                  <a href={e.mobileEmbededBannerUrl} target="_blank" key={id}>
                    <img
                      key={id}
                      src={e.mobileBanner}
                      alt="banner"
                      className={`w-full h-[120px] object-fill`}
                    />
                  </a>
                );
              })
            : null;
        } else {
          return slideDesktopActive?.length > 0
            ? slideDesktopActive?.map((e: any, id: number) => {
                return (
                  <a href={e.desktopEmbededBannerUrl} target="_blank" key={id}>
                    <img
                      key={id}
                      src={e.desktopBanner}
                      alt="banner"
                      className={`w-full h-[81px] object-fill`}
                    />
                  </a>
                );
              })
            : null;
        }
      } else {
        if (isMobile) {
          return slideMobileDefault?.length > 0
            ? slideMobileDefault?.map((e: any, id: number) => {
                return (
                  <a href={e.mobileEmbededBannerUrl} target="_blank" key={id}>
                    <img
                      key={id}
                      src={e.mobileBanner}
                      alt="banner"
                      className={`w-full h-[120px] object-fill`}
                    />
                  </a>
                );
              })
            : null;
        } else {
          return slideDesktopDefault?.length > 0
            ? slideDesktopDefault?.map((e: any, id: number) => {
                return (
                  <a href={e.desktopEmbededBannerUrl} target="_blank" key={id}>
                    <img
                      key={id}
                      src={e.desktopBanner}
                      alt="banner"
                      className={`w-full h-[81px] object-fill`}
                    />
                  </a>
                );
              })
            : null;
        }
      }
    }, [banner]);
  
    const settings = {
      dots: false,
      infinite: slideDesktopActive?.length > 1,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 7000,
    };

    return (
        <>{banner?.length > 0 ? <Slider {...settings}>{renderBanner()}</Slider> : null}</>
    )
}
export default Banner