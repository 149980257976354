import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useSelector } from 'react-redux';
import { CashTable, CashTableActiveState, isPopup, selectCashTable} from '@quanticogames/gameclient-core';
import { StyledAnnounce, StyledButtonCustom, StyledContent } from './styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ModalTerminate: React.FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const table: CashTable = useSelector(selectCashTable);
  const navigate = useNavigate();
  useEffect(() => {
    if(table?.state === CashTableActiveState.Terminated) {
      setOpen(true);
    }
  }, [table]);
 

  const onCloseModal = () => {
    setOpen(false);
    if(isPopup) {
      window.close();
    }
    else {
      navigate('/cash-tables');
    }
    
  };

  return (
    <>
      <Dialog open={open} className='font-raleway modal-confirm-subcribe'>
        <StyledContent>
          <StyledAnnounce sx={{ padding: '16px'}}>
            <p>{t('Table was terminated')}</p>
          </StyledAnnounce>
          <StyledButtonCustom
            type='primary-alt'
            text='Chiudi'
            onClickButton={onCloseModal}
          />
        </StyledContent>
      </Dialog>
    </>
  );
};

export default ModalTerminate;
