import { gameActions, selectForceLogoutDialog, tournamentActions, useCheckLobbyPage } from "@quanticogames/gameclient-core"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "shared/components/button";
import ModalComponent from "shared/components/modal";
import { useEffect, useState } from "react";


export const ConfirmEndSession = () => {
    const { t } = useTranslation();
    const isLobby = useCheckLobbyPage();
    const dispatch = useDispatch()
    const isShow = useSelector(selectForceLogoutDialog) as boolean;
    const [countDown, setCountDown] = useState(10);
    const url = new URL(window.location.href);

    const handleConfirm = (isConfirm: boolean) => { 
        const matchId = url.searchParams.get('id');
        const metadata = {
            isLobby: isLobby,
            matchId: isLobby ? null : matchId
        }
        dispatch(gameActions.forceLogout({ isConfirm, metadata }))
        if (!isConfirm) {
            dispatch(gameActions.updateForceLogoutDialog(true))
        }
   
    }

    useEffect(() => { 
        if (isShow) {
            const interval = setInterval(() => {
                setCountDown((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [isShow])

    useEffect(() => {
        if (countDown === 0) {
            handleConfirm(false)
        }
    }, [countDown])
    return (
        <>
            <ModalComponent
                open={isShow}
                title={t("Notification")}
                showIconClose={false}
                size="small"
                className="end-session-dialog"
                sx={{ zIndex: 100000010 }}
            >
                <p>{t('Your account has been logged in from somewhere else, do you want to disconnect that device ?')} </p>
                <p className="end-session-count-down">{t('Auto close after', {time: countDown})}</p>
                <div className="end-session-dialog-button">
                    <ButtonComponent
                        title={`OK`}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '120px !important' }}
                        onClick={() => handleConfirm(true)}
                    />
                    <ButtonComponent
                        title={`Cancel`}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '120px !important' }}
                        onClick={() => handleConfirm(false)}
                    />
                </div>
                
            </ModalComponent>
        </>
    )
}
