import { styled, TableCell, TableRow } from '@mui/material';
import { COLORS } from '../../../constants/colors';

export const StyledTableRow = styled(TableRow)`
  border-radius: 8px;

  &:nth-of-type(even) {
    background: #f7f7f9;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  color: ${COLORS.text_color};
  text-align: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 16px 0;

    &:nth-of-type(1) {
      width: 90px;
    }

    width: 57px;
    @media (orientation: landscape) {
      text-align: left;
    }
  }
`;
