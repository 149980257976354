export const COLORS = {
  primary_main: '#175EBE',
  base_dark: '#123335',
  base_light: '#24DEDB',
  white: '#FFFFFF',
  btn_grey: '#C2CDDB',
  main_hover: '#226DD1',
  menu_active: '#0066FF',
  text_color: '#0B0E11',
  text_bg: '#F0F1F3',
  bg_grey: '#F7F7F9',
  disabled_light: '#DDDFE2',
  disabled_contrast: '#828B92',
  shadow_white: 'rgba(255, 255, 255, 0.7)',
  shadow_white_hover: 'rgba(255, 255, 255, 0.22)',
};
