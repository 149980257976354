import { IAlertMessage } from 'types/alertMessage';
import './index.scss';

type IProps = {
    alertMesage?: IAlertMessage;
}

const AlertMessageItem = (props : IProps) => {
    const { alertMesage } = props;
  return (
    <>
      <div className='alert-message-item'>
          <div className="content-text" dangerouslySetInnerHTML={{ __html: alertMesage?.content || "" }} />
        </div>
    </>
  );
};

export default AlertMessageItem;
