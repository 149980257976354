export const CHAT_TABS = [
    {
        label: "Lobby",
        value: 0,
    },
    {
        label: "Helpdesk",
        value: 1,
    },
    {
        label: "Table",
        value: 2,
    }
]