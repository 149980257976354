import { useCallback, useEffect, useState } from 'react';
import TabsRegulation from 'shared/Regulation/components/TabsRegulation';
import LeaderBoardContainer from 'shared/Regulation/classifiche';
import { CLASSIFICHE_GAME_LIST, ClassificheInterface } from 'types/classifiche-type';
import { getClassifiches } from "shared/Regulation/classifiche/classifiche.service";
const activeGameCode = JSON.parse(localStorage.getItem('activeGame'))?.code;
import { useTranslation } from 'react-i18next';

const InformationClassifiche = () => {
    const { t } = useTranslation()
    const [classificheList, setclassificheList] = useState<ClassificheInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchData = useCallback(async (gameCode, timeType, mode) => {
        try {
            const response = await getClassifiches(gameCode, timeType, mode);
            setclassificheList(response);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchData(activeGameCode , 0, 1);
    }, []);

    const items = CLASSIFICHE_GAME_LIST.map(game => ({
        key: game.key,
        label: game.label,
        subTabs: [
            {
                key: `${game.key}_weekly`,
                label: t(`Weekly`),
                children: <LeaderBoardContainer loading={loading} classificheList={classificheList} gameCode={game.label} timeType="Weekly" />,
            },
            {
                key: `${game.key}_monthly`,
                label: t(`Monthly`),
                children: <LeaderBoardContainer loading={loading} classificheList={classificheList} gameCode={game.label} timeType="Monthly" />,
            },
            {
                key: `${game.key}_annually`,
                label: t(`Annually`),
                children: <LeaderBoardContainer loading={loading} classificheList={classificheList} gameCode={game.label} timeType="Annually" />,
            },
            {
                key: `${game.key}_historical`,
                label: t(`Historical`),
                children: <LeaderBoardContainer loading={loading} classificheList={classificheList} gameCode={game.label} timeType="Historical" />,
            },
        ],
    }))

    useEffect(() => {
        document.title = "CLASSIFICHE";
    }, []);

    return (
        <TabsRegulation items={items} fetchData={(gameCode, timeType, mode) => { fetchData(gameCode, timeType, mode) }} />
    )
}
export default InformationClassifiche
