import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const TheDeckCardScopone = () => {
  return (
    <ContentTabs>
      <div className="card-body text-justify">
      <h2 className="titolomenu">Regole dello Scopone - Il mazzo di carte </h2>
        <p>
          Si gioca con un mazzo di 40 carte italiane tradizionali. I giocatori possono selezionare il tipo di carte
          (napoletane, piacentine…).E' anche possibile selezionare le carte francesi senza i Jolly, gli 8, i nove e i
          dieci. La corrispondenza dei semi tra carte italiani e francesi è la seguente:
        </p>
        <p>La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
        <table>
          <tbody>
            <tr>
              <th align="center" className="lg:w-[350px]">
                Denari
              </th>
              <td align="center"> = </td>
              <td align="center">quadri</td>
              <td align="center">
                <span className="diamonds !text-[30px] leading-[1.0]">♦</span>
              </td>
            </tr>
            <tr className="odd">
              <th align="center">Coppe</th>
              <td align="center">=</td>
              <td align="center">cuori</td>
              <td align="center">
                <span className="hearts !text-[30px] leading-[1.0]">♥</span>
              </td>
            </tr>
            <tr>
              <th align="center">Bastoni</th>
              <td align="center">=</td>
              <td align="center">fiori</td>
              <td align="center">
                <span className="clubs !text-[30px] leading-[1.0]">♣</span>
              </td>
            </tr>
            <tr className="odd">
              <th align="center">Spade</th>
              <td align="center">=</td>
              <td align="center">picche</td>
              <td align="center">
                <span className="spades !text-[30px] leading-[1.0]">♠</span>
              </td>
            </tr>
          </tbody>
        </table>

        <h4>Il valore delle carte</h4>
        <table>
          <tbody>
            <tr>
              <th align="center" className="lg:w-[350px]">
                Re (K)
              </th>
              <td>10</td>
            </tr>
            <tr className="odd">
              <th align="center">Cavallo (Q)</th>
              <td>9</td>
            </tr>
            <tr>
              <th align="center">Fante (J)</th>
              <td>8</td>
            </tr>
            <tr className="odd">
              <th align="center">7</th>
              <td>7</td>
            </tr>
            <tr>
              <th align="center">6</th>
              <td>6</td>
            </tr>
            <tr className="odd">
              <th align="center">5</th>
              <td>5</td>
            </tr>
            <tr>
              <th align="center">4</th>
              <td>4</td>
            </tr>
            <tr className="odd">
              <th align="center">3</th>
              <td>3</td>
            </tr>
            <tr>
              <th align="center">2</th>
              <td>2</td>
            </tr>
            <tr className="odd">
              <th align="center">Asso</th>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ContentTabs>
  );
};

export const GameProgressScopone = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole dello Scopone - Svolgimento del gioco </h2>
      <p>Lo scopone è giocato da quattro giocatori, due contro due in una coppia fissa.</p>
      <h4>Designazione del mazziere</h4>
      <p>
        Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. Quando il gioco ha inizio,
        il mazziere viene stabilito casualmente dal sistema.
      </p>
      <p>
        Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra stessi giocatori, il mazziere gira in
        senso antiorario e cioè il prossimo mazziere sarà quello seduto alla destra del mazziere precedente.
      </p>
      <h4>Distribuzione delle carte</h4>
      <p>
        Il sistema mescola le carte e ne da 9 ad ogni giocatore distribuendole una alla volta, in senso antiorario,
        partendo dal giocatore alla destra del mazziere. Quindi vengono poste 4 carte scoperte in tavola: se almeno 3
        delle 4 carte iniziali sul tavolo sono Re, la mano non viene giocata e si effettua una nuova distribuzione delle
        carte poiché non sarebbe possibile fare alcuna scopa.
      </p>
      <h4>Il gioco della carta</h4>
      <p>
        Il giocatore seduto alla destra del mazziere è il giocatore di mano, cioè il primo a dover effettuare la
        giocata.
      </p>
      <p>Dopo che ogni giocatore ha effettuato la giocata,la mano passa al giocatore successivo in senso antiorario.</p>
      <p>Ogni giocatore di mano deve giocare una carta tenendo presente che:</p>
      <p>
        <strong>a) può prendere la carta in tavola che ha lo stesso valore della carta da lui giocata,</strong>
      </p>
      <p>
        <strong>b) può prendere le carte in tavola la cui somma è uguale al valore della carta da lui giocata,</strong>
      </p>
      <p>
        Se vi sono diverse combinazioni di carte che il giocatore può prendere giocando la sua carta, si è obbligati a
        prendere la carta in tavola dello stesso valore. Fatta salva la regola poc'anzi enunciata, se vi sono più
        combinazioni composte da un diverso numero di carte, il giocatore può scegliere quella combinazione che più gli
        conviene.
      </p>
      <p>
        Per esempio: se vi sono sul tavolo un tre, un cinque e un fante (valore 8), il giocatore giocando un fante
        (valore 8) può prendere solo il fante; se in tavola ci sono un asso (valore 1), un tre, un quattro e un cinque,
        il giocatore, giocando un cavallo (valore 9) può scegliere se prendere asso, tre e cinque oppure quattro e
        cinque a suo piacimento.
      </p>
      <p>Se il giocatore può fare almeno un tipo di presa è costretto a prendere e non può quindi scartare la carta.</p>
      <p>
        <strong>
          Se un giocatore prende tutte le carte in tavola "fa scopa" ad eccezione che ciò avvenga nell'ultima giocata
          dell'ultima mano di una smazzata.
        </strong>
      </p>
      <h4>Punteggi</h4>
      <p>
        Al termine di ogni smazzata si esaminano le carte prese da ciascun giocatore o coppia e le scope girate e si
        calcolano i punteggi come segue:
      </p>

      <table>
        <tbody>
          <tr>
            <td align="center">SCOPA</td>
            <td align="center">Ogni Scopa Vale 1 Punto</td>
          </tr>
          <tr>
            <td align="center">SETTE BELLO</td>
            <td align="center">Il Sette Di Denari (O Di Quadri) Vale Un 1 Punto</td>
          </tr>
          <tr>
            <td align="center">PRIMIERA</td>
            <td align="center">Vale 1 Punto</td>
          </tr>
          <tr>
            <td align="center">DENARI</td>
            <td align="center">6 O Più Carte Di Denari (O Quadri) Valgono 1 Punto</td>
          </tr>
          <tr>
            <td align="center">CARTE</td>
            <td align="center">21 O Più Carte Valgono 1 Punto</td>
          </tr>
        </tbody>
      </table>
      <p>Ai fini della primiera, il valore delle carte è il seguente:</p>
      <table>
        <tbody>
          <tr>
            <td align="center">
              <p>
                <strong>CARTA</strong>
              </p>
            </td>
            <td align="center">
              <p>
                <strong>VALORE</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td align="center">7</td>
            <td align="center">21 Punti</td>
          </tr>
          <tr>
            <td align="center">6</td>
            <td align="center">18 Punti</td>
          </tr>
          <tr>
            <td align="center">ASSO</td>
            <td align="center">16 Punti</td>
          </tr>
          <tr>
            <td align="center">5, 4, 3, 2</td>
            <td align="center">Rispettivamente 15,14,13,12 Punti</td>
          </tr>
          <tr>
            <td align="center">RE, CAVALLO E FANTE</td>
            <td align="center">10 Punti</td>
          </tr>
        </tbody>
      </table>
      <p>
        Si aggiudica la primiera il giocatore o coppia che mostra le 4 carte, una per ogni seme, la cui somma di valori
        è maggiore.
      </p>
      <h4>Conclusione della partita</h4>
      <p>Le partite si concludono a seconda della tipologia di partita giocata:</p>
      <ul>
        <li>
          Se è stato stabilito il numero di smazzate di cui è composta la partita vince la partita chi fa più punti al
          termine delle smazzate previste.
        </li>
        <li>
          Se è stato stabilito il totale dei punti che un giocatore o una coppia deve raggiungere per aggiudicarsi la
          partita vince la partita chi, avendo superato il punteggio previsto, ha più punti dell'avversario.
        </li>
      </ul>
      <p>
        <strong>Parità</strong>
      </p>
      <p>
        Nei tornei a eliminazione diretta (KO), se la partita finisse in parità, verrà giocata un'altra smazzata ("1°
        spareggio") e si aggiudicherà l'incontro chi farà più punti.
      </p>
      <p>
        In caso di ulteriore parità verrà giocata un'altra smazzata ("2° spareggio") e si aggiudicherà l'incontro chi
        farà più punti.
      </p>
      <p>
        In caso di ulteriore parità verrà giocata un'altra smazzata ("3° spareggio") e si aggiudicherà l'incontro chi
        farà più punti.
      </p>
      <p>Poiché non è ammissibile un gioco ad oltranza, viene stabilita la seguente regola:</p>
      <p>
        se anche il 3° spareggio finisse in parità, perde il giocatore o la coppia che ha fatto l'ultima presa nel 3°
        spareggio, anche senza fare punti.
      </p>
    </ContentTabs>
  );
};

export const PenaltyScopone = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole dello Scopone - Penalità </h2>
      <h4>Penalità in caso di tempo scaduto o abbandono</h4>
      <p>
        Se giocatore fa scadere il tempo partita o il timeout o clicca sul tasto "abbandona" la partita ha termine e il
        giocatore o la coppia perde la partita e la differenza punti è cosi stabilita:
      </p>
      <ul>
        <li>si assegnano tutti i punti ancora non aggiudicati all'avversario con l'aggiunta di una scopa ,</li>
        <li>
          se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il timeout, la differenza punti è uguale
          a zero (ma la partita è comunque persa da chi ha fatto scadere il tempo).
        </li>
      </ul>
      {/* <p>Calcolo dei VP e MP in caso di Timeout o abbandono</p>
      <p>
        Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o abbandona farà 0 VP e la linea avversaria
        farà minimo 14 VP. I MP di entrambe le linee saranno calcolati dai VP in base ai corrispondenti minimi previsti
        dalla tabella dei VP.
      </p> */}
    </ContentTabs>
  );
};

export const HowToPlayScopone = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole dello Scopone - Come si gioca</h2>
      <h4>Tasti e metodi per comunicare le proprie decisioni</h4>
      <p>Per giocare una carta, quando è il proprio turno si può:</p>
      <h4>Selezionare le carte ad una ad una</h4>
      <p>
        Per effettuare una presa si può selezionare la carta in mano e le carte in tavola che si desidera prendere ad
        una ad una. Appena verrà completata una presa questa verrà effettuata.
      </p>
      <p>
        Se si seleziona una carta in mano che non consente prese apparirà sul tavolo una carta semitrasparente,
        cliccatela per scartare la carta.
      </p>
      <h4>Effettuare una presa trascinando la carta sul tavolo</h4>
      <p>
        Se si trascina una carta sul tavolo verrà evidenziata l'unica presa possibile o, se non ci sono prese possibili
        apparirà sul tavolo una carta semitrasparente.
      </p>
      <p>Per effettuare la presa evidenziata è sufficiente rilasciare la carta.</p>
      <p>
        Se non si vuole effettuare la presa evidenziata, si deve riposizionare la carta tra quelle in mano e poi
        rilasciarla.
      </p>
      <p>
        Può succedere che sia possibile effettuare più prese, in questo caso bisognerà spostarsi su una carta in tavola
        che consente una sola presa.
      </p>
      <p>
        Ad esempio, se in tavola fossero presenti 5 ,5, 3, 2, giocando un 10 e posizionandosi su un 2, il sistema non
        può sapere quali dei due 5 si desidera prendere. In questo caso è necessario spostare la carta un 5 in modo che
        sia possibile comporre una sola presa.
      </p>
      <p>
        Può anche succedere che non sia in alcun modo possibile individuare una sola presa, in questo caso non resta che
        selezionare ad una ad una le carte come sotto descritto.
      </p>
      <h4>Doppio clic su una carta in mano</h4>
      <p>
        Il doppio clic su una carta in mano consente di scartare subito quella carta senza far prese. Se la carta
        selezionata col doppio clic consentisse possibili prese apparirà un messaggio di errore.
      </p>
      <p>Si precisa che:</p>
      <ul>
        <li>
          per trascinare una carta bisogna cliccarla col pulsante sinistro del mouse e spostarsi dove si desidera
          tenendo il pulsante premuto,
        </li>
        <li>
          dopo aver trascinato una carta, per rilasciarla è sufficiente smettere di tener premuto il pulsante sinistro
          del mouse
        </li>
        <li>per selezionare una carta è sufficiente cliccarci sopra col pulsante sinistro del mouse,</li>
        <li>
          per deselezionare una carta già selezionata è sufficiente cliccarci sopra col pulsante sinistro del mouse.
        </li>
      </ul>
      <p>
        Si precisa che hanno valore solo le decisioni comunicate con questi tasti. Qualsiasi decisione di gioco
        comunicata in chat, oltre a non avere alcun valore, è severamente vietata.
      </p>
    </ContentTabs>
  );
};
