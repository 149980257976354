export const PATH_ROUTES = {
  ROOT: '/',
  active: '/sit-and-go',
  SIT_AND_GO: '/sit-and-go',
  CRAZY_PRIZE: '/colpo-grosso',
  FILOTTO: '/filotto',
  TOURNAMENTS: '/tournaments',
  CASH_TABLE: '/cash-tables',
  TABLE: '/tables',
  SELECT_GAME: '/select-game',
  CHAT: '/chat',
  PROFILE: '/profile',
  GAME: '/game',
  REGULATION: '/information/regulation',
  INFORMATION_ITALIAN_POKER: '/information/regulation/ItalianPoker',
  INFORMATION_TELESINA: '/information/regulation/telesina',
  INFORMATION_BURRACO: '/information/regulation/burraco',
  INFORMATION_SCALA40: '/information/regulation/scala40',
  INFORMATION_BRISCOLA: '/information/regulation/briscola',
  INFORMATION_SETTE_E_MEZZO: '/information/regulation/sette-e-mezzo',
  INFORMATION_TRESETTE: '/information/regulation/tresette',
  INFORMATION_ASSO_PIGLIA_TUTTO: '/information/regulation/asso-piglia-tutto',
  INFORMATION_CIRULLA: '/information/regulation/cirulla',
  INFORMATION_BESTIA: '/information/regulation/bestia',
  INFORMATION_LOBBY: '/information/regulation/lobby',
  INFORMATION_GAME: '/information/regulation/game',
  ERROR_PAGE: '/error'
};