import { Popover } from "@mui/material";
import { GameHelper, gameActions, isPopup } from "@quanticogames/gameclient-core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { CloseButton } from "shared/ButtonsCollection";
import { FlexBox } from "shared/Flex";
import ButtonComponent from "shared/components/button";
import { OngoingMatches } from "shared/components/match-switcher/component/match-switcher-table";

type MatchSwitcherDesktopProps = {
    onClose: () => void;
    isOpened: boolean;
}

const MatchSwitcherDesktop = ({ onClose, isOpened }: MatchSwitcherDesktopProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const closeRoom = () => {
        dispatch(gameActions.goLobby({}));
        onClose;
    };

    return (
        <Popover
          onClose={onClose}
          open={isOpened}
          className="match-switch"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className='popover-header'>
            {t('Notification')}
            <FlexBox className='popover-header-action' alignItems={'center'}>
              <CloseButton onClick={onClose}/>
            </FlexBox>
          </div>
          <div className="match-switch-main">
            <OngoingMatches playCb={onClose} />
          </div>
          {/* {isShowButton && (
              <div className="notification-goLobby">
                <ButtonComponent
                  title={t('Go to the lobby')}
                  onClick={closeRoom}
                  fullWidth
                />
          </div>)} */}
        </Popover>
    )
}
export default MatchSwitcherDesktop