import React from 'react';
import { TournamentItem } from './tournament-item';
import { GameHelper } from '@quanticogames/gameclient-core';
import { TOURNAMENT_HEAD_DATA } from 'pages/tournament/component/tournament-label';
import TableComponent from 'shared/components/tables';
import TableNoData from 'shared/components/tables/component/table-nodata';
import TournamentItemMobile from 'pages/tournament/component/list/tournament-item-mobile';
import { useMediaQuery, useTheme } from '@mui/material';
import { mockTableData } from 'constants/tutorial';
import { getHighlightClass } from 'utils/tournamentTable';

type TournamentTableProps = {
  type?: string;
};

export const TournamentTableFake: React.FC<TournamentTableProps> = ({ type }) => {
  const tournaments = mockTableData;
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = GameHelper.isMobile();

  return (
    <>
      {isMobileScreenWidth || isMobile ? (
        <div className="tournament-mobile-list">
          {tournaments.length > 0 ? (
            tournaments.map((item, index) => (
              <TournamentItemMobile
                key={`tournament-mobile-${item.id}`}
                index={index}
                data={item}
                onSelect={() => {}}
                onAction={() => {}}
                className={getHighlightClass(item?.highlight)}
                isFake={true}
              />
            ))
          ) : (
            <TableNoData />
          )}
        </div>
      ) : (
        <TableComponent columns={TOURNAMENT_HEAD_DATA} onFilterModal={() => {}} className="tournament-table-container">
          {tournaments.length > 0 ? (
            tournaments.map((item, index) => (
              <TournamentItem
                key={`tournament-${item.id}`}
                index={index}
                data={item}
                onSelect={() => {}}
                onAction={() => {}}
                className={getHighlightClass(item?.highlight)}
                isFake={true}
              />
            ))
          ) : (
            <tr>
              <td colSpan={10} style={{ border: 'none' }}>
                <TableNoData />
              </td>
            </tr>
          )}
        </TableComponent>
      )}
    </>
  );
};
