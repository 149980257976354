import * as React from 'react';;
import { useTranslation } from 'react-i18next';
import { GameHelper, User, convertCentToEuro, formatMoney, gameApi, selectCurrentUser } from '@quanticogames/gameclient-core';
import { useEffect, useState } from 'react';
import ProfileCollapse from 'layouts/profiles/components/profile-collapse';
import { useSelector } from 'react-redux';
import { DEFAULT_MATCH_FINISHED_START_PAGE } from 'utils/constants';
import { useIsMobileLandscape } from 'hooks/useIsMobileLandscape';
import moment from 'moment';

export const Token: React.FC = () => {
  const { t } = useTranslation();
  const [tokenList, setTokenList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_MATCH_FINISHED_START_PAGE);
  const [totalToken, setTotalToken] = useState<number>(0);
  const currentUser: User = useSelector(selectCurrentUser);
  const isMobile = GameHelper.isMobile();
  const isMobileLandscape = useIsMobileLandscape();

  const formatDate = (inputTime) => {
    const date = moment(inputTime);
    return date.format('DD/MM/YYYY');
  }

  const fetchData = async (isFetchNew = false) => {    
    setIsLoading(true);
    const rootApi = `listToken?userId=${currentUser?.id}&status=1&page=${currentPage}`
    try {
      const response: any = await gameApi.getTokens(rootApi);
      const data = (response?.data || []).map((item: any) => {
        const finishedMatchItem = {
          code: item.code,
          value: `€ ${formatMoney(convertCentToEuro(Number(item?.remainValue)))}`,
          name: item.tokenIssuanceName,
          date: formatDate(item.expiredDate),
          game: JSON.parse(item?.gameCodes).join(', '),
          tournament: item.tournamentCode || '-',
          detail: [
            {
              label: 'Token Name',
              content: item.tokenIssuanceName,
              className: 'max-w-[114px] break-word token-name'
            },
            {
              label: 'Code',
              content: item.code,
              className: `${isMobileLandscape ? '' : 'max-w-[114px'} break-word token-code]`
            },
            {
              label: 'Game',
              content: JSON.parse(item?.gameCodes).join(', ')
            },
            {
              label: 'Tournament',
              content: item.tournamentCode || '-'
            },
            {
              label: 'Value',
              content: `€ ${formatMoney(convertCentToEuro(Number(item?.remainValue)))}`
            },
            {
              label: 'Expire Date',
              content: formatDate(item.expiredDate)
            }
          ]
        };
        return finishedMatchItem;
      });

      setTotalToken(response?.total);

      if (isFetchNew) {
        setTokenList(data);
        setIsLoading(false);
        return;
      }

      const finishedMatchConcatOnShowmore = [...tokenList, ...data];
      setTokenList(finishedMatchConcatOnShowmore);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
},[currentPage]);
  
  const columns = [
    {
      id: 'code',
      label: 'Token Name',
      align: 'left',
      classNames: '!text-[#06F] text-ellipsis max-w-[145px]'
    },
    {
      id: 'value',
      label: 'Value',
      align: 'left',
      classNames: ''
    },
    {
      id: 'date',
      label: 'Exp Date',
      align: 'left',
      classNames: ''
    }
  ]

  const columnsLandscape = [
    {
      id: 'name',
      label: 'Token Name',
      align: 'left',
      classNames: '!text-[#06F] text-ellipsis max-w-[125px]'
    },
    // {
    //   id: 'code',
    //   label: 'Token Code',
    //   align: 'left',
    //   classNames: 'text-ellipsis max-w-[145px]'
    // },
    {
      id: 'game',
      label: 'Game',
      align: 'left',
      classNames: 'text-ellipsis max-w-[145px] whitespace-nowrap !text-[#8E8E8E]'
    },
    {
      id: 'value',
      label: 'Value',
      align: 'left',
      classNames: 'whitespace-nowrap color-success'
    },
    {
      id: 'tournament',
      label: 'Tournaments',
      align: 'left',
      classNames: '!text-[#8E8E8E]'
    },
    {
      id: 'date',
      label: 'Exp Date',
      align: 'left',
      classNames: ''
    }
  ]

  return (
    <ProfileCollapse 
      columns={isMobileLandscape ? columnsLandscape : columns} 
      isLoading={isLoading} 
      data={tokenList} 
      totalItem={totalToken}
      onLoadMore={() => setCurrentPage(currentPage + 1)}
    />
  );
};
