import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandMore, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface IOption {
  label: string;
  value?: string;
  icon: React.ReactNode;
  children?: IOption[];
  id?: string;
}

export const ListChildren = ({
  children,
  label,
  icon,
  onClickMenu,
}: {
  children: IOption[];
  label: string;
  icon: React.ReactNode;
  onClickMenu: (label: string) => void;
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton onClick={handleClose} className="profile-menu-item">
        <ListItemIcon className="profile-menu-icon">{icon}</ListItemIcon>
        <ListItemText className="profile-menu-text" primary={label} />
        {open ? <ExpandMore /> : <KeyboardArrowRight />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {children.map((child, childrenKey) => (
            <ListItemButton
              key={childrenKey}
              className="profile-menu-item child"
              onClick={() => {
                onClickMenu(child.value);
              }}
            >
              <ListItemIcon className="profile-menu-icon">{child.icon}</ListItemIcon>
              <ListItemText className="profile-menu-text" primary={t(child.label)} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};
