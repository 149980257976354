import EngineeringIcon from '@mui/icons-material/Engineering';

const ErrorPage = () => {
    return <div className="error-page flex sm:items-center justify-center sm:flex-nowrap flex-wrap h-[100vh] px-[20px] max-w-[1040px] lg:gap-[40px] sm:gap-[30px] mx-auto gap-[20px]">
        <div className='sm:w-[50%] mt-auto sm:mt-[inherit]'>
            <img src='/images/common/error-page.png' className='w-full' />
        </div>
        <div className='font-raleway text-[18px] text-center sm:w-[50%]'>
            <h1 className='font-bold mb-[20px] text-[40px]'>ERRORE</h1>
            <p className='mb-[20px]'><EngineeringIcon sx={{ fontSize: 60 }} /></p>
            <p className='font-medium my-[10px] leading-[1.3]'>Si è verificato un errore durante l'esecuzione delle operazioni richieste.</p>
            <p className='font-bold text-[24px] my-[15px] leading-[1.3]'>Parametri non validi.</p>
            <p className='font-medium leading-[1.3]'>Per ricevere assistenza contattare il concessionario.</p>
        </div>
    </div>
}
export default ErrorPage