import { styled, IconButton, SxProps, Theme } from '@mui/material';
import React from 'react';
import { CloseIconSvg } from '../../../assets/icons/CloseIconSvg';

const StyledIconButton = styled(IconButton)(() => ({
  width: '24px',
  height: '24px',
  padding: 0,
  borderRadius: 0,
  color: 'inherit'
}));

type ButtonType = {
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const CloseButton = ({ sx, onClick }: ButtonType) => (
  <StyledIconButton sx={sx} onClick={onClick}>
    <CloseIconSvg />
  </StyledIconButton>
);
