export const WalletIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1706_64558)">
      <circle cx="11" cy="11" r="11" fill="#CCFF00"/>
    </g>
    <circle cx="11" cy="11" r="9" fill="#E2A90A"/>
    <g filter="url(#filter1_d_1706_64558)">
      <path d="M13.2983 9.41759L12.8409 10.4119H6.35791L6.71586 9.41759H13.2983ZM12.4431 11.4062L11.9858 12.4005H6.35791L6.71586 11.4062H12.4431ZM14.4715 6.73293L13.9943 7.76702C13.7822 7.57479 13.5584 7.40907 13.3231 7.26986C13.0878 7.13066 12.8409 7.02294 12.5823 6.94671C12.3238 6.87048 12.052 6.83236 11.767 6.83236C11.2036 6.83236 10.6965 6.98814 10.2457 7.29969C9.79494 7.61125 9.43864 8.07029 9.1768 8.67682C8.91497 9.28336 8.78405 10.0274 8.78405 10.9091C8.78405 11.7907 8.91497 12.5348 9.1768 13.1413C9.43864 13.7478 9.79494 14.2069 10.2457 14.5184C10.6965 14.83 11.2036 14.9858 11.767 14.9858C12.0587 14.9858 12.3338 14.9477 12.5923 14.8714C12.8541 14.7952 13.0977 14.6908 13.3231 14.5582C13.5518 14.4256 13.7623 14.2765 13.9545 14.1108L14.4517 15.1449C14.1036 15.473 13.696 15.7216 13.2286 15.8906C12.7613 16.0563 12.2741 16.1392 11.767 16.1392C10.9517 16.1392 10.2291 15.9271 9.59939 15.5028C8.97297 15.0786 8.48078 14.4754 8.12283 13.6932C7.76818 12.911 7.59086 11.9829 7.59086 10.9091C7.59086 9.83521 7.76818 8.90718 8.12283 8.12498C8.48078 7.34278 8.97297 6.73956 9.59939 6.31532C10.2291 5.89108 10.9517 5.67896 11.767 5.67896C12.294 5.67896 12.7862 5.7701 13.2436 5.95239C13.7043 6.13468 14.1136 6.39486 14.4715 6.73293Z" fill="#CCFF00"/>
    </g>
    <defs>
    <filter id="filter0_i_1706_64558" x="0" y="0" width="22" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="0.5"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1706_64558"/>
    </filter>
    <filter id="filter1_d_1706_64558" x="6.35791" y="5.67896" width="8.61365" height="10.4602" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="0.5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1706_64558"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1706_64558" result="shape"/>
    </filter>
  </defs>
</svg>

);
