import React, { useEffect, useState } from 'react';
import {
  styled,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { HeadCell, SortingOrder } from '../../../types/commonTable';
import { useTranslation } from 'react-i18next';

const StyledTableSortLabel = styled(TableSortLabel)(() => ({
  color: '#9EA8B4',
  fontSize: '0.875rem',
  lineHeight: '17px',
  fontWeight: 400,
}));

type CommonTableHeadProps = {
  tableHeadData: readonly HeadCell[];
  align?: 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;
  order?: SortingOrder;
  orderBy?: string;
  onClick?: (...arg0: any[]) => void;
  sx?: SxProps<Theme>;
  className?: string;
};

export const CommonTableHead: React.FC<CommonTableHeadProps> = ({
  tableHeadData,
  align = 'left',
  order,
  orderBy,
  onClick = (...agrs: any[]) => { },
  sx,
  className,
}) => {
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortColumn, setSortColumn] = useState('');

  const handleSort = (column) => {
    setSortDirection(prev => {
      if (column !== sortColumn) {
        return SortingOrder.ASC;
      }
      switch (prev) {
        case SortingOrder.ASC:
          return SortingOrder.DESC;
        case SortingOrder.DESC:
          return SortingOrder.ASC;
      }
    })
    setSortColumn(column);
  }

  useEffect(() => {
    onClick(sortColumn, sortDirection)
  }, [sortColumn, sortDirection])

  return (
    <TableHead className={className} sx={sx}>
      <TableRow>
        {tableHeadData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ?? align}
            sx={{ padding: '4px 16px', height: '36px', borderBottom: 'none' }}
          >
            <StyledTableSortLabel
              hideSortIcon={!headCell.sortable}
              active={orderBy === headCell.id}
              {...(headCell?.sortable && sortColumn === headCell.id ? {
                direction: sortDirection
              } : {})}
              onClick={() => headCell?.sortable && handleSort(headCell.id)}
              sx={{ color: '#9EA8B4' }}
            >
              {t(headCell.label)}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
};
