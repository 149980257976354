import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import {
  BoardCardGame,
  BoardGameContext,
  GameHelper,
  selectCasino,
  SKIN,
} from "@quanticogames/gameclient-core";

const LayoutTutorial = () => {
  const { pathname } = useLocation();
  const isMobile = GameHelper.isMobile();
  const paths = pathname.match(new RegExp(/\w+(\/\w+)*/g))?.[0];
  const pathArr = paths?.split('/') || [];
  const gameCode = pathArr.length ? pathArr[pathArr.length - 1] : '';
  const skinType: string = useSelector(selectCasino) || "quantico";

  const [boardGame, updateBoardGame] = useState<BoardCardGame>(null);
  const setBoardGame = (board: BoardCardGame) => {
    if (boardGame) {
      boardGame.unmount();
      boardGame.destroy();
    }

    updateBoardGame(board);
  };

  useEffect(() => {
    document.title = `Quantico - ${gameCode} Tutorial`
  }, [gameCode]);

  useEffect(() => {
    return () => boardGame?.destroy();
  }, [boardGame]);

  return (
    <BoardGameContext.Provider value={{ boardGame, setBoardGame }}>
      <div className={`main-page-game tutorial-page${isMobile ? ' mobile' : ' desktop'}`}>
        <div
          className={`
            game-container flex flex-col ${skinType || "quantico"}
            fixed top-0 left-0 right-0 bottom-0 z-[100] bg-game bg-game-${gameCode}
          `}
        >
          <div
            id="main-table"
            className={`main-table main-table-${gameCode} flex items-center justify-start md:justify-center flex-col p-5`}
          >
            <div
              id="table-round"
              className={`w-full h-full relative rounded-[999px] table-${gameCode} ${gameCode} flex items-center justify-center`}
            >
              <div className={`table-logo table-logo-${gameCode} absolute transform left-[50%] -translate-x-1/2 -translate-y-1/2 top-[50%] opacity-40`}>
                <img
                  src={`/images/logo/skin/logo-${SKIN[skinType]?.logoTable ?? SKIN[skinType]?.logoHeader}.webp`}
                  className="xl:h-[50px] h-[40px] max-w-[300px]"
                  style={{ filter: "brightness(0) invert(1)" }}
                />
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </BoardGameContext.Provider>
  );
};
export default LayoutTutorial;
