import React from 'react';
import { Box } from '@mui/material';
import { CommonTable } from '../CommonTable';
import { TableItem } from './TableItem';
import { styles } from './Styles';
import { useTranslation } from 'react-i18next';

const HEAD_DATA = [
  {
    id: 'stage',
    label: 'Stage',
  },
  {
    id: 'week',
    label: 'Week',
  },
  {
    id: 'month',
    label: 'Month',
  },
  {
    id: 'year',
    label: 'Year',
  },
  {
    id: 'historical',
    label: 'Historical',
  },
];

const DATA = [
  {
    id: 1,
    description: 'Pts money',
    week: '0',
    month: '0',
    year: '0',
    historical: '0',
  },
  {
    id: 2,
    description: 'Classification',
    week: '0',
    month: '0',
    year: '0',
    historical: '0',
  },
  {
    id: 3,
    description: 'P. train',
    week: '16',
    month: '16',
    year: '38',
    historical: '38',
  },
  {
    id: 4,
    description: 'Classification',
    week: '0',
    month: '0',
    year: '0',
    historical: '0',
  },
];

type RankingsProps = {
  onClose?: React.MouseEventHandler<HTMLElement>;
};
export const Rankings: React.FC<RankingsProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={styles.Box}>
        <CommonTable
          showHeadOnMobile
          tableHeadData={HEAD_DATA}
          headerSx={styles.TableHead}
          sx={styles.CommonTable}
        >
          {DATA.map((item) => (
            <TableItem key={`ranking-${item.id}`} data={item} />
          ))}
        </CommonTable>
      </Box>
    </>
  )
};
