import { useTranslation } from 'react-i18next';
import ModalComponent from 'shared/components/modal';

type Props = {
    isOpen: boolean;
    onCloseModal?: () => void;
    dataCancel?: any;
}

const ModalCancelTournament = ({ isOpen, onCloseModal, dataCancel }: Props) => {
    const {t} = useTranslation();
    return (
        <ModalComponent
            open={isOpen}
            title={t('Cancel tournament')}
            showFooter={true}
            onOk={onCloseModal}
            textOk='Ok'
        >
            {dataCancel}
        </ModalComponent>
    )
}

export default ModalCancelTournament;