import { HeadCell } from "types/commonTable";

export const CLASSIFICHE_HEAD_DATA: HeadCell[] = [
    {
        id: 'position',
        label: 'Pos',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'userName',
        label: 'UserName',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'point',
        label: 'Punti',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'play',
        label: 'Giocate',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'won',
        label: 'Vinte',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'avgPoint',
        label: 'Media Punti',
        sortable: false,
        filter: false,
        align: 'center',
    },
    {
        id: 'wonPercentage',
        label: '% Vinte',
        sortable: false,
        align: 'center',
    },
];

export interface ClassificheInterface {
    position: number;
    userName: string;
    point: number;
    play: number;
    won: number;
    avgPoint: number;
    wonPercentage: number;
}

export const CLASSIFICHE_GAME_LIST = [
    {
        key: 'AssoPigliaTutto',
        label: `ASSO PIGLIA TUTTO`,
    },
    {
        key: 'Bestia',
        label: `BESTIA`,
    },
    {
        key: 'Briscola',
        label: `BRISCOLA`,
    },
    {
        key: 'Burraco',
        label: `BURRACO`,
    },
    {
        key: 'Cirulla',
        label: `CIRULLA`,
    },
    {
        key: 'ItalianPoker',
        label: `POKER 5 CARTE`,
    },
    {
        key: 'Peppa',
        label: `PEPPA`,
    },
    {
        key: 'Scala40',
        label: `SCALA 40`,
    },
    {
        key: 'Scopa',
        label: `SCOPA`,
    },
    {
        key: 'SetteEMezzo',
        label: `SETTE E MEZZO`,
    },
    {
        key: 'Telesina',
        label: `TELESINA`,
    },
    {
        key: 'Tresette',
        label: `TRESETTE`,
    },
    {
        key: 'Scopone',
        label: `SCOPONE`,
    },
    {
        key: 'Solitario',
        label: `SOLITARIO`,
    },
];