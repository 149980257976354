import {
  SxProps,
  Table,
  TableBody,
  TableContainer,
  Theme
} from '@mui/material';
import { GameHelper } from '@quanticogames/gameclient-core';
import React, { ReactNode } from 'react';
import { HeadCell, SortingOrder } from '../../types/commonTable';
import { CommonTableHead } from './CommonTableHead';

type CommonTableProps = {
  children: ReactNode;
  tableHeadData: readonly HeadCell[];
  align?: 'left' | 'inherit' | 'center' | 'right' | 'justify' | undefined;
  order?: SortingOrder;
  orderBy?: string;
  onHeadCellClick?: (...arg0: any[]) => void;
  sx?: SxProps<Theme>;
  className?: string;
  showHeadOnMobile?: boolean;
  headerSx?: SxProps;
  bodySx?: SxProps;
};

export const CommonTable: React.FC<CommonTableProps> = ({
  children,
  tableHeadData,
  order,
  orderBy,
  align,
  onHeadCellClick,
  sx,
  className,
  showHeadOnMobile = false,
  headerSx,
  bodySx,
}) => {
  const isDesktop = !GameHelper.isMobile();

  return (
    <TableContainer sx={sx}>
      <Table>
        {(isDesktop || (!isDesktop && showHeadOnMobile)) && (
          <CommonTableHead
            className={className}
            tableHeadData={tableHeadData}
            order={order}
            orderBy={orderBy}
            align={align}
            onClick={onHeadCellClick}
            sx={headerSx}
          />
        )}

        <TableBody sx={bodySx}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
