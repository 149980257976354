import React from 'react';
import { SxProps } from '@mui/material';
import { Rankings } from '../../../types/ranking';
import { StyledTableCell, StyledTableRow } from './Styles';

type RankingsItemProps = {
  data: Rankings;
};

type CELL = {
  name: string;
  sx?: SxProps;
};

const CELLS: CELL[] = [
  {
    name: 'description',
    sx: {
      fontWeight: '400',
      maxWidth: '116px',
      textAlign: 'left',
    },
  },
  {
    name: 'week',
  },
  {
    name: 'month',
  },
  {
    name: 'year',
  },
  {
    name: 'historical',
  },
];

export const TableItem: React.FC<RankingsItemProps> = ({
  data,
}): JSX.Element => (
  <StyledTableRow>
    {CELLS.map((cell) => (
      <StyledTableCell key={cell.name} sx={cell.sx}>
        {data[cell.name]}
      </StyledTableCell>
    ))}
  </StyledTableRow>
);
