import { CashTable, convertCentToEuro, formatMoney, selectCashTable } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CashTableInformation = () => {
    const { t } = useTranslation();
    const table: CashTable = useSelector(selectCashTable);

    return (
        <>
            {table && (
                <div className='tournament-information'>
                    <p>{t('Table Code')}: {t(table.code)}</p>
                    <p>{t('Mode')}: {t('Cash Table')}</p>
                    <p>{t('Ante')}: € {formatMoney(convertCentToEuro(table.invitation))}</p>
                </div>
            )}
        </>
    );
};

export default CashTableInformation;
