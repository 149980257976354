
import "./wdyr";
import "./sentryInit";
import { Provider } from 'react-redux';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './assets/styles/common.css';
import './assets/styles/index.scss';
import './assets/styles/introjs.min.css';
import './assets/styles/casino/casino.scss';
import './assets/styles/mode/dark-mode.scss';
import './assets/styles/mode/mobile-landscape.scss';

// import { ConnectedRouter } from 'connected-react-router';
import { store, history } from './app/store';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { createRoot } from 'react-dom/client';
import './i18n';

import './utils/soundSettings';
import './utils/cardSettings';

const root = createRoot(document.getElementById('root'))

root.render(
  <Provider store={store} >
    <ReduxRouter history={history}>
      < App />
    </ReduxRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
