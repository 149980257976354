import { styled, IconButton, SxProps, Theme } from '@mui/material';
import React, { MouseEventHandler, ReactNode } from 'react';
import { COLORS } from '../../../constants/colors';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '48px',
  height: '48px',
  backgroundColor: COLORS.btn_grey,
  path: {
    fill: COLORS.white,
  },
  '&:hover': {
    backgroundColor: COLORS.base_light,
    boxShadow: '0px 3px 12px rgba(36, 222, 219, 0.46)',
  },
  [theme.breakpoints.up('md')]: {
    width: '36px',
    height: '36px',
  },
}));

type ButtonType = {
  icon?: ReactNode;
  sx?: SxProps<Theme>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const MiniFAButton = ({ icon, sx, onClick }: ButtonType) => {
  return (
    <StyledIconButton sx={sx} onClick={onClick}>{icon}</StyledIconButton>
  );
}
