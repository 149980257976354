import { Box, Dialog, DialogContent, DialogTitle, styled } from "@mui/material";
import { ButtonCustom } from "@quanticogames/gameclient-components";
import { Match, selectActiveMatchId, selectEliminatePos, selectMatch } from "@quanticogames/gameclient-core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "shared/components/button";
import ModalComponent from "shared/components/modal";

const StyledDialogTitle = styled(DialogTitle)({
    background: '#0E345E',
    color: '#fff',
    padding: '10px 20px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
});

const StyledDialogContent = styled(DialogContent)({
    width: "100%",
    textAlign: 'center',
    fontSize: '16px',
    marginTop: '10px',
    fontWeight: '600',
    padding: 0,
});

const ModalEliminate = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const matchId: number = useSelector(selectActiveMatchId);
    const match: Match = useSelector(selectMatch(matchId));
    const eliminatePos: number = useSelector(selectEliminatePos);
    const [open, setOpen] = useState<boolean>(false);

    const getNumberEnd = (pos: number) => {
        const s = Math.floor(pos / 10);
        const e = pos % 10;
        if (s === 1) return 'th';
        switch (e) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        };
    };

    const positionLabel = useMemo(() => {
        if (!eliminatePos) return '';
        return eliminatePos + t(getNumberEnd(eliminatePos));
    }, [eliminatePos]);

    // const goToLobby = () => {
    //     dispatch(gameActions.goLobby({ gameId: match?.gameId }));
    //     let matchWindows: string[] = localStorage['matchWindows'] && localStorage['matchWindows'].split(',') || [];
    //     const name = matchWindows?.find((name) => name == matchId.toString());
    //     if (name) window.close();
    // };

    useEffect(() => {
        setOpen(!!eliminatePos);
        if (eliminatePos) {
          const triggerGoToLobby = setTimeout(() => setOpen(false), 30000);
          return () => clearTimeout(triggerGoToLobby);
        };
    }, [eliminatePos]);

    return (
        <>
            <ModalComponent
                open={false}
                className='font-raleway modal-confirm-subcribe'
                title={t('Eliminated')}
                showIconClose={false}
            >
                <StyledDialogContent>
                    <Box sx={{ py: 2 }}>{t('Player eliminated', { place: positionLabel })}</Box>
                    <ButtonComponent
                        color="warning"
                        title='Chiuso'
                        onClick={() => setOpen(false)}
                    />
                </StyledDialogContent >
            </ModalComponent >
        </>
    )
};

export default ModalEliminate;
