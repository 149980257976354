import { useEffect } from 'react';
import TabsRegulation from 'shared/Regulation/components/TabsRegulation';
import {
    AccreditoDelleVincite, Classifiche, CollusionEDumping, ColpoGrosso, DisattivazioneGiochi, Filotto,
    GestioneDiEventiCasuali, ITavoliCashALivelli, ITavoliCashATurni, ITavoliCashInformazioniGenerali,
    ITorneiALivelli, ITorneiALivelliFreezeout, ITorneiALivelliShootout, ITorneiATurni, ITorneiATurniDanEL,
    ITorneiATurniDanELKO, ITorneiATurniDanese, ITorneiATurniKO, ITorneiAccredito, ITorneiAnnullamento,
    ITorneiAutorizzazioniAAMS, ITorneiBonus, ITorneiCancellarsiDaUnTorneo, ITorneiFormazioneMontepremi,
    ITorneiGarantiti, ITorneiInformazioniGenerali, ITorneiPartecipare, ITorneiRipartizioneMontepremi,
    ITorneiRiservati, ITorneiSatellite, ITorneiToken, InfomazioniAiGiocatori, MeccanismiDiGioco,
    ModalitaAllenamento, OrariDelSito, RatingGiocatori, RegoleDellaCasa, RequisitiMinimiDiSistema,
    RimborsiPerDisconnessioni, SigleEdAbbreviazioni, TempoMassimo, TipiDiGiochi,
    VipLevelsAndFreepoints,
} from 'shared/Regulation/data/DataLobby';

const InformationLobby = () => {
    const items = [
        {
            key: 'Tipi-di-giochi',
            label: `Tipi di giochi`,
            children: <TipiDiGiochi />,
        },
        {
            key: 'Tempo-massimo',
            label: `Tempo massimo`,
            children: <TempoMassimo />,
        },
        {
            key: 'I-tornei',
            label: `I tornei`,
            subTabs: [
                {
                    key: 'I tornei > Informazioni generali',
                    label: 'Informazioni generali',
                    children: <ITorneiInformazioniGenerali />,
                },
                {
                    key: 'I tornei > Tornei a turni',
                    label: 'Tornei a turni',
                    children: <ITorneiATurni />,
                    subTabs: [
                        {
                            key: 'KO',
                            label: 'KO',
                            children: <ITorneiATurniKO />,
                        },
                        {
                            key: 'Danese',
                            label: 'Danese',
                            children: <ITorneiATurniDanese />,
                        },
                        {
                            key: 'DanEL',
                            label: 'DanEL',
                            children: <ITorneiATurniDanEL />,
                        },
                        {
                            key: 'DanKO-e-DanELKO',
                            label: 'DanKO e DanELKO',
                            children: <ITorneiATurniDanELKO />,
                        },
                    ],
                },
                {
                    key: 'Tornei-a-livelli',
                    label: 'Tornei a livelli',
                    children: <ITorneiALivelli />,
                    subTabs: [
                        {
                            key: 'Freezeout',
                            label: 'Freezeout',
                            children: <ITorneiALivelliFreezeout />,
                        },
                        {
                            key: 'Shootout',
                            label: 'Shootout',
                            children: <ITorneiALivelliShootout />,
                        },
                    ],
                },
                {
                    key: 'Token',
                    label: 'Token',
                    children: <ITorneiToken />,
                },
                {
                    key: 'Partecipare-a-un-torneo',
                    label: 'Partecipare a un torneo',
                    children: <ITorneiPartecipare />,
                },
                {
                    key: 'Autorizzazione-AAMS',
                    label: ' Autorizzazione AAMS',
                    children: <ITorneiAutorizzazioniAAMS />,
                },
                {
                    key: 'Annullamento-di-tornei',
                    label: 'Annullamento di tornei',
                    children: <ITorneiAnnullamento />,
                },
                {
                    key: 'Cancellarsi-da-un-torneo',
                    label: 'Cancellarsi da un torneo',
                    children: <ITorneiCancellarsiDaUnTorneo />,
                },
                {
                    key: 'Formazione-montepremi',
                    label: ' Formazione montepremi',
                    children: <ITorneiFormazioneMontepremi />,
                },
                {
                    key: 'Ripartizione-monteprem',
                    label: 'Ripartizione montepremi',
                    children: <ITorneiRipartizioneMontepremi />,
                },
                {
                    key: 'Tornei-bonus',
                    label: 'Tornei bonus',
                    children: <ITorneiBonus />,
                },
                {
                    key: 'Tornei-garantiti',
                    label: 'Tornei garantiti',
                    children: <ITorneiGarantiti />,
                },
                {
                    key: 'Tornei-satellite',
                    label: 'Tornei satellite',
                    children: <ITorneiSatellite />,
                },
                {
                    key: 'Tornei-riservati',
                    label: 'Tornei riservati',
                    children: <ITorneiRiservati />,
                },
                {
                    key: 'I-tornei->-Accredito-delle-vincite',
                    label: 'Accredito delle vincite',
                    children: <ITorneiAccredito />,
                },
            ],
        },
        {
            key: 'I-tavoli-cash',
            label: `I tavoli cash`,
            subTabs: [
                {
                    key: 'I-tavoli-ash->-Informazioni-generali',
                    label: 'Informazioni generali',
                    children: <ITavoliCashInformazioniGenerali />,
                },
                {
                    key: 'Tavoli-cash-a-turni',
                    label: 'Tavoli cash a turni',
                    children: <ITavoliCashATurni />,
                },
                {
                    key: 'Tavoli-cash-a-livelli',
                    label: 'Tavoli cash a livelli',
                    children: <ITavoliCashALivelli />,
                },
            ],
        },
        {
            key: 'Colpo-grosso',
            label: `Colpo grosso`,
            children: <ColpoGrosso />,
        },
        {
            key: 'Filotto',
            label: `Filotto`,
            children: <Filotto />,
        },
        {
            key: 'Classifiche',
            label: `Classifiche`,
            children: <Classifiche />,
        },
        {
            key: 'Livelli-vip-e-freepoints',
            label: `Livelli VIP e VIP Points`,
            children: <VipLevelsAndFreepoints />,
        },
        // {
        //     key: 'Rating-giocatori',
        //     label: `Rating giocatori`,
        //     children: <RatingGiocatori />,
        // },
        {
            key: 'Infomazioni-ai-giocatori',
            label: `Infomazioni ai giocatori`,
            children: <InfomazioniAiGiocatori />,
        },
        {
            key: 'Sigle-ed-abbreviazioni',
            label: `Sigle ed abbreviazioni`,
            children: <SigleEdAbbreviazioni />,
        },
        {
            key: 'Regole-della-casa',
            label: `Regole della casa`,
            children: <RegoleDellaCasa />,
        },
        {
            key: 'Gestione-di-eventi-casuali',
            label: `Gestione di eventi casuali`,
            children: <GestioneDiEventiCasuali />,
        },
        {
            key: 'Meccanismi-di-gioco',
            label: `Meccanismi di gioco`,
            children: <MeccanismiDiGioco />,
        },
        {
            key: 'Accredito-delle-vincite',
            label: `Accredito delle vincite`,
            children: <AccreditoDelleVincite />,
        },
        {
            key: 'Rimborsi-per-disconnessioni',
            label: `Rimborsi per disconnessioni`,
            children: <RimborsiPerDisconnessioni />,
        },
        {
            key: 'Collusion-e-dumping',
            label: `Collusion e dumping`,
            children: <CollusionEDumping />,
        },
        {
            key: 'Modalità-allenamento',
            label: `Modalità allenamento`,
            children: <ModalitaAllenamento />,
        },
        {
            key: 'Disattivazione-giochi',
            label: `Disattivazione giochi`,
            children: <DisattivazioneGiochi />,
        },
        // {
        //     key: 'Requisiti minimi di sistema',
        //     label: `Requisiti minimi di sistema`,
        //     children: <RequisitiMinimiDiSistema />,
        // },
        // {
        //     key: 'Orari di apertura del sito',
        //     label: `Orari di apertura del sito`,
        //     children: <OrariDelSito />,
        // },
    ];

    useEffect(() => {
        document.title = "REGOLAMENTI GENERALI";
    }, []);

    return (
        <TabsRegulation items={items} />
    )
}
export default InformationLobby
