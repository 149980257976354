import { Fab, SxProps } from '@mui/material';
import './index.scss';

type ButtonType = {
  icon?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  sx?: SxProps;
};

export const FAButton = ({ icon, onClick, className, sx }: ButtonType) => (
  <Fab 
    color="primary" 
    aria-label="notification" 
    onClick={onClick} 
    className={`${className || ''} fa-button`}
    sx={{
      ...sx
    }}
  >
    {icon}
  </Fab>
);
