import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./rootSaga";
import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router'
import { combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
import { chatReducer, gameReducer, tournamentReducer, cashTableReducer, configReducer, alertMessageReducer } from '@quanticogames/gameclient-core';
import { matchReducer } from '@quanticogames/gameclient-core';
import { authReducer } from '@quanticogames/gameclient-core';
import { concatenateReducers } from 'utils/concatenateReducers';
import { italianPokerReducer, italianPokerCashReducer } from '../games/italianPoker';
import { scopaReducer } from '../games/scopa';
import { burracoReducer } from '../games/burraco';
import { scala40Reducer } from '../games/scala40';
import { briscolaReducer } from '../games/briscola';
import { tresetteReducer } from '../games/tresette';
import { setteEMezzoReducer } from 'games/setteEMezzo';
import { assoPigliaTuttoReducer } from 'games/assoPigliaTutto';
import { cirullaReducer } from 'games/cirulla';
import { bestiaReducer } from 'games/bestia';
import { scoponeReducer } from 'games/scopone';
import { telesinaCashReducer } from 'games/cashTables/telesina';
import { solitarioReducer } from 'games/solitario';
import { telesinaReducer } from 'games/telesina';
import { setteEMezzoCashReducer } from 'games/cashTables/setteEMezzo';
import { peppaReducer } from 'games/peppa';

export const history = createBrowserHistory()
const routerMiddleware = createRouterMiddleware(history)

const matchesReducer = concatenateReducers([
    italianPokerReducer,
    scopaReducer,
    burracoReducer,
    scala40Reducer,
    briscolaReducer,
    tresetteReducer,
    setteEMezzoReducer,
    assoPigliaTuttoReducer,
    cirullaReducer,
    bestiaReducer,
    scoponeReducer,
    solitarioReducer,
    telesinaReducer,
    peppaReducer,
    matchReducer
]);

const cashTablesReducer = concatenateReducers([
    italianPokerCashReducer,
    telesinaCashReducer,
    setteEMezzoCashReducer,
    cashTableReducer,
]);

const rootReducer = combineReducers({
    router: createRouterReducer(history),
    auth: authReducer,
    chat: chatReducer,
    game: gameReducer,
    tournament: tournamentReducer,
    cashTable: cashTablesReducer,
    match: matchesReducer,
    config: configReducer,
    alertMessage: alertMessageReducer,
})

const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error, detail: { sagaStack: string }) => {  
        console.log("Error Saga: ", error);
        console.log(detail.sagaStack);
    }
});
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware),
});

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
