import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from '../theme';
import { Router } from './routes';
import i18n from 'i18n';
import { authEvent } from '@quanticogames/gameclient-core';
import RedirectLoginModal from 'shared/RedirectLoginModal/RedirectLoginModal';
import * as Sentry from "@sentry/react";
import FailBackPage from 'pages/failback';


function App() {
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLang = localStorage.getItem('i18nextLng');
      i18n.changeLanguage(newLang);
    };
    window.addEventListener('storage', handleLanguageChange);
    localStorage.setItem('isAlreadyTour', 'false');
    return () => {
      window.removeEventListener('storage', handleLanguageChange);
    };
  }, []);

  const [isShowRedirectLoginModal, setIsShowRedirectLoginModal] = useState(false);

  useEffect(() => {
    authEvent.on("authentication-fail", () => {
      setIsShowRedirectLoginModal(true)
    });    
  }, [])

  return (
    <Sentry.ErrorBoundary fallback={FailBackPage}>
      <ThemeProvider theme={theme}>
        <RedirectLoginModal  open={isShowRedirectLoginModal}
            onClose={() => setIsShowRedirectLoginModal(false)}/>
        <Router />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
   
  );
}

export default App;
