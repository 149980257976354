export const LogOutIconSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1_21641"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.54541" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1_21641)">
      <path
        d="M5.39961 21.5454C4.89961 21.5454 4.47461 21.3704 4.12461 21.0204C3.77461 20.6704 3.59961 20.2454 3.59961 19.7454V5.84541C3.59961 5.34541 3.77461 4.92041 4.12461 4.57041C4.47461 4.22041 4.89961 4.04541 5.39961 4.04541H12.1246V5.54541H5.39961C5.33294 5.54541 5.26628 5.57874 5.19961 5.64541C5.13294 5.71208 5.09961 5.77874 5.09961 5.84541V19.7454C5.09961 19.8121 5.13294 19.8787 5.19961 19.9454C5.26628 20.0121 5.33294 20.0454 5.39961 20.0454H12.1246V21.5454H5.39961ZM16.1246 17.0704L15.0996 15.9704L17.5246 13.5454H9.12461V12.0454H17.5246L15.0996 9.62041L16.1246 8.52041L20.3996 12.7954L16.1246 17.0704Z"
        fill="#CD1B1B"
      />
    </g>
  </svg>
);
