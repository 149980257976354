import { Avatar, Badge } from '@mui/material';
import { convertCentToEuro, formatMoney, numberWithDecimals } from 'utils/formatCurrency';
import { useSelector } from 'react-redux';
import { FlexBox } from 'shared/Flex';
import { Text } from 'shared/Text';
import { useAppSelector } from 'app/hooks';
import { CreditIcon } from 'assets/icons/CreditIcon';
import { BonusIcon } from 'assets/icons/BonusIcon';
import { BalanceInfo, User, selectBalance, selectBalanceInfo, selectCurrentUser } from '@quanticogames/gameclient-core';
import UserCurrency from 'layouts/profiles/components/profile-header/component/user-currency';
import { useTranslation } from 'react-i18next';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import StarIconActive from 'assets/icons/StarIconActive';
import StarIcon from 'assets/icons/StarIconBackground';
type ProfileHeaderProps = {
  rankingUser: any;
}

const ProfileHeader = ({ rankingUser }: ProfileHeaderProps) => {
  const { t } = useTranslation()
  const currentUser: User = useSelector(selectCurrentUser);
  const balance = useAppSelector(selectBalance);
  const balanceFormat = formatMoney(convertCentToEuro(Number(balance)));
  const balanceInfo = useSelector(selectBalanceInfo) as BalanceInfo;
  const balanceBonusFormat = formatMoney(convertCentToEuro(Number(balanceInfo?.bonusBalance)));
  const freePointFormat = numberWithDecimals(rankingUser?.freePoint ?? 0, 2);

  const RankingList = ({ numberRanking }) => {
    const totalStars = 5;
    const stars = [];
  
    for (let i = 0; i < totalStars; i++) {
      if (i < numberRanking) {
        stars.push(<StarIconActive />);
      } else {
        stars.push(<StarIcon />);
      }
    }
  
    return (
      <FlexBox className="ranking-list" gap={5}>
        {stars.map((star, index) => (
          <span key={index}>{star}</span>
        ))}
      </FlexBox>
    );
  };

  return (
    <div className="profile-header-info-container">
      <div className="avatar">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ svg: { width: 'auto', height: 'auto' } }}
        >
          <Avatar
            alt={currentUser?.fullName}
            src={`/images/avatar/${currentUser.avatar}` || '../../../assets/images/men.png'}
            className={`profile-avartar-image ranking-vip-${rankingUser?.vipLevel}`}
          />
        </Badge>
      </div>
      <div className="profile-avatar-info">
        <FlexBox className="" flexDirection="column">
          <p className="profile-avatar-name">{currentUser?.userName}</p>
          
          <RankingList numberRanking={rankingUser?.skillLevel ?? 0} />

          <div className="top-up-button">
            <UserCurrency />
          </div>
        </FlexBox>


        <FlexBox flexDirection="column" className="profile-credit-bonus">
          <FlexBox gap={5} justifyContent="flex-start" alignItems="center" className="credit-container">
            <CreditIcon />
            <Text fontWeight={'600'} className="credit-text">
              {t('Credit')}: € {balanceFormat}
            </Text>
          </FlexBox>
          <FlexBox gap={5} justifyContent="flex-start" alignItems="center">
            <BonusIcon />
            <Text fontWeight={'500'} style={{ color: '#8E8E8E' }} className="credit-text">
              {t('Bonus')}: € {balanceBonusFormat}
            </Text>
          </FlexBox>
          <FlexBox gap={5} justifyContent="flex-start" alignItems="center">
            <LocalAtmIcon />
            <Text fontWeight={'500'} style={{ color: '#8E8E8E' }} className="credit-text">
              {t('VIP points')}: {freePointFormat}
            </Text>
          </FlexBox>
        </FlexBox>
      </div>
    </div>
  );
};

export default ProfileHeader;
