import { Waiting } from '@quanticogames/gameclient-components';
import { GameRound, RoundState, Sound, SoundManager, User, cashTableActions, gameActions, matchActions, selectActiveMatchId, selectCurrentUser, selectGameState, selectRound } from '@quanticogames/gameclient-core';
import { PATH_ROUTES } from 'constants/routes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export const GameFlow = () => {
    const dispatch = useDispatch();
    const gameState = useSelector(selectGameState);
    const [searchParams] = useSearchParams();
    const idFromUrl = searchParams.get('id');
    const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
    const matchId = useSelector(selectActiveMatchId);

    const round: GameRound = useSelector(selectRound());
    const currentUser: User = useSelector(selectCurrentUser);

    if (!idFromUrl) {
        dispatch(gameActions.goLobby({}));
    }

    useEffect(() => {
        if (!gameState.initialized) return;
        if (idFromUrl) {
            const id = parseInt(idFromUrl);
            if (isCashTable) {
                dispatch(cashTableActions.open({ tableId: id }));
                return;
            };
            if (!matchId) dispatch(matchActions.play({ matchId: id }));
        }
    }, [gameState.initialized]);

    useEffect(() => {
        // check win or lost
        const winners = round?.winners || [];

        const isWin = !!winners.find(w => w.playerId == currentUser.id);

        if (round.config?.isOffSound === true) {
            return;
        }

        if (round.matchState == 'finished') {
            isWin ? SoundManager.play(Sound.endSession) : SoundManager.play(Sound.winSession);
        } else if (round.state == RoundState.roundFinished) {
            !isWin ? SoundManager.play(Sound.endRound) : SoundManager.play(Sound.winRound);
        }
    }, [round?.state, round?.matchState, round?.winners]);

    return <>
        <Waiting />
        {/* <Winner /> */}
    </>
}