import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    CashTable,
    cashTableActions,

    selectCashTable,
    selectSitPopup,

} from '@quanticogames/gameclient-core';
import {
    StyledAnnounce,

} from './styled';
import ModalComponent from 'shared/components/modal';
import ButtonComponent from 'shared/components/button';
import { useTranslation } from 'react-i18next';

const ModalSitNotification: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const table: CashTable = useSelector(selectCashTable);
    const sitPopup: {
        open: boolean,
        seatId: number | null,
    } = useSelector(selectSitPopup);
    const { open, seatId } = sitPopup;
   

    const onCloseModal = () => {
        dispatch(cashTableActions.setSeatNotification({
            open: null,
            seatId: null,
        }));
        dispatch(cashTableActions.setBuyinPopup({
            open: false,
            seatId: null,
            loading: false,
            rebuy: false,
        }));
    };

   

    return (
        <ModalComponent 
            open={open} 
            title={t('Notification')} 
            typeModal='confirm'
        >
            <StyledAnnounce>
                <p>{t('The seat was taken by another player')}</p>
            </StyledAnnounce>
            <ButtonComponent
                title='Chiudi'
                onClick={onCloseModal}
            />
        </ModalComponent>
    )
}

export default ModalSitNotification;
