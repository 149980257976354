import * as React from 'react';

const usePopup = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isOpened = Boolean(anchorEl);

  return {
    setAnchorEl,
    anchorEl,
    handlePopoverOpen,
    isOpened,
    handlePopoverClose,
  };
};

export default usePopup;
