import { MonetizationBadgeIcon } from '../assets/icons/MonetizationBadgeIcon';
import { LinkBadgeIcon } from '../assets/icons/LinkBadgeIcon';

export enum Badges {
  FREE = 'Free',
  TREBLE = 'Treble',
  PRIZE_POOL = 'Prize_pool',
  SATELLITE = 'Satellite',
  CRAZY_PRIZE = 'Crazy_Prize',
  TWO_PAIRS = '2',
  HEAD_TWO_HEAD = '0',
  FOUR_IN_TWO = '1',
  TOKEN_ICON = 'Token_Icon',
  SIT_AND_GO = 'Normal',
  SCHEDULED = 'Scheduled',
  CASH_TABLE = 'Cash_Table'
}

export const BADGES_COLLECTION = {
  [Badges.FREE]: {
    title: 'Free',
    icon: <img src="/images/badge/free.png" />,
  },
  [Badges.TREBLE]: {
    title: 'Registration also with FP',
    icon: <img src="/images/badge/fillotto.png" />,
  },
  [Badges.PRIZE_POOL]: {
    title: 'Guaranteed Prize',
    background: '#CD1B1B',
    icon: <MonetizationBadgeIcon />,
  },
  [Badges.SATELLITE]: {
    title: 'Shared game',
    background: '#8341D8',
    icon: <LinkBadgeIcon />,
    color: '#fff',
  },
  [Badges.CRAZY_PRIZE]: {
    title: 'Torneo Colpo Grosso',
    icon: <img src="/images/badge/colpo-grosso.png" />,
  },
  [Badges.TWO_PAIRS]: {
    title: 'Two pairs',
    icon: <img src="/images/badge/2vs2.png" />,
  },
  [Badges.FOUR_IN_TWO]: {
    title: 'Four in two',
    icon: <img src="/images/badge/4in2.png" />,
  },
  [Badges.HEAD_TWO_HEAD]: {
    title: 'Head two head',
    icon: <img src="/images/badge/1vs1.png" />,
  },
  [Badges.TOKEN_ICON]: {
    title: 'Token Icon',
    icon: <img src="/images/badge/token.png" />,
  },
  [Badges.SIT_AND_GO]: {
    title: 'SitAndGo',
    icon: <img src="/images/badge/sit-and-go.png" />,
  },
  [Badges.SCHEDULED]: {
    title: 'Scheduled',
    icon: <img src="/images/badge/tournament.png" />,
  },
  [Badges.CASH_TABLE]: {
    title: 'Cash Table',
    icon: <img src="/images/badge/cash-table.png" />,
  },
};



export const bottom = 50;
export const bottomInGame = 20;
export const zIndex = 800;
