const { 
    ItalianPoker, italianPokerReducer, italianPokerSaga,
    ItalianPokerCash, italianPokerCashReducer, italianPokerCashSaga,
    ItalianPokerSound, ItalianPokerTutorial,
} =
    process.env.REACT_APP_NAMESPACE_ITALIANPOKER ? require(process.env.REACT_APP_NAMESPACE_ITALIANPOKER)
        : require('@quanticogames/gameclient-italianpoker');

export { 
    ItalianPoker, italianPokerReducer, italianPokerSaga,
    ItalianPokerCash, italianPokerCashReducer, italianPokerCashSaga,
    ItalianPokerSound, ItalianPokerTutorial,
};
