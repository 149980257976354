import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { SxProps, TableCell, TableRow, styled } from '@mui/material';
import { CashTable, Game, GameCode, GameHelper, User, cashTableActions, selectActiveGame } from '@quanticogames/gameclient-core';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import ButtonComponent from 'shared/components/button';
import StarIconActive from 'assets/icons/StarIconActive';
import StarIcon from 'assets/icons/StarIcon';
import { BADGES_COLLECTION } from 'constants/badges';

type CashGameTableItemProps = {
  data?: (CashTable & { cost: number }) | null;
  onSelect?: (...agrs: any[]) => void | any;
};

type CELL = {
  name: string;
  render?: (data: CashTable & { cost: number }, cell: string, t?: TFunction) => string | JSX.Element;
  sx?: SxProps;
};

export const CASH_GAME_TABLE_HEAD_DATA  = [
  {
    id: 'favourite',
    label: '',
    sortable: false,
  },
  {
    id: 'description',
    label: 'Description',
    sortable: false,
    filter: true
  },
  {
    id: 'invitation',
    label: 'Invitation / Cost',
    sortable: true,
    filter: false
  },
  {
    id: 'badge',
    label: 'Badge',
    sortable: false,
    filter: false
  },
  {
    id: 'players',
    label: 'Players',
    sortable: true,
    filter: false
  },
  {
    id: 'actions',
    label: 'Actions',
    sortable: false,
    filter: false,
    align: 'center',
  },
];


export const CashGameTableItem: React.FC<CashGameTableItemProps> = ({ data, onSelect }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const game: Game = useSelector(selectActiveGame);
  const isMobile = GameHelper.isMobile();
  const [favoriteList, setFavoriteList] = useState<number[]>(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    return storedFavorites ? JSON.parse(storedFavorites) : [];
  });

  useEffect(() => {
    const storedFavorites = localStorage.getItem('favoriteList');
    if (storedFavorites) {
      setFavoriteList(JSON.parse(storedFavorites));
    }
  }, []);

  const handleActionFavourite = (id: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    const isFavorited = favoriteList.includes(id);
    if (isFavorited) {
      const updatedFavorites = favoriteList.filter((itemId) => itemId !== id);
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    } else {
      const updatedFavorites = [...favoriteList, id];
      setFavoriteList(updatedFavorites);
      localStorage.setItem('favoriteList', JSON.stringify(updatedFavorites));
    }
  };

  const renderFavoriteIcon = (id: number) => {
    const isFavorited = favoriteList.includes(id);
    return isFavorited ? <StarIconActive /> : <StarIcon />;
  };

  const CELLS: CELL[] = [
    {
      name: 'favourite',
      render: (data, cell) => {
        return (
          <div className='tournament-favourite' onClick={(e: any) => handleActionFavourite(data.id, e)}>
            {renderFavoriteIcon(data.id)}
          </div>
        );
      },
    },
    {
      name: 'description',
      render: (data, _) => <div className='tournament-des-title'>{data.title} - Cash</div>,
    },
    {
      name: 'invitation',
      render: (data, _) => (
        <span>{`€ ${formatMoney(convertCentToEuro(game.code === GameCode.SetteEMezzo ? data.cost : data.invitation))}`}</span>
      ),
    },
    {
      name: 'badge',
      render: (data, cell) => {
        return (
          <div className='badge-list'>
            {BADGES_COLLECTION['Cash_Table']?.icon}
          </div>
        );
      },
    },
    {
      name: 'players',
      render: (data, _, t): JSX.Element => (<>{`${data.activeCount}/${data.numberOfSeats} ${t(isMobile ? 'players' : '')}`}</>),
    },
  ];

  const handleOpenTable = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    if (!game) return;
    dispatch(cashTableActions.openTable({
      id,
      gameCode: game.code,
    }));
  }, [game]);

  return (
    <TableRow onClick={onSelect}>
      {CELLS.map((cell) => (
        <TableCell key={cell.name} sx={cell.sx}>
          {cell.render ? cell.render(data, cell.name, t) : data[cell.name]}
        </TableCell>
      ))}
      <TableCell size="small" sx={{ width: 138 }}>
        <div className="flex items-center">
          <ButtonComponent
            onClick={(e) => handleOpenTable(e, data.id)}
            variant="outlined"
            title={t('Play')}
            className='lobby-button'
            rouned='large'
            size='large'
            fullWidth
          />
        </div>
      </TableCell>
    </TableRow>
  );
};
