import { IconButton, InputAdornment, styled, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { SearchIconSvg } from '../../assets/icons/SearchIconSvg';
import { useTranslation } from 'react-i18next';
import { getCasinoCodeFromJWT, selectCasino, selectFilterOptions, tournamentActions, TournamentFilter } from '@quanticogames/gameclient-core';
import { useDispatch, useSelector } from 'react-redux';
import { SKIN } from 'constants/skin';
import ClearIcon from '@mui/icons-material/Clear';

type SearchInputType = {
  placeholder?: string;
};

export const SearchInput: React.FC<SearchInputType> = ({
  placeholder = 'Search',
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const filterOptions: any = useSelector(selectFilterOptions) as TournamentFilter;
  const [value, setValue] = useState<string>(filterOptions?.code || '');

  const Search = useMemo(() => {
    return styled(TextField)`
    .MuiInputBase-root {
      &:hover,
      &:focus {
      fieldset {
        border: 1px solid rgba(255, 255, 255, 0.22);
      }
    }
      &.Mui-focused {
        fieldset {
          border: 1px solid rgba(255, 255, 255, 0.22);
        }
      }
    }
  
    svg {
      color: #fff !important;
    }

    .MuiInputBase-input {
      color: ${SKIN[skinType]?.textSearch ? SKIN[skinType]?.textSearch : COLORS.white};
    }

    .uiInputBase-input {
      color: ${SKIN[skinType]?.textSearch ? SKIN[skinType]?.textSearch : COLORS.white};
    }

    fieldset {
      border: 1px solid ${SKIN[skinType].borderButtonSearch ? SKIN[skinType].borderButtonSearch : COLORS.shadow_white};
      border-radius: 50px;
      z-index: -1;
      background-color: ${SKIN[skinType].backgroundButtonSearch ? SKIN[skinType].backgroundButtonSearch : ''}
    }
  `;
  }, [skinType]);

  const filterInput = (e) => {
    const value = e.target.value;
    setValue(value ? value : '');
    const updatedFilterOptions = {
      code: value,
    } as TournamentFilter;
    dispatch(
      tournamentActions.updateFilters(updatedFilterOptions)
    )
  }

  useEffect(() => {
    setValue(filterOptions?.code ? filterOptions?.code : '')
  }, [filterOptions?.code])

  return (
    <Search
      type="search"
      variant="outlined"
      placeholder={t(placeholder)}
      value={value}
      className='search-form z-10'
      id="search-form"
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {skinType && (
              <SearchIconSvg color={SKIN[skinType].colorIconSearch} />
            )}
            {value !== '' && (
              <IconButton aria-label="clear" className="search-clear-icon" onClick={filterInput}>
                <ClearIcon />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      onChange={filterInput}
    />
  )
};
