import { useTranslation } from "react-i18next";
import { OngoingMatches } from "shared/components/match-switcher/component/match-switcher-table";
import { SideDrawer } from "shared/components/side-drawer";

type MatchSwitcherMobileProps = {
  onClose: () => void;
  isOpened: boolean;
}

const MatchSwitcherMobile = ({ onClose, isOpened }: MatchSwitcherMobileProps) => {
  const { t } = useTranslation();
  return (
    <SideDrawer
      showHeader={true}
      open={isOpened}
      key={'left'}
      onClose={onClose}
      titleHeader={t("Notification")}
      anchor="left"
      className="match-switcher-drawer"
      onBack={onClose}
      isBackdropVisible={false}
    >
      <OngoingMatches playCb={onClose} />
    </SideDrawer>
  )
}
export default MatchSwitcherMobile