import { createTheme, PaletteColor } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    white: PaletteColor;
    disabled: PaletteColor;
  }
  interface PaletteOptions {
    white: PaletteColor;
    disabled: PaletteColor;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

const {
  palette: { augmentColor },
} = createTheme({});

export const palette = {
  primary: {
    light: '#fff',
    main: '#0066FF',
    contrastText: '#fff',
  },
  secondary: {
    light: '#fff',
    main: '#8E8E8E',
    contrastText: '#fff',
  },
  success: {
    light: '#fff',
    main: '#00BE2A',
    contrastText: '#fff',
  },
  warning: {
    light: '#F8EEE0',
    main: '#F5A52C',
    contrastText: '#DD911F',
  },
  error: {
    light: '#fff',
    main: '#E20613',
    contrastText: '#fff',
  },
  inherit: {
    light: '#fff',
    main: '#8E8E8E',
    contrastText: '#fff',
  },
  white: augmentColor({
    color: {
      main: '#FFFFFF',
      contrastText: '#FFFFFF',
    },
  }),
  disabled: augmentColor({
    color: {
      main: '#8E8E8E',
      light: '#fff',
      contrastText: '#828B92',
    },
  }),
  signing: augmentColor({
    color: {
      main: '#EAE0F8',
      contrastText: '#8341D8',
    },
  }),
};
