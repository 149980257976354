import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';
import Banner from 'shared/components/banner';
import FooterMenu from 'layouts/footer/component/footer-menu';
import { GameHelper } from '@quanticogames/gameclient-core';
import { useMediaQuery, useTheme } from '@mui/material';


export const Footer = () => {
  const isMobile = GameHelper.isMobile();
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <footer className="footer-container">
      <Banner />
      {(!isMobile && !isMobileScreenWidth) && <FooterMenu />}
    </footer>
  );
};
