import ContentTabs from "shared/Regulation/components/ContentTabs";

export const Scala40IlMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Il mazzo di carte </h2>
            <h2 className="titolo2">Il mazzo di carte </h2>
            <p className="paragraph">Si gioca con due mazzi di carte francesi inclusi i jolly per un totale di 108 carte.</p>
            <p className="paragraph">I giocatori possono selezionare il tipo di carte (francesi standard, francesi Dal Negro).</p>
            <h2 className="titolo2">Valore delle carte</h2>
            <p className="paragraph">Al fine del calcolo dei punti necessari per l’apertura, le carte avranno il seguente valore:</p>
            <p className="paragraph">
                L’Asso vale un punto se messo, all'interno di una 'scala' o 'sequenza', prima del Due, mentre vale 11 punti se messo in tris o in una 'scala' dopo il Re.
                <br />
                Ogni figura vale 10 punti;
            </p>
            <p className="paragraph">Ogni jolly prende il valore della carta che sostituisce;</p>
            <p className="paragraph">Tutte le altre carte hanno il loro valore nominale.</p>
        </ContentTabs>
    );
};

export const Scala40SvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Svolgimento del gioco </h2>
            <h2 className="titolo2">Vengono dette:</h2>
            <p className="paragraph"><strong>Giocata</strong>: la carta mostrata scoperta sul tavolo da un giocatore;</p>
            <p className="paragraph"><strong>Smazzata</strong>: la fase di gioco che inizia con la distribuzione delle carte e si finisce con il conteggio dei punti;</p>
            <p className="paragraph"><strong>Partita</strong>: la fase di gioco composta da una o più <em>smazzate.</em></p>
            <h2 className="titolo2">Designazione del mazziere</h2>
            <p className="paragraph">
                Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. Quando il gioco ha inizio, il mazziere viene stabilito
                casualmente dal sistema.
            </p>
            <p className="paragraph">
                Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra stessi giocatori, il mazziere gira in senso orario e cioè il prossimo
                mazziere sarà quello seduto alla sinistra del mazziere precedente.
            </p>
            <h2 className="titolo2">Distribuzione delle carte</h2>
            <p className="paragraph">Il sistema mescola le carte e:</p>
            <p className="paragraph">
                A seguito del 'taglio', effettuato dal giocatore alla destra del mazziere, vengono distribuite 13 carte, alternandole una per volta ai giocatori in
                senso orario, ponendone infine una scoperta sul tavolo (monte scarti iniziale) e ponendo le restanti (non distribuite) al centro del tavolo (detto
                tallone).
            </p>
            <h2 className="titolo2">Scelte del giocatore di mano</h2>
            <p className="paragraph">Il giocatore seduto alla sinistra del mazziere è il <em>giocatore di mano</em>, cioè il primo a dover effettuare la <em>giocata.</em></p>
            <p className="paragraph">Dopo che ogni giocatore ha effettuato la giocata, la mano passa al giocatore successivo in senso orario.</p>
            <p className="paragraph"><strong>Ogni giocatore di mano deve per prima cosa decidere se:</strong></p>
            <ul itemType="disc" className="paragraph">
                <li><strong>Pescare</strong>, cioè prelevare la prima carta del tallone, o</li>
                <li><strong>Raccogliere</strong>, se ha già aperto, può raccogliere l'ultima carta scartata</li>
            </ul>
            <p className="paragraph">Il giocatore può raccogliere lo scarto anche ove non abbia ancora aperto il gioco ma a due condizioni:</p>
            <p className="paragraph">1. che apra nello stesso turno in cui ha raccolto la carta scartata</p>
            <p className="paragraph">2. che utilizzi la carta raccolta dagli scarti all’interno dei giochi calati con l’apertura</p>
            <p className="paragraph">
                In questo caso si considera completata l’apertura ( con tutti i benefici che ne conseguono) solo quando la carta raccolta viene utilizzata in uno dei
                giochi di apertura.
            </p>
            <p className="paragraph"><strong>Dopo aver pescato o raccolto il giocatore può:</strong></p>
            <p className="paragraph"><strong>Aprire</strong></p>
            <p className="paragraph">Per poter iniziare a aprire dei giochi è necessario che il valore delle carte 'calate' o giocate sia eguale o maggiore a 40 punti.</p>
            <p className="paragraph">Ad esempio posso iniziare a calare carte giocando un tris di 9 (9 x 3=27) e una scala dal 10 alla</p>
            <p className="paragraph">Donna (10x3=30) avendo raggiunto e superato i 40 punti richiesti (27+30=57).</p>
            <p className="paragraph">
                Non posso invece ad esempio iniziare a calare carte giocando un tris di 4 (4x3=12) e una scala dal 3 al 7 (3+4+5+6+7=25) avendo raggiunto in questo
                modo solo 37 punti in totale.
            </p>
            <p className="paragraph">Il jolly all'interno di una scala o combinazione prende il valore della carta che sostituisce.</p>
            <p className="paragraph">In caso un giocatore utilizzi un jolly all’interno di una combinazione, deve dichiarare il valore della</p>
            <p className="paragraph">carta che sostituisce.</p>
            <p className="paragraph">Per terminare il suo turno e passare la mano al giocatore alla sua sinistra deve scartare una carta.</p>
            <p className="paragraph"><em> </em><em></em></p>
            <p className="paragraph"><strong>Calare altri giochi e attaccare a giochi già esistenti</strong></p>
            <p className="paragraph">Se ha già 'aperto' un giocatore può :</p>
            <p className="paragraph">
                Mettere sul tavolo di gioco altre 'scale' o 'sequenze' (tre o più carte ordinate obbligatoriamente dello stesso seme) ovvero può calare 'combinazioni'
                (tre o quattro carte uguali, ma di seme diverso), oppure attaccare carte a giochi già esistenti.
            </p>
            <p className="paragraph">Per terminare il suo turno e passare la mano al giocatore alla sua sinistra deve scartare una carta.</p>
            <p className="paragraph"><strong>Scartare</strong></p>
            <p className="paragraph">Se un giocatore non può aprire, scarterà solamente una carta e passerà il turno al giocatore successivo.</p>
            <h2 className="titolo2">Regole per aprire nuovi giochi</h2>
            <p className="paragraph">Nel gioco della scala 40 i giochi sono costituiti dalle scale o sequenze e dalle combinazioni.</p>
            <p className="paragraph">
                Le <strong>scale</strong>/<strong>sequenze </strong>possono essere formate da un minimo di 3 carte ad un massimo di 13 carte dello stesso seme, messe
                in ordine, più l'eventuale jolly (per un totale massimo di 14 carte):
            </p>
            <p className="paragraph">A-2-3-4-5-6-7-8-9-10-J-Q-K + Jolly</p>
            <p className="paragraph">E' possibile calare una sequenza a scala uguale ad una già calata precedentemente oppure decidere di attaccare le carte alla stessa.</p>
            <p className="paragraph">
                Le <strong>combinazioni </strong>che si possono 'calare' sono almeno tre carte dello stesso valore e devono avere semi differenti (non si può quindi
                calare ad esempio un tris di 9 composto da un 9 di cuori e due 9 di picche).
            </p>
            <p className="paragraph">Le 'combinazioni' possono essere formate da un minimo di 3 carte ad un massimo di 4, compreso l'eventuale jolly.</p>
            <p className="paragraph">Non sono ammesse combinazioni di 'jolly'.</p>
            <p className="paragraph">In ciascun gioco non si può utilizzare più di un 'jolly'.</p>
            <h2 className="titolo2">Regole per allungare i giochi esistenti</h2>
            <p className="paragraph">
                Si possono aggiungere delle carte ai giochi della propria Linea ("allungare un gioco") se il gioco risultante rispetta le regole previste per
                l'apertura di nuovi giochi tenendo presente che, nelle sequenze, i jolly possono essere sostituiti solo dalla carta della quale fanno le veci. Si possono (non
                necessariamente si deve) anche 'attaccare' delle carte a scale o combinazioni presenti sul tavolo, sia proprie che di altri giocatori.
            </p>
            <p className="paragraph">I giochi aperti dalla propria linea sono visualizzati nella parte bassa del tavolo, quelli aperti dalla</p>
            <p className="paragraph">linea avversaria nella parte alta del tavolo.</p>
            <h2 className="titolo2">Sostituzione del jolly</h2>
            <p className="paragraph">
                Un giocatore, solo ove abbia completato l’apertura (con eventuale carta raccolta già calata), può sostituire un jolly presente in una combinazione o
                scala sul tavolo ad una carta che ha in mano, in questo caso però il regolamento della scala 40 prescrive che si debba necessariamente utilizzare poi
                il jolly sostituito in una combinazione o sequenza ovvero attaccarlo ad altre sequenze o combinazioni sul tavolo di gioco prima di scartare.
            </p>
            <h2 className="titolo2">Scarti</h2>
            <p className="paragraph">L'ultima carta scartata può essere raccolta solo dal giocatore che abbia già aperto il gioco.</p>
            <p className="paragraph">
                Qualora si raccolga l'ultima carta scartata nel pozzo essa deve essere necessariamente utilizzata in una combinazione o sequenza dal giocatore che l'ha
                raccolta od in alternativa attaccata ad altre sequenze o combinazioni presenti sul tavolo di gioco.
            </p>
            <p className="paragraph">
                Non si può scartare una carta che 'attacca' in una combinazione o sequenza presente sul tavolo di gioco a meno che non si abbia già 'aperto' o che si
                'chiuda' con lo scarto.
            </p>
            <p className="paragraph">
                Inoltre se si raccoglie una carta scartata, non è possibile scartare quella stessa carta, ma deve essere scartata una delle carte che già si possiede.
            </p>
            <h2 className="titolo2">Chiusura della smazzata</h2>
            <p className="paragraph">
                Per chiudere la smazzata bisogna esaurire le proprie carte in mano . Non è consentito rimanere senza carte in mano senza aver effettuato lo scarto (se
                ciò dovesse capitare, il giocatore deve riprendere in mano le ultime scale o combinazioni calate e ripetere le mosse in modo che non rimanga senza
                carte).
            </p>
            <p className="paragraph">Non è possibile chiudere al primo giro.</p>
            <p className="paragraph">Ogni giocatore presente al tavolo deve aver compiuto almeno una mano di gioco.</p>
            <p className="paragraph">
                Inoltre per chiudere bisogna sempre scartare una carta e questa carta non deve essere un jolly. Quando un giocatore 'chiude' si passa alla fase
                successiva del calcolo del punteggio.
            </p>
            <h2 className="titolo2">Impossibilità a creare nuovi giochi o allungare giochi esistenti</h2>
            <p className="paragraph">
                Qualora a terra si formino dei giochi che non è più possibile allungare ed entrambi i giocatori non hanno la possibilità di formare un nuovo gioco, si crea una situazione di stallo.
                In questo caso la smazzata in corso verrà annullata,il sistema ridistribuirà le carte  ed avrà inizio una nuova smazzata.
            </p>
            <h2 className="titolo2">Esaurimento delle carte del tallone</h2>
            <p className="paragraph">
                Se nessuno riesce a chiudere prima che finiscano le carte del 'tallone', il mazziere ha il compito, finito il turno di gioco del giocatore che ha
                pescato l'ultima carta, di prendere il pozzo (lasciando
            </p>
            <p className="paragraph">
                da parte l'ultima carta scartata che costituirà il nuovo pozzo), mischiarlo, farlo alzare dal giocatore alla sua sinistra e posizionare cosi il nuovo
                tallone al centro del tavolo.
            </p>
            <h2 className="titolo2">Punteggi</h2>
            <p className="paragraph">
                Dopo la 'chiusura' da parte di un qualsiasi giocatore al tavolo, i giocatori mostrano la loro mano e si procede al calcolo del punteggio delle carte
                rimaste ad ogni giocatore o alla linea perdente, secondo i seguenti valori:
                <br />
                ogni Jolly vale 25 punti;
                <br />
                ogni Asso vale 11 punti;in caso il giocatore abbiamo un solo Asso in mano, esso vale 1 punto;
                <br />
                ogni figura vale 10 punti;
                <br />
                tutte le altre carte hanno il loro valore nominale;
                <br />
                Il giocatore o la linea che ha chiuso la partita avrà come punteggio 0 (relativamente alla singola partita) poiché non ha alcuna carta in mano.
                <br />
                Tutti gli altri giocatori avranno un punteggio positivo dato dalla somma dei valori delle carte rimaste in mano al momento della chiusura.
            </p>
            <p className="paragraph">
                Tuttavia, al giocatore che rimane con tutte le carte in mano al momento della chiusura dell'avversario vengono assegnati 100 punti indipendentemente dalla
                somma delle carte possedute.
            </p>
            <p className="paragraph">Quando un giocatore chiude calando tutte le carte contemporaneamente e scartando l'ultima si</p>
            <p className="paragraph">definisce questa azione <strong>'chiusura in mano</strong>'.</p>
            <p className="paragraph">
                In questo caso il punteggio delle carte in mano agli avversari si conta doppio mentre se un giocatore rimane con tutte le carte in mano al momento della
                chiusura dell'avversario vengono assegnati 100 punti indipendentemente dalla somma delle carte possedute.
            </p>
            <h2 className="titolo2">Conclusione della partita</h2>
            <p className="paragraph"><strong>Partite a smazzate</strong></p>
            <p className="paragraph">Si stabilisce il numero di smazzate di cui è composta la partita.</p>
            <p className="paragraph">Vince la partita chi vince il maggior numero di smazzate,generalmente 2 su 3</p>
            <p className="paragraph"><strong>Partite a punti</strong></p>
            <p className="paragraph">
                Si stabilisce il totale dei punti che un giocatore o una coppia deve raggiungere per uscire dal gioco. Di norma il punteggio da raggiungere per uscire dal
                gioco è pari o superiore a 101 o 151 punti. Quando un giocatore supera o raggiunge i punti stabiliti viene eliminato e perde la partita
            </p>
        </ContentTabs>
    );
};

export const Scala40ComeSiGioca = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Come si gioca</h2>
            <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
            <p className="paragraph">Il giocatore di mano :</p>
            <p className="paragraph"><strong>per pescare </strong>deve cliccare sul tallone (la carta coperta a centro tavolo);</p>
            <p className="paragraph"><strong>per raccogliere </strong>deve cliccare sullo scarto o, in assenza di scarti, sulla carta semitrasparente posta accanto al tallone;</p>
            <p className="paragraph">
                <strong>per aprire un nuovo gioco </strong>
                deve cliccare sulle carte che desidera appoggiare (le carte si alzano leggermente) e poi deve cliccare sulla carta semitrasparente inizialmente a sinistra
            </p>
            <p className="paragraph"><strong>per allungare un gioco esistente </strong>deve cliccare sulle carte che desidera legare e poi deve cliccare su</p>
            <p className="paragraph">quel gioco;</p>
            <p className="paragraph"><strong>per scartare </strong>deve cliccare sulla carta e poi deve cliccare sullo scarto o, in assenza di scarti, sulla carta semitrasparente posta accanto al tallone.</p>
            <p className="paragraph">
                <strong>per scegliere il valore del jolly </strong>
                viene visualizzato un pannello che riporta i semi o il valore delle carte. Si clicca sul seme o valore desiderato e poi si clicca sul tasto “conferma” per
                comunicare la scelta.
            </p>
            <p className="paragraph">Inoltre il giocatore, in qualsiasi momento della partita:</p>
            <p className="paragraph">
                per ordinare le carte che ha in mano può cliccare su uno dei due tasti in basso a destra, il primo tasto ordina le carte per seme, il secondo tasto
                ordinerà le carte per valore con i jolly a sinistra; per ordinare le carte a suo piacimento deve cliccare su una carta e, tenendola premuta, la deve
                trascinare nella posizione desiderata.
            </p>

            <p className="paragraph"><strong></strong></p>
            <p className="paragraph"><strong>Conversione dei punti in Match Point (MP)</strong></p>
            <p className="paragraph"><strong>Partite a punti</strong></p>
            <p className="paragraph">Ai fini delle classifiche, i punti totalizzati da ogni giocatore vengono attribuiti all’avversario sotto forma di Match Point</p>
            <p className="paragraph">Es,</p>
            <p className="paragraph">Giocatore A chiude la smazzata</p>
            <p className="paragraph">Giocatore B paga 70 punti</p>
            <p className="paragraph">Il punteggio di 0 a 70 viene convertito a fine partita in MP Giocatore A + 70, Giocatore B – 70</p>
        </ContentTabs>
    );
};

export const Scala40Penalita = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Penalità</h2>
            <h2 className="titolo2">Penalità in caso di tempo scaduto o abbandono </h2>
            <p className="paragraph">
                Se un giocatore fa scadere il tempo massimo o il timeout o clicca sul tasto “abbandona” la partita ha termine e il giocatore
                <strong>perde la partita</strong> e la differenza punti è cosi stabilita:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>Si contano le carte in mano del giocatore perdente,</li>
                <li>si stabilisce una “penalità” pari a 50 punti,</li>
                <li>si fanno i conti con punteggio base + penalità,</li>
                <li>
                    se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il timeout, la differenza punti è uguale a zero (ma la partita è comunque persa da
                    chi ha fatto scadere il tempo).
                </li>
            </ul>
            <p className="paragraph"><strong></strong></p>
            <p className="paragraph">
                <strong>Calcolo dei VP e MP in caso di Timeout o abbandono</strong>
            </p>
            <p className="paragraph">
                Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o abbandona farà 0 VP e la linea avversaria farà minimo 14 VP. I MP di entrambe le
                linee saranno calcolati dai VP in base ai corrispondenti minimi previsti dalla tabella dei VP.
            </p>
            <p className="paragraph"><em>Si sottolinea che quest'ultima regola sul timeout e abbandono è valida solo per determinare i MP e i VP per le classifiche dei tornei a girone e i livelli di abilità in caso di timeout e abbandono , ma non per determinare la differenza punti nei tavoli cash che vengono sempre determinati come descritto nei <a href="generali.php?skin=SNAI&amp;mobile=&amp;argomento=cashgenerico&amp;capitolo=tavoliturni">regolamenti generali</a> anche in caso di timeout e abbandono.</em> </p>
        </ContentTabs>
    );
};

export const Scala40RegoleOpzionali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Regole opzionali </h2>
            <h2 className="titolo2">Partite a coppie a carte scoperte</h2>
            <p className="paragraph">Saranno proposte partite a coppie nelle quali i compagni, senza essere visti dagli avversari, giocano a carte scoperte.</p>
        </ContentTabs>
    );
};

export const Scala40DifferenzaPuntiNeiCash = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Differenza punti nei tavoli cash</h2>
            <h2 className="titolo2">Differenza punti nei tavoli cash </h2><p className="paragraph">
                Nella Scala 40, ai fini del calcolo dell’importo vinto o perso, la differenza punti è stabilita dividendo per 10 I MP del vincitore e poi arrotondando il
                risultato ( se &gt; 0,50 all’unità superiore, se &lt;=0,50 all’unità inferiore).
            </p>
            <p className="paragraph">Esempio:</p>
            <p className="paragraph">Gioc A che vince, ottiene 27 MP</p>
            <p className="paragraph">Differenza punti = 27 / 10 = 2,7 =&gt; 3 punti</p>
            <p className="paragraph">Se la differenza punti effettiva fosse stata di 2,5 punti, l’arrotondamento avrebbe portato la differenza punti = 2.</p>
        </ContentTabs>
    );
};

export const Scala40TabellaDeiVictoryPoints = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole della Scala 40 - Tabella dei Victory Points</h2>
            <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
            <table className="bordi" border={1} cellPadding="5" cellSpacing="5" width="299">
                <tbody>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph"><strong>RISULTATO</strong> </p></td>
                        <td className="bordi" width="37"><p className="paragraph"><strong>VP</strong> </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Vittoria </p></td>
                        <td className="bordi" width="37"><p className="paragraph">20 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Pareggio (ove previsto) </p></td>
                        <td className="bordi" width="37"><p className="paragraph">10 </p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="23"><p className="paragraph">Sconfitta </p></td>
                        <td className="bordi" width="37"><p className="paragraph">0 </p></td>
                    </tr>
                </tbody>
            </table>
        </ContentTabs>
    );
};
