export const getSpeedLabel = (speed: number) => {
    switch (speed) {
        case 0:
            return 'Slow';
        case 1:
            return 'Normal';
        case 2:
            return 'Turbo';
        case 3:
            return 'Super Turbo';
        default:
            return '';
    }
};

export const getWinObject = (obj: string, objValue: number, t: Function) => {
    switch (obj) {
        case 'm':
            return `${objValue} ${t(objValue > 1 ? 'Matches' : 'Match')}`;
        case 'p':
            return `${objValue} ${t('Points')}`;
        case 'b':
            return `${t('Best Of')} ${objValue}`;
        case 'r':
            return `${objValue} ${t('Points')} ${t('To lose')}`
        default:
            return '';
    }
};

export const getGameType = (type: string, t: Function, isSlider: boolean = false) => {
    switch (type) {
        case 'Normal':
            return isSlider ? 'Sit & Go' : '';
        case 'Crazy_Prize':
            return `${t('Colpo Grosso')}`;
        case 'Treble':
            return `${t('Filotto')} `;
        case 'Tornei':
            return `${t('Best Of')} `;
        case 'Scheduled':
            return t('Tournaments');
        default:
            return '';
    }
};
