import React from 'react';
import { Avatar, Box, MenuItem, Select, Tab, TableCell, TableRow, Tabs, styled, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CashTable, GameHelper, selectCashTable, Sound, SoundManager } from '@quanticogames/gameclient-core';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { EmptyData } from 'pages/tournament/component/detail/paticipant-empty';
import TableComponent from 'shared/components/tables';
import { COLORS } from 'constants/colors';

const HEAD_DATA_PARTICIPANTS_IP = [
  {
    id: 'position',
    label: 'Pos.',
  },
  {
    id: 'user',
    label: 'User',
  },
  {
    id: 'stake',
    label: 'Stake',
  },
];

export const TabStyled = styled(Tab)`
  font-weight: 400;
  font-size: 16px;
  font-family: Inter, sans-serif;
  line-height: 15px;
  letter-spacing: 0.01em;
  background-color: #E5E5E5;
  color: #ABABAB;
  padding: 0 15px;
  height: 30px;
  margin-right: 5px;
  border-radius: 30px;
  display: flex;
  min-height: 30px;
  &.Mui-selected {
    background-color: #06F;
    color: ${COLORS.white};
  }
`;

export const PlayerTab: React.FC = () => {
  const { t } = useTranslation();
  const table: CashTable = useSelector(selectCashTable);
  const [activeTab, setActiveTab] = React.useState(0);
  const isMobile = GameHelper.isMobile();
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));

  const tabLabels = [
    {
      label: t('Participants'),
      value: 0,
    },
  ];

  function a11yProps(index: number) {
    return {
      id: `tournament-info-tab-${index}`,
      'aria-controls': `tournament-info-tabpanel-${index}`,
      className: "tournament-info-tabpanel"
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    SoundManager.play(Sound.clickButton);
    setActiveTab(newValue);
  };

  return (
    <div className='tournament-info-table'>
      {isMobileScreenWidth || isMobile ? (
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Game details tabs"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          variant="scrollable"
          sx={{
            padding: '0 0 5px 0',
            minHeight: '30px',
            '.MuiTab-root:first-of-type': {
              marginLeft: '16px',
            },
            '.MuiTab-root:last-of-type': {
              marginRight: '16px',
            },
          }}
        >
          <TabStyled className="tournament-info-table-tabs" label={t('Participants')} {...a11yProps(0)} />
        </Tabs>
      ) : (
        <div className="tournament-info-select">
          <label>{t('View by')}</label>
          <Select
            value={activeTab}
            onChange={(e) => {
              setActiveTab(Number(e.target.value));
              SoundManager.play(Sound.clickButton);
            }}
            aria-label="Game details tabs"
            variant="outlined"
            className="select-component"
          >
            {tabLabels.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      {table?.participants?.length ? (
        <>
          <TableComponent columns={HEAD_DATA_PARTICIPANTS_IP}>
            {table?.participants?.map((player, idx) => (
              <TableRow key={`participant-table-item-${player?.id}`}>
                <TableCell width="50" style={{ textAlign: 'center' }}>
                  {idx + 1}
                </TableCell>
                <TableCell width="50%">
                  <Box sx={{ display: 'flex' }}>
                    <Avatar
                      sx={{
                        borderColor: 'transparent',
                        marginRight: '8px',
                        width: '20px',
                        height: '20px',
                      }}
                      src={`/images/avatar/${player.avatar}`}
                      key={player.id}
                    />
                    {player?.userName}
                  </Box>
                </TableCell>
                <TableCell>€ {formatMoney(convertCentToEuro(player?.finalStake || 0))}</TableCell>
              </TableRow>
            ))}
          </TableComponent>
        </>
      ) : (
        <Box sx={{ height: '340px' }}>
          <EmptyData />
        </Box>
      )}
    </div>
  );
};
