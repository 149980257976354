const PointerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <rect width="30" height="30" fill="url(#pattern0_3203_11998)"/>
    <defs>
      <pattern id="pattern0_3203_11998" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_3203_11998" transform="scale(0.0333333)"/>
      </pattern>
      <image id="image0_3203_11998" width="30" height="30" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHUSURBVHgB5ZZLKERRGMf/HmVB8k4KS0UUGxsbUWLDwsaOHQtlZaOUJRsp2VCSV0lZWthIKImRPEY2slEeYUHymPE/fWdyO4259869JvKrX3PPnXPOd+95fOcCf5QUWoIE00jvaJge0QIkiHMaoE36egkJIB3ypm26PEIP4YJkxEek3bvx67qDhPP/AqfCHQ20mz7BI04C19BKmkSn6S582LN2gdvpoqXeJa2lZfQUHog2x4P0nt7SSTpLs+k2vYHs3yA8YgZuoUN0gi7TLLpPH+gxfMQc6ipIBhqAHACdMdpuwcObm4HVCLzp6w/LNfQDpVjKdfCAGThk3JujJ/p6HD5iBn6lOZZyD34IM/AjLdL3XSd+TZruo1SrpmyTXsQKrFavmsd8egV3FNMDSLKx7paw7ldlvUDkprmdgpZO3LADyWirkOlppeU0D5LlzuhwrA4yIQusC/4yiq9F+i0bdAr+oPJ7H2Sox+wqq88Yt/NrkkH7IQtKzfECzbVr1Kwrl8E9KmAvJNerrbkGOVQcoYbnGjaLIQoVdA/y0PO0OlblaMeiarhCOyCnkRMKIR8IL7SeriNO1OH/rB/CiSrZzEBOM0ck2fyvkomZDKy/IaP8+/kE2TZkwTAWc0IAAAAASUVORK5CYII="/>
      </defs>
    </svg>
);

export default PointerIcon;
