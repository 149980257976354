import { Tournament } from "@quanticogames/gameclient-core";

export const getHighlightClass = (highlight: number) => {
    switch (highlight) {
        case 0:
            return '';
        case 1:
            return 'ribbon-bronze';
        case 2:
            return 'ribbon-silver';
        case 3:
            return 'ribbon-gold';
        default:
            return '';
    }
};

