import { Paper } from "@mui/material";
import ContentTabs from "shared/Regulation/components/ContentTabs";
import { ClassificheItem } from "shared/Regulation/classifiche/ClassificheItem";
import TableComponent from "shared/components/tables";
import TableNoData from "shared/components/tables/component/table-nodata";
import { CLASSIFICHE_HEAD_DATA, ClassificheInterface } from "types/classifiche-type";
import Loading from 'shared/components/loading';
import './index.scss';
import { useTranslation } from "react-i18next";

type LeaderBoardContainerProps = {
  loading: boolean;
  classificheList: ClassificheInterface[];
  gameCode: string;
  timeType: string
}

const LeaderBoardContainer = ({ loading, classificheList, gameCode, timeType }: LeaderBoardContainerProps) => {
  const { t } = useTranslation();
  return (
    <ContentTabs>
      <h2 className="titolomenu !border-none !min-h-[auto]">{t(timeType)} {gameCode} {t('ranking')}</h2>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableComponent
          columns={CLASSIFICHE_HEAD_DATA}
          className='classifiche-table-container'
        >
          {loading ? <tr className="relative !bg-transparent">
            <td colSpan={CLASSIFICHE_HEAD_DATA.length} style={{ border: 'none', height: 300 }}><Loading className="loading-tournament absolute w-full min-h-[300px]" /></td>
          </tr> : <>
            {classificheList.length > 0 ? classificheList.map((item, index) => (
              <ClassificheItem
                key={`cassifiche-${index}`}
                data={item}
              />
            )) : <tr>
              <td colSpan={CLASSIFICHE_HEAD_DATA.length} style={{ border: 'none' }}>
                <TableNoData />
              </td>
            </tr>}
          </>}
        </TableComponent>
      </Paper>
    </ContentTabs>
  )
}
export default LeaderBoardContainer
