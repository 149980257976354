const LockIcon = (props : any) => {
    const { fill } = props
    return (
		<svg version="1.1"  x="0px" y="0px"
			width="11" height="13" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" >
			<path fill={fill || '#D2D2D3'} opacity="1.000000" stroke="none" 
				d="
				M433.000000,513.000000 
				C315.979095,513.000000 198.958206,513.000000 81.372658,512.649414 
				C66.447029,506.405853 60.205811,494.674652 60.148914,480.185822 
				C59.836742,400.692108 59.987988,321.196503 60.043232,241.701584 
				C60.045479,238.466995 60.165390,235.072174 61.113132,232.026672 
				C64.944565,219.714737 70.983086,209.398148 85.552223,207.835007 
				C89.584518,207.402359 93.618301,206.983505 98.000000,206.521576 
				C98.000000,192.933716 98.292633,179.298386 97.932281,165.680313 
				C97.374199,144.589935 100.604790,124.248161 107.940849,104.438660 
				C115.573494,83.828285 127.042091,65.695564 141.899658,49.630981 
				C151.611084,39.130589 162.735153,30.331446 175.009155,23.018635 
				C186.632202,16.093655 198.731949,10.215792 211.967499,6.970714 
				C219.984375,5.005141 227.989563,2.991853 236.000000,1.000000 
				C249.687561,1.000000 263.375122,1.000000 277.671875,1.388904 
				C280.508301,2.184747 282.723511,2.680628 284.964783,2.984013 
				C298.298737,4.788947 311.188965,8.497841 322.993958,14.733498 
				C334.766052,20.951763 346.426422,27.767235 356.933594,35.878197 
				C366.111328,42.962879 374.378815,51.600616 381.669067,60.661976 
				C390.829956,72.048325 398.242371,84.752243 403.813049,98.410736 
				C410.760345,115.444504 415.300568,133.087509 415.885071,151.471756 
				C416.424896,168.450729 415.994873,185.460373 416.009216,202.456482 
				C416.010406,203.898087 416.141663,205.339584 416.190216,206.328094 
				C422.079224,207.093735 427.710663,207.241196 433.015717,208.632904 
				C445.450287,211.894974 453.834961,226.079666 453.876312,239.475525 
				C454.125305,320.123077 454.107544,400.772247 453.774017,481.419159 
				C453.750092,487.202789 450.827240,493.098328 448.669800,498.718231 
				C445.890411,505.958160 439.201385,509.300964 433.000000,513.000000 
				M181.539780,107.968773 
				C178.364746,113.362457 174.601532,118.502441 172.141907,124.204712 
				C167.571487,134.800522 164.801834,145.899689 164.935867,157.635132 
				C165.122543,173.981567 164.982864,190.331741 164.982864,206.793594 
				C226.193314,206.793594 287.906403,206.793594 350.913361,206.793594 
				C349.940552,186.610962 349.661591,166.624115 347.845886,146.777863 
				C345.618195,122.428001 332.849640,103.183830 314.360748,88.137520 
				C291.476501,69.514252 264.840607,63.634506 236.138351,70.177650 
				C213.437790,75.352615 195.232285,87.983658 181.539780,107.968773 
				M226.000000,399.500000 
				C226.000000,409.490784 225.999283,419.481537 226.000214,429.472321 
				C226.001144,439.321686 231.667038,444.998749 241.497604,444.999756 
				C252.154449,445.000824 262.811279,445.009094 273.468140,444.997040 
				C281.687164,444.987762 287.991608,438.737152 287.995911,430.570343 
				C288.007782,408.091064 287.972046,385.611694 288.056915,363.132721 
				C288.063202,361.464172 288.386047,359.373322 289.397766,358.199310 
				C297.293427,349.037476 301.068542,338.751221 300.093994,326.472748 
				C298.791931,310.067535 290.572693,297.740204 276.216339,291.460938 
				C258.856750,283.868073 235.041351,285.850311 221.608658,304.871246 
				C210.446457,320.677185 210.837128,341.541626 223.280594,356.592377 
				C225.252899,358.977936 226.076538,361.085083 226.046982,364.032227 
				C225.931793,375.520508 226.000000,387.010590 226.000000,399.500000 
				z"/>
			<path fill="none" opacity="1.000000" stroke="none" 
				d="
				M433.468658,513.000000 
				C439.201385,509.300964 445.890411,505.958160 448.669800,498.718231 
				C450.827240,493.098328 453.750092,487.202789 453.774017,481.419159 
				C454.107544,400.772247 454.125305,320.123077 453.876312,239.475525 
				C453.834961,226.079666 445.450287,211.894974 433.015717,208.632904 
				C427.710663,207.241196 422.079224,207.093735 416.190216,206.328094 
				C416.141663,205.339584 416.010406,203.898087 416.009216,202.456482 
				C415.994873,185.460373 416.424896,168.450729 415.885071,151.471756 
				C415.300568,133.087509 410.760345,115.444504 403.813049,98.410736 
				C398.242371,84.752243 390.829956,72.048325 381.669067,60.661976 
				C374.378815,51.600616 366.111328,42.962879 356.933594,35.878197 
				C346.426422,27.767235 334.766052,20.951763 322.993958,14.733498 
				C311.188965,8.497841 298.298737,4.788947 284.964783,2.984013 
				C282.723511,2.680628 280.508301,2.184747 278.140533,1.388904 
				C356.291901,1.000000 434.583771,1.000000 512.937866,1.000000 
				C512.937866,171.666656 512.937866,342.333344 512.937866,513.000000 
				C486.576080,513.000000 460.256683,513.000000 433.468658,513.000000 
				z"/>
			<path fill="none" opacity="1.000000" stroke="none" 
				d="
				M235.531342,1.000000 
				C227.989563,2.991853 219.984375,5.005141 211.967499,6.970714 
				C198.731949,10.215792 186.632202,16.093655 175.009155,23.018635 
				C162.735153,30.331446 151.611084,39.130589 141.899658,49.630981 
				C127.042091,65.695564 115.573494,83.828285 107.940849,104.438660 
				C100.604790,124.248161 97.374199,144.589935 97.932281,165.680313 
				C98.292633,179.298386 98.000000,192.933716 98.000000,206.521576 
				C93.618301,206.983505 89.584518,207.402359 85.552223,207.835007 
				C70.983086,209.398148 64.944565,219.714737 61.113132,232.026672 
				C60.165390,235.072174 60.045479,238.466995 60.043232,241.701584 
				C59.987988,321.196503 59.836742,400.692108 60.148914,480.185822 
				C60.205811,494.674652 66.447029,506.405853 80.903999,512.649414 
				C54.347443,513.000000 27.694889,513.000000 1.021167,513.000000 
				C1.021167,342.437744 1.021167,171.875519 1.021167,1.000000 
				C79.020615,1.000000 157.041656,1.000000 235.531342,1.000000 
				z"/>
			<path fill="none" opacity="1.000000" stroke="none" 
				d="
				M181.753662,107.664734 
				C195.232285,87.983658 213.437790,75.352615 236.138351,70.177650 
				C264.840607,63.634506 291.476501,69.514252 314.360748,88.137520 
				C332.849640,103.183830 345.618195,122.428001 347.845886,146.777863 
				C349.661591,166.624115 349.940552,186.610962 350.913361,206.793594 
				C287.906403,206.793594 226.193314,206.793594 164.982864,206.793594 
				C164.982864,190.331741 165.122543,173.981567 164.935867,157.635132 
				C164.801834,145.899689 167.571487,134.800522 172.141907,124.204712 
				C174.601532,118.502441 178.364746,113.362457 181.753662,107.664734 
				z"/>
			<path fill="none" opacity="1.000000" stroke="none" 
				d="
				M226.000000,399.000000 
				C226.000000,387.010590 225.931793,375.520508 226.046982,364.032227 
				C226.076538,361.085083 225.252899,358.977936 223.280594,356.592377 
				C210.837128,341.541626 210.446457,320.677185 221.608658,304.871246 
				C235.041351,285.850311 258.856750,283.868073 276.216339,291.460938 
				C290.572693,297.740204 298.791931,310.067535 300.093994,326.472748 
				C301.068542,338.751221 297.293427,349.037476 289.397766,358.199310 
				C288.386047,359.373322 288.063202,361.464172 288.056915,363.132721 
				C287.972046,385.611694 288.007782,408.091064 287.995911,430.570343 
				C287.991608,438.737152 281.687164,444.987762 273.468140,444.997040 
				C262.811279,445.009094 252.154449,445.000824 241.497604,444.999756 
				C231.667038,444.998749 226.001144,439.321686 226.000214,429.472321 
				C225.999283,419.481537 226.000000,409.490784 226.000000,399.000000 
				z"/>
		</svg>
    )
}
export default LockIcon
