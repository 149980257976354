import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const TipiDiGiochi = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Tipologie di giochi</h2>
            <h2 className="titolo2">Tipologie dei giochi</h2>
            <p className="paragraph">I giochi proposti possono essere divisi in 2 grandi tipologie:</p>
            <ul itemType="disc" className="paragraph">
                <li><strong>Giochi a partite</strong></li>
                <li><strong>Giochi a puntate</strong></li>
            </ul>
            <h2 className="titolo2">Giochi a partite </h2>
            <p className="paragraph">Fanno parte di questa categoria i seguenti giochi: <strong>Asso piglia tutto, Briscola, Burraco, Cirulla, Peppa, Scala 40, Scopa, Tresette.</strong></p>
            <p className="paragraph">
                In questi giochi due linee avversarie (cioè due giocatori o due coppie di giocatori) disputano una partita che può terminare dopo un determinato
                numero di smazzate (in tal caso, alla fine della partita vengono conteggiati i punti di ciascuna linea e vince chi ha totalizzato il punteggio maggiore) o
                quando una linea raggiunge un punteggio precedentemente determinato.
            </p>
            <p className="paragraph">Si possono giocare partite:</p>
            <h2 className="titolo2">A coppie</h2>
            <p className="paragraph">Due giocatori seduti di fronte (convenzionalmente definiti "nord" e "sud") giocano contro altri due giocatori (convenzionalmente definiti "est" e "ovest").</p>
            <p className="paragraph">Le partite a coppie possono essere proposte nelle varianti:</p>
            <ul itemType="disc" className="paragraph">
                <li>A "<em>coppie fisse</em>" o, più semplicemente "<em>a coppie</em>", dove saranno i giocatori a scegliere il compagno con cui giocare,</li>
                <li>
                    A "<em>coppie casuali</em>" nel caso in cui sia il sistema a formare casualmente le coppie. In caso di torneo, le coppie saranno formate casualmente a
                    inizio torneo e tali resteranno per tutta la durata del torneo.
                </li>
            </ul>
            <h2 className="titolo2">Testa a testa</h2>
            <p className="paragraph">
                Sono partite individuali dove un giocatore (seduto in nord) gioca contro un altro giocatore (seduto in est).
                <br />
                Nei tavoli i giocatori sono posti uno di
                fronte all'altro.
            </p>
            <h2 className="titolo2">4 in 2</h2>
            <p className="paragraph">
                Sono partite individuali tra due giocatori dove un singolo giocatore muove sia le carte di nord che le carte di sud, mentre l'altro giocatore muove sia le
                carte di est e che le carte di ovest.
                <br />
                In pratica ciascun giocatore muove le carte di una coppia ma lo svolgimento del gioco è lo stesso di quello delle partite a coppie.
                <br />
                I tornei di questi
                giochi sono suddivisi in uno o più turni (per questo motivo i tornei di tali giochi sono detti "<strong>Tornei a turni</strong>"), durante ciascun
                turno i partecipanti disputano <strong>una</strong> partita.
            </p>
            <h2 className="titolo2">Giochi a puntate</h2>
            <p className="paragraph">Fanno parte di questa categoria i seguenti giochi: <strong>Bestia, Chemin de fer, Mazzetti, Poker 5 carte, Sette e mezzo, Telesina</strong>.</p>
            <p className="paragraph">Il gioco più famoso di questa categoria è il poker.</p>
            <p className="paragraph">In questi giochi i partecipanti si siedono al tavolo e giocano del denaro (virtuale nei tornei di allenamento) o delle chips (nei tornei).</p>
            <p className="paragraph">
                La partecipazione al gioco non ha termine quando finisce "una partita" ma quando il giocatore non ha più credito sul tavolo o chips sufficienti per
                proseguire il gioco o quando decide di alzarsi (nel caso di un tavolo cash).
            </p>
            <p className="paragraph">Non esiste quindi il "tempo partita" ma diversi tempi entro i quali i giocatori devono comunicare le loro decisioni.</p>
            <p className="paragraph">
                Il tornei di questi giochi non sono suddivisi in turni ma, a seguito del crescere continuo delle puntate minime nel tempo (i cosiddetti "	<strong>livelli</strong>"), i giocatori restano senza chips e vengono man mano eliminati fin quando non rimane in gioco un solo giocatore, il vincitore del
                torneo.
            </p>
            <p className="paragraph">Questi tornei sono detti "<strong>Tornei a livelli</strong>".</p>
        </ContentTabs>
    );
};

export const TempoMassimo = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Tempo massimo</h2>
            <h2 className="titolo2">Tempi massimi a disposizione dei giocatori</h2>
            <p className="paragraph">I partecipanti devono rispettare i seguenti tempi:</p>
            <p className="paragraph">
                <strong>tempo partita:</strong>
                è il tempo a disposizione del giocatore o della coppia per completare una partita;
            </p>
            <p className="paragraph"><strong>tempo giocata</strong>: per brevità detto<strong> "Timeout"</strong> è il tempo a disposizione di un singolo giocatore per effettuare una giocata.</p>
            <p className="paragraph">
                Poiché esiste un controllo sul tempo massimo di partita di fatto non esiste il rischio di partite "interminabili e noiose"; al fine di consentire ai
                giocatori di rientrare in caso di disconnessione i tempi giocata sono sempre relativamente lunghi.
            </p>
            <p className="paragraph">I tempi residui (della partita e della giocata) del giocatore di mano sono sempre visualizzati sul tavolo da gioco.</p>
            <p></p>
            <p className="paragraph">In caso di smazzate di spareggio, saranno assegnati 4 minuti di tempo supplementare a ciascun giocatore</p>
            <h2 className="titolo2">Disconnessione dal server</h2>
            <p className="paragraph">
                <strong>
                    In caso di disconnessione dal server i giocatori hanno a disposizione, per riconnettersi, una quantità di tempo pari al valore minimo tra tempo
                    partita e tempo giocata.
                </strong>
            </p>
            <p className="paragraph">Nel caso in cui un giocatore non rientri in gioco entro questo termine <strong>perde la partita</strong>.</p>
            <h2 className="titolo2">Penalità per chi fa scadere il tempo o per chi abbandona la partita</h2>
            <p className="paragraph">
                Se una linea fa scadere il tempo massimo o il timeout o clicca sul tasto "abbandona" la partita ha termine e il giocatore che ha fatto scadere il tempo
                (che ha abbandonato) <strong>perde la partita</strong>.
            </p>
            <p className="paragraph">
                Ai fini del calcolo della differenza punti, al giocatore viene applicata una penalità (per maggiori dettagli vedere come viene calcolato il punteggio
                nel regolamento burraco).
            </p>
        </ContentTabs>
    );
};

export const ITorneiInformazioniGenerali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei programmati</h2>
            <p className="paragraph">I tornei programmati (o più semplicemente "tornei") sono tornei in cui gli orari di inizio iscrizioni e di inizio del torneo sono prestabiliti.</p>
            <p className="paragraph">NOTA: In tutti i tornei la chiusura delle iscrizioni avviene <strong>2 minuti prima dell'inizio del torneo stesso</strong>.</p>
            <p className="paragraph">Numero minimo e numero massimo di partecipanti</p>
            <ul itemType="disc" className="paragraph">
                <li>
                    numero minimo di partecipanti: ove non diversamente indicato, il numero minimo di partecipanti in un torneo è pari a 4 giocatori: nel caso in cui,
                    alla chiusura delle iscrizioni, non sia stato raggiunto il numero minimo di partecipanti Il torneo viene annullato e l'importo del diritto di
                    partecipazione restituito al giocatore.
                </li>
                <li>numero massimo di partecipanti: il sistema non accetta iscrizioni oltre il numero massimo di partecipanti.</li>
            </ul>
            <p className="paragraph">Esclusioni per tavoli incompleti</p>
            <p className="paragraph">
                Nei tornei di giochi che prevedono partite tra giocatori così come nei tornei testa a testa al fine di evitare tavoli incompleti, il numero di
                partecipanti deve essere:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>un multiplo di 2 nei tornei individuali</li>
                <li>un multiplo di 4 nei tornei a coppie.</li>
            </ul>
            <p className="paragraph">
                In base a quanto sopra, al termine della fase di iscrizione potranno essere esclusi dalla partecipazione al torneo gli ultimi giocatori iscritti in ordine
                cronologico: a fronte dell'esclusione dalla partecipazione al torneo il sistema provvede alla contestuale restituzione al giocatore dell'importo del
                diritto di partecipazione.
            </p>
            <h2 className="titolo2">Tornei sit &amp; go</h2>
            <p className="paragraph">
                Nei tornei sit&amp;go, o più semplicemente "<strong><em>sit&amp;go</em></strong>", Il numero di iscritti è prestabilito e può variare da 2
                giocatori (per i cosi detti "<strong><em>heads-up</em></strong>") fino ad un massimo di 128 giocatori: il torneo inizia quando viene raggiunto il numero di
                partecipanti previsto; nei tornei Sit&amp;go il numero di partecipanti è sempre una potenza di 2 (2,4,8,16,32,64,128).
            </p>
            <p className="paragraph">
                NOTA: Al fine di ridurre al minimo la durata complessiva del torneo sia nei tornei programmati sia nei tornei sit&amp;go, i turni di gioco successivi al
                primo hanno inizio entro un minuto dal termine di tutte le partite in corso; non è pertanto possibile conoscere la durata esatta del torneo ma è
                possibile conoscere la sua durata massima.
            </p>
        </ContentTabs>
    );
};

export const ITorneiATurni = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">La struttura dei tornei a turni</h2>
            <p className="paragraph">
                I tornei sono suddivisi in uno o più turni durante i quali due linee avversarie (due giocatori o due coppie di giocatori) giocano una o più
                smazzate o un'intera partita.
            </p>
            <p className="paragraph">
                Le regole del torneo stabiliscono sempre il numero di turni di cui è composto il torneo. Nel caso in cui la struttura del torneo sia mista, le regole
                del torneo stabiliscono il numero dei turni di ciascuna fase.
            </p>
            <p className="paragraph">
                NOTA: Al fine di ridurre al minimo la durata complessiva del torneo sia nei tornei programmati sia nei tornei sit&amp;go, i turni di gioco successivi al
                primo hanno inizio entro un minuto dal termine di tutte le partite in corso; non è pertanto possibile conoscere la durata esatta del torneo ma è
                possibile conoscere la sua durata massima.
            </p>
        </ContentTabs>
    );
};

export const ITorneiATurniKO = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei a turni a eliminazione diretta, detti "KO"</h2>
            <p className="paragraph">Nei tornei KO il giocatore che vince il turno passa al turno successivo, mentre quello che perde è eliminato dal torneo.</p>
            <p className="paragraph">
                Al primo turno ciascun tavolo è numerato da 1 ad N ed i giocatori (o le coppie) sono posizionati ai tavoli in modo casuale. Ai turni successivi il
                vincitore del primo tavolo si scontrerà contro il vincitore del 2° tavolo e cosi via.
            </p>
            <p className="paragraph">Il torneo è vinto dal giocatore che ha eliminato tutti gli altri e si classifica secondo l'ultimo giocatore ad essere stato eliminato.</p>
            <p className="paragraph">Le altre posizioni in classifica sono determinate da "ex aequo" a seconda del turno in cui il giocatore è stato eliminato:</p>
            <ul itemType="disc" className="paragraph">
                <li>dal 3° al 4° classificato,</li>
                <li>dal 5° all'8° classificato e cosi via.</li>
            </ul>
            <p className="paragraph">
                I punti totalizzati nel corso del torneo <strong>non</strong> valgono per determinare la posizione in classifica e le eventuali vincite ma sono tenuti in
                considerazione al solo fine della visualizzazione degli ex aequo nella classifica.
            </p>
            <h2 className="titolo2">Primo turno di aggiustamento nei tornei KO programmati</h2>
            <p className="paragraph">
                Come precedentemente visto, nei tornei Sit&amp;go il numero di partecipanti è sempre una potenza di 2 (2,4,8,16,32,64,128) mentre, nei tornei
                programmati, il totale dei partecipanti può essere un qualsiasi numero compreso tra il numero minimo e il numero massimo di partecipanti previsto.
            </p>
            <p className="paragraph">Al fine di riportare il totale dei partecipanti ad una potenza di 2, è possibile che un certo numero di partecipanti	<strong><u>individuati casualmente</u></strong> passi direttamente al secondo turno senza giocare.</p>
            <p className="paragraph">&nbsp;</p>
        </ContentTabs>
    );
};

export const ITorneiATurniDanese = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei a turni a girone con metodo "Danese" (danese)</h2>
            <p className="paragraph">
                In questa modalità i tavoli del primo turno sono formati casualmente mentre ai turni successivi i tavoli sono formati in base alla classifica parziale
                del turno precedente: il 1° classificato si scontrerà contro il 2° classificato e cosi via.
            </p>
            <p className="paragraph">Al termine di ciascun turno vengono calcolati i Match Point (MP) e i Win Point (WP) come stabilito nelle regole specifiche dei singoli giochi.</p>
            <p className="paragraph">
                La classifica finale viene stilata sulla base dei WP totalizzati in tutti i turni. A parità di WP valgono i MP. In caso di ulteriore parità
                saranno assegnati degli "ex aequo" e il totale dei premi spettanti alle posizioni di ex aequo verrà suddiviso tra i giocatori.
            </p>
            <p className="paragraph">Al fine di determinare gli accoppiamenti per i turni successivi al primo, in caso di ex aequo nelle classifiche parziali, le posizioni saranno	<strong><u>stabilite casualmente</u></strong>.</p>
        </ContentTabs>
    );
};

export const ITorneiATurniDanEL = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei a turni in modalità Danese a eliminazione parziale (danEL)</h2>
            <p className="paragraph">
                Questa modalità prevede che siano utilizzate le regole dei tornei Danese; inoltre, dopo ciascun turno, verranno eliminati dal torneo i giocatori che
                non hanno totalizzato un punteggio minimo espresso in Win Point (WP).
            </p>
            <p className="paragraph">I punteggi minimi sono i seguenti:</p>
            <p className="paragraph">Tornei fino a 3 turni</p>
            <ul itemType="disc" className="paragraph">
                <li>alla fine del primo turno 7 WP</li>
                <li>alla fine del secondo turno 14 WP</li>
            </ul>
            <p className="paragraph">Tornei con 4 turni</p>
            <ul itemType="disc" className="paragraph">
                <li>alla fine del primo turno 4 WP</li>
                <li>alla fine del secondo turno 20 WP</li>
                <li>alla fine del terzo turno 36 WP</li>
            </ul>
            <p className="paragraph">NOTA: In nessun caso saranno eliminati i giocatori che hanno raggiunto una posizione in classifica che dà diritto a un premio.</p>
            <p className="paragraph">
                Nel caso i giocatori rimasti in gioco fossero dispari verrà ripescato il miglior classificato tra gli eliminati. In caso di ex aequo il ripescato
                sarà determinato casualmente tra gli ex aequo.
            </p>
        </ContentTabs>
    );
};

export const ITorneiATurniDanELKO = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei a turni in modalità Danese seguiti da playoff (danKO e danELKO)</h2>
            <p className="paragraph">Questi tornei sono cosi strutturati:</p>
            <ul itemType="disc" className="paragraph">
                <li>viene svolta una prima fase a girone danese o danEL composta da uno o più turni;</li>
                <li>
                    il passaggio alla seconda fase del torneo è possibile solo per un numero prestabilito di migliori classificati; in caso di ex aequo di WP e MP i
                    qualificati, relativamente agli ex aequo, saranno determinati casualmente;
                </li>
                <li>la seconda fase è a eliminazione diretta tra i giocatori qualificati.</li>
            </ul>
        </ContentTabs>
    );
};

export const ITorneiALivelli = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">I tornei a livelli</h2>
            <p className="paragraph">
                I giocatori competono su numerosi tavoli per conquistare le chips degli altri, mentre le puntate minime (bui, inviti o puntate a seconda del gioco)
                aumentano a intervalli di tempo prestabiliti ("<strong>livelli</strong>"); quando un giocatore resta senza chips, secondo le regole stabilite dal gioco
                specifico, viene eliminato dal torneo.
            </p>
        </ContentTabs>
    );
};

export const ITorneiALivelliFreezeout = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">I tornei a livelli in modalità Freezout</h2>
            <p className="paragraph">In un torneo freezeout, i partecipanti iniziano il gioco con un ammontare di chips predeterminato ed uguale per tutti.</p>
            <p className="paragraph">
                Mano a mano che i giocatori vengono eliminati, i tavoli vengono "rotti", e i partecipanti rimasti vengono spostati ad altri tavoli, in modo che questi
                ospitino un numero pressappoco uguale di giocatori.
            </p>
            <p className="paragraph">
                Alla fine, i giocatori rimasti si ritrovano al c.d. "tavolo finale", dove risulterà vincitore colui che riuscirà a impossessarsi di tutte le
                chips rimaste in gioco. I migliori classificati vincono premi, la cui entità è stabilita al momento della chiusura delle iscrizioni (vedere
                formazione e ripartizione del montepremi).
            </p>
            <h2 className="titolo2">Modalità mano per mano ("hand by hand")</h2>
            <p className="paragraph">
                Quando il numero di partecipanti rimasti in gara è di poco superiore al numero dei premiati, il torneo entra in modalità "mano per mano" in modo
                tale che tutte le mani in tutti i tavoli inizino nello stesso istante.
            </p>
            <p className="paragraph">
                Appena il numero di partecipanti rimasti in gara diventa minore o uguale al numero di premiati, il torneo esce dalla modalità "mano per mano" e le
                mani dei vari tavoli possono iniziare in momenti diversi.
            </p>
        </ContentTabs>
    );
};

export const ITorneiALivelliShootout = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">I tornei a livelli in modalità Shootout</h2>
            <p className="paragraph">
                Nei tornei shootout i partecipanti restano al tavolo al quale sono stati assegnati all'inizio del torneo fino a che non sarà rimasto un unico
                giocatore per tavolo: i vincitori di ciascun tavolo accedono al tavolo finale.
            </p>
        </ContentTabs>
    );
};

export const ITorneiToken = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">I token</h2>
            <p className="paragraph">I token (lett. "gettoni") sono un metodo per l'acquisto di diritti di partecipazione al gioco alternativo al denaro.</p>
            <p className="paragraph">
                E' facoltà del Concessionario assegnare uno o più token ad un giocatore o ad un gruppo di giocatori che rispondono, ad esempio, a determinate
                caratteristiche.
            </p>
            <p className="paragraph">
                I token possono essere utilizzati entro il periodo di validità per iscriversi a tornei con vincita in denaro (tornei "money") che prevedano il token
                come mezzo di pagamento del diritto di partecipazione.
            </p>
            <p className="paragraph">Sono previsti tre tipi di token:</p>
            <p className="paragraph">· token di valore: al token viene assegnato un valore che può essere utilizzato per uno o più giochi;</p>
            <p className="paragraph">· token a invito: il token può essere utilizzato esclusivamente per iscriversi a un determinato torneo;</p>
            <p className="paragraph">· token gruppo: il token può essere utilizzato per iscriversi a un gruppo di tornei.</p>
            <p className="paragraph">Cliccando sul tasto "il mio profilo" il giocatore può esaminare tutti i token a sua disposizione e la loro relativa data di scadenza.</p>
            <p className="paragraph">
                L'utilizzo del token è molto semplice: qualora un giocatore decida di partecipare ad un certo torneo ( cliccando sul tasto "partecipa") si aprirà
                una finestra e saranno visualizzati tutti i token disponibili per l'iscrizione a quel torneo, basterà quindi selezionarne uno per acquistare il
                diritto di partecipazione.
            </p>
        </ContentTabs>
    )
};

export const ITorneiPartecipare = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Acquisto del Diritto di partecipazione</h2>
            <p className="paragraph">Il costo del diritto di partecipazione ad un torneo non può essere superiore ad euro 250,00</p>
            <p className="paragraph">Il diritto di partecipazione può essere acquistato:</p>
            <ul itemType="disc" className="paragraph">
                <li>Tramite addebito del costo sul proprio conto di gioco;</li>
                <li>Tramite l'utilizzo dei token a disposizione del giocatore, sempre che il torneo lo consenta;</li>
            </ul>
            <p className="paragraph">
                Qualora il giocatore decida di partecipare ad un certo torneo (cliccando sul tasto "partecipa") gli apparirà una finestra con il saldo del conto, e gli eventuali token a disposizione; in quel momento sarà possibile selezionare il metodo di pagamento desiderato.
            </p>
        </ContentTabs>
    )
};

export const ITorneiAutorizzazioniAAMS = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Autorizzazioni AAMS</h2>
            <p className="paragraph">
                Tutte le iscrizioni e cancellazioni dei tornei a pagamento devono essere autorizzate dall' Amministrazione Autonoma dei Monopoli di Stato (AAMS). Le
                iscrizioni e le cancellazioni che non hanno ricevuto l'autorizzazione AAMS non sono valide.
            </p>
            <p className="paragraph">
                L'Autorizzazione AAMS viene comunicata ai giocatori con un messaggio che compare sullo schermo pochi istanti dopo l'iscrizione o la cancellazione. In caso
                di iscrizione, nello stesso messaggio, sarà anche leggibile il numero del biglietto rilasciato da AAMS (diritto di partecipazione).
            </p>
            <p className="paragraph">Quando un giocatore si è iscritto ad un torneo appare una sfera colorata nella riga del torneo. La sfera può assumere i seguenti colori:</p>
            <p className="paragraph">· verde nel caso in cui l'iscrizione sia già stata autorizzata da AAMS</p>
            <p className="paragraph">· marrone nel caso in cui l'iscrizione sia in attesa di autorizzazione AAMS (o nei tornei di allenamento, iscrizione soggetta a conferma da parte del giocatore).</p>
            <p className="paragraph">Nell'elenco degli iscritti al torneo un nickname può assumere i seguenti colori in base allo stato di iscrizione:</p>
            <p className="paragraph">· blu nel caso in cui l'iscrizione sia già stata autorizzata da AAMS,</p>
            <p className="paragraph">· nero nel caso in cui l'iscrizione sia in attesa di autorizzazione AAMS,</p>
            <p className="paragraph">· rosso nel caso in cui la cancellazione sia in attesa di autorizzazione AAMS.</p>
        </ContentTabs>
    );
};

export const ITorneiAnnullamento = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Annullamento dei tornei per motivi tecnici</h2>
            <p className="paragraph">Se un torneo viene annullato prima del suo inizio sarà rimborsato a tutti gli iscritti il costo di partecipazione.</p>
            <p className="paragraph">Se un torneo viene annullato dopo il suo inizio, si applicano le seguenti regole.</p>
            <p className="paragraph">Se il torneo non è garantito, sarà complessivamente rimborsato l’intero montepremi senza alcuna deduzione, vale a dire:</p>
            <p className="paragraph"><em> totale rimborsi</em> = costo di partecipazione x numero partecipanti</p>
            <p className="paragraph">Se il torneo è garantito il <em>totale rimborsi</em> sarà pari a l’importo maggiore tra il montepremi garantito e la somma come prima determinata.</p>
            <p className="paragraph">Al fine del calcolo di rimborsi fa fede la situazione dei giocatori relativa <strong><u>all'ultimo turno</u></strong> del torneo ccompletato correttamente;</p>
            <p className="paragraph">I rimborsi vengono determinati come segue.</p>
            <p className="paragraph">a) Giocatori già eliminati dal torneo al momento dell’interruzione.</p>
            <ul>
                <li><p className="paragraph">a1) se la posizione nella quale sono stati eliminati non da diritto a un premio, non ricevono alcun rimborso;</p></li>
                <li><p className="paragraph">a2) se la posizione nella quale sono stati eliminati da diritto a un premio, ricevono un rimborso pari a questo premio.</p></li>
            </ul>
            <p className="paragraph">b) Giocatori rimasti in gioco al momento dell’interruzione del torneo.</p>
            <p className="paragraph">Viene detto <em>montepremi residuo</em> il totale dei rimborsi da effettuare meno gli eventuali rimborsi pagati ai giocatori già eliminati.</p>
            <p className="paragraph">Il <em>montepremi residuo</em> viene distribuito tra i giocatori rimasti in gioco al momento dell’interruzione del torneo come segue:</p>
            <p className="paragraph">b1) nei tornei a eliminazione diretta (KO) il <em>montepremi residuo</em> viene suddiviso in parti uguali tra tutti i giocatori rimasti in gioco;</p>
            <p className="paragraph">b2) negli altri tornei i giocatori rimasti in gara ricevono un rimborso pari alla somma delle due seguenti due componenti:</p>
            <ul>
                <li><p className="paragraph">b21) il costo di partecipazione o, se superiore, il premio minimo che era previsto per l’ultima posizione dei giocatori rimasti in gioco;</p></li>
                <li>
                    <p className="paragraph">
                        b22) l’eventuale montepremi rimanente, vale a dire il <em> montepremi residuo</em> meno i rimborsi di cui al punto b21), sarà diviso tra i
                        giocatori rimasti in gioco proporzionalmente ai WP accumulati al momento dell’interruzione.
                    </p>
                </li>
            </ul>
            <p className="paragraph">I tornei annullati non sono validi per le classifiche generali o dei campionati.</p>
        </ContentTabs>
    );
};

export const ITorneiCancellarsiDaUnTorneo = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Cancellazione da un torneo</h2>
            <p className="paragraph">
                E' generalmente possibile, ove non diversamente specificato, cancellarsi da un torneo ed ottenere quindi il riaccredito automatico del diritto di
                partecipazione:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>prima dell'inizio del torneo nei tornei Sit&amp;go,</li>
                <li>prima della chiusura della fase di iscrizione nei tornei programmati.</li>
            </ul>
        </ContentTabs>
    );
};

export const ITorneiFormazioneMontepremi = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Formazione del montepremi</h2>
            <p className="paragraph">
                Al montepremi è destinata una percentuale di quanto incassato dalla vendita dei diritti di partecipazione, variabile sulla base del singolo torneo, ma
                in tutti i casi non inferiore all'80% del costo del diritto di partecipazione.
            </p>
            <p className="paragraph">
                L'importo esatto della quota destinata al montepremi è sempre indicato nella formulazione del costo di partecipazione, che appare quando si seleziona
                un torneo nella lobby di gioco.
            </p>
            <p className="paragraph">Ad esempio un costo di partecipazione €100,00 (90,00+10,00) sta a indicare che:</p>
            <ul itemType="disc" className="paragraph">
                <li>il costo del diritto di partecipazione è di €100,00;</li>
                <li>l'ammontare unitario destinato a montepremi è di €90,00;</li>
                <li>il margine lordo del concessionario è di €10,00.</li>
            </ul>
            <p className="paragraph">Il montepremi complessivo di un torneo è pari dall'ammontare unitario destinato al montepremi moltiplicato per il numero di partecipanti.</p>
            <p className="paragraph">
                Le vincite, sotto forma di quote del montepremi, saranno assegnate con uno schema di ripartizione variabile in funzione del tipo di torneo e del numero di
                partecipanti al torneo, come indicato nel successivo paragrafo Ripartizione del montepremi.
            </p>
            <p className="paragraph">
                Cliccando sul tasto "Lobby del torneo" si può esaminare la ripartizione del montepremi del torneo stesso: se il numero di partecipanti è già
                stabilito (tornei sit&amp;go o tornei la cui fase di iscrizione è terminata) si esaminerà la ripartizione del montepremi definitiva, altrimenti
                si potrà esaminare:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>la ripartizione del montepremi con il numero di iscritti in quel momento (informazione di default);</li>
                <li>la ripartizione del montepremi con un qualsiasi numero di iscritti selezionato a piacere.</li>
            </ul>
        </ContentTabs>
    );
};

export const ITorneiRipartizioneMontepremi = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Ripartizione del montepremi</h2>
            <p className="paragraph">La ripartizione del montepremi varia in funzione del tipo del torneo e del numero dei partecipanti al torneo.</p>
            <p className="paragraph">Tornei Sit&amp;go ad eliminazione diretta e Tornei programmati con fase finale KO (danKO, danELKO)</p>
            <p className="paragraph">
                In questi tornei la ripartizione del montepremi è nota prima dell'inizio del torneo perché si conosce il numero di partecipanti della fase a
                eliminazione diretta. La ripartizione del montepremi di questi tornei è determinata come segue:
            </p>
            <table className="bordi" width="700">
                <tbody>
                    <tr>
                        <td className="bordi" rowSpan={2} width="105"><p className="paragraph"><strong>Classifica</strong></p></td>
                        <td className="bordi" colSpan={7} width="589"><p className="paragraph"><strong>Partecipanti</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79"><p className="paragraph"><strong>2</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>4</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>8</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>16</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>32</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>64</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>128</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="105"><p className="paragraph"><strong>1°</strong></p></td>
                        <td className="bordi" width="79"><p className="paragraph">100%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">100%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">65%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">48%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">35%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">25%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">25%</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="105"><p className="paragraph"><strong>2°</strong></p></td>
                        <td className="bordi" width="79"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><p className="paragraph">35%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">26%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">19%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">15%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">15%</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="105"><p className="paragraph"><strong>3°- 4°</strong></p></td>
                        <td className="bordi" width="79"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><p className="paragraph">13%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">11%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">8%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">8%</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="105"><p className="paragraph"><strong>5°-8°</strong></p></td>
                        <td className="bordi" width="79"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><p className="paragraph">6%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">5%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">5%</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="105"><p className="paragraph"><strong>9°-16°</strong></p></td>
                        <td className="bordi" width="79"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><br /></td>
                        <td className="bordi" width="83"><p className="paragraph">3%</p></td>
                        <td className="bordi" width="83"><p className="paragraph">3%</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="105"><p className="paragraph"><strong>montepremi</strong></p></td>
                        <td className="bordi" width="79"><p className="paragraph"><strong>100%</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>100%</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>100%</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>100%</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>100%</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>100%</strong></p></td>
                        <td className="bordi" width="83"><p className="paragraph"><strong>100%</strong></p></td>
                    </tr>
                </tbody>
            </table>
            <br />
            <p className="paragraph">ad esempio con un costo di partecipazione di 10,00 € e una percentuale di distribuzione dell'80% si ha:</p>
            <table className="bordi" border={1} cellPadding={0} width="700">
                <tbody>
                    <tr>
                        <td className="bordi" rowSpan={2} width="87"><p className="paragraph"><strong>Classifica</strong></p></td>
                        <td className="bordi" colSpan={7}><p className="paragraph"><strong>Partecipanti</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>2</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph"><strong>4</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph"><strong>8</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph"><strong>16</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph"><strong>32</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph"><strong>64</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph"><strong>128</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>1°</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph">€16</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€32</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€41,60</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€61,44</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€89,60</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€128,00</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€256,00</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>2°</strong></p></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><p className="paragraph">€22,40</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€33,28</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€48,64</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€76,80</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€153,60</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>3°- 4°</strong></p></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><p className="paragraph">€16,64</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€28,16</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€40,96</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€81,92</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>5°-8°</strong></p></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><p className="paragraph">€15,36</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€25,60</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€51,20</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>9°-16°</strong></p></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><br /></td>
                        <td className="bordi" width="87"><p className="paragraph">€15,36</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€30,72</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="87"><p className="paragraph"><strong>montepremi</strong></p></td>
                        <td className="bordi" width="87"><p className="paragraph">€16</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€32</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€64</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€128</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€256</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€512</p></td>
                        <td className="bordi" width="87"><p className="paragraph">€1024</p></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph">
                Tornei programmati a eliminazione diretta (KO)
            </p>
            <p className="paragraph">
                Nei tornei programmati a eliminazione diretta il numero dei partecipanti può non essere una potenza di 2 (2,4,8,16,32,64,128).
            </p>
            <p className="paragraph">
                In questo caso si applica il seguente criterio per stabilire il numero di giocatori che hanno diritto ad un premio:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>da 1 a 6 partecipanti ........... 1 premiato</li>
                <li>da 7 a 12 partecipanti ......... 2 premiati</li>
                <li>da 13 a 24 partecipanti ....... 4 premiati</li>
                <li>da 25 a 48 partecipanti ....... 8 premiati</li>
                <li>con più di 48 partecipanti ... 16 premiati</li>
            </ul>
            <p className="paragraph">
                Una volta stabilito il numero dei premiati si applica la stessa percentuale di ripartizione prevista per i tornei Sit&amp;go a eliminazione diretta.
            </p>
            <strong><em><br /></em></strong>
            <h2 className="titolo2">Tornei programmati a girone non KO (danese, danEL)</h2>
            <p className="paragraph">
                La distribuzione del montepremi effettiva sarà determinata alla chiusura delle iscrizioni quando è noto il numero di partecipanti.
            </p>
            <p className="paragraph">
                A titolo di esempio riportiamo la ripartizione percentuale del montepremi per un torneo con buy in avente rake del 10%. Nei limiti previsti dalla normativa
                vigente sono possibili diverse ripartizioni delle aliquote a seconda dello specifico torneo. I giocatori saranno avvisati in anticipo in caso di
                assegnazione non standard dei premi.
            </p>
            <table border={1} cellPadding={0} width="700">
                <tbody>
                    <tr>
                        <td className="bordi" rowSpan={2} width="71"><p className="paragraph"><strong>Posizione in classifica</strong></p></td>
                        <td className="bordi" colSpan={6}><p className="paragraph"><strong>PARTECIPANTI</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi"><p className="paragraph"><strong>10</strong></p></td>
                        <td className="bordi"><p className="paragraph"><strong>25</strong></p></td>
                        <td className="bordi"><p className="paragraph"><strong>50</strong></p></td>
                        <td className="bordi"><p className="paragraph"><strong>100</strong></p></td>
                        <td className="bordi"><p className="paragraph"><strong>200</strong></p></td>
                        <td className="bordi"><p className="paragraph"><strong>300</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><br /></td>
                        <td className="bordi" colSpan={6}><p className="paragraph"><strong>VINCITA (%)</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>1°</strong></p></td>
                        <td className="bordi"><p className="paragraph">49,29</p></td>
                        <td className="bordi"><p className="paragraph">32,62</p></td>
                        <td className="bordi"><p className="paragraph">25,6</p></td>
                        <td className="bordi"><p className="paragraph">20,68</p></td>
                        <td className="bordi"><p className="paragraph">17,1</p></td>
                        <td className="bordi"><p className="paragraph">15,51</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>2°</strong></p></td>
                        <td className="bordi"><p className="paragraph">29,58</p></td>
                        <td className="bordi"><p className="paragraph">19,57</p></td>
                        <td className="bordi"><p className="paragraph">15,37</p></td>
                        <td className="bordi"><p className="paragraph">12,42</p></td>
                        <td className="bordi"><p className="paragraph">10,27</p></td>
                        <td className="bordi"><p className="paragraph">9,31</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>3°</strong></p></td>
                        <td className="bordi"><p className="paragraph">21,13</p></td>
                        <td className="bordi"><p className="paragraph">13,98</p></td>
                        <td className="bordi"><p className="paragraph">10,98</p></td>
                        <td className="bordi"><p className="paragraph">8,87</p></td>
                        <td className="bordi"><p className="paragraph">7,34</p></td>
                        <td className="bordi"><p className="paragraph">6,65</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>4°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">10,88</p></td>
                        <td className="bordi"><p className="paragraph">8,54</p></td>
                        <td className="bordi"><p className="paragraph">6,9</p></td>
                        <td className="bordi"><p className="paragraph">5,71</p></td>
                        <td className="bordi"><p className="paragraph">5,18</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>5°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">8,9</p></td>
                        <td className="bordi"><p className="paragraph">6,99</p></td>
                        <td className="bordi"><p className="paragraph">5,64</p></td>
                        <td className="bordi"><p className="paragraph">4,67</p></td>
                        <td className="bordi"><p className="paragraph">4,23</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>6°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">7,53</p></td>
                        <td className="bordi"><p className="paragraph">5,91</p></td>
                        <td className="bordi"><p className="paragraph">4,78</p></td>
                        <td className="bordi"><p className="paragraph">3,95</p></td>
                        <td className="bordi"><p className="paragraph">3,58</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>7°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">6,52</p></td>
                        <td className="bordi"><p className="paragraph">5,12</p></td>
                        <td className="bordi"><p className="paragraph">4,14</p></td>
                        <td className="bordi"><p className="paragraph">3,42</p></td>
                        <td className="bordi"><p className="paragraph">3,11</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>8°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">4,52</p></td>
                        <td className="bordi"><p className="paragraph">3,65</p></td>
                        <td className="bordi"><p className="paragraph">3,02</p></td>
                        <td className="bordi"><p className="paragraph">2,74</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>9°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">4,04</p></td>
                        <td className="bordi"><p className="paragraph">3,27</p></td>
                        <td className="bordi"><p className="paragraph">2,7</p></td>
                        <td className="bordi"><p className="paragraph">2,45</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>10°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">3,66</p></td>
                        <td className="bordi"><p className="paragraph">2,96</p></td>
                        <td className="bordi"><p className="paragraph">2,45</p></td>
                        <td className="bordi"><p className="paragraph">2,22</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>11°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">3,34</p></td>
                        <td className="bordi"><p className="paragraph">2,7</p></td>
                        <td className="bordi"><p className="paragraph">2,23</p></td>
                        <td className="bordi"><p className="paragraph">2,03</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>12°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">3,08</p></td>
                        <td className="bordi"><p className="paragraph">2,48</p></td>
                        <td className="bordi"><p className="paragraph">2,05</p></td>
                        <td className="bordi"><p className="paragraph">1,86</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>13°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">2,85</p></td>
                        <td className="bordi"><p className="paragraph">2,3</p></td>
                        <td className="bordi"><p className="paragraph">1,9</p></td>
                        <td className="bordi"><p className="paragraph">1,73</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>14°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">2,14</p></td>
                        <td className="bordi"><p className="paragraph">1,77</p></td>
                        <td className="bordi"><p className="paragraph">1,61</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>15°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">2</p></td>
                        <td className="bordi"><p className="paragraph">1,66</p></td>
                        <td className="bordi"><p className="paragraph">1,5</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>16°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,88</p></td>
                        <td className="bordi"><p className="paragraph">1,56</p></td>
                        <td className="bordi"><p className="paragraph">1,41</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>17°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,77</p></td>
                        <td className="bordi"><p className="paragraph">1,47</p></td>
                        <td className="bordi"><p className="paragraph">1,33</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>18°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,68</p></td>
                        <td className="bordi"><p className="paragraph">1,39</p></td>
                        <td className="bordi"><p className="paragraph">1,26</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>19°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,59</p></td>
                        <td className="bordi"><p className="paragraph">1,32</p></td>
                        <td className="bordi"><p className="paragraph">1,19</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>20°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,51</p></td>
                        <td className="bordi"><p className="paragraph">1,25</p></td>
                        <td className="bordi"><p className="paragraph">1,14</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>21°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,44</p></td>
                        <td className="bordi"><p className="paragraph">1,19</p></td>
                        <td className="bordi"><p className="paragraph">1,08</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>22°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,38</p></td>
                        <td className="bordi"><p className="paragraph">1,14</p></td>
                        <td className="bordi"><p className="paragraph">1,04</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>23°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,32</p></td>
                        <td className="bordi"><p className="paragraph">1,09</p></td>
                        <td className="bordi"><p className="paragraph">0,99</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>24°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,27</p></td>
                        <td className="bordi"><p className="paragraph">1,05</p></td>
                        <td className="bordi"><p className="paragraph">0,95</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>25°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">1,22</p></td>
                        <td className="bordi"><p className="paragraph">1,01</p></td>
                        <td className="bordi"><p className="paragraph">0,91</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>26°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,97</p></td>
                        <td className="bordi"><p className="paragraph">0,88</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>27°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,93</p></td>
                        <td className="bordi"><p className="paragraph">0,85</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>28°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,9</p></td>
                        <td className="bordi"><p className="paragraph">0,82</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>29°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,87</p></td>
                        <td className="bordi"><p className="paragraph">0,79</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>30°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,84</p></td>
                        <td className="bordi"><p className="paragraph">0,76</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>31°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,82</p></td>
                        <td className="bordi"><p className="paragraph">0,74</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>32°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,79</p></td>
                        <td className="bordi"><p className="paragraph">0,72</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>33°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,77</p></td>
                        <td className="bordi"><p className="paragraph">0,7</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>34°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,74</p></td>
                        <td className="bordi"><p className="paragraph">0,68</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>35°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,72</p></td>
                        <td className="bordi"><p className="paragraph">0,66</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>36°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,7</p></td>
                        <td className="bordi"><p className="paragraph">0,64</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>37°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,69</p></td>
                        <td className="bordi"><p className="paragraph">0,62</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>38°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,67</p></td>
                        <td className="bordi"><p className="paragraph">0,61</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>39°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,65</p></td>
                        <td className="bordi"><p className="paragraph">0,59</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>40°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,63</p></td>
                        <td className="bordi"><p className="paragraph">0,58</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>41°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,62</p></td>
                        <td className="bordi"><p className="paragraph">0,56</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>42°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,6</p></td>
                        <td className="bordi"><p className="paragraph">0,55</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>43°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,59</p></td>
                        <td className="bordi"><p className="paragraph">0,54</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>44°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,58</p></td>
                        <td className="bordi"><p className="paragraph">0,52</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>45°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,56</p></td>
                        <td className="bordi"><p className="paragraph">0,51</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>46°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,55</p></td>
                        <td className="bordi"><p className="paragraph">0,5</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>47°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,54</p></td>
                        <td className="bordi"><p className="paragraph">0,49</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>48°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,53</p></td>
                        <td className="bordi"><p className="paragraph">0,48</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>49°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,52</p></td>
                        <td className="bordi"><p className="paragraph">0,47</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>50°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,51</p></td>
                        <td className="bordi"><p className="paragraph">0,46</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>51°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,45</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>52°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,44</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>53°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,44</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>54°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,43</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>55°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,42</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>56°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,41</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>57°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,41</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>58°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,4</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>59°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,39</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>60°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,39</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>61°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,38</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>62°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,37</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>63°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,37</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>64°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,36</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>65°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,36</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>66°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,35</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>67°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,35</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>68°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,34</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>69°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,34</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>70°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,33</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>71°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,33</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>72°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,32</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>73°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,32</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>74°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,31</p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="71"><p className="paragraph"><strong>75°</strong></p></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><br /></td>
                        <td className="bordi"><p className="paragraph">0,31</p></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph">
                La tabella indica esclusivamente le modalità standard di assegnazione delle vincite. Nei limiti previsti dalla normativa vigente sono infatti
                possibili diverse ripartizioni delle aliquote a seconda dello specifico torneo.
            </p>
            <p className="paragraph">Nella fase di iscrizione cliccando sul tasto "montepremi" verranno mostrate (espresse in euro):</p>
            <ul itemType="disc" className="paragraph">
                <li>la distribuzione del montepremi calcolata in base al numero di iscritti in quel momento;</li>
                <li>tutte le possibili distribuzioni del montepremi al variare del numero degli iscritti che sarà selezionabile a piacere dal giocatore.</li>
            </ul>
            <p className="paragraph">Dopo la chiusura delle iscrizioni verrà mostrata solo la ripartizione del montepremi effettiva espressa in euro.</p>
        </ContentTabs>
    );
};

export const ITorneiBonus = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei gratuiti con (“Freerolls Bonus”)</h2>
            <p className="paragraph">
                E' prevista la possibilità, a discrezione del concessionario, di creare tornei a partecipazione gratuita con premi erogati sotto forma di bonus	<strong><u>non prelevabile</u></strong> ma utilizzabile solo per <u>l'acquisto di altri diritti di partecipazione</u>. Questi tornei saranno offerti dal
                Concessionario a titolo promozionale mentre la ripartizione del montepremi sarà definita e comunicata di volta in volta.
            </p>
            <h2 className="titolo2">Tornei gratuiti con vincita prelevabile (“Freerolls  Real”)</h2>
            <p className="paragraph">E' prevista la possibilità, a discrezione del concessionario, di creare tornei a partecipazione gratuita premi  erogati come vincita prelevabile.Questi tornei saranno offerti dal Concessionario a titolo promozionale mentre la ripartizione del montepremi sarà definita e comunicata di volta in volta.</p>
        </ContentTabs>
    );
};

export const ITorneiGarantiti = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei con somma minima garantita destinata a montepremi <br />("Tornei garantiti")</h2>
            <p className="paragraph">
                E' prevista la possibilità, a discrezione del concessionario, di creare tornei con una somma minima garantita da destinare a montepremi. L'eventuale
                differenza tra la somma minima garantita e la raccolta effettiva sarà a carico del Concessionario.
            </p>
        </ContentTabs>
    );
};

export const ITorneiSatellite = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei con struttura a fasi di qualificazione ("tornei satelliti")</h2>
            <p className="paragraph">Saranno organizzati tornei nei quali la vincita può consistere nell'iscrizione a un altro torneo ("fase successiva").</p>
            <p className="paragraph">Il numero di giocatori, migliori classificati, che si qualifica alla fase successiva, è stabilito come segue:</p>
            <p className="paragraph">qualificati = Montepremi del torneo / Costo di partecipazione della fase successiva.</p>
            <p className="paragraph">L'eventuale resto di tale divisione sarà assegnato al miglior classificato che non si classifica alla fase successiva o  in casi di ex equo al primo giocatore in elenco negli accoppiamenti al primo turno</p>
        </ContentTabs>
    );
};

export const ITorneiRiservati = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tornei</h2>
            <h2 className="titolo2">Tornei riservati</h2>
            <p className="paragraph">Sono proposto tornei e tavoli delle seguenti tipologie:</p>
            <p className="paragraph"><strong>Open:</strong> aperto a tutti i giocatori;</p>
            <p className="paragraph">
                <strong>Ristretto</strong>
                : tornei a cui possono partecipare solo alcuni giocatori selezionati in base a certe caratteristiche (ad esempio tutti coloro i quali hanno una certa
                posizione in una certa classifica, tutti coloro i quali hanno vinto un torneo o una serie di tornei, tutti coloro che abitano in una certa città,
                ecc.);
            </p>
            <p className="paragraph"><strong>A inviti</strong>: possono partecipare solo i giocatori che sono stati invitati dal Concessionario.</p>
        </ContentTabs>
    );
};

export const ITorneiAccredito = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Accredito delle vincite</h2>
            <h2 className="titolo2">Accredito delle vincite</h2>
            <p className="paragraph">Le vincite dei tornei money e le vincite bonus vengono accreditate sul Conto di Gioco entro pochi minuti dal termine del torneo, mentre le vincite bonus entro 3 giorni lavorativi.</p>
        </ContentTabs>
    );
};

export const ITavoliCashInformazioniGenerali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tavoli cash</h2>
            <h2 className="titolo2">Come entrare in un tavolo cash</h2>
            <p className="paragraph">
                Per osservare un tavolo cash è necessario cliccare sulla riga del tavolo desiderato nell'elenco dei tavoli nella lobby e successivamente cliccare sul
                tasto "Vai al tavolo" che comparirà sulla destra in basso nella lobby.
            </p>
            <p className="paragraph">
                Se la partita è in corso e le regole del tavolo non ammettono "osservatori" il tasto "Vai al tavolo" non apparirà e non sarà possibile
                osservare il tavolo.
            </p>
            <p className="paragraph">
                Per entrare in un tavolo cash è necessario versare sul tavolo la somma minima indicata dalle regola del tavolo. La somma massima che si può
                versare su un tavolo è stabilita dalla normativa vigente ed attualmente è pari a € 1.000,00.
            </p>
            <h2 className="titolo2">Come sedersi ad un tavolo</h2>
            <p className="paragraph">
                Appena un giocatore si unisce ad un tavolo è in grado di osservare il gioco senza però avere la possibilità di conoscere le carte in mano ai
                giocatori seduti.
            </p>
            <p className="paragraph">
                Nel caso in cui ci siano posti liberi e il giocatori desideri partecipare alla partita è necessario che clicchi su un posto libero e che versi, dal
                proprio conto di gioco al <em>credito sul tavolo,</em> una somma almeno pari al <em>credito di ingresso minimo</em> stabilito dalle regole del tavolo.
            </p>
            <p className="paragraph">
                Nel caso in cui non ci siano posti liberi è comunque possibile inserire il proprio nickname nella lista di attesa cliccando l'apposito pulsante.
            </p>
            <p className="paragraph">
                Una volta che si è liberato un posto al tavolo, al giocatore primo della lista di attesa comparirà un avviso sullo schermo. Da quel momento il
                giocatore ha <strong>un minuto</strong> di tempo per decidere di partecipare alla partita, altrimenti sarà cancellato dalla lista di attesa e
                perderà la possibilità il suo diritto.
            </p>
            <h2 className="titolo2">Accredito delle vincite - il rake</h2>
            <p className="paragraph">
                Le vincite sono automaticamente accreditate sul credito al tavolo dei giocatori al netto del margine lordo di spettanza del concessionario, stabilito dalle
                regole del tavolo, nei limiti di legge. Per le modalità di calcolo del rake si veda il paragrafo relativo, in base alla tipologia di gioco.
            </p>
            <p className="paragraph">&nbsp;</p>
            <h2 className="titolo2">Gestione dei malfunzionamenti, annullamento dei tornei e attribuzione del montepremi</h2>
            <p className="paragraph">
                Per quanto riguarda l'attribuzione del montepremi, nel caso di sessioni di gioco annullate per motivi tecnici, le puntate effettuate e le vincite
                conseguite nelle mani disputate prima dell'interruzione vengono considerate regolarmente e le poste vengono riaccreditate sui conti di gioco.
            </p>
            <p className="paragraph">Per quanto riguarda invece la mano interrotta, tutte le puntate effettuate vengono integralmente rimborsate.</p>
        </ContentTabs>
    );
};

export const ITavoliCashATurni = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tavoli cash</h2>
            <h2 className="titolo2">Tavoli cash nei giochi a partita: Asso piglia tutto, Briscola, Burraco, Cirulla, Peppa, Scala 40, Scopa, Tresette.</h2>
            <h2 className="titolo2">Quota della raccolta destinata a montepremi</h2>
            <p className="paragraph">Al montepremi di ogni singola mano è destinata una percentuale che non è mai inferiore al 90% della raccolta.</p>
            <p className="paragraph">Nei tavoli cash non si ha un montepremi predeterminato, ma è il risultato di gioco che determina il montepremi.</p>
            <p className="paragraph">La quota della raccolta destinata a montepremi è formata dalla somma di più componenti:</p>
            <ul itemType="disc" className="paragraph">
                <li>
                    una parte del montepremi è formata dal valore corrispondente alla "posta partita" (che è specificata con il nome "Posta" nella lobby tra le
                    caratteristiche del tavolo e sul tavolo cliccando sul tasto "info" )
                </li>
                <li>
                    una parte è formata dalla differenza punti (match points) tra il giocatore che ha vinto e il giocatore che ha perso la mano; la differenza punti
                    viene moltiplicata per il valore della "posta unitaria" (che è specificata con il nome "Punto" riportata nella Lobby accanto al valore della Posta
                    e sul tavolo cliccando su "info").
                </li>
                <li>Eventuali "cappotti", ossia il conseguimento di tutti i punti possibili da parte di un solo giocatore</li>
            </ul>
            <p className="paragraph">Con il termine "perdita massima" viene definito l'importo massimo che un giocatore può perdere in una partita.</p>
            <p className="paragraph">Con il termine "vincita massima" viene definito l'importo massimo che un giocatore può vincere in una partita.</p>
            <p className="paragraph">Esempio:</p>
            <p className="paragraph">Montepremi (vincita massima) = <em>posta partita</em> + <em>posta unitaria</em> x differenza punti + eventuali cappotti</p>
            <p className="paragraph">Il montepremi (vincita massima) però non può mai essere superiore alla perdita massima prevista.</p>
            <p className="paragraph">Supponiamo che:</p>
            <p className="paragraph">posta partita = € 10,00</p>
            <p className="paragraph">posta unitaria = € 1,00</p>
            <p className="paragraph">perdita massima = € 20,00</p>
            <p className="paragraph">e che la partita finisca con il seguente punteggio:</p>
            <p className="paragraph">Giocatore A: 33 punti</p>
            <p className="paragraph">Giocatore B: 14 punti</p>
            <p className="paragraph">Si ha:</p>
            <p className="paragraph">Importo perdita giocatore B= €10 + €1 x (33 - 14) = € 29,00</p>
            <p className="paragraph">Poiché € 29,00 è maggiore della perdita massima, la perdita del giocatore B sarà uguale a € 20,00.</p>
            <p className="paragraph">Per le solite motivazioni la vincita del giocatore A sarà pari ad € 20,00.</p>
            <h2 className="titolo2">Rake</h2>
            <p className="paragraph">il rake del concessionario, ossia il margine lordo di sua spettanza, viene determinato come segue:</p>
            <p className="paragraph">montepremi * 2 * % rake.</p>
            <p className="paragraph">Esempio:</p>
            <p className="paragraph">Montepremi: Euro 10</p>
            <p className="paragraph">% di Rake : 5%</p>
            <p className="paragraph">€ 10 * 2 * 0,05 = € 1.0</p>
            <h2 className="titolo2">Vincita effettiva</h2>
            <p className="paragraph">
                Per calcolare la vincita effettiva è necessario sottrarre al montepremi, ottenuto come illustrato nel precedente paragrafo, il rake, ossia il margine
                lordo di spettanza del concessionario.
            </p>
            <p className="paragraph">Montepremi: Euro 10</p>
            <p className="paragraph">rake € 1,00</p>
            <p className="paragraph">Vincita effettiva € 9,00</p>
            <h2 className="titolo2">Inizio della partita</h2>
            <p className="paragraph">
                Nei giochi a partita, quando il tavolo si completa, apparirà a tutti i giocatori seduti il tasto "Sono pronto per iniziare" oltre che un conteggio
                alla rovescia che rappresenta il numero di secondi rimanenti all'inizio della partita. Appena tutti i giocatori seduti avranno cliccato su questo tasto, la
                partita avrà inizio.
            </p>
            <p className="paragraph">Se un giocatore non cliccasse sul tasto "Sono pronto per iniziare", al termine del conto alla rovescia, la partita avrà ugualmente inizio.</p>
            <h2 className="titolo2">Abbandono di una partita in corso</h2>
            <p className="paragraph">
                Nei giochi a partita, se durante una partita un giocatore cliccasse sul tasto "<strong>Abbandona</strong>" e confermasse tale scelta, abbandonerà
                definitivamente la partita senza possibilità di rientrarvi. Ai fini del punteggio saranno applicate le stesse regole previste in caso di tempo
                scaduto.
            </p>
            <h2 className="titolo2">Fine partita</h2>
            <p className="paragraph">A fine partita al centro del tavolo apparirà un pannello, con un conto di secondi alla rovescia, nel quale viene proposto al giocatore:</p>
            <p className="paragraph">se ha un credito sufficiente</p>
            <ul itemType="disc" className="paragraph">
                <li>di fare un'altra partita, oppure</li>
                <li>di alzarsi dal tavolo.</li>
            </ul>
            <p className="paragraph">se non ha un credito sufficiente</p>
            <ul itemType="disc" className="paragraph">
                <li>di fare un versamento sul tavolo, oppure</li>
                <li>di alzarsi dal tavolo.</li>
            </ul>
            <p className="paragraph">Se il giocatore non prende una decisione, al termine del conto alla rovescia verrà fatto automaticamente alzare.</p>
        </ContentTabs>
    );
};

export const ITavoliCashALivelli = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I tavoli cash</h2>
            <h2 className="titolo2">Tavoli cash nei giochi a puntate: Bestia, Chemin de fer, Mazzetti, Poker 5 carte, Sette e mezzo, Telesina.</h2>
            <h2 className="titolo2">Quota della raccolta destinata a montepremi - rake di spettanza del concessionario</h2>
            <p className="paragraph">
                Per la determinazione della quota della raccolta destinata a montepremi e per il calcolo del rake di spettanza del concessionario si rimanda al paragrafo
                specifico dei singoli giochi.
            </p>
            <h2 className="titolo2">Inizio della partita</h2>
            <p className="paragraph">Nei giochi a puntate, si comincerà a giocare appena terminerà la smazzata in corso al momento dell'iscrizione.</p>
            <h2 className="titolo2">Come alzarsi dal tavolo</h2>
            <p className="paragraph">Nei giochi a puntate<strong>,</strong> è possibile alzarsi dal tavolo:</p>
            <ul itemType="disc" className="paragraph">
                <li>alla fine della mano in corso, selezionando l'opzione "esci alla prossima mano"</li>
                <li>in qualsiasi momento cliccando sul tasto "Esci dal tavolo"</li>
            </ul>
            <p className="paragraph">In entrambi i casi, una volta che il giocatore si sarà alzato il suo credito residuo sul tavolo sarà accreditato sul conto di gioco.</p>
            <p className="paragraph">
                <strong><u>Nota bene:</u></strong>
                se un giocatore non clicca sul tasto "Esci dal tavolo" ma abbandona il tavolo in altra maniera (ad esempio chiudendo la finestra del tavolo o
                disconnettendosi ecc.) , non si alzerà dal tavolo ma sarà posto in stato di <strong>sit-out</strong>.
            </p>
            <h2 className="titolo2">Il credito sul tavolo</h2>
            <p className="paragraph">Il credito residuo sul tavolo <strong><u>non</u></strong> può essere diminuito tramite riaccrediti sul proprio conto di gioco.</p>
            <p className="paragraph">Per riaccreditare il credito residuo del tavolo sul proprio conto è necessario alzarsi dal tavolo cliccando sull'apposito tasto.</p>
        </ContentTabs>
    );
};

export const ColpoGrosso = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Colpo Grosso</h2>
            <h2 className="titolo2">Come Funziona</h2>
            <p className="paragraph">Colpo grosso è un torneo con numero di partecipanti prefissato, ma a differenza dei normali sit&amp;go,  il montepremi effettivo non sarà noto al momento dell'iscrizione:ma verrà determinato solo ad inizio torneo, sorteggiato casualmente da una rosa di valori.</p>
            <h2 className="titolo2">Struttura</h2>
            <p className="paragraph">
                I tornei Colpo Grosso saranno a 4 giocatori in diversi tagli di buy in:
                1 €, 5 € e 10 €.
                Saranno disponibili in lobby nell’apposita sezione “Colpo Grosso”, oppure nella lista dei normali sit&amp;go.
            </p>
            <h2 className="titolo2">Montepremi e Rake</h2>
            <p className="paragraph">
                Il montepremi per singolo torneo potrà essere superiore o inferiore al totale della raccolta; l'unico vincolo è il mantenimento, sul complesso dei tornei disputati, di un RTP medio dell'90%.
                La quota destinata al concessionario sarà pari al 10% del buy-in.
            </p>
            <h2 className="titolo2">Tabelle vincite</h2>
            <p className="paragraph">Nelle tabelle sottostanti sono indicate ulteriori informazioni sulla frequenza con cui ricorrono i possibili importi del Montepremi, a seconda del buy-in.</p>
            <table border={1} cellSpacing={5} cellPadding={5} width="387" className="bordi">
                <tbody>
                    <tr>
                        <td className="bordi" width="185" colSpan={2} valign="bottom"><p className="paragraph"><strong>Buy in 1 €</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="185" colSpan={2} valign="bottom"><p className="paragraph"><strong>Buy in 5 €</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="199" colSpan={2} valign="bottom"><p className="paragraph"><strong>Buy in 10 €</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>Premio</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>Probabilità</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>Premio</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>Probabilità</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>Premio</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>Probabilità</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 2,50</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>82050 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 12,50</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>82050 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 25,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>82050 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 5,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>15290 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 25,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>15290 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 50,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>15290 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 10,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>1620 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 50,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>1620 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 100,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>1620 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 25,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>609 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 125,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>609 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 250,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>609 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 50,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>300 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 250,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>300 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 500,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>300 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 100,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>120 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 500,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>120 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 1.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>120 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 1.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>10 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 5.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>10 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 10.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>10 su 100000</strong></p></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 10.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>1 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="79" valign="bottom"><p className="paragraph"><strong>€ 50.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>1 su 100000</strong></p></td>
                        <td className="bordi" width="3" valign="bottom"></td>
                        <td className="bordi" width="94" valign="bottom"><p className="paragraph"><strong>€ 100.000,00</strong></p></td>
                        <td className="bordi" width="106" valign="bottom"><p className="paragraph"><strong>1 su 100000</strong></p></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph"><strong>
                Nel caso in cui il valore del premio estratto sia uguale o superiore a € 1.000,00, il premio verrà distribuito come segue: il primo classificato vince il 70% del montepremi, il secondo il 15 % , il terzo ed il quarto classificato ricevono il 7,5 % ciascuno.</strong>
                <br />
                <br />
                Ad esempio, per  il montepremi da € 1.000,00 la ripartizione sarà quindi:<br />
                1° classificato €  700,00<br />
                2° classificato €  150,00<br />
                3° classificato €  75,00<br />
                4° classificato €  75,00<br />
            </p>
            <h2 className="titolo2">Rimborsi in caso di annullamento</h2>
            <p className="paragraph">
                Se un torneo viene annullato prima del suo inizio, sarà rimborsato a tutti gli iscritti il costo di partecipazione. <br />
                Se un torneo viene annullato dopo il suo inizio, si applicano le seguenti regole: <br />
            </p>
            <ul itemType="disc" className="paragraph">
                <li>nel caso in cui il montepremi sia già stato stabilito, verrà suddiviso tra i partecipanti</li>
                <li>se l’annullamento avviene prima di rendere noto ai giocatori il montepremi finale, verrà restituito l’importo del buy-in</li>
            </ul>
        </ContentTabs>
    );
};

export const Filotto = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Filotto</h2>
            <h2 className="titolo2">Come funziona</h2>
            <p className="paragraph">Triplete è un torneo con un numero fisso di partecipanti, ma a differenza dei normali sit & go, il montepremi sarà interamente assegnato al primo posto. Per classificarti primo e vincere il premio, devi vincere tre partite consecutive nell'evento a cui ti sei iscritto.</p>
            <h2 className="titolo2">Struttura</h2>
            <p className="paragraph">I tornei Triplete saranno tornei da 8 giocatori con diverse dimensioni di buy-in. Saranno disponibili nella lobby nella sezione Triplete, o nell'elenco dei normali sit & go riconoscibili dall'icona della coppa Triplete.</p>
            <h2 className="titolo2">Montepremi e Rake</h2>
            <p className="paragraph !mb-[10px]">Il montepremi di ciascun torneo individuale sarà vinto dall'unico giocatore che vince 3 partite consecutive. I buy-in e i rispettivi rake e montepremi sono:</p>
            <ul>
                <li>Buy-in di €1,00 di cui €0,80 montepremi e €0,20 rake. Montepremi Triplete pari a €6,40;</li>
                <li>Buy-in di €7,00 di cui €6,25 montepremi e €0,75 rake. Montepremi Triplete di €50,00;</li>
                <li>Buy-in di €18,00 di cui €16,25 montepremi e €1,75 rake. Montepremi Triplete pari a €130,00.</li>
            </ul>
            <h2 className="titolo2">Rimborsi in caso di annullamento</h2>
            <p className="paragraph">Il torneo Triplete consiste in tre partite.</p>
            <p className="paragraph">A seconda del momento in cui il torneo viene annullato, si applicano le regole descritte nel regolamento generale dei tornei nel paragrafo che descrive l'annullamento dei tornei per motivi tecnici.</p>
        </ContentTabs>
    );
};

export const Classifiche = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Classifiche</h2>
            <h2 className="titolo2">Classifiche</h2>
            <p className="paragraph">In base ai PT di ogni giocatore, vengono stilate classifiche settimanali, mensili, annuali e storiche.</p>
            <h2 className="titolo2">I Punti torneo (PT)</h2>
            <p className="paragraph">Quando un giocatore partecipa ad un torneo money si aggiudica:</p>
            <p className="paragraph"><strong>Punti Torneo (PT) = 2 x rake + quota montepremi.</strong></p>
            <p className="paragraph">
                Il giocatore ha quindi la certezza di aggiudicarsi un numero di Punti Torneo (PT) pari a 2 x rake e potrà aggiudicarsi altri PT in base alla
                classifica finale del torneo.
            </p>
            <p className="paragraph">Viene infatti stabilito un "montepremi fittizio" in PT pari a:</p>
            <p className="paragraph">numero di iscritti x rake</p>
            <p className="paragraph">che viene ripartito tra i migliori classificati con gli stessi criteri illustrati per i tornei money.</p>
            <h2 className="titolo2">Classifiche speciali</h2>
            <p className="paragraph">Potranno essere create delle classifiche speciali riservate ad un gruppo di tornei.</p>
            <p className="paragraph">
                Quando un torneo concorre ad una classifica speciale, ciò sarà sempre indicato nelle informazioni che appaiono nell'apposita area della lobby una
                volta selezionato il torneo.
            </p>
        </ContentTabs>
    );
};

export const RatingGiocatori = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Rating e livelli di abilità</h2>
            <h2 className="titolo2">Rating e livelli di abilità</h2>
            <p className="paragraph">
                I livelli di abilità ("<strong>rating</strong>") sono un criterio di valutazione dell'abilità del singolo giocatore rispetto all'abilità
                degli altri giocatori: al fine di determinare il livello di abilità si prendono in considerazione i tornei ed i Sit&amp;Go giocati nella sezione money
                negli ultimi 4 mesi.
            </p>
            <p className="paragraph">Esempio: Il rating del Burraco</p>
            <p className="paragraph">Nel Burraco il rating del singolo giocatore è ottenuto calcolando la media dei WP conquistati dal giocatore in ogni singolo torneo (MVP).</p>
            <p className="paragraph">Inoltre, ad ogni torneo viene attribuito un peso che dipende:</p>
            <p className="paragraph">Dai giorni trascorsi dal momento della fine del torneo.</p>
            <p className="paragraph">Il peso (Pg) è uguale a:</p>
            <ul itemType="disc" className="paragraph">
                <li>4 se il torneo si è concluso non più di 30 giorni prima dell'aggiornamento della classifica,</li>
                <li>3 se il torneo si è concluso tra 31 e 60 giorni prima dell'aggiornamento della classifica,</li>
                <li>2 se il torneo si è concluso tra 61 e 90 giorni prima dell'aggiornamento della classifica,</li>
                <li>1 se il torneo si è concluso tra 91 e 120 giorni prima dell'aggiornamento della classifica.</li>
            </ul>
            <p className="paragraph">Dal costo del diritto di partecipazione al torneo.</p>
            <p className="paragraph">Il peso (Pc) è uguale a:</p>
            <ul itemType="disc" className="paragraph">
                <li>5 se il diritto di partecipazione al torneo ha un costo uguale o superiore a €100,00;</li>
                <li>4 se il diritto di partecipazione al torneo ha un costo compreso tra €30,01 e €99,99;</li>
                <li>3 se il diritto di partecipazione al torneo ha un costo compreso tra €10,01 e €30,00;</li>
                <li>2 se il diritto di partecipazione al torneo ha un costo compreso tra €2,01 e €10,00;</li>
                <li>1 se il diritto di partecipazione al torneo ha un costo compreso tra €0,50 e €2,00.</li>
            </ul>
            <p className="paragraph">Dal numero di smazzate giocate per ogni turno del torneo .</p>
            <p className="paragraph">Il peso (Ps) è uguale al numero di smazzate previste per ogni turno.</p>
            <p className="paragraph">Dal numero di turni giocati dal singolo partecipante al torneo.</p>
            <p className="paragraph">Il peso (Pn) è uguale al numero di turni giocati dal singolo partecipante al torneo.</p>
            <p className="paragraph">Il peso totale di un torneo (Pt) è calcolato dal prodotto dei singoli pesi:</p>
            <p className="paragraph">Pt = Pg x Pc x Ps x Pn</p>
            <p className="paragraph"><br />Il rating del giocatore è la media pesata dei MVP dei singoli tornei, ovvero:</p>
            <p className="paragraph">R = somma [MVP*Pt] / somma[Pt]</p>
            <h2 className="titolo2">Determinazione del livello di abilità</h2>
            <p className="paragraph">
                Per fornire una rappresentazione sintetica ai giocatori del loro livello di abilità rispetto all'abilità degli altri giocatori, sono definite 5
                categorie di abilità decrescente:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>1° Livello (i giocatori con rating compreso nel migliore 20%),</li>
                <li>2° Livello (i giocatori con rating compreso nel secondo 20%),</li>
                <li>3° Livello (i giocatori con rating compreso nel terzo 20%),</li>
                <li>4° Livello (i giocatori con rating compreso nel quarto 20%),</li>
                <li>5° Livello (i giocatori con rating compreso nel quinto e ultimo 20%),</li>
            </ul>
            <p className="paragraph">Per essere inserito in una di queste categorie un giocatore deve aver effettuato almeno 7 tornei, altrimenti è definito ESORDIENTE.</p>
            <p className="paragraph">L'aggiornamento dei livelli di abilità avviene giornalmente, normalmente durante la pausa notturna.</p>
            <p className="paragraph">Il rating è uno dei criteri previsti per l'accesso a tornei riservati o a tornei "ad invito"</p>
            <p className="paragraph">&nbsp;	</p>
        </ContentTabs>
    );
};

export const InfomazioniAiGiocatori = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Informazioni ai giocatori</h2>
            <h2 className="titolo2">Informazioni ai giocatori</h2>
            <p className="paragraph">
                Ciascun torneo ha delle proprie regole specifiche con le quali vengono fissati i parametri del gioco come il costo di partecipazione, la struttura del
                torneo, i tempi a disposizione, eventuali varianti di gioco ecc.
            </p>
            <p className="paragraph">Nelle regole di ciascun gioco sono riepilogati i parametri che devono essere predeterminati e noti ai giocatori.</p>
            <p className="paragraph">
                I giocatori possono e devono prendere visione delle regole del singolo torneo prima di partecipare: le principali regole di un torneo sono riepilogate
                nell'elenco dei tornei e dei tavoli della lobby.
            </p>
            <p className="paragraph">Le altre caratteristiche sono riportate nelle informazioni che compaiono sulla parte destra della lobby quando si clicca su un torneo.</p>
            <p className="paragraph">Per i tornei, inoltre, si possono visualizzare ulteriori informazioni cliccando sul tasto "lobby del torneo".</p>
            <p className="paragraph">Per il significato di sigle e abbreviazioni vedere il paragrafo "Significati di sigle e abbreviazioni usate".</p>
            <h2 className="titolo2">Avvisi di gioco</h2>
            <p className="paragraph">
                Sul tavolo da gioco sono previsti numerosi "<strong>avvisi a scomparsa automatica</strong>" (cioè avvisi che spariscono automaticamente pochi attimi
                dopo la loro comparsa). Ad esempio, se il giocatore cerca di fare una giocata o una dichiarazione non ammessa dalle regole del gioco apparirà un
                avviso del tipo "Giocata non ammessa".
            </p>
            <p className="paragraph">
                In rari casi possono anche apparire "<strong>avvisi a conferma</strong>" ( cioè il gioco non va avanti se il giocatore non da una risposta
                all'avviso).
            </p>
        </ContentTabs>
    );
};

export const SigleEdAbbreviazioni = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Sigle ed abbreviazioni</h2>
            <h2 className="titolo2">Elenco dei tornei programmati e tavoli cash</h2>
            <p className="paragraph"><strong>data: </strong>è la data e l'orario di inizio del torneo (ad esempio "26 giu 2011 20:30") ;</p>
            <p className="paragraph">
                <strong>descrizione: </strong>è la descrizione della struttura del torneo e delle sue principali caratteristiche, come l'eventuale variante, eventuali regole
                opzionali, l'eventuale importo garantito o bonus e l'eventuale password necessaria (per il significato delle abbreviazioni vedere sotto);
            </p>
            <p className="paragraph"><strong>durata:</strong></p>
            <ul itemType="disc" className="paragraph">
                <li>
                    nei tornei che sono suddivisi in turni, sono indicati i minuti a disposizione di ciascuna linea (singolo giocatore o coppia) per completare il turno
                    seguiti, tra parentesi, dal numero di turni x il numero di smazzate (ad esempio "14 min (2 x 3 sm)") o dal numero di turni per i punti necessari a
                    vincere la partita (ad esempio "14 min (2 x 11 pt)");
                </li>
                <li>nei tornei che non sono suddivisi in turni, appare una delle seguenti voci che sintetizzano la durata della partita:</li>
                <ul itemType="circle">
                    <li>lento,</li>
                    <li>normale,</li>
                    <li>turbo,</li>
                    <li>superturbo.</li>
                </ul>
            </ul>
            <p className="paragraph"><strong>costo:</strong> è il costo del diritto di partecipazione;</p>
            <p className="paragraph">
                <strong>iscritti:</strong> è il numero degli iscritti al torneo rapportato al numero massimo di iscrizioni (ad esempio "126/500" significa 126 giocatori iscritti su
                500 giocatori massimi possibili);
            </p>
            <p className="paragraph"><strong>rake:</strong> quota della raccolta spettante al concessionario;</p>
            <p className="paragraph"><strong>stato:</strong> è lo status in cui si trova il torneo e può assumere i seguenti valori:</p>
            <ul itemType="disc" className="paragraph">
                <li><strong>Annunciato</strong><strong>: </strong>le iscrizioni non sono ancora aperte;</li>
                <li><strong>Iscrizione</strong><strong>: </strong>è possibile iscriversi;</li>
                <li><strong>Riservato</strong><strong>: </strong>il torneo è riservato ad alcuni giocatori;</li>
                <li><strong>Chiuso</strong>: la fase di iscrizione è terminata;</li>
                <li><strong>In corso</strong>: il torneo è in corso;</li>
                <li><strong>Terminato</strong><strong>: </strong>il torneo è terminato;</li>
                <li><strong>Annullato</strong><strong>: </strong>il torneo è stato annullato.</li>
            </ul>
            <h2 className="titolo2">Elenco dei tornei Sit&amp;go:</h2>
            <p className="paragraph">I codici sono uguali a quelli dei tornei programmati, con le seguenti due differenze:</p>
            <ul itemType="disc" className="paragraph">
                <li>codice: (al posto di "data") codice unico attribuito al torneo dal Concessionario ;</li>
                <li>iscritti: è il numero di partecipanti al quale il torneo avrà inizio.</li>
            </ul>
            <h2 className="titolo2">Colore dei tornei negli elenchi:</h2>
            <p className="paragraph">Negli elenchi i tornei possono assumere, in ordine prioritario, i seguenti colori:</p>
            <table border={1} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td valign="top" width="86"><p className="paragraph"><strong>rosso</strong></p></td>
                        <td valign="top" width="531"><p className="paragraph">torneo garantito</p></td>
                    </tr>
                    <tr>
                        <td valign="top" width="86"><p className="paragraph"><strong>arancione</strong></p></td>
                        <td valign="top" width="531"><p className="paragraph">il torneo partecipa ad una classifica speciale</p></td>
                    </tr>
                    <tr>
                        <td valign="top" width="86" height="21"><p className="paragraph"><strong>verde</strong></p></td>
                        <td valign="top" width="531" height="21"><p className="paragraph">torneo gratuito con premi in bonus ("Freerolls")</p></td>
                    </tr>
                    <tr>
                        <td valign="top" width="86"><p className="paragraph"><strong>fucsia</strong></p></td>
                        <td valign="top" width="531"><p className="paragraph">torneo satellite di altro torneo</p></td>
                    </tr>
                    <tr>
                        <td valign="top" width="86"><p className="paragraph"><strong>blu</strong></p></td>
                        <td valign="top" width="531"><p className="paragraph">è consentito acquistare il diritto di partecipazione con mezzi diversi dal denaro reale (VIP Points, Token...)</p></td>
                    </tr>
                </tbody>
            </table>

            <h2 className="titolo2">Sfera colorata sul lato destro degli elenchi</h2>
            <p className="paragraph">Se appare una sfera colorata significa che il giocatore è in qualche modo attivo nel torneo e più precisamente:</p>
            <p className="paragraph">Per i tornei "money":</p>
            <ul itemType="disc" className="paragraph">
                <li>colore verde: l'iscrizione è stata approvata da AAMS</li>
                <li>colore marrone: l'iscrizione non è ancora stata approvata da AAMS</li>
            </ul>
            <p className="paragraph">Per i tornei di allenamento:</p>
            <ul itemType="disc" className="paragraph">
                <li>colore verde: l'iscrizione è stata confermata</li>
                <li>
                    colore marrone: l'iscrizione non è ancora stata confermata, il giocatore deve cliccare sul tasto "conferma iscrizione" che appare 4 minuti prima
                    dell'inizio del torneo.
                </li>
            </ul>
            <h2 className="titolo2">Informazioni sul torneo</h2>
            <p className="paragraph">Quando si clicca su un torneo, nell'apposita area della lobby situata sulla parte destra appaiono le seguenti informazioni:</p>
            <p className="paragraph"><strong>Nome del torneo</strong></p>
            <h2 className="titolo2">Costo dettagliato del torneo</h2>
            <p className="paragraph">Il costo di partecipazione è suddiviso in:</p>
            <ul itemType="disc" className="paragraph">
                <li>quota che andrà a formare il montepremi, mai inferiore all'80% della raccolta</li>
                <li>quota di spettanza del Concessionario,</li>
            </ul>
            <p className="paragraph">
                Ad esempio, in un torneo con un costo di partecipazione di €10,00 di cui €8,00 vanno a montepremi e €2,00 sono di spettanza del
                concessionario, sarà visualizzato:
            </p>
            <p className="paragraph">€ 10,00 (€ 8,00 + € 2,00).</p>
            <p className="paragraph">Viene inoltre indicato l'eventuale importo garantito.</p>
            <p className="paragraph">Codice Concessionario del torneo e codice AAMS del torneo</p>
            <p className="paragraph">Codice unico attribuito al torneo dal Concessionario e, se il torneo è money, codice unico attribuito al torneo da AAMS.</p>
            <h2 className="titolo2">Descrizione dettagliata (senza abbreviazioni) del tipo di torneo</h2>
            <p className="paragraph"><strong>Altre informazioni non sempre presenti</strong></p>
            <ul itemType="disc" className="paragraph">
                <li>eventuale torneo del quale il torneo selezionato è satellite,</li>
                <li>eventuali classifiche speciali alle quali è abbinato il torneo,</li>
                <li>eventuali livelli VIP ai quali sono riservate le iscrizioni,</li>
                <li>eventuale obbligatorietà di conferma,</li>
            </ul>
            <h2 className="titolo2"><strong>Tempi a disposizione del giocatore</strong></h2>
            <ul itemType="disc" className="paragraph">
                <li>tempo giocata ("timeout"): il tempo a disposizione del giocatore per terminare una giocata,</li>
                <li>tempo partita, se previsto: il tempo a disposizione del giocatore per terminare un turno,</li>
            </ul>
            <p className="paragraph"><strong>I tempi e lo stato del torneo</strong></p>
            <p className="paragraph">A seconda dello stato del torneo saranno visualizzati:</p>
            <ul itemType="disc" className="paragraph">
                <li>quanto manca all'apertura delle iscrizioni,</li>
                <li>quanto manca all'inizio del torneo,</li>
                <li>il numero del turno in corso o del livello in corso,</li>
                <li>i giocatori rimasti in gara.</li>
            </ul>
            <h2 className="titolo2">La Lobby del torneo</h2>
            <p className="paragraph">Quando si clicca sul tasto "lobby del torneo" si apre una schermata più ampia con altre informazioni, tra le quali:</p>
            <h2 className="titolo2">L'elenco degli iscritti al torneo con la classifica se il torneo è in corso</h2>
            <p className="paragraph">
                nella fase di iscrizione, il nickname appare in blu se è già stato confermato o in nero se è ancora in attesa di approvazione AAMS nei
                tornei money, o se deve ancora confermarsi nei torneo di allenamento,
            </p>
            <h2 className="titolo2">La ripartizione del montepremi</h2>
            <p className="paragraph">
                Se le iscrizioni sono chiuse la ripartizione del montepremi è definitiva, altrimenti apparirà la ripartizione con il numero degli iscritti e la
                possibilità di simulare qualsiasi numero di iscritti inferiore o uguale al numero massimo di iscritti.
            </p>
            <h2 className="titolo2">La struttura del torneo</h2>
            <p className="paragraph">Se il torneo è a livelli, apparirà l'elenco dei livelli con le tempistiche.</p>
            <h2 className="titolo2">La lista di eventuali tornei satelliti al torneo stesso</h2>
            <p className="paragraph">Cliccando su un torneo dell'elenco, il giocatore sarà spostato alla lobby di quel torneo.</p>
            <h2 className="titolo2">Abbreviazioni usate</h2>
            <h2 className="titolo2">Tipo di torneo</h2>
            <ul itemType="disc" className="paragraph">
                <li><strong>KO: </strong>torneo a eliminazione diretta</li>
                <li><strong>Danese: </strong>torneo a girone Danese</li>
                <li><strong>DanEl: </strong>Danese a eliminazione parziale</li>
                <li><strong>DanKO8: </strong>Danese con KO tra i migliori 8 classificati</li>
                <li><strong>DanELKO4: </strong>Danese a eliminazione parziale con KO tra i migliori 4 classificati</li>
            </ul>
            <h2 className="titolo2">Numero di giocatori al tavolo</h2>
            <ul itemType="disc" className="paragraph">
                <li><strong>2 gioc</strong>: partite "testa a testa"</li>
                <li><strong>4in2</strong>: partite 4 in 2</li>
            </ul>
            <p className="paragraph"><strong>VP: </strong>VIP points</p>
            <p className="paragraph"><strong>MP: </strong>Match points</p>
            <p className="paragraph"><strong>PW: </strong>se appare nella descrizione dell'elenco, è necessario inserire la password corretta per iscriversi</p>
            <p className="paragraph"><strong>PT: </strong>Punti torneo</p>
            <p className="paragraph"><strong>WP: </strong>Win points</p>
        </ContentTabs>
    );
};

export const RegoleDellaCasa = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Regole della Casa</h2>
            <h2 className="titolo2">Regole della Chat</h2>
            <p className="paragraph">La chat è uno strumento di intrattenimento messo a disposizione di tutti gli utenti. All’interno della chat è possibile confrontarsi, discutere del Gioco e delle proprie strategie, conoscere altri giocatori, chiedere informazioni e ricevere assistenza di primo livello. Per questo motivo il clima deve essere sereno e tranquillo, e tutti gli utenti sono tenuti ad utilizzare un linguaggio appropriato e ad evitare ogni tipo di polemica. </p>
            <p className="paragraph">Per ogni altro tipo di comunicazione sono messi a disposizione di ogni utente due strumenti: </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>HELP </strong></li>
                <li><strong><a href="mailto:mailto:snaicard@snai.it">snaicard@snai.it</a></strong></li>
            </ul>
            <p className="paragraph">
                Il corretto funzionamento della chat è affidato a più moderatori (denominati “Staff”), i quali vigilano affinché ogni dibattito si svolga in modo civile e attinente al presente Regolamento<br />
                In tutti i loro interventi, gli Staff si ispireranno a criteri di tolleranza e collaborazione con gli utenti.
                Difficilmente gli Staff potranno entrare nel merito di un dibattito e sicuramente non lo potranno fare in chat. Pertanto:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>è dovere dello Staff&nbsp;invitare sempre gli Utenti alla moderazione ed indirizzarli in HELP per ogni tipo di problematica, lamentela e/o approfondimento.</li>
                <li>è dovere degli Utenti mantenere in chat un clima sereno e tranquillo, avvalendosi del servizio HELP o del servizio Assistenza online messo a disposizione da SNAI  per ogni tipo di segnalazione, lamentela e approfondimento.</li>
            </ul>
            <p className="paragraph">
                La sospensione del servizio chat verrà prolungata in base alla frequenza con cui uno stesso utente violerà il Regolamento. <br />
                La chat è a disposizione degli utenti per intrattenersi e ricevere assistenza di primo livello in caso di bisogno. Tutto ciò che riguarda problematiche più ampie e approfondimenti di ogni genere, dovrà essere trattato nelle sezioni sopraelencate.
            </p>
            <p className="paragraph">Gli staff potranno effettuare dei controlli sulla chat per verificare eventuali scorrettezze di gioco, volgarità, offese personali e quant'altro previsto nei divieti e in generale per tutelare gli utenti e l'attività del sito, e potranno intervenire in diversi modi (in base alla gravità e alla recidività dei comportamenti scorretti): </p>
            <ul itemType="disc" className="paragraph">
                <li>semplice richiamo, pubblico o privato, dell'utente interessato</li>
                <li>ammonizione</li>
                <li>sospensione del servizio chat per brevi periodi </li>
                <li>sospensione del servizio chat per lunghi periodi </li>
                <li>sospensione del servizio chat con effetto permanente </li>
                <li>sospensione dell’utente</li>
                <li>banning anche immediato dell’utente</li></ul>
            <p className="paragraph">Le seguenti azioni, in particolare, sono considerate violazioni del regolamento:</p>
            <ul itemType="disc" className="paragraph">
                <li>Insultare o utilizzare un linguaggio volgare e/o offensivo a discapito di un qualsiasi giocatore, visitatore o dipendente;</li>
                <li>Bestemmiare;</li>
                <li>Utilizzare un linguaggio osceno;</li>
                <li>Utilizzare osservazioni a sfondo razzista ;</li>
                <li>Intimidire un giocatore ad abbandonare il gioco e/o il sito;</li>
                <li>Promuovere altri siti e/o iniziative e/o attività economiche che esulino dalle attività promosse da SNAI giochi;</li>
                <li>Ripetere lo stesso messaggio molte volte o comunque intasare la chat con continui messaggi;</li>
                <li>Scrivere in maiuscolo</li>
                <li>Utilizzare dialetto</li>
                <li>Provocazioni</li>
            </ul>
            <p className="paragraph">Si sottolinea che per esporre lamentele, polemiche e reclami di ogni genere inerenti il Sito, il Gioco, lo Staff e gli Utenti, è previsto ed obbligatorio l’utilizzo della funzione HELP e/o l’invio di una segnalazione all’indirizzo <strong><a href="mailto:mailto:snaicard@snai.it">snaicard@snai.it</a></strong>.</p>
            <p className="paragraph">Pertanto, sarà considerato violazione del Regolamento:</p>
            <ul itemType="disc" className="paragraph">
                <li>Mettere in dubbio la regolarità del gioco, ed invitare gli altri utenti a farlo</li>
                <li>Mettere in dubbio la professionalità dello Staff </li>
                <li>Affrontare problematiche che esulino dall’Assistenza di Primo Livello </li>
                <li>Lamentarsi troppo a lungo di una partita sfortunata</li>
                <li>Esporre lamentele, polemiche e reclami di ogni genere inerenti il Sito, il Gioco, lo Staff e gli Utenti. </li>
            </ul>
            <h2 className="titolo2">Durante il gioco</h2>
            <p className="paragraph">Durante il gioco è considerato infrazione del regolamento l’utilizzo di qualsiasi espressione che possa compromettere lo svolgimento e l’esito dello stesso, ed in particolare: </p>
            <ul itemType="disc" className="paragraph">
                <li>Dare indicazioni in chat sul proprio punteggio prima che sia apparsa la classifica finale</li>
                <li>Rivelare le carte in possesso o quelle già scartate </li>
                <li>Discutere di una partita in corso </li>
                <li>Indurre un giocatore ad agire in un certo modo</li>
                <li>Intimidire un giocatore ad abbandonare il tavolo </li>
                <li>Falsare l’esito di una partita accordandosi con l’avversario </li>
            </ul>
            <p className="paragraph">
                Nel Burraco testa a testa il 50% dei giocatori perde e il 5% circa (1 partita su 20) perde in maniera molto sfortunata. Su 1.000 partite giocate, 50 giocatori potrebbero quindi manifestare il loro disappunto in chat. Una piccolo sfogo è consentito ma se fossero accettate continue lamentele la chat non raggiungerebbe lo scopo per il quale è stata creata.<br /><br />
                Si precisa quindi che: gli Staff inviteranno gli utenti che richiedono assistenza ad accedere alla chat help dove potranno essere aiutati direttamente senza disturbare gli altri utenti o, in alternativa, ad inviare una email cliccando sull’apposito tasto “contatti” in alto a destra. Pertanto esporre lunghe lamentele in chat ignorando i suggerimenti dello Staff ad esporre il reclamo in altra sede verrà considerato violazione del regolamento.
            </p>
            <h2 className="titolo2">Disclaimer</h2>
            <p className="paragraph">
                Ogni messaggio nelle chat del sito esprime soltanto il punto di vista di chi l'ha scritto e pertanto ne gli amministratori ne i moderatori del sito potranno essere ritenuti responsabili dei suddetti messaggi, né potranno rispondere di eventuali danni anche morali causati dall'uso di questo sito o dei relativi servizi.<br /><br />
                Il controllo della chat è stato affidato ad un’Azienda Esterna che si è avvalsa della collaborazione di alcuni moderatori per garantire il rispetto del suddetto regolamento;
                si specifica pertanto che lo Staff, esprime il punto di vista di SNAI limitatamente al gioco del Burraco e non è alle dipendenze di SNAI.
            </p>
            <h2 className="titolo2">Comportamento di Gioco</h2>
            <p className="paragraph">
                E’ necessario precisare che è consentita qualsiasi strategia di gioco non collusiva, di fatto qualsiasi azione di gioco consentita dal software è da ritenersi lecita.
                Esempio di comportamenti corretti:
            </p>
            <ul itemType="disc" className="paragraph">
                <li>è lecito non chiudere per far scadere il tempo all'avversario e conseguire in questo modo più punti tuttavia invitiamo i giocatori ad un comportamento sportivo.</li>
                <li>Benché consapevoli di come la riflessione sia parte integrante di ogni gioco, invitiamo tutti i nostri giocatori a non rallentare senza motivo l'andamento delle partite per far innervosire l'avversario.</li>
                <li>Nel caso in cui l'avversario che sta perdendo regali punti sarebbe opportuno segnalare l'accaduto allo Staff in modo da monitorare il comportamento del giocatore.</li>
            </ul>
            <h2 className="titolo2">Ammonizioni e provvedimenti automatici nei tornei di allenamento</h2>
            <p className="paragraph">Nei tornei di allenamento, per evitare che un utente si iscriva a uno o più tornei e poi non giochi o che abbandoni il torneo in caso di punteggio basso, il sistema assegna automaticamente delle ammonizioni in caso di timeout o in particolari casi di tempo scaduto nei tornei a coppie. In quest'ultimo caso il compagno può abbandonare il torneo senza conseguenze e la sua partecipazione al torneo sarà considerata nulla.</p>
            <p className="paragraph">A fine partita, nel resoconto che appare sul tavolo, sono sempre visualizzati tutti i provvedimenti presi automaticamente dal sistema.</p>
            <p className="paragraph">
                Nella scheda personale di ciascun utente, visualizzabile da tutti i giocatori cliccando sul nickname, appare il numero effettivo delle ammonizioni e cioè: numero di ammonizioni ricevute - 1 ammonizione bonus per ogni 30 turni di torneo completati.<br /><br />
                Qualora un giocatore fosse trovato colpevole di comportamento scorretto su segnalazione da parte di altri giocatori o per controlli su iniziativa del Concessionario, o in caso di numerose ammonizioni automatiche effettive, potranno essere applicate delle sanzioni, sulla base della gravità della scorrettezza commessa, tenendo anche conto della “reiterazione” del fatto imputato.
            </p>
            <h2 className="titolo2">Utilizzo di dispositivi automatizzati o sistemi computerizzati complementari</h2>
            <p className="paragraph">
                Ai giocatori è espressamente vietato l'utilizzo di sistemi automatizzati (BOT) sul sito di gioco al fine di ottenere agevolazioni nelle decisioni di gioco e/o ottonere informazioni statistiche sugli avversari.
                <br />Non esistono sistemi di risoluzione automatica delle situazioni gioco che possano influenzare il risultato della partita.
                Per i  giochi attualmente presenti non esistono software di tracking che permettano  l'ottenimento di informazioni sui giocatori con i quali si sta giocando.
            </p>
        </ContentTabs>
    );
};

export const GestioneDiEventiCasuali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Gestione di eventi casuali</h2>
            <h2 className="titolo2">Gestione di eventi casuali</h2>
            <p className="paragraph">Nel corso di una partita è necessario stabilire casualmente il mazzo di carte da distribuire.</p>
            <p className="paragraph">Nel corso di un torneo, inoltre, è necessario stabilire casualmente:</p>
            <ul itemType="disc" className="paragraph">
                <li>gli accoppiamenti al primo turno,</li>
                <li>le posizioni al tavolo;</li>
                <li>ripescaggi e accoppiamenti ai turni successivi in caso di ex-aequo.</li>
            </ul>
            <p className="paragraph">
                E' necessario precisare che la ripartizione delle vincite <strong><u>non verrà in nessun caso stabilita casualmente </u></strong>e che le somme vinte
                saranno sempre suddivise in ugual misura tra eventuali ex equo.
                <br />
                A inizio torneo viene generato casualmente un elenco dei partecipanti.
                <br />
                Questo elenco viene utilizzato:
            </p>
            <p className="paragraph">
                • per determinare gli accoppiamenti al primo turno (ad esempio, in un torneo individuale, il primo dell'elenco siede al tavolo 1 in nord, il secondo
                al tavolo 1 in est, il terzo al tavolo 2 in nord e cosi via),
            </p>
            <p className="paragraph">
                • per determinare chi prevale in caso di ex-aequo (ad esempio, in caso di ex equo tra il terzo dell'elenco e il sesto dell'elenco, sarà
                ripescato il terzo dell'elenco).
            </p>
            <h2 className="titolo2">Il sistema di generazione di numeri casuali (RNG)</h2>
            <p className="paragraph">
                La piattaforma di gioco si avvale di un sistema di generazione di numeri casuali e mescolamento delle carte tecnicamente all'avanguardia. Il sistema è
                stato testato e certificato dal Dipartimento di Matematica e Informatica dell'Università degli Studi di Cagliari ed è basato su un generatore di
                numeri casuali hardware in grado di campionare un fenomeno fisico naturale dall'esito imprevedibile: la riflessione o la trasmissione di un fotone da parte
                di uno specchio semitrasparente.
                <br />
                <br />
                Per chi desiderasse approfondire di più l'argomento sono allegati alla fine del presente documento:
            </p>
            <ul itemType="disc" className="paragraph">
                <li><a href="#">Attestazione del Dipartimento di Matematica e Informatica dell'Università degli Studi di Cagliari</a></li>
                <li><a href="#">Relazione tecnica del Dipartimento di Matematica e Informatica dell'Università degli Studi di Cagliari</a></li>
                <li><a href="#">Certificato di conformità rilasciato dall'Ufficio Federale di Meteorologia della Svizzera.</a></li>
            </ul>
        </ContentTabs>
    );
};

export const MeccanismiDiGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - I meccanismi di gioco</h2>
            <h2 className="titolo2">Il controllo dei tempi</h2>
            <p className="paragraph">Il controllo dei tempi è interamente demandato al server di gioco che comunica ai giocatori i loro tempi residui.</p>
            <p className="paragraph">
                Il tempo di un giocatore comincia a decorrere dal momento in cui il server riceve dal giocatore precedente la comunicazione della fine della sua fase di
                gioco (ad esempio, nel Burraco, il tempo di un giocatore comincia a decorre quando il server riceve la comunicazione dello scarto dal giocatore
                precedente).
            </p>
            <p className="paragraph">
                Quando un giocatore termina la sua fase di gioco, il tempo visualizzato si ferma immediatamente ma il tempo effettivo continuerà a scorrere fino a che
                il server non riceverà la comunicazione di avvenuta fine della fase di gioco: il tempo visualizzato sarà aggiornato non appena il client
                riceverà dal server la conferma dell'azione del giocatore.
            </p>
            <h2 className="titolo2">Disconnessioni e avvisi</h2>
            <p className="paragraph">
                Se il client si accorge di non essere connesso al server, sulla lobby o su un tavolo, appare il messaggio: "Tentativo di riconnessione in corso". Dopo aver
                fatto apparire questo messaggio il sistema continua ad inviare richieste al server fino ad un tempo massimo prestabilito dopo il quale si visualizza il
                messaggio "Tentativo di riconnessione fallito - controlla la tua connessione". A questo punto il sistema interromperà l'invio di ulteriori richieste
                automatiche.
            </p>
            <h2 className="titolo2">Congruenza dei dati trasmessi</h2>
            <p className="paragraph">
                Il software residente sul server effettua il controllo di ogni singola azione trasmessa dai giocatori. Se un giocatore, per un qualsiasi motivo, trasmette
                al server dati incompleti o errati o impossibili in accordo alle regole del gioco il server non memorizza i dati trasmessi dal giocatore e riallinea il
                giocatore con i dati di gioco immediatamente precedenti all'azione di gioco non accettata dal server.
            </p>
        </ContentTabs>
    );
};

export const AccreditoDelleVincite = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Accredito delle vincite</h2>
            <h2 className="titolo2">Accredito delle vincite</h2>
            <p className="paragraph">Le vincite dei tornei money e le vincite bonus vengono accreditate sul Conto di Gioco entro pochi minuti dal termine del torneo, mentre le vincite bonus entro 3 giorni lavorativi.</p>
        </ContentTabs>
    );
};

export const VipLevelsAndFreepoints = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Livelli VIP e VIP Points</h2>
            <p className="paragraph">Quando un giocatore partecipa a un torneo o a un tavolo cash, per ogni diritto di partecipazione acquistato, scommessa o partita giocata, indipendentemente dal fatto che abbia vinto o perso, gli viene assegnato un certo numero di VIP Points (VP). A seconda del numero di VP raggiunti, il giocatore viene inserito in un determinato Livello VIP secondo i livelli elencati di seguito:</p>
            <h2 className="titolo2">AMBRA </h2>
            <p className="paragraph">Nel mese precedente o nel mese corrente devi aver accumulato da 0 a 500 VP</p>
            <ul>
                <li className="!mb-[10px]">Guadagni 20 VP per ogni €1 di rake</li>
                <li>Una volta raggiunti 500 VP, passi al livello TOPAZIO</li>
            </ul>
            <h2 className="titolo2">TOPAZIO </h2>
            <p className="paragraph">Nel mese precedente o nel mese corrente devi aver accumulato da 500 a 2500 VP  </p>
            <ul>
                <li className="!mb-[10px]">Guadagni 25 VP per ogni €1 di rake</li>
                <li>Una volta raggiunti 2500 VP, passi al livello ZAFFIRO</li>
            </ul>
            <h2 className="titolo2">ZAFFIRO</h2>
            <p className="paragraph">Nel mese precedente o nel mese corrente devi aver accumulato da 2500 a 8000 VP</p>
            <ul>
                <li className="!mb-[10px]">Guadagni 30 VP per ogni €1 di rake  </li>
                <li>Una volta raggiunti 8000 VP, passi al livello SMERALDO</li>
            </ul>
            <h2 className="titolo2">SMERALDO  </h2>
            <p className="paragraph">Nel mese precedente o nel mese corrente devi aver accumulato da 8000 a 25000 VP </p>
            <ul>
                <li className="!mb-[10px]">Guadagni 40 VP per ogni €1 di rake  </li>
                <li>Una volta raggiunti 25000 VP, passi al livello RUBINO</li>
            </ul>
            <h2 className="titolo2">RUBINO </h2>
            <p className="paragraph">Nel mese precedente o nel mese corrente devi aver accumulato da 25000 a 60000 VP </p>
            <ul>
                <li className="!mb-[10px]">Guadagni 50 VP per ogni €1 di rake</li>
                <li>Una volta raggiunti 60000 VP, passi al livello DIAMANTE </li>
            </ul>
            <h2 className="titolo2">DIAMANTE </h2>
            <p className="paragraph">Nel mese precedente o nel mese corrente devi aver accumulato almeno 60000 VP </p>
            <ul>
                <li className="!mb-[10px]">Guadagni 60 VP per ogni €1 di rake</li>
                <li>Una volta raggiunti 60000 VP, rimani al livello DIAMANTE</li>
            </ul>
            <h2 className="titolo2">Calcolo dei Livelli VIP e degli VP</h2>
            <p className="paragraph">Il sistema calcola il livello VIP prendendo in considerazione il valore più alto tra gli VP del mese precedente e gli VP del mese corrente. Gli VP sono calcolati e assegnati come segue:</p>
            <ul>
                <li className="!mb-[10px]">Nei tornei: quando il torneo inizia </li>
                <li>Ai tavoli cash: quando il giocatore si alza dal tavolo </li>
            </ul>
            <p className="paragraph">I livelli VIP vengono aggiornati in tempo reale in base agli VP assegnati.</p>
            <h2 className="titolo2">VIP Points</h2>
            <p className="paragraph">Come già accennato, quando un giocatore partecipa a un torneo con denaro reale, gli viene assegnato un certo numero di VP, proporzionale al rake generato e al livello VIP raggiunto. Cliccando sul pulsante "Il mio profilo" verranno visualizzati i seguenti dettagli: </p>
            <ul>
                <li className="!mb-[10px]">Il saldo di cassa dell'account,</li>
                <li className="!mb-[10px]">Il saldo degli VP disponibili, </li>
                <li className="!mb-[10px]">I token disponibili, </li>
                <li className="!mb-[10px]"><p className="paragraph !mb-[10px]">Il livello VIP corrente.</p>
                    <div className="pl-[40px]">
                        <p className="paragraph !mb-[10px]">Gli VP possono:  </p>
                        <ul style={{ marginLeft: "20px"}}>
                            <li className="!mb-[10px]">Essere utilizzati per pagare la quota di iscrizione ad alcuni tornei con denaro reale </li>
                            <li>Essere convertiti in Bonus validi per giocare a tutti i giochi.</li>
                        </ul>
                    </div>
                </li>
            </ul>

            <table className="bordi" border={1} cellPadding="5" width="221">
                <thead>
                    <tr>
                        <th>PUNTI</th>
                        <th>BONUSES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">5,500 </span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€10</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">10,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€ 20.00</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">24,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€50</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">45,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€ 100.00</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">100,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€ 250</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">190,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€ 500.00</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">360,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€ 1.000</span></td>
                    </tr>
                    <tr>
                        <td className="bordi" width="50%"><span className="text-center w-full block">1,000,000</span></td>
                        <td className="bordi" width="50%"><span className="text-center w-full block">€3,000</span></td>
                    </tr>
                </tbody>
            </table>
            <p className="paragraph !mb-[10px]">Usare la tabella è semplice: con 5500 VP puoi richiedere un Bonus di €10, con 10000 VP un Bonus di €20, e così via. I Bonus saranno accreditati sul conto di gioco entro 7 giorni. Nel caso in cui tutti o parte degli VP disponibili per un giocatore vengano convertiti in Bonus, il livello VIP acquisito nel mese corrente rimane invariato. </p>
            <p className="paragraph !mb-[10px]">Esempio:  </p>
            <p className="paragraph !mb-[10px]">Un giocatore con un livello VIP "ZAFFIRO" che ha a disposizione 6500 VP decide di convertire 5500 VP in un bonus di €10. Una volta convertiti, il giocatore avrà a disposizione 1000 VP residui (da utilizzare per acquistare diritti di partecipazione a qualsiasi torneo) ma il suo livello VIP rimane "ZAFFIRO".</p>
        </ContentTabs>
    );
};

export const RimborsiPerDisconnessioni = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Rimborsi per disconnessioni</h2>
            <h2 className="titolo2">Rimborsi per disconnessioni</h2>
            <p className="paragraph">
                Il rimborso della quota d'iscrizione è previsto solo in caso di interruzione di sessioni di gioco imputabili alla Piattaforma. Non sono previsti
                rimborsi per disconnessioni che dipendono dalla connessione dei singoli utenti e che non siano causate da malfunzionamenti dei server del concessionario.
            </p>
        </ContentTabs>
    );
};

export const CollusionEDumping = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Collusion e Dumping</h2>
            <h2 className="titolo2">Dumping</h2>
            <p className="paragraph">
                Il dumping consiste nel trasferimento illecito di somme di denaro tra due conti gioco eludendo le limitazioni riportate nella fase di deposito. Un esempio
                tipico di dumping è quello che si verifica, ad esempio, quando un giocatore A perde volutamente una o più partite contro il giocatore B.
            </p>
            <h2 className="titolo2">Accordi illeciti tra giocatori - collusion</h2>
            <p className="paragraph">
                E'vietato qualsiasi tipo di accordo tra giocatori per falsare volontariamente il risultato di una partita o di un torneo; il monitoraggio delle
                attività illecite e dei comportamenti collusivi è affidato ad un Team anti-collusione che monitorizza costantemente l'andamento delle partite e
                della chat al fine di evitare tali comportamenti: nel caso in cui un giocatore risulti avere comportamenti collusivi, l'amministratore del sito
                provvederà a contattare/ammonire e, ove necessario, a prendere i dovuti provvedimenti e comunicare l'accaduto alle Autorità competenti; il Team
                è inoltre in grado di sospendere, salvo ulteriori verifiche, un conto di gioco in caso di sospetto dumping.
            </p>
            <p className="paragraph">
                Al fine del monitoraggio di tali attività fraudolente il Team preposto si avvale di software di analisi automatica delle partite: attraverso tale
                software vengono tracciate tutte le giocate anomale dei giocatori al fine di poter monitorare attraverso controlli incrociati eventuali dumping.
            </p>
            <p className="paragraph">In particolare vengono tracciate le seguenti decisioni anomale:</p>
            <ul itemType="disc" className="paragraph">
                <li>abbandono della partita,</li>
                <li>persa partita per tempo scaduto,</li>
                <li>sit out (per qualsiasi motivo).</li>
            </ul>
        </ContentTabs>
    );
};

export const ModalitaAllenamento = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Modalità di allenamento</h2>
            <h2 className="titolo2">Modalità di allenamento</h2>
            <p className="paragraph">
                La sezione di allenamento ha lo scopo di permette ai giocatori di acquisire esperienze, abilità e competenze per partecipare ai tornei Money. Per
                questo motivo i tavoli cash e i tornei di allenamento sono identici ai tornei Money salvo le seguenti indispensabili differenze.
            </p>
            <ul itemType="disc" className="paragraph">
                <li>I tornei di allenamento sono gratuiti, la partecipazione è gratuita e non si vincono soldi o premi in denaro.</li>
                <li>Nei tornei, il costo di partecipazione fittizio è sempre uguale a 10 Punti Torneo.</li>
                Si può partecipare a un solo torneo programmato per volta.
                <li>Si può partecipare al massimo a 2 tornei sit&amp;go contemporaneamente.</li>
                <li>
                    Ai fini della determinazione del totale dei Punti Torneo da suddividere tra i migliori classificati viene aggiunto un bonus di partecipazione
                    proporzionale al numero di smazzate espresso in punti torneo.
                </li>
                <li>
                    Nei tornei Danese (ma non nei danEL) il costo di partecipazione viene risuddiviso tra i peggiori classificati in maniera non omogenea (ad esempio il
                    40° classificato perde 2 PT e il 60° ne perde 8). Questo per motivare chi sta nelle ultime posizioni della classifica parziale.
                </li>
                <li>
                    Nei tornei programmati è spesso necessario confermare la propria iscrizione cliccando sul tasto "<strong>Conferma iscrizione</strong>" che appare
                    4 minuti prima dell'inizio del torneo e scompare 2 minuti prima dell'inizio del torneo, alla chiusura delle iscrizioni; l'obbligatorietà della
                    conferma è sempre indicata nei dettagli del torneo.
                </li>
                <li>Non è permesso cancellarsi da alcuni tornei di allenamento; l'eventuale impossibilità a cancellarsi è sempre indicata nei dettagli del torneo.</li>
                <li><u>Ammonizioni automatiche</u></li>
            </ul>
            <p className="paragraph">A fine torneo vengono assegnate automaticamente le seguenti ammonizioni:</p>
            <ul itemType="disc" className="paragraph">
                <li>1 ammonizione in caso di timeout nei tornei individuali;</li>
                <li>2 ammonizioni in caso di timeout nei tornei a coppie;</li>
                <li>1 ammonizione per ogni partita terminata per tempo scaduto nei tornei a coppie se il giocatore ha utilizzato più dei 3/4 del tempo a disposizione.</li>
            </ul>
            <p className="paragraph">Si possono ricevere al massimo 2 ammonizioni per ogni torneo.</p>
            <p className="paragraph">Il sistema assegna 1 ammonizione bonus ogni 30 turni di tornei completati.</p>
            <p className="paragraph">Le ammonizioni effettive sono la differenza tra le ammonizioni ricevute e le ammonizioni bonus.</p>
            <p className="paragraph">Sono applicati i seguenti provvedimenti automatici:</p>
            <ul itemType="disc" className="paragraph">
                <li>alla 10° ammonizione effettiva al giocatore non sarà permesso partecipare a tornei Freerolls (bonus) per 3 giorni,</li>
                <li>alla 20° ammonizione effettiva al giocatore non sarà permesso partecipare a tornei Freerolls (bonus) per ulteriori 5 giorni.</li>
                <li>alla 30° ammonizione effettiva al giocatore non sarà permesso partecipare a tornei Freerolls (bonus) per ulteriori 10 giorni.</li>
                <li>alla 40° ammonizione effettiva al giocatore non sarà permesso partecipare a tornei Freerolls (bonus) ulteriori 20 giorni.</li>
                <li>oltre le 40 ammonizioni, ogni 10 ammonizioni successive saranno comminati 10 giorni di blocco partecipazione ai tornei Freerolls (bonus).</li>
            </ul>
            <h2 className="titolo2">Recupero punti automatico</h2>
            <p className="paragraph">
                Nei tornei a coppie il sistema ricalcola la classifica del compagno del giocatore che ha utilizzato più dei 3/4 del tempo attribuendogli un punteggio
                di 10 WP e 0 MP.
                <br />
                Se il compagno ha ricevuto 2 ammonizioni il giocatore può abbandonare il torneo senza conseguenze e la sua partecipazione al torneo sarà
                considerata nulla.
            </p>
        </ContentTabs>
    );
};

export const DisattivazioneGiochi = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Disattivazione giochi</h2>
            <h2 className="titolo2">Disattivazione giochi</h2>
            <p className="paragraph">Il Concessionario può disattivare il gioco in qualsiasi momento.</p>
            <p className="paragraph">Gli effetti della disattivazione dei tornei di un gioco sono i seguenti:</p>
            <p className="paragraph">1. i tornei in corso continueranno regolarmente fino al loro termine;</p>
            <p className="paragraph">
                2. i tornei programmati che avrebbero dovuto iniziare durante la disattivazione del gioco saranno annullati e il costo del diritto di partecipazione
                sarà rimborsato a tutti gli iscritti.
            </p>
            <p className="paragraph">3. non sarà possibile iscriversi a tornei;</p>
            <p className="paragraph">4. non sarà possibile cancellarsi dai tornei;</p>
            <p className="paragraph">
                5. al momento della riattivazione del gioco saranno automaticamente cancellate tutte le iscrizioni in essere ai sit&amp;go con accredito sul conto del
                costo di partecipazione al torneo (ciò per evitare che giocatori che abbiano abbandonato il sito si trovino a giocare il sit&amp;go senza essere
                presenti).
            </p>
            <p className="paragraph">Nel caso vengano disattivati i tornei non sarà possibile accedere alla sezione money del gioco.</p>
            <p className="paragraph">Il Concessionario ha la facoltà di non disattivare il gioco nella sezione di allenamento.</p>
            <p className="paragraph">
                Nel caso vengano disattivate tutte le modalità di gioco sia nella sezione money che nella sezione di allenamento non sarà possibile accedere al
                gioco.
            </p>
        </ContentTabs>
    );
};

export const RequisitiMinimiDiSistema = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Requisiti minimi</h2>
            <h2 className="titolo2">Requisiti minimi</h2>
            <p className="paragraph">PC con sistema operativo Windows, Linux o MacOs.</p>
            <p className="paragraph">1GHz Pentium CPU o superiore;</p>
            <p className="paragraph">512MB RAM;</p>
            <p className="paragraph">Risoluzione grafica almeno 1024x768 colori 16 bit;</p>
            <p className="paragraph">Browser Compatibili: Internet Explorer 7 o superiore, Mozilla Firefox 2.0 o superiore, Safari 4.0;</p>
            <p className="paragraph">Flash player versione 10 o superiore</p>
        </ContentTabs>
    );
};

export const OrariDelSito = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regolamenti Generali - Orari del sito</h2>
            <h2 className="titolo2">Orari del sito</h2>
            <p className="paragraph">Il sito chiude tutti i giorni dalle ore 5:30 alle ore 5:45.</p>
            <p className="paragraph">Dalle ore 4:00 alle ore 5:30 non è possibile iscriversi ai tornei ma è possibile entrare nel sito e cancellarsi dai tornei.</p>
        </ContentTabs>
    );
};

export const Club = () => {
    return (
        <ContentTabs>
            <div className="card-body text-justify">
                <p>
                    Il CLUB è una modalità di gioco che consente a un giocatore di invitare altri giocatori scegliendo tra gli
                    utenti connessi alla lobby. I giochi che prevodno la modalità CLUB sono i seguenti: Burraco, Scopa.
                    All'interno della lobby la sezione CLUB permetterà di creare eventi, mandare inviti e gestire gli inviti
                    ricevuti da altri utenti.
                </p>
                <h4>Creazione dell'evento</h4>
                <p>Ogni giocatore potrà scegliere tra alcune o tutte le seguenti caratteristiche:</p>
                <ul>
                    <li>tipologia evento: sit&amp;go o tavolo cash;</li>
                    <li>
                        numero di partecipanti: combo che varia da 2 a N in base al primo filtro. (Nei cash il gioco può avere
                        inizio anche prima che tutti si siedano al tavolo);
                    </li>
                    <li>costo per i tornei (e, in automatico, relativo rake e montepremi abbinato);</li>
                    <li>buy-in minimo di ingresso per i tavoli cash;</li>
                    <li>
                        per i <b>Giochi a partita</b>:
                    </li>
                    <ul>
                        <li>tipo partita (testa a testa, 4in2, a coppie, 4 individuali);</li>
                        <li>obiettivo (1 smazzata, 2 smazzate, punti, 2 su 3 etc, etc);</li>
                        <li>
                            tempo partita e timeout non saranno modificabili ma verranno stabiliti in base all'obiettivo della
                            partita;
                        </li>
                        <li>eventuale variante di gioco particolare;</li>
                    </ul>
                    <li>
                        per i <b>Giochi a puntate</b>:
                    </li>
                    <ul>
                        <li>stack iniziale di chips;</li>
                        <li>
                            velocità della struttura del torneo che incide sul tempo di aumento dei livelli di puntata (Turbo,
                            Normale, Lento);
                        </li>
                        <li>eventuale variante di gioco particolare.</li>
                    </ul>
                </ul>
                <h4>La scelta degli invitati</h4>
                <p>
                    Dopo aver stabilito le caratteristiche della propria partita, si procede con la scelta dei giocatori da
                    invitare, il cui numero è sempre pari al numero di posti a sedere che sono disponibili per il tavolo creato.
                    Nei giochi a coppie è anche possibile specificare se un giocatore è il compagno di chi crea l'evento. In
                    questa circostanza l'invitato prescelto come compagno, qualora accettasse l'invito, sarà obbligato a giocare
                    come compagno di chi ha creato l'evento. Dopo la conferma e l'invio degli inviti il creatore dell'evento non
                    potrà cancellarsi dall'evento. Gli inviti devono essere inviati entro 3 minuti di tempo dal momento della
                    creazione dell'evento.
                </p>
                <h4>Attesa ed inizio partita</h4>
                <p>
                    Dopo la comunicazione degli invitati, si attende l'accettazione dell'invito. Durante la fase di attesa
                    l'evento può essere annullato in uno dei seguenti casi:
                </p>
                <ul>
                    <li>se anche uno solo degli inviti viene rifiutato;</li>
                    <li>se entro i 3 minuti di attesa non vengono accettati tutti gli inviti.</li>
                </ul>
                <p>
                    Una volta accettato l'invito, non sarà possibile cancellarsi dall'evento e, una volta che tutti gli inviti
                    vengono accettati, l'evento CLUB inizia e si apre il tavolo da gioco. Finita la partita, se non viene
                    confermata la rivincita da tutti i partecipanti, l'evento si distrugge e i giocatori vengono fatti alzare dal
                    tavolo.
                </p>
                <h4>Vincite</h4>
                <p>
                    Nei tornei a coppie la vincita verrà suddivisa in parti uguali tra i componenti della coppia e l'eventuale
                    resto di un centesimo di Euro viene dato al primo componente. Le partite e i tornei giocati nel CLUB non
                    valgono ai fini delle classifiche e non sono previste ammonizioni per partite e tornei di allenamento giocati
                    nel CLUB.
                </p>
            </div>
        </ContentTabs>
    );
};
