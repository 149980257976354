import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const TresetteIlMazzoDiCarte = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Il mazzo di carte </h2>
      <h2 className="titolo2">Il mazzo di carte </h2>
      <p className="paragraph">
        Si gioca di default con un mazzo di carte francesi senza i Jolly, gli otto, i
        nove e i dieci; i giocatori possono eventualmente selezionare un altro tipo di
        carte (napoletane, piacentine).
      </p>
      <p className="paragraph">La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
      <table className="bordi" border={1} cellPadding="5" width="221">
        <tbody>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">denari </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">quadri </p></td>
            <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♦ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">coppe </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">cuori </p></td>
            <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♥ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">bastoni </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">fiori </p></td>
            <td className="bordi" width="43"><p className="paragraph">♣ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">spade </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">picche </p></td>
            <td className="bordi" width="43"><p className="paragraph">♠ </p></td>
          </tr>
        </tbody>
      </table>
    </ContentTabs >
  );
};

export const TresetteSvolgimentoDelGioco = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Svolgimento del gioco </h2>
      <h2 className="titolo2">Vengono dette:</h2>
      <p className="paragraph"><strong>giocata</strong> : la carta mostrata scoperta sul tavolo da un giocatore; </p>
      <p className="paragraph"><strong>mano</strong> : la fase di una <em>smazzata</em> in cui tutti i giocatori al tavolo effettuano una <em>giocata</em>; </p>
      <p className="paragraph"><strong>smazzata</strong> : la fase di gioco che inizia con la distribuzione delle carte e si conclude con il conteggio dei punti; </p>
      <p className="paragraph"><strong>partita</strong> : la fase di gioco composta da una o più <em> smazzate.</em> </p>
      <h2 className="titolo2">Designazione del mazziere</h2>
      <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. </p>
      <p className="paragraph">Quando il gioco ha inizio, il mazziere viene stabilito casualmente dal sistema. </p>
      <p className="paragraph">
        Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra
        stessi giocatori, il mazziere gira in senso antiorario e cioè il prossimo
        mazziere sarà quello seduto alla destra del mazziere precedente.
      </p>
      <h2 className="titolo2">Distribuzione delle carte</h2>
      <p className="paragraph">
        Il sistema mescola le carte ne da 10 ad ogni giocatore distribuendole una
        alla volta, in senso antiorario, partendo dal giocatore alla destra del
        mazziere.
      </p>
      <p className="paragraph">Nello <em>spizzichino</em> le restanti 20 carte"mazzo" vengono poste a centro tavolo. </p>
      <h2 className="titolo2">Il gioco della carta</h2>
      <p className="paragraph">Il giocatore seduto alla destra del mazziere è il <em>giocatore di mano</em>, cioè il primo a dover effettuare la <em>giocata.</em> </p>
      <p className="paragraph">La <em>giocata</em> consiste nel porre sul tavolo una carta scoperta. </p>
      <p className="paragraph">
        Tutti gli altri giocatori, a turno e in senso antiorario, hanno l'obbligo di
        porre sul tavolo una carta dello stesso seme mostrato dal primo giocatore di
        mano ("<em>rispondere al seme</em>"). Se non possiedono carte di quel seme
        possono giocare una carta qualsiasi.
      </p>
      <p className="paragraph">
        Il giocatore che mostra la carta più alta al seme del primo di mano si
        aggiudica la mano ("<em>fa la presa</em>") e sarà lui a iniziare la prossima
        mano (diventa il "<em>giocatore di mano</em>").
      </p>
      <p className="paragraph">Ai fini della presa, le carte con hanno il seguente valore decrescente: </p>
      <ul itemType="disc" className="paragraph">
        <li>Tre </li>
        <li>Due </li>
        <li>Asso </li>
        <li>Re </li>
        <li>Cavallo </li>
        <li>Fante </li>
        <li>Sette </li>
        <li>Sei </li>
        <li>Cinque </li>
        <li>Quattro </li>
      </ul>
      <h2 className="titolo2">Punteggi</h2>
      <p className="paragraph">Al termine di ogni smazzata si calcolano i punteggi come segue: </p>
      <p className="paragraph">chi ha fatto l'ultima presa si aggiudica 1 punto, </p>
      <p className="paragraph">si calcolano i punti di ciascuna coppia o giocatore, attribuendo il seguente valore alle carte prese: </p>
      <ul itemType="disc" className="paragraph">
        <li>Asso: 1 punto </li>
        <li>Tre, Due, Re, Cavallo e Fante: 1/3 di punto </li>
        <li>Le altre carte (7,6,5, 4) zero punti. </li>
      </ul>
      <p className="paragraph">La somma dei punti viene sempre arrotondata per difetto. </p>
      <p className="paragraph">Se, ad esempio, un giocatore avesse totalizzato 7 punti + 1/3 di punto il suo punteggio sarebbe di 7 punti. </p>
      <p className="paragraph">Il totale dei punti di una smazzata è pari a 11. </p>
      <p className="paragraph">
        Prestate attenzione al fatto che l'asso è, ai fini del punteggio, la carta di
        maggior valore ma che, hai fini della presa, il tre e il due valgono più dell'asso.
      </p>
      <h2 className="titolo2">Conclusione della partita</h2>
      <p className="paragraph">Ai fini della loro conclusione, le partite possono essere di due tipi: </p>
      <p className="paragraph"><strong>Partite a smazzate</strong> </p>
      <p className="paragraph">Si stabilisce il numero di smazzate di cui è composta la partita. </p>
      <p className="paragraph">Vince la partita chi fa più punti al termine delle smazzate previste, </p>
      <p className="paragraph"><strong>Partite a punti</strong> </p>
      <p className="paragraph">Si stabilisce il totale dei punti che un giocatore o una coppia deve raggiungere per aggiudicarsi la partita. </p>
      <p className="paragraph">Vince la partita: </p>
      <ul itemType="disc" className="paragraph">
        <li>chi si trova nella condizione di <strong>"Vincere subito"</strong> (vedi sotto), </li>
        <li>chi, avendo superato il punteggio previsto, ha più punti dell'avversario,</li>
      </ul>
      <p className="paragraph">
        Nelle partite con il cosidetto "<strong>Vinco subito</strong>" in qualsiasi
        momento della partita, il <em>giocatore di mano</em> (cioè quello che ha appena
        <em>fatto la presa</em>) <strong>pone automaticamente fine alla partita,</strong>
        <strong>vincendola</strong> non appena ha totalizzato un punteggio almeno pari
        ai punti necessari per vincere la partita.
      </p>

      <h2 className="titolo2">Parità</h2>
      <p className="paragraph">
        Nei tornei a eliminazione diretta (KO), se la partita finisse in parità,
        verrà giocata un'altra smazzata ("1° spareggio") e si aggiudicherà l'incontro
        chi farà più punti.
      </p>
      <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("2° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
      <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("3° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
      <p className="paragraph">Poiché non è ammissibile un gioco ad oltranza, viene stabilita la seguente regola: </p>
      <p className="paragraph">se anche il 3° spareggio finisse in parità, perde il giocatore che ha fatto l'ultima presa nel 3° spareggio, anche senza fare punti. </p>
      <h2 className="titolo2"><strong>Spizzichino </strong></h2>
      <p className="paragraph">Nel tresette, le partite "testa a testa" si chiamano "spizzichino". </p>
      <p className="paragraph">
        Questo nome particolare è anche dovuto al fatto che lo svolgimento del
        gioco è diverso rispetto ad una partita con 4 giocatori perché distribuendo 10
        carte a giocatore ne rimangono 20 nel mazzo.
      </p>
      <p className="paragraph">Dopo ogni mano ciascun giocatore pescherà una carta dal mazzo mostrandola all'altro giocatore. </p>
      <p className="paragraph">Pesca per primo il giocatore che ha fatto la presa. </p>
    </ContentTabs >
  );
};

export const TresetteComeSiGioca = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Come si gioca</h2>
      <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
      <p className="paragraph">Per giocare una carta, quando è il proprio turno si può: </p>
      <ul itemType="disc" className="paragraph">
        <li>Fare un "doppio clic" sulla carta, oppure </li>
        <li>Selezionare una carta (cioè cliccarla) e poi cliccare sulla carta semitrasparente che appare sul tavolo </li>
        <li>Selezionare una carta e, tenendo premuto il tasto sinistro del mouse, trascinarla sulla carta semitrasparente che appare sul tavolo </li>
      </ul>
      <p className="paragraph">Per deselezionare una carta è sufficiente cliccarla . </p>
      <p className="paragraph">Nelle partite a spizzichino (testa a testa) le carte pescate vengono mostrate automaticamente. </p>
      <p className="paragraph">
        Se il giocatore prova a giocare una carta non ammessa dalle regole del gioco
        apparirà l'alert "Giocata non ammessa" che scomparirà dopo pochi attimi.
      </p>
      <p className="paragraph">
        Si precisa che hanno valore solo le decisioni comunicate con questi tasti.
        Qualsiasi decisione di gioco comunicata in chat, oltre a non avere alcun valore,
        è severamente vietata.
      </p>
    </ContentTabs >
  );
};

export const TresettePenalita = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Penalità</h2>
      <h2 className="titolo2">Penalità in caso di tempo scaduto o abbandono </h2>
      <p className="paragraph">
        Se giocatore fa scadere il tempo partita o il timeout o clicca sul tasto
        "abbandona" la partita ha termine e il giocatore <strong>perde la partita</strong> e la differenza punti è cosi stabilita:
      </p>
      <p className="paragraph">· si assegnano tutti o punti ancora non aggiudicati all'avversario fino a un massimo di 6 punti, </p>
      <p className="paragraph">
        · se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il
        timeout, la differenza punti è uguale a zero (ma la partita è comunque persa da
        chi ha fatto scadere il tempo).
      </p>
      <p className="paragraph"><a>Calcolo dei VP e MP nei tornei in caso di Timeout o abbandono</a> </p>
      <p className="paragraph">
        Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o
        abbandona farà 0 VP e la linea avversaria farà minimo 14 VP. I MP di entrambe le
        linee saranno calcolati dai VP in base ai corrispondenti minimi previsti dalla
        tabella dei VP.
      </p>
      <p className="paragraph"><em>Si sottolinea che quest'ultima regola sul timeout e abbandono è valida solo per determinare i MP e i VP per le classifiche dei tornei a girone e i livelli di abilità in caso di timeout e abbandono , ma non per determinare la differenza punti nei tavoli cash che vengono sempre determinati come descritto nei <a href="generali.php?skin=SNAI&amp;mobile=&amp;argomento=cashgenerico&amp;capitolo=tavoliturni">regolamenti generali</a> anche in caso di timeout e abbandono.</em> </p>
    </ContentTabs >
  );
};

export const TresetteRegoleOpzionali = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Regole opzionali</h2>
      <h2 className="titolo2">Regole opzionali</h2>
      <p className="paragraph">
        In questo capitolo saranno descritte alcune regole del Tresette che vengono
        spesse utilizzate nelle partite dal vivo e che sono state definite "opzionali"
        perché nel sito saranno proposte partite con e senza queste regole.
      </p>
      <h2 className="titolo2">A perdere</h2>
      <p className="paragraph">Si gioca con le stesse regole del Tresette classico con la differenza che lo scopo è quello di fare meno punti dell'avversario. </p>
      <p className="paragraph">
        La partita ha termine quando almeno un giocatore raggiunge il punteggio
        previsto. Vince chi, al termine della partita, ha fatto meno punti. In caso di
        parità vince chi ha fatto l'ultima presa dell'ultima smazzata.
      </p>
      <p className="paragraph">
        Nelle partite a smazzate vince chi, al termine delle smazzate previste, ha
        fatto meno punti. In caso di parità vince chi ha fatto l'ultima presa
        dell'ultima smazzata.
      </p>
      <p className="paragraph">Nella variante a a perdere non sono previsti chiamata fuori e accuso. Qualora nella modalita' tresette a perdere non venga fatto almeno 1 punto a smazzata , sara' considerato cappotto</p>
      <h2 className="titolo2">Accuso</h2>
      <p className="paragraph">Se un giocatore possiede una delle combinazioni di carte sotto descritta si aggiudica: </p>
      <p className="paragraph">3 punti con le seguenti combinazioni: </p>
      <ul itemType="disc" className="paragraph">
        <li>Napoletana, ovvero il tre, il due e l'asso di uno stesso seme </li>
      </ul>
      <ul itemType="disc" className="paragraph">
        <li>3 tre </li>
        <li>3 due </li>
        <li>3 assi </li>
      </ul>
      <p className="paragraph">4 punti con le seguenti combinazioni </p>
      <ul itemType="disc" className="paragraph">
        <li>4 tre </li>
        <li>4 due </li>
        <li>4 assi </li>
      </ul>
      <p className="paragraph"><strong>Modalità di accuso </strong></p>
      <p className="paragraph">Si può accusare solo quando si è di turno nel corso della prima mano, prima di giocare una carta. </p>
      <p className="paragraph">Il sistema effettua l'accuso automaticamente a meno che il giocatore non lo abbia disattivato nel menù "opzioni di gioco" o nel tavolo stesso. </p>
      <p className="paragraph">
        Se l'accuso automatico è stato disattivato, il giocatore deve cliccare
        sull'apposito tasto "Accuso" prima di giocare una carta nel corso della prima
        mano e selezionare l'accuso desiderato.
      </p>
      <p className="paragraph">
        È consentito un accuso multiplo, ossia la dichiarazione contemporanea di più
        <em>accusi</em>. In questo caso i punti guadagnati sono somma dei singoli
        accusi.
      </p>
      <p className="paragraph">Quando si gioca con il cappotto, se un giocatore fa cappotto, l'accuso dell'avversario non è valido. </p>
      <h2 className="titolo2">Cappotto (mai applicato nei tornei)</h2>
      <p className="paragraph">
        Se la partita prevede il cappotto, il giocatore che in una smazzata totalizza
        11 punti vince una partita extra e, <em>nel caso di partite</em> una posta
        extra. La partita continua comunque il suo corso e può anche accadere che il
        giocatore che aveva fatto un cappotto perda poi la partita (nel qual caso la
        posta partita vinta col cappotto rimane comunque valida).
      </p>
      <h2 className="titolo2">Dichiarazioni</h2>
      <p className="paragraph">
        Nei tavoli a coppie ciascun giocatore di mano ha la facoltà di effettuare
        delle <em>dichiarazioni</em>, per dare informazioni sulle carte del seme che sta
        per giocare,
      </p>
      <p className="paragraph">
        Poiché Il tressette è giocato in molte regioni italiane con diverse
        terminologie, per evitare errate interpretazioni tra i giocatori al tavolo,
        useremo una dichiarazione estremamente semplice anche se inusuale.
      </p>
      <p className="paragraph">La dichiarazione più comune assume la seguente forma: </p>
      <p className="paragraph"><strong>Ho + (eventuali <em>onori</em> in mano) + (quante carte si posseggono a quel seme)</strong> </p>
      <p className="paragraph">Gli <em>onori</em> soni il Tre, il Due e l'Asso </p>
      <p className="paragraph">Esempi: </p>
      <ul itemType="disc" className="paragraph">
        <li><em>Ho il 3 quarto</em> possiedo il Tre e, in totale, 4 carte al seme</li>
        <li><em>Ho il 2 terzo</em> possiedo il Due e, in totale, 5 carte al seme</li>
        <li><em>Ho il 2 e l'asso quarti</em> possiedo il Due e l'Asso e, in totale, 5 carte al seme </li>
        <li><em>Ho 5 carte</em> non possiedo onori e ho 5 carte nel seme (inclusa la carta giocata) </li>
        <li><em>Ho 3 carte</em> non possiedo onori e ho 3 carte nel seme (inclusa la carta giocata) </li>
        <li><em>Ho 1 carta</em> non possiedo onori e ho 1 carta nel seme (inclusa la carta giocata), in pratica la carta che sto giocando è l'ultima che possiedo nel seme </li>
      </ul>
      <p className="paragraph">Sono in oltre possibili altre due tipi di dichiarazione: </p>
      <ul itemType="disc" className="paragraph">
        <li>
          <strong>Busso </strong>senza dare informazioni sulle mie carte, invito
          il compagno a giocare la carta più alta che ha nel seme e, se fa la presa o
          appena può, a ritornare nel seme<strong><em> </em></strong>
        </li>
        <li>
          <strong>Ho per te … </strong>se il compagno aveva bussato o se aveva
          comunque dato indicazioni di preferire un seme, ma il giocatore preferisse
          giocare prima un altro seme lo fa ma avverte il compagno che, poi, sarà in
          grado di tornare al seme voluto da compagno.
        </li>
      </ul>
      <p className="paragraph">
        Si può far seguire "Ho per te" da una qualsiasi altra dichiarazione al seme
        giocato. Ad esempio: <em>Ho per te e ho il 3 e il 2 quinti.</em>
      </p>
      <p className="paragraph">
        <strong>Il sistema effettua la dichiarazione "Ho …." automaticamente ma non
          effettua mai gli altri due tipi di dichiarazione.</strong>
      </p>
      <p className="paragraph">
        <strong>I giocatori possono disattivare tale automatismo dal menù "opzioni di
          gioco" nella lobby o direttamente nel tavolo cliccando su "opzioni". </strong>
      </p>
      <p className="paragraph">
        Se la dichiarazione automatica è stata disattivata al giocatore di mano
        comparirà il tasto "Dichiarazione": Cliccando su questo tasto appariranno le
        dichiarazioni che il giocatore può effettuare. Ad esempio:
      </p>
      <p className="paragraph">Ho il 3 di denari </p>
      <p className="paragraph">Busso </p>
      <p className="paragraph">Ho per te </p>
      <p className="paragraph">Se si clicca "<em>Ho …</em>" si esclude "<em>busso</em>" e viceversa. </p>
      <p className="paragraph">
        Il giocatore deve selezionare la dichiarazione che vuole effettuare prima di
        giocare la carta. Il giocatore, se lo desidera, può non effettuare alcuna
        dichiarazione e, se ne aveva impostata una, può annullarla cliccando su
        "annulla".
      </p>
      <p className="paragraph">
        <strong>Le dichiarazioni possono essere fatte automaticamente o usando gli
          appositi tasti, sono severamente vietate dichiarazioni in chat.</strong>
      </p>
      <p className="paragraph">&nbsp;</p>
      <h2 className="titolo2">Partite a "carte viste"</h2>
      <p className="paragraph">Saranno mostrate: </p>
      <ul itemType="disc" className="paragraph">
        <li>le carte viste nelle precedenti mani della smazzata; </li>
        <li></li>
        <li>nelle partite con dichiarazione, le carte in mano agli altri giocatori in base alle dichiarazioni effettuate; </li>
        <li>nello spizzichino, le carte in mano all'avversario in base alle pescate e alle giocate precedenti. le carte in mano all'avversario sono evidenziate;</li>
        <li>sempre nello spizzichino vengono mostrate scoperte le carte che l'avversario ha sicuramente in mano (perché pescate ma non giocate). </li>
      </ul>
      <p className="paragraph">Nelle partite spizzichino "a carte viste" , al termine delle pescate, si gioca a carte scoperte. </p>
      <h2 className="titolo2">Partite a coppie a "carte scoperte"</h2>
      <p className="paragraph">Saranno proposte partite a coppie nelle quali i compagni, senza essere visti dagli avversari, giocano a carte scoperte. </p>
    </ContentTabs >
  );
};

export const TresetteOpzioniDiGiocoAutomatico = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Opzioni di gioco automatico</h2>
      <h2 className="titolo2">Opzioni di gioco automatico </h2>
      <p className="paragraph">Il sistema effettua automaticamente: </p>
      <p className="paragraph"><strong>la chiamata fuori, </strong>come e quando previsto dal regolamento</p>
      <p className="paragraph"><strong>l'accuso,</strong> come e quando previsto dal regolamento </p>
      <p className="paragraph"><strong>la dichiarazione</strong> come e quando previsto dal regolamento </p>
      <p className="paragraph">Ciascun giocatore, se e quando lo desidera, può eliminare o riattivare questi automatismi: </p>
      <p className="paragraph">dalla lobby del Tresette, cliccando sul tasto "Il mio profilo" e selezionando "Opzioni di gioco"; </p>
      <p className="paragraph">dal tavolo di Tresette, cliccando su "opzioni". </p>
    </ContentTabs >
  );
};

export const TresetteTabellaDeiVictoryPoints = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole del Tresette - Tabella dei Victory Points</h2>
      <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
      <table className="bordi" border={1} cellPadding="5" cellSpacing="5" width="299">
        <tbody>
          <tr>
            <td className="bordi" width="23"><p className="paragraph"><strong>RISULTATO</strong> </p></td>
            <td className="bordi" width="37"><p className="paragraph"><strong>VP</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="23"><p className="paragraph">Vittoria </p></td>
            <td className="bordi" width="37"><p className="paragraph">20 </p></td>
          </tr>
          <tr>
            <td className="bordi" width="23"><p className="paragraph">Pareggio (ove previsto) </p></td>
            <td className="bordi" width="37"><p className="paragraph">10 </p></td>
          </tr>
          <tr>
            <td className="bordi" width="23"><p className="paragraph">Sconfitta </p></td>
            <td className="bordi" width="37"><p className="paragraph">0 </p></td>
          </tr>
        </tbody>
      </table>
    </ContentTabs >
  );
};
