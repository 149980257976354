import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const CirullaIlMazzoDiCarte = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Il mazzo di carte </h2>
      <h2 className="titolo2">Il mazzo di carte </h2>
      <p className="paragraph">
        Si gioca di default con un mazzo di carte francesi senza i Jolly, gli otto, i
        nove e i dieci; i giocatori possono eventualmente selezionare un altro tipo di
        carte (napoletane, piacentine).
      </p>
      <p className="paragraph">La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
      <table className="bordi" border={1} cellPadding="5" width="221">
        <tbody>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">denari </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">quadri </p></td>
            <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♦ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">coppe </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">cuori </p></td>
            <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♥ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">bastoni </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">fiori </p></td>
            <td className="bordi" width="43"><p className="paragraph">♣ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">spade </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">picche </p></td>
            <td className="bordi" width="43"><p className="paragraph">♠ </p></td>
          </tr>
        </tbody>
      </table>
      <h2 className="titolo2">Il valore delle carte</h2>
      <table border={1} cellPadding="0" cellSpacing="0" width="180">
        <tbody>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>Re (K)</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>10</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>Cavallo (Q)</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>9</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>Fante (J)</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>8</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>7</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>7</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>6</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>6</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>5</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>5</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>4</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>4</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>3</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>3</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>2</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>2</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="104"><p className="paragraph"><strong>Asso</strong> </p></td>
            <td className="bordi" width="76"><p className="paragraph"><strong>1</strong> </p></td>
          </tr>
        </tbody>
      </table>
    </ContentTabs>
  );
};

export const CirullaSvolgimentoDelGioco = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Svolgimento del gioco </h2>
      <h2 className="titolo2">Vengono dette: </h2>
      <p className="paragraph"><strong>giocata</strong> : la carta mostrata scoperta sul tavolo da un giocatore; </p>
      <p className="paragraph"><strong>mano</strong> : la fase di una <em>smazzata</em> in cui tutti i giocatori al tavolo effettuano una <em>giocata</em>; </p>
      <p className="paragraph"><strong>smazzata</strong> : la fase di gioco che inizia con la distribuzione delle carte e si conclude con il conteggio dei punti; </p>
      <p className="paragraph"><strong>partita</strong> : la fase di gioco composta da una o più <em> smazzate.</em> </p>
      <h2 className="titolo2">Designazione del mazziere </h2>
      <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. </p>
      <p className="paragraph">Quando il gioco ha inizio, il mazziere viene stabilito casualmente dal sistema. </p>
      <p className="paragraph">
        Ad ogni fine smazzata, anche nel caso di gioco composto da più partite tra
        stessi giocatori, il mazziere gira in senso antiorario e cioè il prossimo
        mazziere sarà quello seduto alla destra del mazziere precedente.
      </p>
      <h2 className="titolo2">Distribuzione delle carte</h2>
      <p className="paragraph">
        Il sistema mescola le carte e ne da 3 ad ogni giocatore distribuendole una
        alla volta, in senso antiorario, partendo dal giocatore alla destra del mazziere.
      </p>
      <p className="paragraph">Quindi vengono poste 4 carte scoperte in tavola. </p>
      <p className="paragraph">Se nella distribuzione iniziale sono presenti due assi, la mano va a monte e il sistema mischia e ridistribuisce le carte. </p>
      <h2 className="titolo2">Il gioco della carta</h2>
      <p className="paragraph">Il giocatore seduto alla destra del mazziere è il <em>giocatore di mano</em>, cioè il <u>primo</u> a dover effettuare la <em>giocata.</em> </p>
      <p className="paragraph">Dopo che ogni giocatore ha effettuato la giocata,la mano passa al giocatore successivo in senso antiorario. </p>
      <p className="paragraph"><strong>Ogni giocatore di mano deve giocare una carta tenendo presente che:</strong></p>
      <ol type="a" className="paragraph">
        <li> può prendere la carta in tavola che ha lo stesso valore della carta da lui giocata, </li>
        <li> può prendere le carte in tavola la cui somma è uguale al valore della carta da lui giocata, </li>
        <li> può prendere le carte in tavola la cui somma, inclusa la carta da lui giocata, è uguale a 15. </li>
        <li> se gioca un asso: </li>
        <ul itemType="disc" className="paragraph">
          <li>se in tavola c'è un asso prende l'asso, </li>
          <li>se in tavola non ci sono assi prende tutte le carte in tavola,</li>
          <li>se non ci sono carte in tavola scarta l'asso senza fare prese.</li>
        </ul>
      </ol>
      <p className="paragraph">Si fa notare che le giocate a) e b) sono quelle prevista nel gioco della scopa. </p>
      <p className="paragraph">
        Si sottolinea, tuttavia, che, in presenza di più alternative, il giocatore ha
        sempre facoltà di scegliere quale attuare (a differenza della scopa dove è
        costretto a prendere la carta dello stesso valore) ad eccezione del caso di
        giocata di un asso.
      </p>
      <p className="paragraph">Se il giocatore può fare almeno un tipo di presa è costretto a prendere e non può quindi scartare la carta. </p>
      <p className="paragraph">
        <strong>Se un giocatore prende tutte le carte in tavola (anche giocando un
          asso) "fa scopa" ad eccezione che ciò avvenga nell'ultima giocata dell'ultima
          mano di una smazzata. </strong>
      </p>
      <h2 className="titolo2">Accusi</h2>
      <p className="paragraph">Si possono fare punti aggiuntivi, detti accusi, nei seguenti casi: </p>
      <p className="paragraph">Se la somma delle 4 carte in tavola scoperte dal mazziere: </p>
      <ul itemType="disc" className="paragraph">
        <li>è esattamente uguale a 15, il mazziere fa 1 punto ("gira una scopa"),</li>
        <li>è esattamente uguale a 30, il mazziere fa 2 punti ("gira due scope").</li>
      </ul>
      <p className="paragraph">Inoltre, in tutti e due i casi, il mazziere prende tutte le carte scoperte in tavola. </p>
      <p className="paragraph">il giocatore di mano può accusare<em> </em>le seguenti combinazioni, scoprendo le sue carte prima di aver effettuato la sua giocata: </p>
      <ul itemType="disc" className="paragraph">
        <li><strong><em>cirulla: </em></strong>se la <u>somma</u> delle tre carte è uguale o inferiore a 9 realizza 3 punti ("gira 3 scope") </li>
        <li><strong><em>decino:</em></strong> se possiede tre carte <u>uguali</u> realizza 10 ("gira 10 scope"). </li>
      </ul>
      <h2 className="titolo2">Il 7 di cuori (coppe) </h2>
      <p className="paragraph">Il 7 di cuori (coppe) diventa una "matta", cioè può assumere qualsiasi valore da 1 a 10 soltanto se consente di aggiudicarsi un qualsiasi accuso. </p>
      <p className="paragraph">In caso di accuso, ai fini delle prese, il sistema assegna automaticamente al 7 di cuori i seguenti valori: </p>
      <ul itemType="disc" className="paragraph">
        <li>in caso di cirulla il 7 di cuori vale sempre asso </li>
        <li>in caso di decino o di 4 carte scoperte in tavola, il 7 di cuori assume l'unico valore che consente di effettuare l'accuso </li>
      </ul>
      <p className="paragraph">Se il 7 di cuori non viene utilizzato per fare una presa ma viene calata sul tavolo, alla mano successiva torna a valere 7. </p>
      <h2 className="titolo2">Punteggi</h2>
      <p className="paragraph">Al termine di ogni smazzata si esaminano le carte prese da ciascun giocatore o coppia e le scope girate e si calcolano i punteggi come segue: </p>
      <ul itemType="disc" className="paragraph">
        <li><em>Scopa</em> ogni scopa vale 1 punto, </li>
        <li><em>Sette bello </em>il sette di denari (o di quadri) vale un 1 punto,</li>
        <li><em>Primiera(*) </em>vale 1 punto, </li>
        <li><em>Denari </em>6 o più carte di denari (o quadri) valgono 1 punto, </li>
        <li><em>Carte </em>21 o più carte valgono 1 punto, </li>
        <li><em>Grande </em>fante, cavallo e re di denari (o quadri) valgono 5 punti </li>
        <li><em>
          Piccola </em>asso, due e tre di denari (o quadri) valgono 3 punti +
          1 punto per ogni ulteriore carta di denari (o quadri) successiva, fino al 6
          (ad esempio asso,2,3,4,5 di denari (o quadri) vale 5 punti).
        </li>
      </ul>
      <p className="paragraph">Ai fini della primiera, il valore delle carte è il seguente: </p>
      <p className="paragraph">7 = 21 punti </p>
      <p className="paragraph">6 = 18 punti </p>
      <p className="paragraph">Asso = 16 punti </p>
      <p className="paragraph">5,4,3,2 sono uguali rispettivamente a 15,14,13,12 punti </p>
      <p className="paragraph">Re, cavallo e fante =10 punti </p>
      <p className="paragraph">- vince la primiera il giocatore o coppia che mostra le 4 <u>carte</u>, una per ogni seme, la cui somma di valori è maggiore. </p>
      <h2 className="titolo2">Conclusione della partita</h2>
      <p className="paragraph">Le partite si concludono: </p>
      <ul itemType="disc" className="paragraph">
        <li>per "cappotto", se previsto dalle caratteristiche della partita, </li>
        <li>a seconda della tipologia di partita giocata. </li>
      </ul>
      <h2 className="titolo2">Conclusione per "Cappotto" </h2>
      <p className="paragraph">Se un giocatore riesce a prendere tutte le carte di denari (quadri) effettua un cappotto e la partita termina immediatamente con la sua vittoria. </p>
      <p className="paragraph">Nei tornei chi ha fatto cappotto vince con il massimo numero <u>di</u> VP previsto per quella partita e il corrispondente numero minimo di MP. </p>
      <p className="paragraph">Nei tavoli cash chi ha fatto cappotto vince la perdita massima stabilita dal tavolo. </p>
      <h2 className="titolo2">Conclusione a seconda della tipologia di partita giocata: </h2>
      <p className="paragraph">Se è stato stabilito il numero di smazzate di cui è composta la partita vince la partita chi fa più punti al termine delle smazzate previste. </p>
      <p className="paragraph">
        Se è stato stabilito il totale dei punti che un giocatore o una coppia deve
        raggiungere per aggiudicarsi la partita vince la partita chi, avendo superato il
        punteggio previsto, ha più punti dell'avversario.
      </p>
      <h2 className="titolo2">Parità</h2>
      <p className="paragraph">
        Nei tornei a eliminazione diretta (KO), se la partita finisse in parità,
        verrà giocata un'altra smazzata ("1° spareggio") e si aggiudicherà l'incontro
        chi farà più punti.
      </p>
      <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("2° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
      <p className="paragraph">In caso di ulteriore parità verrà giocata un'altra smazzata ("3° spareggio") e si aggiudicherà l'incontro chi farà più punti. </p>
      <p className="paragraph">Poiché non è ammissibile un gioco ad oltranza, viene stabilita la seguente regola: </p>
      <p className="paragraph">se anche il 3° spareggio finisse in parità, perde il giocatore che ha fatto l'ultima presa nel 3° spareggio, anche senza fare punti. </p>
    </ContentTabs>
  );
};

export const CirullaComeSiGioca = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Come si gioca</h2>
      <h2 className="titolo2">Come si gioca: tasti e metodi per comunicare le proprie decisioni </h2>
      <p className="paragraph">Per giocare una carta, quando è il proprio turno si può: </p>
      <p className="paragraph"><strong>Selezionare le carte ad una ad una</strong></p>
      <p className="paragraph">
        Per effettuare una presa si può selezionare la carta in mano e le carte in
        tavola che si desidera prendere ad una ad una. Appena verrà completata una presa
        questa verrà effettuata.
      </p>
      <p className="paragraph">Se si seleziona una carta in mano che non consente prese apparirà sul tavolo una carta semitrasparente, cliccatela per scartare la carta. </p>
      <p className="paragraph">
        Nel caso venga selezionata una carta in tavola che consente di effettuare una
        presa, ma allo stesso tempo sia ancora possibile effettuare una presa
        selezionando una o più carte in tavola, apparirà un pulsante di "Conferma presa"
        che completerà l'azione di presa che, solo in questo caso, non avviene
        automaticamente. <br />
        <br />
        Esempio: in tavola ci sono un 4, un 7 e un 2. Se tra le carte in mano si ha un 4
        è possibile effettuare due prese: <br />
        1) il 4 e il 7 (somma 15) <br />
        2) il 4 da solo <br />
        <br />
        se tra le carte in tavola si seleziona prima il 4, apparirà il pulsante
        "Conferma presa", che permetterà di prendere il 4 da solo. Se invece si vuole
        prendere il 4 e il 7 è sufficiente selezionare anche il 7 e la presa verrà fatta
        automaticamente. <br />
        <br />
      </p>
      <p className="paragraph"><strong>Effettuare una presa trascinando la carta sul tavolo</strong></p>
      <p className="paragraph">
        Se si trascina una carta sul tavolo verrà evidenziata l'unica presa possibile
        o, se non ci sono prese possibili apparirà sul tavolo una carta semitrasparente.
        Per effettuare la presa evidenziata è sufficiente rilasciare la carta.
      </p>
      <p className="paragraph">Se non si vuole effettuare la presa evidenziata, si deve riposizionare la carta tra quelle in mano e poi rilasciarla. </p>
      <p className="paragraph">Può succedere che sia possibile effettuare più prese, in questo caso bisognerà spostarsi su una carta in tavola che consente una sola presa. </p>
      <p className="paragraph">
        Ad esempio, se in tavola fossero presenti 5 ,5, 3, 2, giocando un 10 e
        posizionandosi su un 2, il sistema non può sapere quali dei due 5 si desidera
        prendere. In questo caso è necessario spostare la carta un 5 in modo che sia
        possibile comporre una sola presa.
      </p>
      <p className="paragraph">
        Può anche succedere che non sia in alcun modo possibile individuare una sola
        presa, in questo caso non resta che selezionare ad una ad una le carte come
        sopra descritto.
      </p>
      <p className="paragraph"><strong>Doppio clic su una carta in mano</strong></p>
      <p className="paragraph">
        Il doppio clic su una carta in mano consente di scartare subito quella carta
        senza far prese. Se la carta selezionata col doppio clic consentisse possibili
        prese apparirà un messaggio di errore.
      </p>
      <p className="paragraph">Si precisa che: </p>
      <p className="paragraph">· per trascinare una carta bisogna cliccarla col pulsante sinistro del mouse e spostarsi dove si desidera tenendo il pulsante premuto, </p>
      <p className="paragraph">· dopo aver trascinato una carta, per rilasciarla è sufficiente smettere di tener premuto il pulsante sinistro del mouse, </p>
      <p className="paragraph">· per selezionare una carta è sufficiente cliccarci sopra col pulsante sinistro del mouse, </p>
      <p className="paragraph">· per deselezionare una carta già selezionata è sufficiente cliccarci sopra col pulsante sinistro del mouse. </p>
      <h2 className="titolo2">Come si accusa </h2>
      <p className="paragraph">L'accuso del mazziere è sempre automatico. </p>
      <p className="paragraph">
        Per la cirulla e il decino, il sistema effettua l'accuso automaticamente a
        meno che il giocatore non lo abbia disattivato nel menù "opzioni di gioco" o nel
        tavolo stesso.
      </p>
      <p className="paragraph">
        Se l'accuso automatico è stato disattivato il giocatore può effettuarlo,
        quando è di mano e prima di effettuare la giocata, cliccando sul tasto "Accuso"
        e selezionando il tipo di accuso.
      </p>
    </ContentTabs>
  );
};

export const CirullaPenalita = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Penalità</h2>
      <h2 className="titolo2">Penalità in caso di tempo scaduto o abbandono </h2>
      <p className="paragraph">
        Se giocatore fa scadere il tempo partita o il timeout o clicca sul tasto
        "abbandona" la partita ha termine e il giocatore <strong>perde la partita</strong>
        e la differenza punti è cosi stabilita:
      </p>
      <p className="paragraph">
        · si assegnano tutti i punti ancora non aggiudicati all'avversario con
        l'aggiunta di una scopa , fermo restando che la penalità massima è pari a 6
        punti,
      </p>
      <p className="paragraph">
        · se il punteggio è ancora a favore di chi ha fatto scadere il tempo o il
        timeout, la differenza punti è uguale a zero (ma la partita è comunque persa da
        chi ha fatto scadere il tempo).
      </p>
      <p className="paragraph">Calcolo dei VP e MP in caso di Timeout o abbandono </p>
      <p className="paragraph">
        Al fine del calcolo dei MP e VP, la linea che fa scadere il timeout o
        abbandona farà 0 VP e la linea avversaria farà minimo 14 VP. I MP di entrambe le
        linee saranno calcolati dai VP in base ai corrispondenti minimi previsti dalla
        tabella dei VP.
      </p>
      <p className="paragraph"><em>Si sottolinea che quest'ultima regola sul timeout e abbandono è valida solo per determinare i MP e i VP per le classifiche dei tornei a girone e i livelli di abilità in caso di timeout e abbandono , ma non per determinare la differenza punti nei tavoli cash che vengono sempre determinati come descritto nei <a href="generali.php?skin=SNAI&amp;mobile=&amp;argomento=cashgenerico&amp;capitolo=tavoliturni">regolamenti generali</a> anche in caso di timeout e abbandono.</em> </p>
    </ContentTabs>
  );
};

export const CirullaRegoleOpzionali = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Regole opzionali </h2>
      <h2 className="titolo2">Partite a "carte viste" </h2>
      <p className="paragraph">Saranno mostrate: </p>
      <p className="paragraph">· le carte viste nelle precedenti mani della smazzata; </p>
      <p className="paragraph">· nelle partite con dichiarazione, le carte in mano agli altri giocatori in base alle dichiarazioni effettuate; </p>
      <p className="paragraph">Nelle partite testa a testa "a carte viste" , l'ultima mano si gioca a carte scoperte. </p>
      <h2 className="titolo2">Partite a coppie a "carte scoperte" </h2>
      <p className="paragraph">Saranno proposte partite a coppie nelle quali i compagni, senza essere visti dagli avversari, giocano a carte scoperte. </p>
    </ContentTabs>
  );
};

export const CirullaOpzioniDiGiocoAutomatico = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Opzioni di gioco automatico</h2>
      <h2 className="titolo2">Opzioni di gioco automatico </h2>
      <p className="paragraph">Il sistema effettua automaticamente <strong>l'accuso secondo quanto previsto dal regolamento generale.</strong> </p>
      <p className="paragraph">Ciascun giocatore, se e quando lo desidera, può eliminare questo automatismo: </p>
      <p className="paragraph">· dalla lobby della cirulla, cliccando sul tasto "Il mio profilo" e selezionando "Opzioni di gioco"; </p>
      <p className="paragraph">· dal tavolo di cirulla, agendo sulle apposite selezioni. </p>
    </ContentTabs>
  );
};

export const CirullaTabellaDeiVictoryPoints = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Cirulla - Tabella dei Victory Points</h2>
      <h2 className="titolo2">Tabella dei Victory Points (VP)</h2>
      <table className="bordi" border={1} cellPadding="5" cellSpacing="5" width="299">
        <tbody>
          <tr>
            <td className="bordi" width="23"><p className="paragraph"><strong>RISULTATO</strong> </p></td>
            <td className="bordi" width="37"><p className="paragraph"><strong>VP</strong> </p></td>
          </tr>
          <tr>
            <td className="bordi" width="23"><p className="paragraph">Vittoria </p></td>
            <td className="bordi" width="37"><p className="paragraph">20 </p></td>
          </tr>
          <tr>
            <td className="bordi" width="23"><p className="paragraph">Pareggio (ove previsto) </p></td>
            <td className="bordi" width="37"><p className="paragraph">10 </p></td>
          </tr>
          <tr>
            <td className="bordi" width="23"><p className="paragraph">Sconfitta </p></td>
            <td className="bordi" width="37"><p className="paragraph">0 </p></td>
          </tr>
        </tbody></table>
    </ContentTabs>
  );
};
