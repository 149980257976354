import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const BestiaIlMazzoDiCarte = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Bestia - Il mazzo di carte </h2>
      <h2 className="titolo2">Il mazzo di carte </h2>
      <p className="paragraph">Si gioca con un mazzo di 40 carte italiane tradizionali. </p>
      <p className="paragraph">I giocatori possono selezionare il tipo di carte (napoletane, piacentine).</p>
      <p className="paragraph">E' anche possibile selezionare le carte francesi senza i Jolly, gli 8, i nove e i dieci. </p>
      <p className="paragraph">La corrispondenza dei semi tra carte italiani e francesi è la seguente: </p>
      <table className="bordi" border={1} cellPadding="5" width="221">
        <tbody>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">denari </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">quadri </p></td>
            <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♦ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">coppe </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">cuori </p></td>
            <td className="bordi" width="43"><p style={{ color: "#FF0000" }} className="paragraph">♥ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">bastoni </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">fiori </p></td>
            <td className="bordi" width="43"><p className="paragraph">♣ </p></td>
          </tr>
          <tr>
            <td className="bordi" width="72"><p className="paragraph">spade </p></td>
            <td className="bordi" width="22"><p className="paragraph">= </p></td>
            <td className="bordi" width="74"><p className="paragraph">picche </p></td>
            <td className="bordi" width="43"><p className="paragraph">♠ </p></td>
          </tr>
        </tbody></table>
      <h2 className="titolo2">Il valore delle carte - la briscola</h2>
      <p className="paragraph">
        Ai fini delle prese, Il valore delle carte nella bestia è esattamente uguale
        a quello della briscola e cioè, dall'alto al basso: Asso, tre, re, cavallo,
        fante, 7, 6, 5, 4, 2.
      </p>
      <p className="paragraph">
        All'inizio di ogni smazzata viene stabilito un seme dominante detto <strong>"briscola"</strong>:
        al fine di stabilire chi farà la presa, le carte del seme
        di briscola hanno valore maggiore di quelle di tutti gli altri semi.
      </p>
      <p className="paragraph">Nota bene: ai fini del punteggio, le carte non hanno valore ma conta solo il numero di prese fatte. </p>
    </ContentTabs>
  );
};

export const BestiaSvolgimentoDelGioco = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Bestia - Svolgimento del gioco </h2>
      <h2 className="titolo2">L'invito, il piatto, il rischio bestia </h2>
      <p className="paragraph">L'<strong><em>invito</em> </strong>è l'importo che i giocatori devono versare nel piatto per partecipare alla smazzata. </p>
      <p className="paragraph">Il <strong><em>piatto</em></strong> è la somma posta a centro tavolo che sarà suddivisa tra i vincitori della smazzata. </p>
      <p className="paragraph">
        Si dice che un giocatore <strong><em>va in bestia</em></strong><em> </em>
        quando non realizza neanche una presa nel gioco della carta. Si dice
        <strong> rischio bestia </strong>(o semplicemente "<strong>bestia</strong>")<strong>
        </strong>l'importo che devono versare nel piatto i giocatori che sono andati in
        bestia. </p>
      <p className="paragraph">Il piatto viene formato dal pagamento degli <em>inviti</em> e dalle <em> bestie.</em> </p>
      <p className="paragraph">
        Il valore del piatto a inizio smazzata, prima degli inviti, è detto <strong>
          <em>piatto iniziale. </em></strong>Il piatto iniziale può essere uguale a zero o
        può avere un valore se nella smazzata precedente si è verificata almeno una
        bestia.
      </p>
      <h2 className="titolo2">I giocatori che hanno diritto a partecipare alla smazzata </h2>
      <p className="paragraph">I giocatori che hanno diritto a partecipare a una smazzata vengono detti "giocatori attivi" e sono determinati come segue. </p>
      <p className="paragraph"><strong>
        Nei tornei vengono eliminati i giocatori che rimangono senza chips a
        inizio smazzata, prima di mettere l'invito. </strong>Si precisa che ciò è valido
        anche se, nella smazzata precedente, alcuni di essi avevano pagato una bestia;
        ciò detto:
      </p>
      <ul itemType="disc" className="paragraph">
        <li>se il piatto iniziale è uguale a zero, partecipano al gioco tutti i giocatori presenti al tavolo al termine della smazzata precedente; </li>
        <li>
          se il piatto iniziale è maggiore di zero, possono partecipare alla
          smazzata solo i giocatori che hanno versato l'invito nella smazzata
          precedente. Se un giocatore nuovo si aggiunge a un tavolo e il piatto
          iniziale è maggiore di zero, non gioca fino alla smazzata in cui il piatto
          iniziale torna ad essere = 0
        </li>
      </ul>
      <h2 className="titolo2">Il valore dell'invito e della bestia </h2>
      <p className="paragraph">Il valore massimo dell'invito è predeterminato ed aumenta nel tempo secondo "livelli" prestabiliti. </p>
      <p className="paragraph">Il valore massimo del rischio bestia è uguale al piatto. </p>
      <p className="paragraph">
        Può succedere, però, che uno o più giocatori presenti al tavolo non abbiano
        credito sufficiente per versare nel piatto l'invito previsto o, dopo aver
        versato l'invito, non siano in grado di pagare l'eventuale bestia. In questi
        casi valgono le seguenti regole:
      </p>
      <ul itemType="disc" className="paragraph">
        <li>
          se almeno un giocatore non ha credito sufficiente per versare
          l'invito previsto, l'invito è pari al credito residuo più basso tra quelli
          dei giocatori che hanno diritto a partecipare alla smazzata che si gioca con
          bestia = 0;
        </li>
        <li>
          se, dopo aver versato <u>l'invito</u>, almeno un giocatore non
          ha credito sufficiente per far fronte all'eventuale rischio bestia, il
          rischio bestia è determinato dal credito residuo più basso tra quelli dei
          giocatori che hanno diritto a partecipare alla smazzata;
        </li>
      </ul>
      <p className="paragraph">
        Si precisa che non è necessario fare i conti per stabilire questi valori
        perché, a inizio di ogni smazzata, appariranno sempre i seguenti valori:
      </p>
      <ul itemType="disc" className="paragraph">
        <li>invito </li>
        <li>piatto </li>
        <li>rischio bestia </li>
      </ul>
      <h2 className="titolo2">Designazione del mazziere</h2>
      <p className="paragraph">Il mazziere è il giocatore da cui il sistema fa partire la distribuzione delle carte. </p>
      <p className="paragraph">Quando un tavolo viene creato e per la prima volta il gioco ha inizio, il mazziere viene stabilito casualmente dal sistema. </p>
      <p className="paragraph">
        Ad ogni fine smazzata il mazziere gira in senso antiorario e cioè il prossimo
        mazziere sarà il giocatore attivo seduto alla destra del mazziere della smazzata
        precedente
      </p>
      <p className="paragraph">
        Se la partita viene interrotta perché il numero dei giocatori seduti è
        inferiore al numero minimo fissato dalle regole del tavolo, i giocatori presenti
        al momento dell'interruzione conservano la priorità ad essere mazzieri.<u> </u>
      </p>
      <p className="paragraph">
        Se un giocatore si alza dal tavolo perde questa priorità anche se rientra
        prima della ripresa della partita.
      </p>
      <h2 className="titolo2">Prelievo automatico degli inviti</h2>
      <p className="paragraph">
        L'invito viene sempre prelevato a tutti i giocatori che hanno diritto a
        partecipare alla smazzata, anche se sono in stato di sit out.
      </p>
      <h2 className="titolo2">Distribuzione delle carte e determinazione della briscola</h2>
      <p className="paragraph">
        Il sistema mescola le carte e distribuisce tre carte ai giocatori, in senso
        antiorario, partendo dal giocatore alla destra del mazziere.
      </p>
      <p className="paragraph">Quindi scopre una carta il cui seme indicherà la <strong>briscola</strong>.</p>
      <p className="paragraph">Al termine della distribuzione, le carte residue ("mazzo") vengono poste in modo da coprire parzialmente la briscola. </p>
      <h2 className="titolo2">Dichiarazione</h2>
      <p className="paragraph">
        Dopo aver esaminato le proprie carte, ciascun giocatore attivo a turno,
        partendo dal giocatore alla destra del giocatore di mano e proseguendo in senso
        antiorario, dovrà comunicare se:
      </p>
      <ul itemType="disc" className="paragraph">
        <li><strong><em>passare</em></strong> , cioè non partecipare alla smazzata, oppure<strong><em> </em></strong></li>
        <li>
          <strong><em>giocare</em></strong> , cioè partecipare alla smazzata, nel
          qual caso deve anche decidere quante carte cambiare (ne può cambiare quante
          ne vuole, anche tutte e tre).<strong><em> </em></strong>
        </li>
      </ul>
      <p className="paragraph">
        Terminato il primo giro di dichiarazioni, ogni giocatore attivo, avrà la
        facoltà, finché nel mazzo resteranno almeno tre carte, di <strong>partecipare al
          gioco</strong> <strong>al buio, </strong>pescando le prime tre carte dal mazzo<strong>.
        </strong>Se il giocatore prende questa decisione si impegna a partecipare alla
        smazzata anche se le carte pescate non dovessero piacergli.
      </p>
      <h2 className="titolo2">Gioco della carta</h2>
      <p className="paragraph">
        Inizia a giocare Il primo giocatore attivo alla destra del mazziere (" <em>
          giocatore di mano") </em>. Nelle mani successive alla prima,
        il <em>giocatore di mano</em> è quello che ha fatto la presa.
      </p>
      <p className="paragraph">Ogni giocatore, partendo dal <em>giocatore di mano</em> e proseguendo in senso antiorario, pone sul tavolo una carta scoperta. </p>
      <p className="paragraph">Le regole che ciascun giocatore deve rispettare sono le seguenti: </p>
      <ul itemType="disc" className="paragraph">
        <li>
          si deve giocare una carta del seme di uscita del primo giocatore <em>
            ("seme dominante</em>"); ad esempio se il primo giocatore gioca denari,
          tutti devono giocare una carta di denari;
        </li>
        <li>
          nel caso che un giocatore non abbia carte nel seme dominante, deve
          giocare una carta di briscola anche se tale briscola è inferiore alla
          briscola già giocata da un altro giocatore;
        </li>
        <li>se un giocatore non ha carte nel seme dominante e non ha briscole può giocare una carta qualsiasi; </li>
        <li>
          se il primo <em>giocatore di mano</em> possiede la briscola dominante
          (l'asso, oppure il 3 con l'asso scoperto per indicare la briscola) la deve
          giocare.
        </li>
      </ul>
      <p className="paragraph">
        La presa spetta al giocatore che ha giocato la carta più alta nel seme di
        uscita, a meno che non sia stata giocata una briscola, nel qual caso predomina
        la carta di briscola più alta.
      </p>
      <h2 className="titolo2">Spartizione delle vincite e pagamento delle bestie.</h2>
      <p className="paragraph">Al termine della smazzata, vengono contate le prese fatte da ciascun giocatore. </p>
      <p className="paragraph">
        Il piatto, viene diviso per tre e ciascuna parte, arrotondata alla seconda
        cifra decimale, spetta ai giocatori che hanno fatto una presa. I resti generati
        dagli arrotondamenti vengono attribuiti al giocatore che ha fatto la prima presa.
      </p>
      <p className="paragraph">Al giocatore che ha fatto due prese, spettano due parti e al giocatore che ha fatto tre prese spetta l'intero piatto. </p>
      <p className="paragraph">
        L'eventuale resto spetterà al primo giocatore partendo dal giocatore alla
        destra del mazziere e continuando in senso antiorario. Verrà quindi prelevata la
        bestia ai giocatori che, avendo deciso di giocare, non hanno fatto neanche una
        presa con gli stessi criteri di arrotondamento e resti previsti per la variante
        a tre carte.
      </p>
    </ContentTabs>
  );
};

export const BestiaComeSiGioca = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Bestia - Come si gioca </h2>
      <h2 className="titolo2">Dichiarazione</h2>
      <p className="paragraph">Nella fase di dichiarazione sono visualizzati tre tasti. </p>
      <ul itemType="disc" className="paragraph">
        <li><strong>Passo</strong> : cliccando questo tasto il giocatore non partecipa alla smazzata. </li>
        <li><strong>Servito</strong> : cliccando questo tasto il giocatore partecipa alla smazzata senza cambiare carte. </li>
        <li><strong>
          Cambio:</strong> prima di cliccare questo tasto il giocatore
          deve selezionare le carte che intende cambiare; solo dopo aver selezionato
          tutte le carte che vuol cambiare deve cliccare su questo tasto.
        </li>
      </ul>
      <p className="paragraph">Per deselezionare una carta è sufficiente cliccarla. </p>
      <h2 className="titolo2">Gioco della carta</h2>
      <p className="paragraph">Per giocare una carta si può: </p>
      <ul itemType="disc" className="paragraph">
        <li>fare un "doppio clic" sulla carta, oppure </li>
        <li>selezionare una carta (cioè cliccarla) e poi cliccare sulla carta semitrasparente che appare sul tavolo </li>
        <li>Selezionare una carta e, tenendo premuto il tasto sinistro del mouse, trascinarla sulla carta semitrasparente che appare sul tavolo </li>
      </ul>
      <p className="paragraph">Per deselezionare una carta è sufficiente cliccarla. </p>
      <h2 className="titolo2">Sit out nel gioco della Bestia </h2>
      <p className="paragraph">Rammentiamo che un giocatore va in stato di <em>sitout</em> nei seguenti casi: </p>
      <ul itemType="disc" className="paragraph">
        <li>per sua scelta, cliccando su "sitout prossima smazzata", </li>
        <li>perché non comunica la sua decisione entro il <em>tempo massimo,</em></li>
        <li>perché, all'inizio di un colpo, il suo credito residuo è inferiore al <em>credito residuo minimo.</em> </li>
      </ul>
      <p className="paragraph">
        Un giocatore presente al tavolo può uscire dallo stato di sit out cliccando
        sul tasto "sono tornato", oppure automaticamente nel caso di rientro nel tavolo.
      </p>
      <p className="paragraph">Ai giocatori in stato di <em>sitout</em> viene prelevato automaticamente l'invito.</p>
      <p className="paragraph">
        Se il giocatore va in sit out dopo aver messo l'invito ma prima di aver
        comunicato la sua decisione se giocare o meno, il sistema non lo fa giocare.
      </p>
      <p className="paragraph">
        Se il giocatore va in sit out dopo aver messo l'invito e dopo aver comunicato
        la sua decisione di voler giocare (servito o cambio carte o rientro), quando il
        giocatore è di turno, il sistema giocherà automaticamente la prima carta in mano
        tra quelle ammesse dalle regole del gioco.
      </p>
    </ContentTabs>
  );
};

export const BestiaRegoleOpzionali = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Bestia - Regole Opzionali </h2>
      <h2 className="titolo2">Bestia a 4 carte</h2>
      <p className="paragraph">Vengono distribuite 4 carte invece di tre. </p>
      <p className="paragraph">
        <strong>Il primo giocatore che decide di partecipare alla smazzata va in bestia se non realizza almeno due prese</strong> .
        Tutti gli altri partecipanti vanno in bestia se non realizzano almeno una presa.
      </p>
      <p className="paragraph">Il piatto finale viene suddiviso in 4 parti, ciascuna delle quali spetterà al giocatore che ha realizzato una presa. </p>
    </ContentTabs>
  );
};

export const BestiaRegoleDeiTavoliCash = () => {
  return (
    <ContentTabs>
      <h2 className="titolomenu">Regole della Bestia - Regole specifiche dei tavoli cash </h2>
      <h2 className="titolo2">Quota della raccolta destinata a montepremi</h2>
      <p className="paragraph">
        Al montepremi di ogni singola mano è destinata una percentuale della somma
        delle puntate dei giocatori, variabile sulla base della singola sessione di
        gioco, ma in tutti i casi non inferiore al 90%.
      </p>
      <p className="paragraph">
        L'importo del montepremi di una singola mano è sempre derivabile dalla somma
        delle puntate diminuite del margine lordo del concessionario (rake). 
      </p>
      <p className="paragraph">
        Il rake massimo applicato al tavolo è chiaramente indicato nel pannello
        informativo presente sulla destra della lobby e sul tavolo di gioco. 
      </p>
      <p className="paragraph">Rake calcolato sulla base degli importi incassati dai giocatori al termine della mano: </p>
      <p className="paragraph">Es. </p>
      <ul itemType="disc" className="paragraph">
        <li>Rake: 10% </li>
        <li>N° giocatori al tavolo: 3 </li>
        <li>Invito: € 10 </li>
      </ul>
      <p className="paragraph">· Piatto = invito * n° giocatori al tavolo = € 10 * 3 = € 30 </p>
      <p className="paragraph">Il giocatore A fa due prese, il giocatore B fa una presa: </p>
      <p className="paragraph">Il giocatore A incassa: Vincita - rake = (€ 10 *2 ) - ((€ 10 *2) * 0,1) = € 20 - € 2 = € 18 </p>
      <p className="paragraph">Il giocatore B incassa: Vincita - rake = (€ 10 *1 ) - ((€ 10 *1) * 0,1) = € 10 - € 1 = € 9 </p>
      <h2 className="titolo2">I giocatori che hanno diritto a partecipare alla smazzata </h2>
      <p className="paragraph">I giocatori che hanno diritto a partecipare a una smazzata vengono detti "giocatori attivi" e sono determinati come segue: </p>
      <p className="paragraph">Per sedersi a un tavolo è necessario versare dal proprio conto di gioco un importo almeno pari al credito minimo d'ingresso. </p>
      <p className="paragraph">
        Per poter iniziare a giocare una smazzata con piatto iniziale uguale a zero è
        necessario avere un credito residuo almeno pari al credito minimo.
      </p>
      <p className="paragraph">
        Un giocatore che ha partecipato a una smazzata ha sempre diritto a
        partecipare a tutte le successive smazzate fino a che il piatto torna ad essere
        uguale a zero. Solo in questo caso, per continuare, come detto, deve avere un
        credito sul tavolo almeno uguale al credito minimo.
      </p>
      <h2 className="titolo2">Il valore dell'invito e della bestia</h2>
      <p className="paragraph">Il valore massimo dell'invito è predeterminato ed è fisso per ogni tavolo.</p>
      <p className="paragraph">Il valore massimo del rischio bestia è uguale al piatto. </p>
      <p className="paragraph">
        Può succedere, però, che uno o più giocatori presenti al tavolo non abbiano
        credito sufficiente per versare nel piatto l'invito previsto o, dopo aver
        versato l'invito, non siano in grado di pagare l'eventuale bestia. In questi
        casi valgono le seguenti regole:
      </p>
      <ul itemType="disc" className="paragraph">
        <li>
          se almeno un giocatore non ha credito sufficiente per versare l'invito
          previsto, l'invito è pari al credito residuo più basso tra quelli dei
          giocatori che hanno diritto a partecipare alla smazzata che si gioca con
          bestia = 0; 
        </li>
        <li>
          se, dopo aver versato l'invito, almeno un giocatore non ha credito
          sufficiente per far fronte all'eventuale rischio bestia, il rischio bestia è
          determinato dal credito residuo più basso tra quelli dei giocatori che hanno
          diritto a partecipare alla smazzata; 
        </li>
      </ul>
      <p className="paragraph">
        Si precisa che non è necessario fare i conti per stabilire questi valori
        perché, a inizio di ogni smazzata, appariranno sempre i seguenti valori:
      </p>
      <ul itemType="disc" className="paragraph">
        <li>invito </li>
        <li>piatto </li>
        <li>rischio bestia </li>
      </ul>
      <h2 className="titolo2">Prelievo automatico degli inviti </h2>
      <p className="paragraph">
        L'invito non viene prelevato ai giocatori in stato di sit out, che quindi non
        partecipano alla smazzata anche se il piatto iniziale fosse maggiore di zero.
      </p>
      <p className="paragraph">
        Rammentiamo che se un giocatore non desidera partecipare alla smazzata
        successiva, con prelievo automatico, deve selezionare la casella "sitout".
      </p>
      <h2 className="titolo2">Spartizione delle vincite e pagamento delle bestie</h2>
      <p className="paragraph">Al termine della smazzata, vengono contate le prese fatte da ciascun giocatore. </p>
      <p className="paragraph">
        Il piatto, previa trattenuta del rake, viene diviso per tre e ciascuna parte,
        arrotondata alla seconda cifra decimale, spetta ai giocatori che hanno fatto una
        presa. I resti generati dagli arrotondamenti vengono attribuiti al giocatore che
        ha fatto la prima presa.
      </p>
      <p className="paragraph">Al giocatore che ha fatto due prese, spettano due parti e al giocatore che ha fatto tre prese spetta l'intero piatto. </p>
      <p className="paragraph">
        L'eventuale resto spetterà al primo giocatore partendo dal giocatore alla
        destra del mazziere e continuando in senso antiorario. Ad esempio con un piatto
        di € 10,00 le tre parti saranno: € 3,34, € 3,33, € 3,33. La parte di € 3,34
        spetterà al primo giocatore.
      </p>
      <p className="paragraph">
        Verrà quindi prelevata la bestia ai giocatori che, avendo deciso di giocare,
        non hanno fatto neanche una presa con gli stessi criteri di arrotondamento e
        resti previsti per la variante a tre carte.
      </p>
      <h2 className="titolo2">Sit out nel gioco della bestia cash</h2>
      <p className="paragraph">
        Se un giocatore salta tre turni consecutivi, perché in sit out, è fatto
        alzare automaticamente dal tavolo con accredito del suo credito residuo sul suo
        conto di gioco.
      </p>
      <p className="paragraph">
        Ai giocatori in stato di sitout non viene prelevato l'invito e quindi non
        partecipano alla smazzata. Questa regola è valida anche se il piatto iniziale è
        maggiore di zero o se l'invito è uguale a zero o se il rischio bestia è uguale a zero.
      </p>
    </ContentTabs>
  );
};
