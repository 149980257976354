import { TypographyOptions } from '@mui/material/styles/createTypography';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    details: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    details?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    details: true;
  }
}

const typography: TypographyOptions = {
  fontFamily: ['Inter', 'Inter V', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold:700,
  h1: {
    fontSize: '3rem',
    fontWeight: 800,
    lineHeight: '58px',
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '38px',
  },
  h3: {
    fontSize: '1.625rem',
    fontWeight: 700,
    lineHeight: '31px',
  },
  h4: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '24px',
    display: 'inline-flex',
    alignItems: 'center',
  },
  h5: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.3',
    color: '#0B0E11',
  },
  h6: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '22px',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.3',
    color: '#101223',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.3',
    color: '#101223',
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: '1.3',
    color: '#101223',
  },
  button: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.0',
    letterSpacing: '0.01em',
    textTransform: 'none',
    boxShadow: 'none'
  },
  details: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.3',
    color: '#101223',
  },
};

export default typography;
