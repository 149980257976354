import ContentTabs from "shared/Regulation/components/ContentTabs";

export const SolitariollMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Solitario - Il mazzo di carte </h2>
            <p className="paragraph">Si gioca con un mazzo di carte francesi per un totale di 52 carte.</p>
        </ContentTabs>
    );
};

export const SolitarioSvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Solitario - Svolgimento del gioco </h2>
            <p className="paragraph">Lo scopo del Solitario è di spostare le carte sulle quattro pile dette “basi”. Ogni pila deve essere composta da carte dello stesso seme. Inoltre le carte devono essere disposte in ordine, dall'Asso al Re (A-2-3-4-5-6-7-8-9-10-Jack-Donna-Re).</p>
            <h2>Distribuzione delle carte</h2>
            <p>Il sistema mescola le carte e le dispone in 7 colonne come segue:</p>
            <ul>
                <li>1’ colonna: 1 carta scoperta</li>
                <li>2’ colonna: 1 carta coperta e sopra 1 carta scoperta</li>
                <li>3’ colonna: 2 carte coperte e sopra 1 carta scoperta</li>
                <li>4’ colonna: 3 carte coperte e sopra 1 carta scoperta</li>
                <li>5’ colonna: 4 carte coperte e sopra 1 carta scoperta</li>
                <li>6’ colonna: 5 carte coperte e sopra 1 carta scoperta</li>
                <li>7’ colonna: 6 carte coperte e sopra 1 carta scoperta</li>
            </ul>
            <p>Delle carte rimanenti del mazzo, verranno mostrate, e posizionate sul tavolo, le prime tre, formando il “mazzetto”.</p>
            <p>In alto sono presenti quattro carte semitrasparenti che corrispondono alle quattro basi dove verranno posizionate le carte dello stesso seme, dall'Asso al Re.</p>
            <p>La situazione iniziale di gioco consentirà sempre di poter effettuare almeno una mossa e di poter realizzare dei punti per la classifica finale della partita.</p>
            <p>Durante lo svolgimento del gioco potrebbe verificarsi una situazione, detta “stallo”, dove non è più possibile effettuare mosse consentite dal regolamento. In questo caso apparirà un avviso sul tavolo, e il giocatore dovrà attendere il completamento della partita.</p>
            <h2>Meccanismo di gioco</h2>
            <p>Non è consentito spostare una carta sul mazzetto.</p>
            <p>Durante il gioco si potranno spostare carte su una colonna seguendo la regola che tutte le carte scoperte di una colonna devono essere in ordine decrescente e a colori alternati.</p>
            <p>es. Re rosso - Donna nera - Jack rosso - 10 nero - 9 rosso - 8 nero ….</p>
            <p>Sulle colonne potranno essere posizionate:</p>
            <ol>
                <li>una sequenza di carte scoperte di un’altra colonna</li>
                <li>la prima carta visibile del mazzetto</li>
                <li>una delle carte esposte e visibili su una delle 4 basi</li>
            </ol>
            <p>Si potrà spostare una carta per volta su una delle basi seguendo la regola che tutte le carte, presenti su una delle basi, dovranno essere in ordine crescente, a partire dall’asso, e dello stesso seme.</p>
            <p>Le carte posizionabili sulle basi possono essere:</p>
            <ol>
                <li>la prima carta visibile del mazzetto</li>
                <li>la carta più bassa e scoperta di una colonna</li>
            </ol>
            <p>Se non sono disponibili mosse, è necessario girare nuove carte facendo clic sul mazzo nell'angolo in alto.</p>
            <h1>Punteggi</h1>
            <p>Vengono assegnati:</p>
            <ul>
                <li>5 punti quando si sposta una carta dal mazzetto;</li>
                <li>5 punti quando si scopre una carta di una delle colonne;</li>
                <li>10 punti per ogni carta posizionata su una delle basi;</li>
                <li>meno 10 punti per ogni carta tolta da una delle basi;</li>
            </ul>
            <h2>Parità</h2>
            <p>In caso di parità tra due o più giocatori, ha la miglior posizione in classifica chi ha raggiunto per primo il punteggio finale.</p>
            <p>Esempio:</p>
            <ul>
                <li>il giocatore A totalizza 50 punti alla mossa 32</li>
                <li>il giocatore B totalizza 50 punti alla mossa 27</li>
                <li>il giocatore B avrà una migliore posizione in classifica perché ha raggiunto per primo i 50 punti.</li>
            </ul>
            <p>N.B.: Ogni volta che uno dei partecipanti effettua una mossa di gioco, un contatore generale detto “mossa” viene aumentato di 1. Come specificato sopra e nell’esempio, in caso di parità si guarderà l’ultima mossa in cui sono stati realizzati dei punti.</p>
            <p>In caso di parità tra giocatori che non hanno effettuato alcuna mossa durante la partite, avrà una migliore posizione in classifica il giocatore stabilito casualmente dal sistema.</p>
            <h2>Variante senza tempo</h2>
            <p>In una variante di gioco, espressamente indicata nelle informazioni di gioco, è previsto che in caso di parità di punti non conti chi ha raggiunto per primo il punteggio finale. In questa variante in caso di parità si rigioca una nuova partita ripatendo da zero punti. Anche nelle partite con più di due giocatori è sufficiente che i primi due siano in parità di punti per rigiocare una nuova partita a cui parteciperanno tutti i giocatori ripartendo da zero punti.</p>
            <h1>Conclusione della partita</h1>
            <p>La partita si conclude quando:</p>
            <ul>
                <li>tutti i giocatori rientrano nei seguenti casi:
                    <ul>
                        <li>solitario risolto</li>
                        <li>stallo</li>
                    </ul>
                </li>
                <li>scade il tempo</li>
            </ul>
            <p>Vince chi ha totalizzato il maggior numero di punti, e la classifica finale si basa sempre sui punteggi conseguiti dai giocatori.</p>
        </ContentTabs>
    );
};

export const SolitarioComeSiGioca = () => {
    return (
      <ContentTabs>
        <h2 className="titolomenu">Regole del Solitario - Come si gioca </h2>
        <h2>Tasti e metodi per comunicare le proprie decisioni</h2>
        <p>Per spostare una carta si può:</p>
        <ul>
            <li>selezionare una carta (cioè cliccarla) e poi cliccare su una delle destinazioni consentite.</li>
            <li>Selezionare una carta e, tenendo premuto il tasto sinistro del mouse, trascinarla su una delle destinazioni consentite</li>
            <li>Fare doppio click sulla carta se la carta può andare in una delle basi</li>
        </ul>
        <p>Per deselezionare una carta è sufficiente cliccarla.</p>
      </ContentTabs >
    );
  };