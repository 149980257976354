export const MedalIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2404_1864)">
      <path d="M11.6894 12.075C11.3799 12.075 11.185 12.2225 11.185 12.6075V15.525C11.185 15.91 11.3799 16.0575 11.6894 16.0575C11.9989 16.0575 12.1937 15.91 12.1937 15.525V12.6075C12.1937 12.2225 11.9989 12.075 11.6894 12.075Z" fill="currentColor"/>
      <path d="M8.58862 10.31H8.89526L8.74051 9.4175L8.58862 10.31Z" fill="currentColor"/>
      <path d="M11.3541 10.4475V9.53C11.3541 9.405 11.2939 9.3575 11.1936 9.3575H11.0131V10.6175H11.1936C11.2939 10.6175 11.3541 10.5725 11.3541 10.445V10.4475Z" fill="currentColor"/>
      <path d="M10.0014 0L0 5V15L10.0014 20L20.0029 15V5L10.0014 0ZM10.6949 9.105H11.1964C11.5145 9.105 11.6693 9.26 11.6693 9.54V10.4325C11.6693 10.715 11.5145 10.87 11.1964 10.87H10.6949V9.105ZM9.58877 3.1925H10.5631V8.595H9.58877V3.1925ZM6.12982 7.2975V6.965H7.04972V7.35C7.04972 7.735 7.24459 7.875 7.55409 7.875C7.86359 7.875 8.05846 7.735 8.05846 7.35C8.05846 6.2375 6.15561 6.03 6.15561 4.4875C6.15561 3.6225 6.64279 3.13 7.58848 3.13C8.53417 3.13 9.02135 3.625 9.02135 4.4875V4.6575H8.10145V4.4325C8.10145 4.0475 7.92377 3.9 7.61427 3.9C7.30477 3.9 7.1271 4.0475 7.1271 4.4325C7.1271 5.545 9.02995 5.7525 9.02995 7.295C9.02995 8.16 8.53417 8.6525 7.57988 8.6525C6.62559 8.6525 6.12982 8.1575 6.12982 7.295V7.2975ZM9.31365 10.87H8.99269L8.93824 10.55H8.5485L8.49405 10.87H8.20175L8.52558 9.105H8.98983L9.31365 10.87ZM9.66328 13.185H8.74337V12.6075C8.74337 12.2225 8.5485 12.075 8.239 12.075C7.9295 12.075 7.73463 12.2225 7.73463 12.6075V15.525C7.73463 15.91 7.9295 16.05 8.239 16.05C8.5485 16.05 8.74337 15.91 8.74337 15.525V14.53H8.30205V13.7575H9.66328V15.47C9.66328 16.335 9.1675 16.8275 8.21321 16.8275C7.25892 16.8275 6.76315 16.3325 6.76315 15.47V12.66C6.76315 11.795 7.25892 11.3025 8.21321 11.3025C9.1675 11.3025 9.66328 11.7975 9.66328 12.66V13.185ZM9.74352 9.5925V10.87H9.45694V9.105H9.85528L10.182 10.1625V9.105H10.4657V10.87H10.139L9.74352 9.5925ZM13.1652 15.47C13.1652 16.335 12.6436 16.8275 11.6865 16.8275C10.7293 16.8275 10.2106 16.3325 10.2106 15.47V12.66C10.2106 11.795 10.7322 11.3025 11.6865 11.3025C12.6408 11.3025 13.1652 11.7975 13.1652 12.66V15.47ZM13.9934 3.965H12.9761V8.595H12.0017V3.965H10.9844V3.1925H13.9934V3.965Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_2404_1864">
      <rect width="20" height="20" fill="currentColor"/>
    </clipPath>
    </defs>
  </svg>
);
