/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import {
    BuyinPopup,
    CashTableConfig,
    CashTablePlayer,
    User,
    cashTableActions,
    convertCentToEuro,
    formatMoney,
    selectActiveTableId,
    selectBalance,
    selectBuyinPopup,
    selectCashTableConfig,
    selectCashTablePlayer,
    selectCurrentUser,
    CashTableGameRound,
    selectCashTableRound,
    RoundState,
    GameHelper
} from '@quanticogames/gameclient-core';
import { NumericFormatCustom } from '@quanticogames/gameclient-components';
import {
    StyledBackDrop,
    StyledBalance,
    StyledButton,
    StyledContent,
    StyledSlider,
    StyledStack,
    StyledTextField,
    StyledTitle,
    StyledValueBtn,
    StyledWarning,
} from './styled';
import ModalComponent from 'shared/components/modal';

const ModalJoinTable: React.FC = () => {
    const dispatch = useDispatch();
    const balance: number = useSelector(selectBalance);
    const tableId: number = useSelector(selectActiveTableId);
    const round: CashTableGameRound = useSelector(selectCashTableRound());
    const tableConfig: CashTableConfig = useSelector(selectCashTableConfig(tableId));
    const buyinPopup: BuyinPopup = useSelector(selectBuyinPopup);
    const currentUser: User = useSelector(selectCurrentUser);
    const { open, seatId, loading } = buyinPopup;
    const { t } = useTranslation();
    const [value, setValue] = useState<number>(0);
    const player: CashTablePlayer = useSelector(selectCashTablePlayer(currentUser.id, tableId));
    const timeOut = useRef(null);
    const isMobile = GameHelper.isMobile();

    const insufficientBalance = useMemo(
        () => balance < (tableConfig?.minStake || 0),
        [balance, tableConfig]
    );

    const maxStake = useMemo(
        () => Math.min(balance, tableConfig?.maxStake || 0),
        [balance, tableConfig]
    );

    const disableBuyin = useMemo(
        () => insufficientBalance || (value > balance),
        [insufficientBalance]
    );

    useEffect(() => {
        if (open) setValue(tableConfig?.minStake || 0);
    }, [open]);

    useEffect(() => {
        if (value === 0) setValue(tableConfig?.minStake || 0);
    }, [tableConfig]);

    useEffect(() => {
        if (player && tableConfig?.invitation > player?.balance && round?.state === RoundState.waiting) {
            timeOut.current = setTimeout(() => {
                dispatch(cashTableActions.setBuyinPopup({
                    open: true,
                    seatId: seatId,
                    rebuy: true,
                }));
            }, 1200);
        };
        return () => {
            timeOut.current && clearTimeout(timeOut.current);
        };
    }, [tableConfig, player, round]);

    const onCloseModal = () => {
        if (buyinPopup.rebuy) {
            dispatch(cashTableActions.leaveTable({
                tableId,
                playerId: currentUser?.id,
            }));
        };
        dispatch(cashTableActions.setBuyinPopup({
            open: false,
            seatId: null,
            loading: false,
            rebuy: false,
        }));
    };

    const handleSubmit = () => {
        if (buyinPopup.rebuy) return handleRebuy();
        return handleBuyIn();
    };

    const handleBuyIn = () => {
        dispatch(cashTableActions.joinTable({
            tableId,
            seatId,
            initialStake: value,
        }));
    };

    const handleRebuy = () => {
        dispatch(cashTableActions.rebuy({
            tableId,
            amount: value,
        }));
    };

    return (
        <>
            <ModalComponent
                open={open}
                onClose={onCloseModal}
                title={t('Buy-in')}
                size='medium'
                className={isMobile ? 'mobile' : ''}
                sx={{ '&.mobile .modal-main': { padding: '8px' } }}
            >
                <StyledContent className='content-main-modal'>
                    {loading && (
                        <StyledBackDrop>
                            <img
                                src='/images/common/loading_circle.svg'
                                className='loader w-[50px]'
                            />
                        </StyledBackDrop>
                    )}
                    <p className='font-bold uppercase'>{t('Your current balance')}</p>
                    <StyledBalance children={`€ ${formatMoney(convertCentToEuro(balance))}`} />
                    <StyledTitle>Quanto vuoi portare al tavolo?</StyledTitle>
                    {insufficientBalance ? (
                        <StyledWarning children={t('Insufficient Balance')} />
                    ) : (
                        <>
                            <StyledStack direction={'row'} gap={4}>
                                <StyledValueBtn
                                    onClick={() => setValue(tableConfig?.minStake || 0)}
                                    disabled={value <= tableConfig?.minStake}
                                >
                                    <span>{t('Min')}</span>
                                    {`(€ ${formatMoney(convertCentToEuro(tableConfig?.minStake || 0))})`}
                                </StyledValueBtn>
                                <Box className='flex-1 d-flex'>
                                    <StyledSlider
                                        sx={{ height: '20px', color: '#3F51B5' }}
                                        defaultValue={tableConfig?.minStake || 0}
                                        value={value}
                                        min={tableConfig?.minStake || 0}
                                        max={maxStake}
                                        onChange={(_, val) => setValue(val as number)}
                                    />
                                </Box>
                                <StyledValueBtn
                                    onClick={() => setValue(maxStake)}
                                    disabled={value >= maxStake}
                                >
                                    <span>{t('Max')}</span>
                                    {`(€ ${formatMoney(convertCentToEuro(maxStake))})`}
                                </StyledValueBtn>
                            </StyledStack>
                            <Stack direction={'row'} gap={4} alignItems={'center'}>
                                <span>{t('Buy in')}: €</span>
                                <StyledTextField
                                    variant='outlined'
                                    value={value}
                                    error={value > balance}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e?.target?.value))
                                    }
                                    InputProps={{
                                        inputComponent: NumericFormatCustom as any,
                                        inputProps: {
                                            min: tableConfig?.minStake || 0,
                                            max: maxStake,
                                            // delayTime: 1000,
                                        },
                                    }}
                                />
                            </Stack>
                        </>
                    )}
                </StyledContent>

                <Stack gap={'10px'} direction={'row'} alignItems="center" width="100%" justifyContent={'center'}>
                    <StyledButton
                        color='error'
                        title='Annulla'
                        onClick={onCloseModal}
                        disabled={loading}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '120px !important' }}
                    />
                    <StyledButton
                        title='Iscriviti'
                        onClick={handleSubmit}
                        disabled={disableBuyin || loading}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '120px !important' }}
                    />

                </Stack>
            </ModalComponent>
        </>
    );
};

export default ModalJoinTable;
