import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import {
  BoardCardGame,
  BoardGameContext,
  Game,
  GameHelper,
  GameRound,
  matchActions,
  selectActiveGame,
  selectActiveMatchId,
  selectCasino,
  selectCashTableRound,
  selectRound,
  tournamentActions,
  useCardType,
  selectActiveTableId,
  cashTableActions,
  convertCentToEuro,
  Tournament,
  selectCancelReplyTournament,
  CancelTournamentReply,
  selectActiveTournament,
  gameActions,
  SoundManager,
  Sound,
} from '@quanticogames/gameclient-core';

import { PATH_ROUTES } from 'constants/routes';
import { GameFlow } from 'games/game-flow';
import SideBar from 'layouts/sidebar';
import Table from 'shared/components/table';
import Loading from 'shared/components/loading';
import FullScreen from 'shared/FullScreenButton';
import { EndSession, ConfirmEndSession } from 'shared';
import TournamentInformation from 'shared/components/tournament-information';

import CashTableInformation from 'pages/cash-table/component/cash-table-information';
import { useTranslation } from 'react-i18next';
import { ChatAnimation } from 'shared/Chat/Component/chat-animation';
import ModalJoinTable from 'pages/cash-table/modal/modal-join-table';
import ModalJoinTableSuccess from 'pages/cash-table/modal/modal-join-table-success';
import ModalEliminate from 'shared/Tournament/ModalEliminate';
import ModalForceLeave from 'pages/cash-table/modal/ModalForceLeave';
import ModalTerminate from 'pages/cash-table/modal/ModalTerminate';
import ModalCancelTournament from 'pages/tournament/modal/modal-cancel-tournament';
import ButtonComponent from 'shared/components/button';
import { InfoOutlined } from '@mui/icons-material';
import ModalSitNotification from 'pages/cash-table/modal/ModalSitNotification';
import { ColpoGrosso } from 'games/colpo-grosso';

const LayoutGame = () => {
  const { t } = useTranslation();
  const activeGame: Game = useSelector(selectActiveGame);
  const [open, setOpen] = useState(false);
  const isMobile = GameHelper.isMobile();
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [showCrazyPrizeAnimation, setShowCrazyPrizeAnimation] = useState<boolean>(false);
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
  const id: number = useSelector(isCashTable ? selectActiveTableId : selectActiveMatchId);
  const round: any = useSelector(isCashTable ? selectCashTableRound() : selectRound());
  const activeTournament = useSelector(selectActiveTournament) as Tournament;
  const cancelTournamentData = useSelector(selectCancelReplyTournament(activeTournament?.id)) as CancelTournamentReply;
  const [isCancelTournament, setIsCancelTournament] = useState<boolean>(false);
  const [isShowInfoTable, setIsShowInfoTable] = useState<boolean>(false);

  useEffect(() => {
    if (cancelTournamentData) {
      setIsCancelTournament(true);
    }
  }, [cancelTournamentData]);

  useEffect(() => {
    if (isCashTable) {
      if (id) dispatch(cashTableActions.loadCashTable(id));
      return () => { };
    }
    const matchId = id;
    if (matchId) dispatch(tournamentActions.getTournament({ matchId }));
    return () => {
      dispatch(matchActions.sitout({ matchId }));
    };
  }, [id]);

  useEffect(() => {
    if (round?.loaded) setLoading(false);
  }, [round?.loaded]);

  useEffect(() => {
    if (!round?.crazyPrize?.showAnimation) return;
    const showAnimation = localStorage.getItem(`isRunAnimationsColpoGrosso_${id}`);
    if(showAnimation) return;
    const crazyPrize = new ColpoGrosso({
      data: {
        ...round.crazyPrize,
        cost: convertCentToEuro(round.tournamentCost || 0),
        prize: convertCentToEuro(round.totalPrize),
      },
      container: '.colpo-grosso-wrapper',
      onComplete: () => {
        localStorage.setItem(`isRunAnimationsColpoGrosso_${id}`, 'true');
        setShowCrazyPrizeAnimation(false);
        crazyPrize?.unmount();
      },
    });

    crazyPrize.start();
    setShowCrazyPrizeAnimation(true);

    return () => {
      crazyPrize?.unmount();
    };
  }, [round?.crazyPrize?.showAnimation]);

  const [boardGame, updateBoardGame] = useState<BoardCardGame>(null);
  const setBoardGame = (board: BoardCardGame) => {
    if (boardGame) {
      boardGame.unmount();
      boardGame.destroy();
    }

    updateBoardGame(board);
  };

  useEffect(() => {
    return () => boardGame?.destroy();
  }, [boardGame]);

  const cardType = useCardType(activeGame?.code);
  useEffect(() => {
    if (cardType && boardGame) boardGame.setCardType(cardType);
  }, [cardType]);

  const isShowFullScreen = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isMobileApp = navigator.userAgent === 'qg-mobile-app';
    return isMobile && !isSafari && !isMobileApp;
  };

  const handleCancelTournament = () => {
    dispatch(gameActions.goLobby({}));
    setIsCancelTournament(false);
  };

  const handleOpenInfoTable = () => {    
    SoundManager.play(Sound.clickButton);
    setIsShowInfoTable(!isShowInfoTable);
  }

  return (
    <BoardGameContext.Provider value={{ boardGame, setBoardGame }}>
      <div className="main-page-game">
        <div
          className={`colpo-grosso-container ${
            !showCrazyPrizeAnimation ? 'invisible' : 'z-[10000]'
          } flex items-center justify-center`}
        >
          <div className="colpo-grosso-wrapper absolute"></div>
        </div>
        <div
          className={`
                  game-container flex flex-col ${skinType || 'quantico'}
                  fixed left-0 right-0 bottom-0 z-[100] bg-game bg-game-${activeGame?.code}
                  ${isMobile ? 'top-0' : 'top-[60px]'}
              `}
        >
          <div className={`header-mobile px-[15px] py-[10px] w-[100px] flex items-center justify-start ${round.state === "finished" ? 'z-0' : 'z-10'}`}>
            <a className="header-bar px-[5px] py-[5px] cursor-pointer" onClick={() => setOpen(true)}>
              <img src="/images/common/menuNew.svg" alt="menu" width="" height="" />
            </a>
            {isShowFullScreen() && <FullScreen />}
          </div>

          <div
            id="main-table"
            className={`main-table main-table-${activeGame?.code} flex items-center justify-start md:justify-center flex-col ${activeGame?.code}`}
          >
            <div className="info-table-box">
              <ButtonComponent
                onClick={handleOpenInfoTable}
                icon={<InfoOutlined color="inherit" />}
                variant="text"
                size="large"
                className="info-table-icon"
                sx={{ padding: 0, minWidth: 0 }}
              />
              {isShowInfoTable && <>{isCashTable ? <CashTableInformation /> : <TournamentInformation />}</>}
            </div>
            {activeTournament?.matches && activeTournament?.matches.length > 0 && cancelTournamentData && (
              <ModalCancelTournament
                isOpen={isCancelTournament}
                onCloseModal={handleCancelTournament}
                dataCancel={t('Message cancel in game', { tournamentId: cancelTournamentData?.tournamentId })}
              />
            )}
            <Table>
              <ChatAnimation />
              <Outlet />
            </Table>
            <GameFlow />
          </div>
          <SideBar open={open} onClose={() => setOpen(false)} />
          {isCashTable ? (
            <>
              <ModalJoinTable />
              <ModalJoinTableSuccess />
              <ModalForceLeave />
              <ModalTerminate />
              <ModalSitNotification />
            </>
          ) : (
            <ModalEliminate />
          )}

          {loading && <Loading color="#fff" className="top-0" inGame={true} />}
        </div>
        <EndSession />
        <ConfirmEndSession />
      </div>
    </BoardGameContext.Provider>
  );
};
export default LayoutGame;
