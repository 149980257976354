import theme from '../../theme';

export const styles = {
  CommonTable: {
    pt: '8px',
  },
  TableHead: {
    '.MuiTableCell-root': {
      padding: '12px 14px',
    },
    [theme.breakpoints.down('md')]: {
      '.MuiTableRow-root': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 16px',
      },
      '.MuiTableCell-root': {
        '&:nth-of-type(1)': {
          textAlign: 'left',
          width: '90px',
        },
        padding: '8px 0',
        width: '57px',
        textAlign: 'center',
        '@media (orientation: landscape)': {
          textAlign: 'left',
        },
      },
    },
  },
  Box: {
    paddingBottom: '25px',
  },
};
