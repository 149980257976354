import { Box, styled, Typography } from '@mui/material';
import { ParticipantIcon } from '../../../../assets/icons/ParticipantIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Root = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TextWrapper = styled(Box)(() => ({
  marginTop: '32px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const EmptyData = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <ParticipantIcon />
      <TextWrapper>
        <Typography variant="h5" style={{fontSize: 14}}>{t("No participants yet")}</Typography>
      </TextWrapper>
    </Root>
  )
}

export const CrazyPrizeRule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Root>
      <ParticipantIcon />
      <TextWrapper>
        <Typography variant="h5"  onClick={() => navigate(`/information/regulation/lobby?tabs=4`)}><a className="hover:text-sky-600" href="#">Visualizza tabelle del montepremi variabile</a></Typography>
        <Typography variant="details" mt={1}>
        </Typography>
      </TextWrapper>
    </Root>
  )
}
