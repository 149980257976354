import {  styled } from '@mui/material';

interface ImageProps extends React.HTMLAttributes<HTMLDivElement> {
    width: number;
    height: number;
}

export const Image = styled('img')<ImageProps>(({ theme, width, height }) => ({
    width: width,
    height: height,
}));
