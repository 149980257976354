export const Treble = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <g clipPath="url(#clip0_2406_1882)">
      <path d="M11.0473 7.5715C10.772 7.5715 10.5973 7.70688 10.5973 8.06075V10.735C10.5973 11.0889 10.772 11.2243 11.0473 11.2243C11.3226 11.2243 11.4973 11.0889 11.4973 10.735V8.06075C11.4973 7.70688 11.3226 7.5715 11.0473 7.5715Z" fill="currentColor"/>
      <path d="M16.659 7.5715C16.3838 7.5715 16.209 7.70688 16.209 8.06075V10.735C16.209 11.0889 16.3838 11.2243 16.659 11.2243C16.9343 11.2243 17.109 11.0889 17.109 10.735V8.06075C17.109 7.70688 16.9343 7.5715 16.659 7.5715Z" fill="currentColor"/>
      <path d="M9.39286 0L0.154602 4.75V14.25L9.39286 19L18.6311 14.25V4.75L9.39286 0ZM3.17226 7.6285H1.74549V9.12H2.8652V9.82775H1.74549V11.8726H0.877251V6.92075H3.17226V7.6285ZM4.2999 11.8726H3.43167V6.92075H4.2999V11.8726ZM7.06609 11.8726H4.77108V6.92075H5.63932V11.1649H7.06609V11.8726ZM9.5808 11.8726H7.28579V6.92075H8.15403V11.1649H9.5808V11.8726ZM12.3629 10.6851C12.3629 11.4784 11.897 11.9296 11.0473 11.9296C10.1976 11.9296 9.73168 11.476 9.73168 10.6851V8.11063C9.73168 7.31738 10.1976 6.86613 11.0473 6.86613C11.897 6.86613 12.3629 7.31975 12.3629 8.11063V10.6851ZM15.1952 7.6285H14.2873V11.8726H13.419V7.6285H12.5111V6.92075H15.1926V7.6285H15.1952ZM17.9773 10.6851C17.9773 11.4784 17.5114 11.9296 16.6617 11.9296C15.812 11.9296 15.3461 11.476 15.3461 10.6851V8.11063C15.3461 7.31738 15.812 6.86613 16.6617 6.86613C17.5114 6.86613 17.9773 7.31975 17.9773 8.11063V10.6851Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_2406_1882">
        <rect width="18.4739" height="19" fill="currentColor" transform="translate(0.154602)"/>
      </clipPath>
    </defs>
  </svg>
);
