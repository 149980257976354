import { Tournament, convertCentToEuro, formatMoney, selectActiveTournament } from '@quanticogames/gameclient-core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const TournamentInformation = () => {
    const { t } = useTranslation();
    const tournament: Tournament = useSelector(selectActiveTournament);
    const tournamentMap = {
        'Normal': 'Sit & Go',
        'Crazy_Prize': 'Colpo Grosso',
        'Treble': 'Filotto',
    }

    return (
        <>
            <div className='tournament-information'>
                <p>{t("Tournament Code")}: {t(tournament?.code)}</p>
                {
                    tournament?.aamsCode && <p>{t("AAMS Code")}: {tournament?.aamsCode}</p>
                }
                <p>{t("Mode")}: {t(tournamentMap[tournament?.type])}</p>
                {
                    tournament?.prizePlan &&
                    <>
                        <p>{t("Cost")}: € {formatMoney(convertCentToEuro(tournament?.cost))}</p>
                        <p>{t("Winner prize")}: € {formatMoney(convertCentToEuro(tournament?.prizePlan?.totalPrize))}</p>
                    </>
                }
            </div>
        </>
    )
}

export default TournamentInformation
