import { IAlertMessage } from '@quanticogames/gameclient-core';
import AlertMessageItem from 'shared/AlertMessageModal/AlertMessageItem';
import ModalComponent from 'shared/components/modal';
import './index.scss';

type ModalComponenProps = {
  open: boolean;
  onClose?: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  alertMessage?: IAlertMessage
};

const AlertMessageModal = (props: ModalComponenProps) => {
  const { open, onClose, alertMessage } = props;

  const onCloseModal = () => {
    onClose();
  };

  return (
    <ModalComponent
      className="alert-message-container"
      open={open}
      title={alertMessage?.title || ""}
      onClose={onCloseModal}
    >
      <AlertMessageItem alertMesage={alertMessage}/>
    </ModalComponent>
  );
};

export default AlertMessageModal;
