import ContentTabs from 'shared/Regulation/components/ContentTabs';

export const ItalianPokerIlMazzoDiCarte = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Poker 5 carte - Il mazzo di carte </h2>
            <h2 className="titolo2">Il mazzo di carte </h2>
            <p className="paragraph">Il Poker 5 carte si gioca con un massimo di 6 giocatori al tavolo </p>
            <p className="paragraph">Si gioca con un mazzo di carte francesi senza i jolly. </p>
            <p className="paragraph">La carta più bassa del mazzo è stabilita sottraendo al numero 11, il numero dei giocatori. </p>
            <p className="paragraph">Ad esempio, con 4 giocatori, la carta più bassa è il 7 (11 - 4). </p>
            <p className="paragraph">Si può quindi giocare con un numero di carte compreso tra: </p>
            <ul itemType="disc" className="paragraph">
                <li>24 carte, dal 9 all'asso, nel caso di 2 giocatori </li>
                <li>40 carte, dal 5 all'asso, nel caso di 6 giocatori </li>
            </ul>
            <p className="paragraph">Per dirimere casi di assoluta parità, i semi hanno un valore decrescente secondo la regola "Come Quando Fuori Piove" (Cuori, Quadri, Fiori, Picche). </p>
            <h2 className="titolo2">Il valore delle carte </h2>
            <p className="paragraph">Il valore decrescente delle carte è il seguente: </p>
            <p className="paragraph">A, K, Q, J, 10, 9 e, se usate, 8, 7, 6, 5. </p>
            <p className="paragraph">
                l'asso è la carta con valore più alto, ma anche con valore minimo in caso
                venga utilizzata per chiudere una scala, ponendola al di sotto della carta con
                il numero più basso in gioco.
            </p>
        </ContentTabs>
    );
};

export const ItalianPokerSvolgimentoDelGioco = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Poker 5 carte - Svolgimento del gioco</h2>
            <h2 className="titolo2">I giocatori che hanno diritto a partecipare alla mano </h2>
            <p className="paragraph">I giocatori che hanno diritto a partecipare a una smazzata vengono detti "giocatori attivi" e sono determinati come segue. </p>
            <p className="paragraph">Nei tornei vengono eliminati i giocatori che rimangono senza chips alla fine della mano. </p>
            <p className="paragraph">Nei cash games i giocatori che hanno diritto a partecipare alla mano sono quelli che hanno un credito sufficiente(secondo le regole del tavolo specifico) e non si trovano in stato di sitout. Unica eccezione: in caso di piatto di parol, possono partecipare alla mano solo quelli che hanno puntato qualsiasi somma nel piatto in tavola, inclusi quelli che attualmente si trovano in sitout.</p>
            <h2 className="titolo2">Il Mazziere, l'invito, il piatto, i piatti secondari e la dotazione di chips iniziale. </h2>
            <p className="paragraph">Il <strong><em>mazziere</em></strong> è il giocatore che distribuisce le carte nella mano, in senso orario. </p>
            <p className="paragraph">Nella prima mano di una partita, il primo mazziere viene designato tramite un sorteggio. </p>
            <p className="paragraph">L'<strong><em>invito</em></strong> è l'importo che i giocatori devono versare nel piatto per partecipare alla mano. </p>
            <p className="paragraph">Il <strong><em>piatto</em></strong> è la somma delle varie puntate nella mano. </p>
            <p className="paragraph">
                Nel caso in cui un giocatore punti tutto il suo credito, e altri giocatori
                coinvolti nella mano continuano a puntare ulteriori somme queste verranno messe
                in <strong><em>piatti secondari </em></strong>ai quali partecipa solo chi ha
                contribuito con somme di chips.
            </p>
            <p className="paragraph">La <strong><em>dotazione di chips</em></strong> <strong><em>iniziale</em></strong>, in un torneo, è uguale per tutti i partecipanti. </p>
            <p className="paragraph">In una partita cash game la dotazione di chips iniziale è pari all'importo	che un giocatore porta al tavolo, ed è delimitata dai limiti del tavolo stesso.</p>
            <p className="paragraph">Il gioco si svolge nelle seguenti fasi: </p>
            <ul itemType="disc" className="paragraph">
                <li>Invito </li>
                <li>Apertura al buio </li>
                <li>Distribuzione </li>
                <li>Apertura </li>
                <li>Cambio carte </li>
                <li>Ultima fase di puntata </li>
                <li>Fase finale: esibizione punteggio e assegnazione piatti </li>
                <li>Adeguamento parol </li>
            </ul>
            <h2 className="titolo2">Invito </h2>
            <p className="paragraph">
                In un torneo l'invito viene prelevato automaticamente a tutti i giocatori
                attivi presenti al tavolo inclusi i giocatori in sitout. I giocatori in sitout
                non partecipano alle fasi successive, perdendo automaticamente l'invito che va
                nel piatto.
            </p>
            <p className="paragraph">
                In una partita cash game, invece, viene richiesto ai giocatori attivi di
                mettere l'invito o andare in sitout non partecipando alla mano. Tramite le
                opzioni è possibile impostare l'invito automatico per i cash games.
            </p>
            <h2 className="titolo2">Apertura del gioco al buio </h2>
            <p className="paragraph">È possibile anche aprire senza possedere una coppia vestita o un punto superiore: si tratta dell'<em>apertura al buio</em>. </p>
            <p className="paragraph">
                Quest'apertura può essere effettuata soltanto prima della distribuzione delle
                carte, soltanto nei colpi con apertura ai J e soltanto dal giocatore alla
                sinistra del mazziere, che deve mettere nel piatto una somma pari al piatto. Il
                giocatore che apre al buio è detto <em>buiante. </em>In questo caso il giocatore
                alla sinistra del buiante ha diritto, prima del distribuzione delle carte, di
                effettuare un altro rilancio al buio (il doppio di quello precedente), in questo
                caso si parla di <em>controbuiante. </em>Anche al giocatore alla sinistra del
                controbuiante è consentito fare un ulteriore rilancio al buio l'<em>over (pari al doppio di quello precedente).</em>
                All'inizio di una mano possono essere effettuati massimo 3 rilanci al buio.
            </p>
            <p className="paragraph">Si può fare un rilancio al buio solo se si dispone della somma necessaria.</p>
            <p className="paragraph">
                Ad esclusione dell'ultimo giocatore che ha rilanciato al buio, tutti i
                giocatori attivi devono, dopo aver preso visione delle carte, decidere se vedere
                o passare, senza possibilità di rilancio. Se tutti i giocatori non si adeguano
                all'ultimo rilancio al buio effettuato, il giocatore che l'ha effettuato vince
                il piatto. Se almeno uno di loro vedono l'apertura al buio solo colui che ha
                fatto l'ultimo rilancio al buio ha la facoltà di rilanciare nuovamente: a questo
                punto gli altri giocatori potranno vedere, controrilanciare a loro volta, o
                passare. Nel caso di apertura al buio, dopo la fase di cambio carte il buiante
                (o controbuiante se presente) ha diritto a parlare per ultimo: questo significa
                che il primo a parlare sarà il primo giocatore alla sua sinistra.
            </p>
            <h2 className="titolo2">Distribuzione delle carte </h2>
            <p className="paragraph">
                A partire dal primo giocatore alla sinistra del mazziere vengono distribuite
                una carta coperta alla volta ai giocatori che hanno messo l'invito, fino ad
                avere 5 carte coperte ciascuno. Se non è stata effettuata alcuna apertura al
                buio, il primo giocatore alla sinistra del mazziere decide se effettuare l'<strong>apertura del gioco</strong>.
            </p>
            <h2 className="titolo2">Apertura </h2>
            <p className="paragraph">
                Per poter aprire si usano tali regole: una volta distribuite le carte, il
                primo giocatore alla sinistra del mazziere può aprire il gioco o passare la
                parola al giocatore successivo. Qualsiasi giocatore, comunque, può aprire solo
                se in possesso almeno di una coppia di <em>J </em>o di un punto superiore. Se
                nessuno dei giocatori apre, si ritirano le carte e si gioca una nuova mano, e la
                combinazione minima per poter aprire sale a due <em>Q</em>. Se anche in questa
                mano nessuno dei giocatori apre la procedura si ripete, e l'apertura minima sale
                a due <em>K </em>(cosiddetta "apertura ai K e assi"), fino a che il piatto non
                viene assegnato: dopodiché l'apertura minima tornerà ad essere costituita dalla
                coppia di <em>J</em>.
            </p>
            <p className="paragraph">In caso di non apertura nei tornei, nella mano successiva viene rimesso l'invito automaticamente </p>
            <p className="paragraph">In caso di non apertura nei cash games, nella mano successiva non si mette l'invito. </p>
            <p className="paragraph">
                In ogni caso, l'apertura è sempre consentita al giocatore in possesso di
                progetto bilaterale di scala reale (quattro carte dello stesso seme in scala).
            </p>
            <p className="paragraph">
                Anche in caso di carte che permettono l'apertura, essa è puramente opzionale
                da parte del giocatore, e non obbligatoria. Ad apertura effettuata gli altri
                giocatori potranno "vedere" (si intende adeguarsi alla puntata) la puntata o a
                loro volta effettuare dei rilanci ( che possono essere limitati da eventuali
                limiti del tavolo).
            </p>
            <p className="paragraph">Nel caso in cui uno o più giocatori attivi abbiano finito le chips a disposizione mettendo l'invito: </p>
            <p className="paragraph">Nei tornei: </p>
            <p className="paragraph">
                · se nessuno dei giocatori con credito ha aperto si procede comunque alla
                fase di cambio carte, dove tutti quelli che hanno messo l'invito hanno la
                possibilità di cambiare le carte.
            </p>
            <p className="paragraph">
                · se invece qualcuno ha aperto (anche al buio precedentemente) oltre ai
                giocatori attivi rimasti senza chips, partecipano al cambio carte i giocatori
                attivi che vedono l'apertura.
            </p>
            <p className="paragraph">
                Nei cash games viene richiesta l'apertura anche ai giocatori rimasti senza
                chips, che in caso di apertura non punteranno alcun importo ma apriranno
                soltanto il gioco.
            </p>
            <p className="paragraph">
                Una volta definito il numero di giocatori ancora in gioco, che hanno versato
                nel piatto la somma delle eventuali puntate e rilanci, si passa alla fase del
                <strong>cambio delle carte</strong>.
            </p>
            <h2 className="titolo2">Cambio carte </h2>
            <p className="paragraph">
                A partire sempre dal primo giocatore alla sinistra del mazziere, ogni
                giocatore può cambiare fino a 4 carte della propria mano, con altrettante carte
                prese dal mazzo, oppure dichiararsi servito e non sostituire nessuna carta.
            </p>
            <p className="paragraph">
                In caso le carte del mazzo non siano sufficienti per procedere ai cambi di
                tutti i giocatori, si rimescolano solo le carte scartate dai giocatori che hanno
                effettuato il cambio carte, formando un nuovo mazzo, da dove si prendono le
                nuove carte per i cambi.
            </p>
            <p className="paragraph">
                In caso un giocatore cambi più di tre carte, la quarta carta gli verrà data
                solo dopo aver distribuito il cambio a tutti gli altri giocatori. Se più di un
                giocatore cambia 4 carte, si effettuerà lo stesso procedimento, con l'ultima
                carta che verrà data ad entrambi giocatori (sempre in senso orario) solo quando
                saranno completati i cambi precedenti.
            </p>
            <p className="paragraph">Completato il cambio delle carte, che è disponibile solo una volta a mano, si procede all'ultima fase di puntata </p>
            <h2 className="titolo2">Ultima fase di puntata </h2>
            <p className="paragraph">
                In questa fase il primo giocatore a parlare è l'ultimo giocatore ad aver
                effettuato una puntata attiva nella fase di apertura(ovvero ad aver puntato
                aprendo il gioco o ad aver rilanciato o contro-rilanciato).
            </p>
            <p className="paragraph">In questa fase di puntata le azioni disponibili sono: </p>
            <ul itemType="disc" className="paragraph">
                <li>
                    <strong>Passo</strong> - che equivale ad uscire dalla mano. Nel caso in
                    cui un giocatore decida di passare senza che ci siano puntate attive, gli
                    viene richiesto di confermare la scelta.
                </li>
                <li>
                    <strong>Busso</strong> - è il classico passare parola al giocatore
                    successivo, e nel caso in cui tutti giocatori rimasti in gioco bussino si
                    procede allo show down, e all'assegnazione del piatto al giocatore che
                    mostra il punteggio migliore.
                </li>
                <li>
                    <strong>Parol -<em> </em></strong>è il passare parola al giocatore
                    successivo che però risulta valido solo se effettuato da tutti i giocatori
                    del tavolo ancora in gioco. In caso almeno un giocatore faccia busso, oppure
                    venga effettuata un nuova puntata, il parol si annulla e in ogni caso il
                    piatto viene assegnato. Se invece tutti i giocatori dichiarano <em>paròl</em>
                    il piatto non viene assegnato. Nella mano successiva l'apertura minima sarà
                    pari a due <em>K </em>(o progetto bilaterale di scala reale). Il piatto così
                    formato è chiamato "<em>piatto di paròl</em>". I giocatori che non avevano
                    partecipato nella mano precedente e vogliono intervenire al <em>piatto di
                        paròl</em> dovranno adeguarsi ad esso, versando nel piatto una somma pari a
                    quella versata da coloro che lo hanno determinato, prima dell'inizio della
                    mano. Nella mano successiva l'importo minimo dell'apertura è pari al piatto
                    , e nei tornei l'invito verrà messo automaticamente
                    , mentre nei cash l'invito non si mette del tutto.
                </li>
            </ul>
            <p className="paragraph">
                <strong>NB</strong> : non è possibile dichiarare parol nel caso in cui ci
                siano dei piatti secondari o giocatori attivi che hanno terminato le chips a
                disposizione.
            </p>
            <ul itemType="disc" className="paragraph">
                <li>
                    <strong>Punto - </strong>viene puntata una somma pari all'importo
                    selezionato. Gli altri giocatori rimasti in gioco possono adeguarsi, oppure
                    rilanciare. Dopo la prima puntata non è più possibile "puntare" ma solo
                    "rilanciare" e "contro rilanciare", "passare" o "vedere".
                </li>
                <li>
                    <strong>Rilancio</strong> - viene puntata una somma superiore all'ultima
                    puntata effettuata sia puntata normale, sia rilancio che controrilancio.
                    Quest'azione è disponibile solo a seguito di una puntata.
                </li>
                <li>
                    <strong>Vedo - </strong>equivale ad adeguarsi all'ultima puntata
                    effettuata. Quest'azione è disponibile solo a seguito di una puntata o di un
                    rilancio.
                </li>
            </ul>
            <p className="paragraph">
                Se in una delle fasi di gioco un giocatore punta e non viene seguito da
                nessun altro giocatore, egli vince la mano, e senza dover continuare nelle varie
                fasi di gioco si aggiudica il piatto. Inoltre non è obbligato a mostrare le
                proprie carte coperte oscurando il suo punteggio totale.
            </p>
            <p className="paragraph">
                Nel caso un giocatore finisca le chips a disposizione, durante una delle fasi
                dove sono previste delle puntate (incluso l'invito) , rimane comunque attivo
                fino alla fine della mano, partecipando però solo al piatto che si è creato sino
                a quel momento. Se tale situazione si verifica con solo un altro giocatore
                ancora in gioco non ci saranno più altre puntate, e si continua con il regolare
                svolgimento delle fasi di gioco fino alla determinazione del vincitore della
                mano.
            </p>
            <p className="paragraph">
                Se ciò accade con altri giocatori ancora in gioco, essi potranno continuare
                il regolare svolgimento della mano e delle puntate, creando un altro piatto
                aggiuntivo, a cui partecipano solo loro, denominato <strong>piatto secondario</strong>.
            </p>
            <h2 className="titolo2">Fase finale: esibizione punteggio e assegnazione piatti </h2>
            <p className="paragraph">
                Al termine dell'ultima fase di puntata se è rimasto in gioco un solo
                giocatore questo incassa tutto il piatto e non è obbligato a mostrare il proprio
                punteggio. Gli verrà richiesto di mostrare le sue carte o di nasconderle.
                Qualora non decida entro il tempo limite le carte verranno automaticamente
                nascoste.
            </p>
            <p className="paragraph">
                Qualora ci sia più di un giocatore rimasto in gioco, a partire dal giocatore
                che fatto l'ultimo rilancio, mostrano automaticamente le carte i giocatori che
                hanno un punteggio superiore al miglior punteggio mostrato.
            </p>
            <p className="paragraph">Inoltre mostrano automaticamente le carte i giocatori che durante la mano hanno rilanciato puntando tutto il loro credito. </p>
            <p className="paragraph">Agli altri giocatori che non ricadono nei casi precedenti viene richiesto di mostrare o nascondere le proprie carte. </p>
            <p className="paragraph">Terminata la fase di esibizione delle carte si passa all'assegnazione del piatto e dei piatti secondari. </p>
            <p className="paragraph">Il giocatore con la mano vincente(secondo i punteggi descritti nel paragrafo <strong>Punteggi</strong>) si aggiudica il piatto. </p>
            <p className="paragraph">
                Nel caso ci siano un piatto principale e uno o più piatti secondari, si
                stabilisce per ogni piatto il giocatore vincente tra quelli che hanno diritto
                all'assegnazione dello stesso. </p>
            <h2 className="titolo2">Adeguamento parol </h2>
            <p className="paragraph">
                Nel caso in cui nella mano appena terminata tutti i giocatori attivi abbiano
                dichiarato parol, verrà richiesto ai giocatori che erano presenti all'inizio
                della mano e che non hanno partecipato fino all'ultimo, di adeguarsi al piatto
                di parol o passare. L'adeguamento è possibile solo se si dispone del credito
                sufficiente per coprire l'importo mancante per adeguarsi al piatto. In caso
                contrario il sistema passerà automaticamente la mano.
            </p>
            <h2 className="titolo2">Punteggi </h2>
            <p className="paragraph">Valgono le seguenti regole generali: </p>
            <p className="paragraph">· l'ordine decrescente del valore delle carte è: A - K - Q - J - 10 - 9 e se usate 8 - 7 - 6 - 5 </p>
            <p className="paragraph">
                · l'ordine di valore decrescente dei semi è <span className="testorosso">♥ cuori</span> -
                <span className="testorosso">♦ quadri</span> - ♣ fiori - ♠
                picche: questa regola è conosciuta anche come la regola del Come Quando Fuori
                Piove.
            </p>
            <p className="paragraph">· le combinazioni vengono valutate prima in base alla categoria, e poi in base al valore e ai semi delle carte. </p>
            <p className="paragraph">Le combinazioni possibili in ordine di valore crescente sono: </p>
            <p className="paragraph"><strong>Carta Alta</strong> </p>
            <p className="paragraph">es: <span className="testorosso">A♥</span> K♠ 10♠ <span className="testorosso">8♦</span> 7♣ </p>
            <p className="paragraph">Questo è la combinazione di minor valore realizzabile. </p>
            <p className="paragraph">È superiore la combinazione che presenta fra le proprie carte quella di valore maggiore. </p>
            <p className="paragraph">In caso di parità, è superiore quella che presenta come seconda carta quella di valore maggiore e così di seguito. </p>
            <p className="paragraph">
                In caso di ulteriore parità, a partire dalla carta di maggior valore sino a
                quella di minor valore si confrontano i semi con la regola Come Quando Fuori Piove.
            </p>
            <p className="paragraph"><strong>Coppia</strong> </p>
            <p className="paragraph">es: K♣ <span className="testorosso">J♥</span> 10♠ <span className="testorosso">10♥ 7♦ </span></p>
            <p className="paragraph">La coppia presenta due carte dello stesso valore. </p>
            <p className="paragraph">In caso di parità di coppie si valutano le tre carte residue applicando la stessa regola della Carta Alta (quindi prima i valori e poi i semi). </p>
            <p className="paragraph"><strong>Doppia coppia</strong> </p>
            <p className="paragraph">es: Q♣ Q♠ <span className="testorosso">9♦</span> 9♣ <span className="testorosso">8♥ </span> </p>
            <p className="paragraph">Due coppie. </p>
            <p className="paragraph">È superiore il giocatore che ha la coppia di valore maggiore </p>
            <p className="paragraph">In caso di parità si guarda la seconda coppia </p>
            <p className="paragraph">In caso di ulteriore parità si guarda il valore della quinta carta. </p>
            <p className="paragraph">In caso le quinte carte mostrino lo stesso valore, conta il seme della quinta carta. </p>
            <p className="paragraph"><strong>Tris</strong> </p>
            <p className="paragraph">es: <span className="testorosso">A♦ J♥</span> J♣ J♠ 8♠ </p>
            <p className="paragraph">Tre carte dello stesso valore. </p>
            <p className="paragraph">In caso di parità di tris si applica la regola della carta alta considerando le due carte residue. </p>
            <p className="paragraph"><strong>Scala</strong> </p>
            <p className="paragraph">es: K♣ <span className="testorosso">Q♥</span> J♠ <span className="testorosso">10♦ 9♥ </span> </p>
            <p className="paragraph">Sequenza di carte in ordine di valore, appartenenti a 2 o più semi. </p>
            <p className="paragraph">
                Fra due scale è superiore quella che comprende la carta di valore più alto.
                In caso di ulteriore parità si guardano i semi a partire dalla carta più alta
                rispettando l'ordine Cuori, Quadri, Fiori, Picche.
            </p>
            <p className="paragraph">NB: <em>L'asso può aprire la scala se posto dopo la carta di valore più basso tra quelle del mazzo </em></p>
            <p className="paragraph">
                (<span className="testorosso">10♦ 9♦</span> 8♠ 7♣
                <span className="testorosso">A♥</span> se i giocatori sono 4).</p><p className="paragraph">
                Ugualmente può 	chiudere la scala ponendolo prima del K (A♠
                <span className="testorosso">K♦</span> Q♣ <span className="testorosso">J♥ 10♦</span> ).  Nel
                primo caso la scala sarà definita "minima", nel secondo "massima"; le scale che
                non comprendono l'asso sono definite "medie".
            </p>
            <p className="paragraph"><strong>Full</strong> </p>
            <p className="paragraph">es: <span className="testorosso">J♥ J♦</span> J♣ 6♠ 6♣ </p>
            <p className="paragraph">Tre carte dello stesso valore e una coppia. </p>
            <p className="paragraph">In caso di parità di tris si guarda il valore della coppia. </p>
            <p className="paragraph"><strong>Colore</strong> </p>
            <p className="paragraph">es: <span className="testorosso">A♦ K♦ J♦ 9♦ 8♦ </span> </p>
            <p className="paragraph">
                Cinque carte dello stesso seme, non in scala. Fra due colori è superiore
                quello con il seme più alto secondo la regola Come Quando Fuori Piove. Es.
                <span className="testorosso"> K♥ Q♥ 10♥ 9♥ 8♥</span> batte A♠ K♠ J♠ 9♠ 8♠ . </p>
            <p className="paragraph">In caso di parità di seme a partire dalla carta più alta si confrontano i valori delle 5 carte. </p>
            <p className="paragraph"><strong>Poker</strong> </p>
            <p className="paragraph">es: <span className="testorosso">Q♥ Q♦</span> Q♣ Q♠ 7♣ </p>
            <p className="paragraph">Quattro carte dello stesso valore. </p>
            <p className="paragraph">In caso di parità si guarda il valore della quinta carta e in caso di ulteriore parità si guarda il seme della quinta carta </p>
            <p className="paragraph"><strong>Scala reale</strong> </p>
            <p className="paragraph">es: K♠ Q♠ J♠ 10♠ 9♠ </p>
            <p className="paragraph">Scala con cinque carte dello stesso seme. </p>
            <p className="paragraph">In caso di parità della carta di maggior valore si guarda il seme. </p>
            <p className="paragraph">
                NB.1: <em>Nel caso in cui, grazie alle vele presenti lo stesso giocatore
                    realizzi 2 scale reali, ai fini del confronto con i punteggi degli altri
                    giocatori vale sempre la scala reale superiore per seme e poi per valore. </em>
            </p>
            <p className="paragraph">
                NB.2: <em>La scala reale massima di cuori (scala reale che termina con l'asso
                    di cuori) è il punto più forte nel poker, ma non garantisce la certezza assoluta
                    della vittoria. Infatti, nel caso rarissimo ma non impossibile di due o più
                    scale reali, la scala minima di picche è superiore alla massima di cuori. </em>
            </p>
        </ContentTabs>
    );
};

export const ItalianPokerComeSiGioca = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Poker 5 carte - Come si gioca</h2>
            <h2 className="titolo2">Invito (solo cash games) </h2>
            <p className="paragraph">Nella fase di invito sono visualizzati due tasti: </p>
            <p className="paragraph">·<strong>SITOUT</strong>: non si partecipa alla mano e si va sitout </p>
            <p className="paragraph">·<strong>INVITO</strong>: mettendo l'invito si partecipa alla mano </p>
            <h2 className="titolo2">Apertura al buio</h2>
            <p className="paragraph">Nella fase di apertura al buio è visualizzato uno di questi tasti associato ad un importo: </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>BUIO</strong>: compare al primo giocatore alla sinistra del mazziere per aprire al buio </li>
                <li><strong>CONTROBUIO</strong>: compare al secondo giocatore alla sinistra del mazziere per fare il controbuio</li>
                <li><strong>OVER</strong>: compare al terzo giocatore alla sinistra del mazziere per fare l'over </li>
            </ul>
            <p className="paragraph">
                In ognuno dei tre casi compare anche il tasto <strong>DAI CARTE</strong> per per non
                aprire/rilanciare al buio e procedere con la fase successiva
            </p>
            <h2 className="titolo2">Cambio carte </h2>
            <p className="paragraph">Viene visualizzato un pannello che riporta le 5 carte. </p>
            <p className="paragraph">Si clicca sulle carte che si vuole cambiare e poi si clicca sul tasto "conferma" per comunicare la scelta. </p>
            <h2 className="titolo2">Apertura e Puntata </h2>
            <p className="paragraph">Durante la fase di apertura o di puntata il giocatore di turno visualizzerà i tasti per decidere quale azione effettuare tra le seguenti: </p>
            <ul itemType="disc" className="paragraph">
                <li>
                    <strong>PASSO</strong> - equivale ad uscire dalla mano e a rinunciare al
                    piatto. Nel caso in cui un giocatore decida di passare senza che ci siano
                    state fatte delle puntate , gli viene richiesto di confermare la scelta.
                </li>
                <li>
                    <strong>PAROL</strong> - si passa la parola al giocatore successivo. Nel
                    caso in cui tutti i giocatori dichiarano <em>paròl</em> il piatto non viene
                    assegnato. Nella mano successiva non sarà versato alcun invito e l'apertura
                    minima sarà pari a due <em>K </em>(o progetto bilaterale di scala reale). Il
                    piatto così formato è chiamato "<em>piatto di paròl</em>".
                </li>
                <li>
                    <strong>BUSSO</strong> - si passa la parola al giocatore successivo.
                    Nel caso in cui tutti giocatori rimasti in gioco bussino si procede alla fase successiva: quest'azione è disponibile solo se nella fase incorso non è stata fatta alcuna puntata.
                </li>
                <li>
                    <strong>PUNTO</strong> - viene puntata una somma pari all'importo
                    selezionato. Gli altri giocatori rimasti in gioco possono adeguarsi, oppure
                    rilanciare. Dopo la prima puntata non è più possibile "puntare" ma solo
                    "rilanciare" e "contro rilanciare", "passare" o "vedere".
                </li>
                <li>
                    <strong>RILANCIO</strong> - viene puntata una somma superiore all'ultima
                    puntata effettuata sia puntata normale, sia rilancio che controrilancio.
                    Il rilancio apre a nuovi contro rilanci solo se maggiore o uguale del doppio
                    dell'ultima puntata effettuata. Quest'azione è disponibile solo a seguito di una puntata.
                </li>
                <li><strong>VEDO</strong> - equivale ad adeguarsi all'ultima puntata effettuata. Quest'azione è disponibile solo a seguito di una puntata o di un rilancio.</li>
                <li>
                    <strong>VA BENE</strong> - visibile solo al buiante in caso di apertura
                    al buio, equivale a non rilanciare rispetto al buio effettuato. Quest'azione è disponibile solo a seguito di un'apertura al buio
                </li>
            </ul>
            <p className="paragraph">
                <strong>NB.1</strong> :per i tasti PUNTO, RILANCIO, VEDO, qualora l'importo
                associato fosse pari al credito disponibile il testo diventa "RESTO".
            </p>
            <p className="paragraph">
                <strong>NB.2</strong> : qualora sia consentito puntare o rilanciare è
                presente un riquadro sulla destra dove si può decidere l'importo da associare
                alla decisione. L'importo è possibile digitarlo nell'apposito riquadro oppure si
                può stabilire utilizzando i tasti di trascinamento o moltiplicazione.
            </p>
            <h2 className="titolo2">Esibizione punteggio </h2>
            <p className="paragraph">
                Nella fase finale è possibile che vanga richiesto, se si desidera mostrare la
                proprie carte rivelando il proprio punteggio. Vengono visualizzati i seguenti tasti:
            </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>MOSTRO</strong> : le carte vengono mostrate agli altri giocatori </li>
                <li><strong>NASCONDO</strong> : la carte non vengono mostrate </li>
            </ul>
            <h2 className="titolo2">Adeguamento parol </h2>
            <p className="paragraph">
                Ai giocatori che devono coprire la differenza di puntate rispetto ai
                giocatori che sono rimasti in gioco fino alla dichiarazione di parol viene
                richiesto di adeguare l'importo puntato.
            </p>
            <p className="paragraph">I giocatori che non hanno credito sufficiente verranno automaticamente esclusi dal piatto di parol. </p>
            <p className="paragraph">I tasti visualizzati sono: </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>PARTECIPO</strong> : si punta la differenza indicata. </li>
                <li><strong>PASSO</strong> : non si partecipa al piatto di parol </li>
            </ul>
            <h2 className="titolo2">Sit-out nel Poker 5 carte </h2>
            <p className="paragraph">Rammentiamo che un giocatore va in stato di sitout nei seguenti casi: </p>
            <ul itemType="disc" className="paragraph">
                <li>per sua scelta, cliccando su "sitout",</li>
                <li>perché in una fase di puntata non comunica la sua decisione entro il <em>tempo massimo,</em> </li>
                <li>perché, all'inizio di una mano (solo tavoli cash), il suo credito residuo è inferiore al <em>credito residuo minimo.</em></li>
            </ul>
            <p className="paragraph">
                Un giocatore presente al tavolo può uscire dallo stato di sitout cliccando
                sul tasto "sono tornato", oppure automaticamente nel caso di rientro nel tavolo.
            </p>
            <p className="paragraph">
                Se un giocatore si trova in sitout durante la mano a cui sta partecipando, e
                successivamente deve prendere una decisione in una fase di puntata:
            </p>
            <p className="paragraph">· se è consentito "bussare", bussa automaticamente rimane in gioco anche se in stato di sitout </p>
            <p className="paragraph">
                · se invece è necessario mettere dei soldi nel piatto per continuare a
                partecipare al gioco, perde il diritto di partecipare al piatto e passa la mano.
                Unica eccezione è il caso in cui un giocatore sitout abbia già puntato tutte le
                sue chips nella mano in corso. In questo caso partecipa al piatto principale ed
                eventuali piatti secondari come se fosse normalmente in gioco.
            </p>
            <h2 className="titolo2">Tempo di giocata e Time Bank </h2>
            <p className="paragraph">Il tempo di giocata, ovvero in cui il giocatore può decidere la propria mossa, in un tavolo normale è di 30 secondi. </p>
            <p className="paragraph">
                Se il giocatore non gioca entro questo tempo prefissato, il sistema prenderà
                automaticamente la decisione al posto del giocatore. Se inoltre questo avviene
                durante una fase di puntata va in sitout e:
            </p>
            <p className="paragraph">· se è consentito "bussare", bussa automaticamente rimane in gioco anche se in stato di sitout </p>
            <p className="paragraph">
                · se invece è necessario mettere dei soldi nel piatto per continuare a
                partecipare al gioco, perde il diritto di partecipare al piatto e passa la mano.
                In questo caso, se previsto dal tavolo è possibile usufruire di una scorta di
                ulteriori secondi, detta "time bank", per prendere la propria decisione nella
                fase di puntata. Il "time bank" non si rigenera ogni volta che viene utilizzato,
                e se usato più volte ricomincia sempre dalla scorta di secondi rimasti.
            </p>
            <br />
            <h2 className="titolo2">COMANDI PRESENTI SOLO SE GIRO PAZZO ATTIVO CON VARIANTE TELESINA </h2>
            <h2 className="titolo2">Scelta Variante </h2>
            <p className="paragraph">In unico pannello è possibile decidere le 2 varianti che seguono: </p>
            <p className="paragraph">Se la <strong>Variante vele</strong> è libera si visualizzano dei riquadri da selezionare per decidere la variante vele desiderata. </p>
            <p className="paragraph">Sela variante <strong>Distribuzione carta coperta </strong>è libera si visualizzano tre tasti : </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>Mai</strong> : le carte vengono distribuite coperte </li>
                <li><strong>Solo al primo giro</strong> : solo al primo giro vengono date due carte coperte </li>
                <li><strong>Sempre</strong> : le carte vengono sempre distribuite coperte</li>
            </ul>
            <p className="paragraph">Per confermare le selezioni precedenti è presente il tasto "conferma". </p>
            <h2 className="titolo2">Scelta carta da scoprire </h2>
            <p className="paragraph">Viene visualizzato un pannello che riporta le due carte coperte. Si clicca su una delle due e poi si clicca sul tasto "conferma" per comunicare la scelta. </p>
        </ContentTabs>
    );
};

export const ItalianPokerRegoleOpzionali = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Poker 5 carte - Regole opzionali</h2>
            <h2 className="titolo2">Regole opzionali</h2>
            <p className="paragraph">
                In questo capitolo saranno descritte alcune regole del Poker 5 carte che vengono
                spesse utilizzate nelle partite dal vivo e che sono state definite "opzionali"
                perché nel sito saranno proposte partite con e senza queste regole.
            </p>
            <h2 className="titolo2">Limiti di puntata </h2>
            <p className="paragraph">Le regole del tavolo possono prevedere i seguenti limiti di puntata </p>
            <p className="paragraph">· Nessuno (NL): la puntata è libera fino al massimo del proprio credito, in questo caso la puntata si definisce "RESTO" </p>
            <p className="paragraph">· Piatto (PL): la puntata massima è pari al piatto attuale + eventuali puntate della fase in corso + l'importo per vedere l'ultima puntata. </p>
            <p className="paragraph">
                · Ad esempio, nel piatto ci sono 100 chips e due giocatori prima del
                giocatore di turno hanno puntato 20 chips ciascuno (il primo ha puntato e il
                secondo ha visto). Il giocatore di turno può puntare fino ad un massimo di 160
                chips (100+20+20+20 = 160)
            </p>
            <p className="paragraph">· Fisso (FL): la puntata e il rilancio hanno un importo fisso pari all'invito messo nella mano. </p>
            <h2 className="titolo2">Giro pazzo </h2>
            <p className="paragraph">
                Se previsto dalle regole del tavolo, nel caso in cui in una mano si arriva
                alla fase di esibizione del punteggio, e almeno uno dei giocatori mostra un full
                o un punteggio superiore, dalla mano successiva, per un giro intero si gioca il
                <strong>giro pazzo. </strong>
            </p>
            <p className="paragraph">Durante questo giro il mazziere può decidere una variante tra quelle di seguito elencate: </p>
            <p className="paragraph">Telesina (8 varianti vele e 3 varianti di distribuzione): </p>
            <p className="paragraph">
                In questa variante non si giocano le fasi di <strong>Apertura al buio</strong>,
                <strong>Apertura</strong> e <strong>Cambio carte </strong>e non è possibile
                dichiarare <strong>Parol</strong>.
            </p>
            <p className="paragraph">Oltre a quelle appena elencate, nella telesina abbiamo le seguenti differenze </p>
            <p className="paragraph"><strong><em><u>Distribuzione carte</u></em></strong> </p>
            <p className="paragraph">
                La distribuzione avviene sempre a partire dal primo giocatore alla sinistra
                del mazziere. In una mano ci possono essere fino a 4 giri di distribuzione.
            </p>
            <p className="paragraph">
                Nel primo giro di distribuzione che avviene dopo l'invito, vengono
                distribuite 2 carte a ciascun giocatore. La prima sarà coperta e la seconda
                scoperta. Nel secondo, nel terzo e nel quarto giro viene distribuita una carta
                scoperta. In tutto un giocatore può avere fino ad un massimo di 5 carte.
            </p>
            <p className="paragraph">Al termine di una fase di distribuzione si passa alla fase di puntata. </p>
            <p className="paragraph"><strong><em><u>Girata vele</u></em></strong> </p>
            <p className="paragraph">
                Dopo la fase di puntata a seguito della distribuzione della quinta carta,
                qualora siano state disposte sul tavolo delle vele si passa alla fase di girata vele.
            </p>
            <p className="paragraph">In base ad ogni variante di vele previste è possibile che vengano girate una o più vele contemporaneamente. </p>
            <p className="paragraph">Al termine della girata vele si passa nuovamente alla fase di puntata. </p>
            <p className="paragraph"><strong><em><u>Puntata</u></em></strong> </p>
            <p className="paragraph">
                In questa fase il primo giocatore a parlare è colui che mostra il miglior
                punteggio considerando le sue carte scoperte e le eventuali combinazioni di vele.
            </p>
            <p className="paragraph"><strong><em><u>Le vele </u></em></strong></p>
            <p className="paragraph">le varianti di vele disponibili sono </p>
            <p className="paragraph"><em><u>Libera</u></em> : all'inizio della mano il mazziere sceglie una delle varianti sotto descritte </p>
            <p className="paragraph"><em><u>Nessuna vela</u></em> : si passa direttamente alla fase finale </p>
            <p className="paragraph"><em><u>Una vela</u></em> : disposta al centro del tavolo si effettua l'unica girata della vela A </p>
            <p className="paragraph"><img height="89" src="/images/regulation/poker/1vela.jpg" width="120" /></p>
            <p className="paragraph"><em><u>Due vele</u></em> : disposte orizzontalmente sul tavolo, si effettuano 2 girate: prima A e poi B. </p>
            <p className="paragraph">Le due vele formano una combinazione. </p>
            <p className="paragraph"><img height="88" src="/images/regulation/poker/2vele.jpg" width="118" /></p>
            <p className="paragraph"><em><u>Tre vele</u></em> : disposte orizzontalmente si effettuano 3 girate: prima A, poi C e infine B. </p>
            <p className="paragraph">Le tre vele formano una combinazione </p>
            <p className="paragraph"><img height="89" src="/images/regulation/poker/3vele.jpg" width="121" /></p>
            <p className="paragraph"><em><u>Croce</u></em> : 5 vele disposte sul tavolo in due file incrociate.</p>
            <p className="paragraph">Si effettuano 3 girate: prima A - C, poi B - D, e infine E. </p>
            <p className="paragraph"><img height="89" src="/images/regulation/poker/croce.jpg" width="121" /></p>
            <p className="paragraph">Le combinazioni in questo caso sono 2: </p>
            <p className="paragraph">
                <img height="90" src="/images/regulation/poker/croceH.jpg" width="121" />
                <img height="89" src="/images/regulation/poker/croceV.jpg" width="120" />&nbsp;&nbsp;
            </p>
            <p className="paragraph"><em><u>H</u></em> : 7 vele disposte a formare visivamente la lettera "H". </p>
            <p className="paragraph"><img height="89" src="/images/regulation/poker/H.jpg" width="121" /></p>
            <p className="paragraph">Si effettuano 4 girate: prima C - G, poi B - F, poi A - E, e infine D. </p>
            <p className="paragraph">
                <img height="89" src="/images/regulation/poker/H2bottom.jpg" width="121" />
                <img height="88" src="/images/regulation/poker/Horizontal3.jpg" width="119" />
                <img height="89" src="/images/regulation/poker/H2top.jpg" width="120" />
            </p>
            <p className="paragraph">Le combinazioni possibili sono 7: </p>
            <p className="paragraph">
                <img height="90" src="/images/regulation/poker/H3left.jpg" width="121" />
                <img height="90" src="/images/regulation/poker/H3right.jpg" width="122" />
                <img height="90" src="/images/regulation/poker/Hdiagonall.jpg" width="121" />
                <img height="90" src="/images/regulation/poker/Hdiagonalr.jpg" width="121" />
            </p>
            <p className="paragraph"><em><u>Piramide</u></em> : 7 vele disposte a "V" capovolta. </p>
            <p className="paragraph">Le girate sono 4: prima A - G, poi B - F, poi C - E, e infine D. </p>
            <p className="paragraph"><img height="89" src="/images/regulation/poker/piramide.jpg" width="121" /></p>
            <p className="paragraph">Le combinazioni possibili sono due: </p>
            <p className="paragraph">
                <img height="88" src="/images/regulation/poker/piramideL.jpg" width="118" />
                <img height="88" src="/images/regulation/poker/piramideR.jpg" width="119" />
            </p>
            <p className="paragraph"><em><u>Ascensore</u></em> : 9 vele </p>
            <p className="paragraph">Le girate sono 5 : prima D - I, poi C - H , poi B - G, poi A - F, e infine E</p>
            <p className="paragraph"><img height="90" src="/images/regulation/poker/Ascensore1.jpg" width="121" /></p>
            <p className="paragraph">Le combinazioni sono 4 e la vela centrale (detta "ascensore") è presente in tutte e 4 le combinazioni: </p>
            <p className="paragraph">
                <img height="90" src="/images/regulation/poker/Ascensore2.jpg" width="121" />
                <img height="88" src="/images/regulation/poker/Ascensore3.jpg" width="119" />
                <img height="89" src="/images/regulation/poker/Ascensore4.jpg" width="120" />
                <img height="89" src="/images/regulation/poker/ascensore5.jpg" width="121" />
            </p>
            <h2 className="titolo2">Distribuzione carta coperta </h2>
            <p className="paragraph">
                In base alle regole del tavolo è possibile che le carte che normalmente
                vengono distribuite già scoperte vengano distribuite coperte per poi far
                scegliere a ciascun giocatore quale delle due girare definitivamente. Le
                varianti possibili sono :
            </p>
            <ul itemType="disc" className="paragraph">
                <li><strong>Libera</strong>: all'inizio della mano il mazziere sceglie una delle varianti sotto descritte</li>
                <li><strong>Mai</strong>: le carte vengono distribuite nel modo classico: la prima coperta e le seguenti scoperte</li>
                <li><strong>Solo al primo giro</strong>: solo al primo giro di distribuzione vengono date due carte coperte, nelle distribuzioni successive le carte vengono sempre distribuite scoperte</li>
                <li><strong>Sempre</strong>: le carte vengono distribuite sempre coperte ed ogni giocatore avrà la possibilità di tenere coperta la carta che preferisce</li>
            </ul>
        </ContentTabs>
    );
};

export const ItalianPokerOpzioniDiGiocoAutomatico = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Poker 5 carte - Opzioni di gioco automatico</h2>
            <h2 className="titolo2">Opzioni di gioco automatico</h2>
            <p className="paragraph">Il giocatore può impostare le seguenti regole di gioco automatico: </p>
            <p className="paragraph">· Variante vele preferita. (valida solo nel giro pazzo). Il valore predefinito è "nessuna preferenza"; </p>
            <p className="paragraph">
                · Utilizzare sempre la variante vele preferita. (valida solo nel giro
                pazzo)Se attivato ogni volta che si è mazziere verranno distribuite le vele
                secondo quanto impostato al punto precedente. Di default il l'opzione è
                disabilitata;
            </p>
            <p className="paragraph">· Variante di distribuzione preferita. Il valore predefinito è "nessuna preferenza"; </p>
            <p className="paragraph">
                · Utilizzare sempre la variante distribuzione preferita. (valida solo nel
                giro pazzo) Se attivato ogni volta che si è mazziere verranno distribuite le
                carte secondo quanto impostato al punto precedente. Di default il l'opzione è
                disabilitata; 
            </p>
            <p className="paragraph">· Mostra / nascondi la tua mano. Le scelte possibili sono: </p>
            <ul itemType="disc" className="paragraph">
                <li>Chiedimelo sempre": verrà sempre richiesto al giocatore di effettuare una scelta </li>
                <li>Nascondo sempre": le carte verranno sempre nascoste automaticamente se permesso dal gioco e dalla situazione al tavolo </li>
                <li>Mostro sempre": le carte verranno sempre mostrate. </li>
                <li>Il valore predefinito è "Chiedimelo sempre". </li>
            </ul>
            <p className="paragraph">
                · Invito automatico (solo cash games). Se attivato ogni volta che si deve
                mettere l'invito verrà messo automaticamente dal sistema. Di default il
                l'opzione è disabilitata.
            </p>
        </ContentTabs>
    );
};

export const ItalianPokerRegoleDeiTavoliCash = () => {
    return (
        <ContentTabs>
            <h2 className="titolomenu">Regole del Poker 5 carte - Regole specifiche dei tavoli cash </h2>
            <h2 className="titolo2">Regole specifiche dei tavoli cash </h2>
            <p className="paragraph">
                Al montepremi di ogni singola mano è destinata una percentuale della somma
                delle puntate dei giocatori, variabile sulla base della singola sessione di
                gioco, ma in tutti i casi non inferiore al 90%.
            </p>
            <p className="paragraph">
                L'importo del montepremi di una singola mano è sempre derivabile dalla somma
                delle puntate diminuite del margine lordo del concessionario (rake).
            </p>
            <p className="paragraph">
                Il rake massimo applicato al tavolo ("cap") è chiaramente indicato nel
                pannello informativo presente sulla destra della lobby e sul tavolo di gioco.
            </p>
            <h2 className="titolo2">La vincita del giocatore con il punteggio migliore. </h2>
            <p className="paragraph">Il rake che viene prelevato da ogni piatto è calcolato come segue: </p>
            <p className="paragraph">Piatto * %rake </p>
            <p className="paragraph">esempio con rake al 5% </p>
            <p className="paragraph">3 giocatori mettono ciascuno €10 nel piatto </p>
            <p className="paragraph">Piatto = €30 </p>
            <p className="paragraph">Rake = €30 * 5% = €1,5 </p>
            <p className="paragraph">sempre seguendo l'esempio se il cap fosse pari a €1 </p>
            <p className="paragraph">il rake prelevato sarebbe pari al cap quindi a €1 </p>
            <p className="paragraph">la vincita del giocatore con il punteggio migliore sarebbe quindi pari a €29 = €30 - €1 </p>
            <h2 className="titolo2">Abbandono automatico nei tavoli cash </h2>
            <p className="paragraph">
                Se un giocatore salta dieci turni di gioco consecutivi perchè in sitout, è
                fatto alzare automaticamente dal tavolo con accredito del suo credito residuo
                sul suo conto di gioco.
            </p>
            <p className="paragraph">
                Nei tavoli cash in caso di sit-out il giocatore ha un tempo massimo di 10
                minuti per rientrare in gioco. Passati i 10 minuti viene fatto alzare dal tavolo
                e il credito al tavolo viene accreditato sul conto di gioco.
            </p>
        </ContentTabs>
    );
};
