import { Box, Button, DialogContent, DialogTitle, Slider, Stack, TextField, styled } from '@mui/material';
import { ButtonCustom } from '@quanticogames/gameclient-components';
import ButtonComponent from 'shared/components/button';

export const StyledCloseBtn = styled(Button)({
    minWidth: '36px',
    padding: 0,
    position: 'absolute',
    top: 4,
    right: 4,
});

export const StyledDialogTitle = styled(DialogTitle)({
    background: '#0E345E',
    color: '#fff',
    padding: '10px 20px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
});

export const StyledContent = styled(DialogContent)({
    position: 'relative',
    width: '100%',
    fontSize: '15px',
    fontWeight: '500',
    padding: '15px 0 !important',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '15px',
    '.mobile &': {
        padding: '0px 8px 8px !important',
        gap: '8px',
    },
});

export const StyledBackDrop = styled(Box)({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000',
    opacity: 0.5,
    zIndex: 100,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
});

export const StyledBalance = styled(Box)({
    backgroundColor: '#d4d7f1',
    fontWeight: '600',
    display: 'inline-flex',
    height: '38px',
    width: '100%',
    maxWidth: '150px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    fontFamily: `'Inter', sans-serif !important`,
});

export const StyledTitle = styled(Box)({
    borderTop: '2px solid #3F51B5',
    paddingTop: '15px',
    fontWeight: '600',
    '.mobile &': {
        paddingTop: '8px',
    },
});

export const StyledWarning = styled(Box)({
    color: '#ff0000',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '16px',
});

export const StyledStack = styled(Stack)({
    width: '100%',
    padding: '8px',
    background: '#3F51B522',
    alignItems: 'center',
    '.mobile &': {
        padding: '4px',
    },
});

export const StyledValueBtn = styled(Button)({
    textTransform: 'uppercase',
    backgroundColor: '#3F51B5',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '80px',
    gap: '4px',
    padding: '8px',
    fontSize: '12px',
    height: '48px !important',

    '&:hover': {
        backgroundColor: '#3F51B5CC',
    },

    '&:disabled': {
        backgroundColor: '#ababab',
        color: '#FFFFFF',
    },

    '.mobile &': {
        padding: '4px',
    },
});

export const StyledButtonCustom = styled(ButtonCustom)({
    width: '100%',
    maxWidth: '130px',
    fontSize: '14px',
    '&:disabled': {
        backgroundColor: '#ababab',
        boxShadow: 'none !important',
        '&::before': {
            backgroundColor: '#ababab',
        },
    },
});

export const StyledTextField = styled(TextField)({
    maxWidth: '100px',
    'input': {
        padding: '4px 8px',
        '.mobile &': {
            padding: '2px 4px',
        },
    },
});

export const StyledAnnounce = styled(Box)({
    'p': {
        fontFamily: 'Inter',
        textAlign: 'center',
    },
    'span': {
        fontWeight: '600',
        color: '#175EBE'
    },
});

export const StyledSlider = styled(Slider)({
    '&.MuiSlider-root': {
        padding: '13px 0',
    },
});

export const StyledButton = styled(ButtonComponent)({
    '.mobile &': {
        height: '30px',
    },
});
