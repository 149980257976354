import { GameHelper } from '@quanticogames/gameclient-core';
import gsap from 'gsap';

const removeIcon = (elem) => {
  gsap.killTweensOf(elem);
  elem.remove();
};

export const particleAnimation = (elem: any, fromId?: number, isEmoji = false) => {
  if (!elem) return;
  const isMobile = GameHelper.isMobile();
  const table: HTMLElement = document.querySelector('.board-wrapper');
  const playerId = fromId ?? elem.getAttribute('from');
  const fromPlayerEl = document.getElementById(`player-${playerId}`);
  const avaEl: HTMLElement = fromPlayerEl?.querySelector('.player-avatar');
  if (!fromPlayerEl || !avaEl) return;
  avaEl.appendChild(elem);
  const nearTop = (fromPlayerEl?.offsetTop * 2) < table?.offsetHeight;
  let fromX, fromY;
  if (isMobile) {
    const transformStyle = avaEl.style.transform;
    const rotateVal = Number((/rotate\((.*?)deg\)/g).exec(transformStyle)?.[1] || '0');
    const nearLeft = (fromPlayerEl?.offsetLeft * 2) < table?.offsetWidth;
    fromX = rotateVal ?
      isEmoji
        ? elem?.offsetWidth / 2
        : nearLeft ? avaEl?.offsetWidth + elem?.offsetWidth / 2 : - elem?.offsetWidth / 2
      : avaEl?.offsetWidth / 2;
    fromY = isEmoji || rotateVal ? avaEl?.offsetHeight / 2 : (nearTop ? avaEl?.offsetHeight + 10 : -10)
  } else {
    fromX = avaEl?.offsetWidth / 2;
    fromY = isEmoji ? avaEl?.offsetHeight / 2 : (nearTop ? avaEl?.offsetHeight + 10 : -10);
  }

  const animation = gsap.timeline()
    .set(elem, {
      x: fromX,
      y: fromY,
      top: 0,
      left: 0,
      xPercent: -50,
      yPercent: -50,
      scaleX: 0.5,
      scaleY: 0.5,
      zIndex: 999,
    })
    .to(elem, {
      duration: 0.5,
      scaleX: 1.5,
      scaleY: 1.5,
      ease: 'linear',
    })
    .to(elem, {
      duration: 1,
      delay: 1.5,
      scaleX: 0,
      scaleY: 0,
      ease: 'circ.in',
      onComplete: () => removeIcon(elem),
    })
    .pause();

  const play = () => {
    animation.play();
  };

  const pause = () => {
    animation.pause();
  };

  return { play, pause };
};
