import { styled } from "@mui/material";

const RootStyle = styled('span')(({ color, background }: { color: string; background: any }) => {
  return {
    padding: '0 8px',
    display: 'flex',
    width: 'fit-content',
    fontWeight: 500,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    cursor: 'default',
    color: color,
    background: background 
  };
});

export const StateLabel = ({ children, color, background }) => {
  return (
    <RootStyle color={color} background={background} className="state-label">
      {children}
    </RootStyle>
  );
};
