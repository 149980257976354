import { axiosClient } from "@quanticogames/gameclient-core";

const rootApi = process.env.REACT_APP_API_URL;

export const convertParamToQueryString = (data?: any) => {

    if(data == null) return "";
    
    const params = new URLSearchParams()
  
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.length && value.forEach(v => params.append(key, v?.toString()));
      } else {
        if(value !== undefined && value !== null) params.append(key, value.toString());
      }
    });
  
    return params.toString();
  }

export const getChatWarning = (param: any) => {
    let queryString = convertParamToQueryString(param);
    return axiosClient.get(rootApi + `/chat/flagged?` + queryString).then((response) => {
        return response.data;
    })
}