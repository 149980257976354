export const mockTableData = [
    {
        "id": 36748,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM000021",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000045",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T17:08:03.948971",
        "updatedTime": "2024-07-08T17:08:10.889103",
        "award": 0
    },
    {
        "id": 36736,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000020",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T10:56:04.727164",
        "updatedTime": "2024-07-08T11:26:02.508786",
        "award": 0
    },
    {
        "id": 36734,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000019",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T10:48:04.273679",
        "updatedTime": "2024-07-08T10:48:04.273679",
        "award": 0
    },
    {
        "id": 36724,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000018",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T07:16:04.981282",
        "updatedTime": "2024-07-08T10:58:33.506205",
        "award": 0
    },
    {
        "id": 36722,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000017",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T06:47:04.078522",
        "updatedTime": "2024-07-08T06:47:04.078522",
        "award": 0
    },
    {
        "id": 36713,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM000016",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000031",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:13.482338",
        "award": 0
    },
    {
        "id": 36712,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM000015",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000002F",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:13.419477",
        "award": 0
    },
    {
        "id": 36711,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000014",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:05.952209",
        "award": 0
    },
    {
        "id": 36709,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000012",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:05.952209",
        "award": 0
    },
    {
        "id": 36708,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000011",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:05.952209",
        "updatedTime": "2024-07-08T04:28:05.952209",
        "award": 0
    },
    {
        "id": 36707,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000010",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:04.74852",
        "updatedTime": "2024-07-08T04:28:04.74852",
        "award": 0
    },
    {
        "id": 36706,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF000009",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-08T04:28:04.74852",
        "updatedTime": "2024-07-08T04:28:04.74852",
        "award": 0
    },
    {
        "id": 36633,
        "title": "Telesina Scheduled_1 ",
        "description": "",
        "code": "TLTM000006",
        "networkId": 1,
        "gameId": 15,
        "casinoId": 1007,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000005B",
        "state": 1,
        "cost": 1400,
        "fee": 70,
        "feeRate": 0.05,
        "speed": 1,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "0",
        "objectiveValue": null,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,
        "structure": "KO",

        "playingTurnTime": 30,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": false,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-02T07:48:56.336755",
        "updatedTime": "2024-07-02T07:50:05.499883",
        "award": 0
    },
    {
        "id": 36625,
        "title": "Telesina Scheduled_1 ",
        "description": "",
        "code": "TLTM000001",
        "networkId": 1,
        "gameId": 15,
        "casinoId": 1007,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000050",
        "state": 1,
        "cost": 1300,
        "fee": 65,
        "feeRate": 0.05,
        "speed": 1,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "0",
        "objectiveValue": null,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 30,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": false,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-07-02T03:11:48.165733",
        "updatedTime": "2024-07-02T03:13:02.429604",
        "award": 0
    },
    {
        "id": 36187,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM36187",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS0000000005B0",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,
        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-18T07:29:02.461678",
        "updatedTime": "2024-06-18T07:29:07.863155",
        "award": 0
    },
    {
        "id": 36151,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM36151",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS0000000005A0",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-18T05:01:02.744882",
        "updatedTime": "2024-06-18T05:01:08.180356",
        "award": 0
    },
    {
        "id": 36147,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF36147",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-18T04:36:02.530361",
        "updatedTime": "2024-06-18T04:36:02.530361",
        "award": 0
    },
    {
        "id": 36112,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF36112",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-17T13:58:02.484827",
        "updatedTime": "2024-06-17T13:58:02.484827",
        "award": 0
    },
    {
        "id": 33258,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM33258",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000001B",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-16T16:28:02.368776",
        "updatedTime": "2024-06-16T16:28:02.679847",
        "award": 0
    },
    {
        "id": 33257,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM33257",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000001A",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 2,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-16T16:25:02.89552",
        "updatedTime": "2024-06-16T16:27:09.587784",
        "award": 0
    },
    {
        "id": 33256,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM33256",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000018",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-16T14:38:02.310915",
        "updatedTime": "2024-06-16T14:38:02.639395",
        "award": 0
    },
    {
        "id": 33017,
        "title": "Telesina Scheduled_2 ",
        "description": "",
        "code": "TLTM33016",
        "networkId": 1,
        "gameId": 15,
        "casinoId": 1,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS0000000009C4",
        "state": 1,
        "cost": 1100,
        "fee": 55,
        "feeRate": 0.05,
        "speed": 2,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "0",
        "objectiveValue": null,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 30,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": false,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 1,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-11T08:15:36.612743",
        "updatedTime": "2024-06-11T08:15:36.69697",
        "award": 0
    },
    {
        "id": 32861,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32861",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000029",
        "state": 3,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 2,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 2,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": "2024-06-08T08:17:02.812532",
        "startingTime": "2024-06-08T08:17:02.905606",
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-07T10:50:03.544986",
        "updatedTime": "2024-06-08T08:17:02.996886",
        "award": 0
    },
    {
        "id": 32844,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32844",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000001F",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 1,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-07T09:20:04.37938",
        "updatedTime": "2024-06-13T03:30:38.119706",
        "award": 0
    },
    {
        "id": 32557,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32557",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS0000000001C8",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:18.78382",
        "award": 0
    },
    {
        "id": 32556,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32556",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000187",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:18.132242",
        "award": 0
    },
    {
        "id": 32555,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32555",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000182",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:18.137988",
        "award": 0
    },
    {
        "id": 32554,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32554",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS0000000001C1",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:18.800365",
        "award": 0
    },
    {
        "id": 32553,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32553",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000211",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:19.076196",
        "award": 0
    },
    {
        "id": 32550,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32550",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000215",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-11T04:14:54.154517",
        "award": 0
    },
    {
        "id": 32547,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32547",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32546,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32546",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32545,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32545",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32544,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32544",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32543,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32543",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32542,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32542",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32541,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32541",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32540,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32540",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32539,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32539",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32538,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32538",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32537,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32537",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32536,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32536",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32535,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32535",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32534,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32534",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32533,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32533",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32532,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32532",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:08.329231",
        "updatedTime": "2024-06-06T08:44:08.329231",
        "award": 0
    },
    {
        "id": 32285,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32285",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS0000000001AD",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:18.606019",
        "award": 0
    },
    {
        "id": 32284,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32284",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000177",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:18.239955",
        "award": 0
    },
    {
        "id": 32283,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32283",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000172",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:17.987033",
        "award": 0
    },
    {
        "id": 32281,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32281",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000012A",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:16.767096",
        "award": 0
    },
    {
        "id": 32280,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32280",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS00000000016E",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:17.334714",
        "award": 0
    },
    {
        "id": 32279,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32279",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000169",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:17.409461",
        "award": 0
    },
    {
        "id": 32278,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTM32278",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": "AAMS000000000162",
        "state": 1,
        "cost": 100,
        "fee": 14,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:17.84052",
        "award": 0
    },
    {
        "id": 32275,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32275",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32274,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32274",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32272,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32272",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 6,
        "maxPlayers": 6,
        "minPlayers": 6,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32271,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32271",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32270,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32270",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32269,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32269",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32268,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32268",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 5,
        "maxPlayers": 2,
        "minPlayers": 2,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32267,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32267",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32266,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32266",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32265,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32265",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32264,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32264",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 4,
        "maxPlayers": 4,
        "minPlayers": 4,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32263,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32263",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32262,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32262",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 1,
        "rewardValue": 1000,
        "publicity": 0,
        "freeroll": true,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32261,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32261",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": true,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-06-06T08:44:06.378711",
        "award": 0
    },
    {
        "id": 32260,
        "title": "Telesina_0 1m",
        "description": "",
        "code": "TLTF32260",
        "networkId": 1,
        "gameId": 15,
        "casinoId": null,
        "password": null,
        "type": "Normal",
        "aamsCode": null,
        "state": 1,
        "cost": 0,
        "fee": 0,
        "feeRate": 0,
        "speed": 0,
        "maxSeats": 3,
        "maxPlayers": 3,
        "minPlayers": 3,
        "registered": 0,
        "objective": "m",
        "objectiveValue": 1,
        "isSubscribed": false,
        "currentPlayerStatus": null,
        "openingTime": null,
        "closingTime": null,
        "startingTime": null,
        "finishedTime": null,


        "playingTurnTime": 180,
        "matchTime": 300,
        "otherInfo": null,
        "participants": [],
        "matches": [],
        "prizePlan": null,
        "allowToken": true,
        "highlight": 0,
        "rewardType": 0,
        "rewardValue": 0,
        "publicity": 0,
        "freeroll": false,
        "openingTimeOffset": null,
        "closedTimeOffset": null,
        "hasPassword": false,
        "isPassFirstRound": null,
        "guaranteedValue": 0,
        "totalRounds": null,
        "initChip": 0,
        "invitationType": 0,
        "timeOutExtra": null,
        "gameCode": null,
        "createdTime": "2024-06-06T08:44:06.378711",
        "updatedTime": "2024-07-09T04:40:02.755738",
        "award": 0
    }
]