import { Game, GameHelper, Tournament, selectGames } from "@quanticogames/gameclient-core";
import UserGroup from "assets/icons/UserGroup";
import { useTranslation } from "react-i18next";
import { mapStyleGame } from "utils/styleGame";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getGameType, getSpeedLabel } from "utils/tournamentTitle";

type SliderTableItemProps = {
    game: Tournament,
}

const SliderTableItem = ({ game }: SliderTableItemProps) => {
    const { t } = useTranslation('common')
    const isMobile = GameHelper.isMobile();
    const games: Game[] = useSelector(selectGames);

    const gameName = useMemo(() => {
        const g = games?.find(g => g.id === game?.gameId);
        if (g) return g.name;
        return '';
    }, [games, game?.gameId]);

    const gameCode = useMemo(() => {
        const g = games?.find(g => g.id === game?.gameId);
        if (g) return g.code;
        return '';
    }, [games, game?.gameId]);

    const title = useMemo(() => {
        const type = getGameType(game?.type, t, true);
        const speed = getSpeedLabel(game?.speed);
        const winObj = game?.objective && game?.objectiveValue ? `${game?.objectiveValue}${game?.objective}` : '';

        return `${type} - ${t(speed)} ${winObj}`;
    }, [game]);

    return (
        <div
            className={`slider-table-item slider-${gameCode}`}
            style={{
                backgroundColor: mapStyleGame(gameCode)?.background,
                color: mapStyleGame(gameCode)?.color,
            }}
        >
            {!isMobile && <div className="slider-table-images">
                <img src={`/images/common/logo/logo-game-${gameCode || 'Scopa'}-no-bg.png`} />
            </div>}
            <div className="slider-table-content">
                <h3 className="slider-table-name">{gameName}</h3>
                <p className="slider-table-des">{title}</p>
                <span className="slider-table-user">
                    <UserGroup />
                    {game?.registered} / {game?.maxPlayers}
                </span>
            </div>
        </div>
    )
}
export default SliderTableItem