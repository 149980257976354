type BtnCloseProps = {
    handleClick?: () => void,
    className?: string
}

const BtnClose = ({ handleClick, className }: BtnCloseProps) => {
    return (
        <a className={`sidebar-close w-[36px] h-[36px] rounded-[50%] flex items-center justify-center btn-action ${className ? className : ''}`} onClick={handleClick}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.10938 0.544922L15.1094 14.5449" stroke="#9EA8B4" strokeWidth="1.5" />
                <path d="M15.1094 0.544922L1.10938 14.5449" stroke="#9EA8B4" strokeWidth="1.5" />
            </svg>
        </a>
    );
};
export default BtnClose;
