import * as React from 'react';
import {
  GameHelper,
  convertCentToEuro,
  formatMoney,
  matchApi,
} from '@quanticogames/gameclient-core';
import { useEffect, useState } from 'react';
import ButtonComponent from 'shared/components/button';
import ProfileCollapse from 'layouts/profiles/components/profile-collapse';
import { BADGES_COLLECTION } from 'constants/badges';
import { ReplayIcon } from 'assets/icons/ReplayIcon';
import {
  DEFAULT_MATCH_FINISHED_ITEM_PER_PAGE,
  DEFAULT_MATCH_FINISHED_START_PAGE,
  HISTORY_TYPE,
  ORDER_BY_RECENT_MATCH_TIME,
} from 'utils/constants';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type ProfileLastGameListProps = {
  type: HISTORY_TYPE;
};

export const History: React.FC<ProfileLastGameListProps> = ({ type }) => {
  const [finishedMatchList, setFinishedMatchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(DEFAULT_MATCH_FINISHED_START_PAGE);
  const [totalMatches, setTotalMatches] = useState(0);
  const pageSize = DEFAULT_MATCH_FINISHED_ITEM_PER_PAGE;
  const sortByMostRecent = ORDER_BY_RECENT_MATCH_TIME;
  const rootApi = process.env.REACT_APP_HISTORY_CLIENT_URL;
  const { t } = useTranslation('common');
  const isMobile = GameHelper.isMobile();
  const isMobileLandscape = GameHelper.isLandscape() && isMobile;

  const formatDate = (inputTime) => {
    const date = moment(inputTime);
    return date.format('DD/MM/YYYY');
  };

  const handleOpenHistory = (item) => {
    const searchParams = new URLSearchParams();
    searchParams.append('token', localStorage.getItem('token'));
    searchParams.append('game', item.game);
    if (type === HISTORY_TYPE.CASH_TABLE) {
      searchParams.append('session', item.timesJoin);
      searchParams.append('table', item.id);
    } else {
      searchParams.append('tournament', item.id);
    }
    window.open(`${rootApi}?${searchParams.toString()}`);
  };

  const fetchData = async (isFetchNew = false) => {
    setIsLoading(true);
    try {
      const response = await matchApi.getFinishedMatchList({
        page: currentPage,
        pageSize,
        orderBy: sortByMostRecent,
        type,
      });
      const data = response.data.map((item: any) => {
        const awardValue = item.cost >= 0 && item?.award > 0 ? item?.award : -item?.cost;
        const finishedMatchItem = {
          code: item.code,
          date: formatDate(type === HISTORY_TYPE.CASH_TABLE ? item.updatedTime : item.startingTime),
          players: item.registered + '/' + item.maxPlayers,
          game: item.gameCode,
          session: `${item.code} - ${item.timesJoin}`,
          timesJoin: item.timesJoin,
          id: item.id,
          rake: `${item.rake * 100}%`,
          invitation: `€ ${formatMoney(convertCentToEuro(item.invitation))}`,
          replay: (
            <ButtonComponent
              sx={{ padding: 0, minWidth: 0 }}
              variant="text"
              icon={<ReplayIcon />}
              onClick={() => handleOpenHistory(finishedMatchItem)}
              className="!min-w-[0] !p-0 replay-btn"
            />
          ),
          bagde: (
            <div className="badge-list">
              {item.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
              {item.structure === 'MT' && item.matchType === 0 && item?.type !== 'Scheduled' ? (
                <div className="badge-icon" style={{
                  width: 34,
                  height: 34,
                }}>{`${item.maxPlayers}P`}</div>
              ) : (
                <>{BADGES_COLLECTION[item.matchType]?.icon}</>
              )}
              {item.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
              {BADGES_COLLECTION[item?.type]?.icon}
            </div>
          ),
          win: Number((type === HISTORY_TYPE.CASH_TABLE ? item?.winAmount : awardValue) || 0),
          detail: [
            ...(type === HISTORY_TYPE.TOURNAMENT
              ? [
                  {
                    label: t('Code'),
                    content: item.code,
                  },
                  {
                    label: t('Badge'),
                    content: (
                      <div className="badge-list">
                        {item.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
                        {item.structure === 'MT' && item.matchType === 0 && item?.type !== 'Scheduled' ? (
                          <div className="badge-icon">{`${item.maxPlayers}P`}</div>
                        ) : (
                          <>{BADGES_COLLECTION[item.matchType]?.icon}</>
                        )}
                        {item.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
                        {BADGES_COLLECTION[item?.type]?.icon}
                      </div>
                    ),
                  },
                  {
                    label: t('Win'),
                    content:  <span className={`profit ${awardValue < 0 ? 'losses' : 'wins'}`}>
                    € {formatMoney(convertCentToEuro(Math.abs(awardValue || 0)))}
                  </span>,
                  },
                  {
                    label: t('Date'),
                    content: formatDate(item.startingTime),
                  },
                  {
                    label: t('Players'),
                    content: item.registered + '/' + item.maxPlayers,
                  },
                ]
              : [
                  {
                    label: t('Sessions'),
                    content: `${item.code} - ${item.timesJoin}`,
                  },
                  {
                    label: 'Invitation',
                    content: `€ ${formatMoney(convertCentToEuro(item.invitation))}`,
                  },
                  {
                    label: 'Rake',
                    content: `${item.rake * 100}%`,
                  },
                ]),
            {
              label: t('Replay'),
              content: (
                <ButtonComponent
                  sx={{ padding: 0, minWidth: 0 }}
                  variant="text"
                  icon={<ReplayIcon />}
                  onClick={() => handleOpenHistory(finishedMatchItem)}
                  className="!min-w-[0] !p-0 replay-btn"
                />
              ),
            },
          ],
        };
        return finishedMatchItem;
      });

      setTotalMatches(response.total);

      if (isFetchNew) {
        setFinishedMatchList(data);
        setIsLoading(false);
        return;
      }

      const finishedMatchConcatOnShowmore = [...finishedMatchList, ...data];
      setFinishedMatchList(finishedMatchConcatOnShowmore);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const columns = [
    {
      id: 'code',
      label: t('Code'),
      align: 'left',
      classNames: '!text-[#06F]',
    },
    {
      id: 'win',
      label: t('Win/Lose'),
      align: 'left',
      classNames: '!text-[#00BE2A]',
      render: (value: number) => {
        const formatedValue = formatMoney(convertCentToEuro(Math.abs(value)));
        return (
          <>
            <span className={`profit ${value < 0 ? 'losses' : 'wins'}`}>
              € {formatedValue}
            </span>
          </>
        )
      }
    },
    {
      id: 'date',
      label: t('Date'),
      align: 'left',
      classNames: '',
    },
  ];

  const columnsCashTableLandscape = [
    {
      id: 'code',
      label: 'Code',
      align: 'center',
      classNames: '!text-[#06F] !text-center',
    },
    {
      id: 'session',
      label: 'Session',
      align: 'center',
      classNames: '!text-center',
    },
    {
      id: 'invitation',
      label: 'Invitation',
      align: 'center',
      classNames: '!text-center !text-[#00BE2A]',
    },
    {
      id: 'rake',
      label: 'Rake',
      align: 'center',
      classNames: '!text-center',
    },
    {
      id: 'date',
      label: 'Date',
      align: 'center',
      classNames: '!text-center',
    },
    {
      id: 'replay',
      label: 'Replay',
      align: 'center',
      classNames: '!text-center',
    },
  ];

  const columnsLandscape = [
    {
      id: 'code',
      label: 'Code',
      align: 'left',
      classNames: '!text-[#06F]',
    },
    {
      id: 'bagde',
      label: 'Bagde',
      align: 'center',
      classNames: '!text-center',
    },
    {
      id: 'win',
      label: 'Win/Lose',
      align: 'center',
      render: (value: number) => {
        const formatedValue = formatMoney(convertCentToEuro(Math.abs(value)));
        return (
          <>
            <span className={`profit ${value < 0 ? 'losses' : 'wins'}`}>
              € {formatedValue}
            </span>
          </>
        )
      }
    },
    {
      id: 'date',
      label: 'Date',
      align: 'center',
      classNames: '!text-center',
    },
    {
      id: 'players',
      label: 'Player',
      align: 'center',
      classNames: '!text-center',
    },
    {
      id: 'replay',
      label: 'Replay',
      align: 'center',
      classNames: '!text-center',
    },
  ];

  return (
    <ProfileCollapse
      columns={isMobileLandscape ? (type === HISTORY_TYPE.CASH_TABLE ? columnsCashTableLandscape : columnsLandscape) : columns}
      data={finishedMatchList}
      isLoading={isLoading}
      totalItem={totalMatches}
      onLoadMore={() => setCurrentPage(currentPage + 1)}
    />
  );
};
