import { ReactNode } from 'react';
import { Box, styled, SxProps, Theme, Typography } from '@mui/material';

const NotificationsBox = styled(Box)`
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  p {
    font-size: 0.75rem;
    line-height: 14px;
    font-weight: 400;
  }

  svg {
    margin-right: 1rem;
  }
`;

type NotificationsType = {
  title: string;
  icon?: ReactNode;
  sx?: SxProps<Theme>;
};

export const Notifications = ({
  title = '...',
  icon,
  sx,
}: NotificationsType) => (
  <NotificationsBox sx={sx}>
    <Box> {icon}</Box>
    <Typography>{title}</Typography>
  </NotificationsBox>
);
