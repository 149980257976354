export const listEmojiData = {
  // love: {
  //   name: 'love',
  //   src: '/images/emoji/svg/heart.gif',
  // },
  like: {
    name: 'like',
    src: '/images/emoji/svg/like.gif',
  },
  smiling: {
    name: 'smiling',
    src: '/images/emoji/svg/smiling-heart.gif',
  },
  hushed: {
    name: 'hushed',
    src: '/images/emoji/svg/hushed.gif',
  },
  sad: {
    name: 'sad',
    src: '/images/emoji/svg/sad.gif',
  },
  angry: {
    name: 'angry',
    src: '/images/emoji/svg/angry.gif',
  },
};
