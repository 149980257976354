export const ChatSmileIconSvg = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.7959C17.1086 21.7959 21.25 17.6545 21.25 12.5459C21.25 7.43726 17.1086 3.2959 12 3.2959C6.89137 3.2959 2.75 7.43726 2.75 12.5459C2.75 17.6545 6.89137 21.7959 12 21.7959Z"
      stroke="#9EA8B4"
      strokeWidth="1.5"
    />
    <path
      d="M9 10.5459C9.55228 10.5459 10 10.0982 10 9.5459C10 8.99361 9.55228 8.5459 9 8.5459C8.44772 8.5459 8 8.99361 8 9.5459C8 10.0982 8.44772 10.5459 9 10.5459Z"
      fill="#9EA8B4"
    />
    <path
      d="M15 10.5459C15.5523 10.5459 16 10.0982 16 9.5459C16 8.99361 15.5523 8.5459 15 8.5459C14.4477 8.5459 14 8.99361 14 9.5459C14 10.0982 14.4477 10.5459 15 10.5459Z"
      fill="#9EA8B4"
    />
    <path
      d="M17.742 14.2917C17.3807 15.5305 16.6238 16.617 15.587 17.3851C14.5501 18.1533 13.2903 18.5609 12 18.5457C10.686 18.5297 9.41221 18.0898 8.36839 17.2914C7.32457 16.4929 6.5665 15.3788 6.20703 14.1147"
      stroke="#9EA8B4"
      strokeWidth="1.5"
    />
  </svg>
);
