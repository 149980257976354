import clsx from 'clsx';
import {
  Game,
  GameHelper,
  GameRound,
  IAlertMessage,
  SessionInfo,
  cashTableActions,
  darkMode,
  footerMobileLeft,
  gameActions,
  isPopup,
  selectActiveGame,
  selectActiveMatchId,
  selectAlertMessage,
  selectCashTableRound,
  selectCasino,
  selectRound,
  selectRounds,
  selectSessionInfo,
  useCheckLobbyPage,
} from '@quanticogames/gameclient-core';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { CONTAINER_ID } from '../../constants/common';
import { PATH_ROUTES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalSetUserName from 'shared/Tournament/ModalSetUserName';
import ModalLogGame from 'shared/components/modal-log-game';
import { EndSession, ConfirmEndSession } from 'shared';
import ChatDesktop from 'shared/Chat/Component/chat-desktop';
import ModalComponent from 'shared/components/modal';
import './index.scss';
import { ControlPanel } from 'layouts/control-panel';
import { Footer } from 'layouts/footer';
import { FooterMobile } from 'layouts/footer/component/footer-mobile';
import Header from 'layouts/headers';
import ToastContainerCustom from 'layouts/main-layout/component/toast-container';
import 'react-toastify/dist/ReactToastify.css';
import AlertMessageModal from 'shared/AlertMessageModal/AlertMessageModal';
import { useIsMobileLandscape } from 'hooks/useIsMobileLandscape';
import SliderTable from 'shared/components/slider-table';
import MatchSwitcher from 'shared/components/match-switcher';
import { useDispatch } from 'react-redux';
import InactiveNotice from 'layouts/inactive-notice';
import useSound from 'hooks/useSoundManage';
import { useMediaQuery } from '@mui/material';
import theme from 'theme';

type roundStart = {
  gameCode: string;
  id: number;
};

const MainLayout = () => {
  const { t } = useTranslation();
  const isMobile = GameHelper.isMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLobby = useCheckLobbyPage();
  const isGamePage = location.pathname.includes(PATH_ROUTES.GAME);
  const [isRegulationPage, setIsRegulationPage] = useState<boolean>(false);
  const activeGame: Game = useSelector(selectActiveGame);
  const rounds: GameRound[] = useSelector(selectRounds);
  const url = new URL(window.location.href);
  const [openBlockPopup, setOpenBlockPopup] = useState<boolean>(false);
  const [roundStart, setRoundStart] = useState<roundStart>();
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
  const casino = (useSelector(selectCasino) as string) || 'quantico';
  const isDarkMode: boolean = useSelector(darkMode);
  const isFooterMenuLeft: boolean = useSelector(footerMobileLeft);
  const alertMessage: IAlertMessage = useSelector(selectAlertMessage);
  const [headerSmall, setHeaderSmall] = useState<boolean>(false);
  const isMobileLandscape = useIsMobileLandscape();
  const [showLayoutFooterMenuLeft, setShowLayoutMenuLeft] = useState<boolean>(false);
  const [isOpenAlertMessageModal, setIsOpenAlertMessageModal] = useState(false);
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
  const round: any = useSelector(isCashTable ? selectCashTableRound() : selectRound());
  const [loading, setLoading] = useState<boolean>(true);

  useSound();

  useEffect(() => {
    if (alertMessage && !isOpenAlertMessageModal) setIsOpenAlertMessageModal(!isOpenAlertMessageModal);
  }, [alertMessage]);

  useEffect(() => {
    if (isFooterMenuLeft) {
      setShowLayoutMenuLeft(true);
    } else {
      setShowLayoutMenuLeft(false);
    }
  }, [isMobileLandscape, isFooterMenuLeft])

  useEffect(() => {
    if (location.pathname.includes('regulation')) {
      setIsRegulationPage(true);
    } else {
      setIsRegulationPage(false);
    }
  }, [location]);

  // handle notification request
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  useEffect(() => {
    if (localStorage['allowNotification'] === '0') {
      return;
    }
    if (isPopup || isMobile) {
      return;
    }
  }, []);

  const requestPermission = () => {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // if (permission === 'granted') {

      // }
    });

    setIsOpenNotification(false);
  };

  const cancelNotification = () => {
    localStorage['allowNotification'] = '0';
    setIsOpenNotification(false);
  };

  useEffect(() => {
    const listRoundStart = rounds.filter((item) => item.state === 'started');
    let roundStartLastId;
    let roundStartLast;
    if (listRoundStart.length > 0) {
      roundStartLast = listRoundStart[listRoundStart.length - 1];
      roundStartLastId = roundStartLast?.matchId;
      setRoundStart(roundStartLast);
    } else {
      setOpenBlockPopup(false);
    }

    if (isPopup === undefined && roundStartLastId) {
      setOpenBlockPopup(true);
    }
  }, [rounds]);

  const handleClickGioca = () => {
    if (!roundStart) return;
    const inGameUrl = `${url.origin}/game/${roundStart?.gameCode}?id=${roundStart?.id}`;
    window.open(inGameUrl, roundStart?.id.toString(), 'width=' + screen.availWidth + ',height=' + screen.availHeight);
    setOpenBlockPopup(false);
  };


  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const ticking = useRef(false);

  useEffect(() => {
    if (isMobileLandscape) {
      const handleScroll = debounce(() => {
        if (!ticking.current) {
          window.requestAnimationFrame(() => {
            const isSmall = window.scrollY > 100;
            if (isSmall !== headerSmall) {
              setHeaderSmall(isSmall);
            }
            ticking.current = false;
          });
          ticking.current = true;
        }
      }, 50);

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobileLandscape, headerSmall]);

  let containerClass;

  switch (true) {
    case isLobby:
      containerClass = 'container-lobby';
      break;
    case isRegulationPage:
      containerClass = 'container-regulation';
      break;
    default:
      containerClass = `container-game container-game-${activeGame?.code}`;
      break;
  }

  useEffect(() => {
    if (sessionInfo) {
      dispatch(cashTableActions.loadOnGoingGame());
    }
  }, [sessionInfo]);

  useEffect(() => {
    if (sessionInfo) {
      const matchId = url.searchParams.get('id');
      const metaData = {
        isLobby: isLobby,
        matchId: isLobby ? null : matchId
      }
      dispatch(gameActions.ping(metaData))
    }
  }, [isLobby, sessionInfo]);


  const className = clsx(
    'root-container',
    isMobile && 'mobile',
    !isMobile && 'desktop',
    isDarkMode ? 'dark-mode' : casino,
    isMobileLandscape && 'mobile-landscape',
    isMobileLandscape && (showLayoutFooterMenuLeft ? 'root-container-left' : 'root-container-right'),
    containerClass
  );
  useEffect(() => {

    if(sessionInfo) {
      dispatch(cashTableActions.loadOnGoingGame())
    }

  }, [sessionInfo]);

  useEffect(() => {
    if (round?.loaded) setLoading(false)
  }, [round?.loaded]);

  return (
    <div id={CONTAINER_ID} className={className}>
      {sessionInfo?.isAuthenticated && !sessionInfo?.user?.userName && <ModalSetUserName />}

      <div className={`header-box ${isMobileLandscape ? headerSmall ? 'header-small' : '' : ''}`}>
        {((isMobile && !isGamePage) || !isMobile) && <Header />}

        {isLobby && !isRegulationPage && (
          <>
            <SliderTable />
            <ControlPanel />
          </>
        )}
      </div>

      <div className="main-content">
        {/* alert message modal */}
        <AlertMessageModal
          alertMessage={alertMessage}
          open={isOpenAlertMessageModal}
          onClose={() => setIsOpenAlertMessageModal(!isOpenAlertMessageModal)}
        />

        <div className="main-container">
          <Outlet />
        </div>
      </div>
      {(isLobby || isRegulationPage) && <Footer />}

      {isMobile && !isGamePage && <FooterMobile />}

      {loading && isGamePage ? <></> : <><MatchSwitcher /> <ChatDesktop /></>}

      <ModalComponent
        title={t('Turn on the notifications')}
        open={isOpenNotification}
        typeModal="confirm"
        textOk="Ok"
        onClose={cancelNotification}
        onOk={requestPermission}
        onCancel={() => setIsOpenNotification(false)}
        showIconClose={openBlockPopup && roundStart ? false : true}
      >
        {t('We will keep you informed about game actions.')}
      </ModalComponent>

      {openBlockPopup && roundStart && (
        <ModalComponent
          title={t("Notification")}
          onOk={handleClickGioca}
          textOk="Gioca"
          onCancel={() => setOpenBlockPopup(false)}
          className="modal-tooltip"
          open={openBlockPopup}
          size='small'
        >
          <p>La tua nuova partita nel torneo {roundStart?.id} è iniziata, clicca sul pulsante per andare al tavolo!</p>
        </ModalComponent>
      )}
      <ModalLogGame />

      <EndSession />
      <ConfirmEndSession />
      <ToastContainerCustom />
      <InactiveNotice />
    </div>
  );
};
export default MainLayout;
