import React, {memo} from 'react';
import { FlexBox } from 'shared/Flex';
import { CountDown } from '@quanticogames/gameclient-components';
import UserGroup from 'assets/icons/UserGroup';
import { convertCentToEuro, formatMoney } from '@quanticogames/gameclient-core';
import { StateLabel } from 'pages/tournament/component/tournament-state';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';
import { BADGES_COLLECTION } from 'constants/badges';

interface TournamentPlayerProps {
  data: {
    registered: number;
    maxPlayers: number;
};
};

interface TournamentFavouriteProps {
  data: {
    id: number;
  };
  handleActionFavourite: (id: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  renderFavoriteIcon: (id: number) => React.ReactNode;
};

interface TournamentStartProps {
  data: {
    startingTime: string;
  };
  duration: number;
  t?: (key: string) => string;
};

interface TournamentCostProps {
  data: {
    cost: number;
  };
  t?: (key: string) => string;
};

interface TournamentStatusProps {
  data: {
    state: string;
  };
  t?: (key: string) => string;
};


interface TournamentLineProps {
  children: React.ReactNode;
};

interface TournamentBadgeProps {
  data: {
    cost: number;
    structure: string;
    matchType: number;
    type: string;
    allowToken: boolean;
    maxPlayers: number;
  };
};

interface TournamentPrizeProps {
  data: {
    cost: number;
  };
  t?: (key: string) => string;
};

export const TournamentPlayer: React.FC<TournamentPlayerProps> = memo(({ data }) => (
  <FlexBox alignItems="center" gap={5} className="tournament-mobile-player">
    <UserGroup />
    {`${data.registered}/${data.maxPlayers}`}
  </FlexBox>
));

export const TournamentFavourite: React.FC<TournamentFavouriteProps> = memo(({ data, handleActionFavourite, renderFavoriteIcon }) => (
  <div className="tournament-mobile-favourite" onClick={(e) => handleActionFavourite(data.id, e)}>
    {renderFavoriteIcon(data.id)}
  </div>
));

export const TournamentStart: React.FC<TournamentStartProps> = memo(({ data, duration, t }) => (
  <>
    {data.startingTime && duration > 0 && (
      <p className="tournament-des-time">
        {t('Start in')} <CountDown second={duration} format={duration > 86400 ? 'DD/MM' : duration > 3600 ? 'hh:mm:ss' : 'mm:ss'} />
      </p>
    )}
  </>
));

export const TournamentCost: React.FC<TournamentCostProps> = memo(({ data, t }) => (
  <div className="tournament-mobile-code">
    <span className="label">{t('Cost')}: </span>
    {data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}
  </div>
));

export const TournamentStatus: React.FC<TournamentStatusProps> = memo(({ data, t }) => (
  <div className="tournament-mobile-status">
    <StateLabel
      color={TOURNAMENT_STATES_TITLES[data?.state]?.color}
      background={TOURNAMENT_STATES_TITLES[data?.state]?.background}
    >
      {t(TOURNAMENT_STATES_TITLES[data?.state]?.title)}
    </StateLabel>
  </div>
));

export const TournamentLine: React.FC<TournamentLineProps> = memo(({ children }) => (
  <FlexBox
    alignItems="center"
    width="100%"
    gap={10}
    justifyContent="space-between"
    className="tournament-mobile-line"
  >
    {children}
  </FlexBox>
));

export const TournamentBadge: React.FC<TournamentBadgeProps> = memo(({ data }) => (
  <div className="tournament-mobile-badge">
    <div className="badge-list">
      {data.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
      {data.structure === 'MT' && data.matchType === 0 && data?.type !== 'Scheduled' ? (
        <div className="badge-icon">{`${data.maxPlayers}P`}</div>
      ) : (
        <>{BADGES_COLLECTION[data.matchType]?.icon}</>
      )}
      {data.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
      {BADGES_COLLECTION[data?.type]?.icon}
    </div>
  </div>
));

export const TournamentPrize: React.FC<TournamentPrizeProps> = memo(({ data, t }) => (
  <div className="tournament-mobile-prize">
    <span className="label">{t('Prize')}:</span>
    {data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}
  </div>
));