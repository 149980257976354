import { Stack } from '@mui/material';
import {
  Game,
  Tournament,
  selectBalanceInfo,
  TournamentStateEnum,
  selectActiveGame,
  selectCurrentUser,
  selectActiveTournament,
  tournamentApi,
  selectPassFirstRound,
  ParticipantStatusEnum,
  tournamentActions,
  selectGames,
} from '@quanticogames/gameclient-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDHMS, parseISOLocal } from 'utils/caculateTime';
import { InfoList } from './tournament-info-list';
import moment from 'moment';
import ButtonComponent from 'shared/components/button';
import PassModal from 'pages/tournament/modal/modal-pass';
import { useDispatch } from 'react-redux';
import { getSpeedLabel, getWinObject } from 'utils/tournamentTitle';

type TournamentInfoProps = {
  onClose?: () => void;
  onAction?: (action: string, id: number, index?: number) => void;
  tournamentId: number;
};

export const TournamentInfo: React.FC<TournamentInfoProps> = ({ onAction, tournamentId }) => {
  const { t } = useTranslation();
  const currentUser: any = useSelector(selectCurrentUser);
  const tournament: Tournament = useSelector(selectActiveTournament);
  const closeTime = tournament && tournament?.closingTime;
  const startingTime = tournament && tournament?.startingTime;
  const isPlayButton = [TournamentStateEnum.inprogress, TournamentStateEnum.closed].includes(tournament?.state);
  const isSubscribed = !!(tournament?.participants || []).find((p) => p.userId === currentUser.id);
  const games: Game[] = useSelector(selectGames);
  const balance = useSelector(selectBalanceInfo);

  const [timeCalculator, setTimeCalculator] = useState<any>(null);
  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState<string>('');
  const tournamentPassRound = useSelector(selectPassFirstRound);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentDate = moment();

    if (!currentDate.isAfter(moment(startingTime)) && closeTime && startingTime) {
      const conditionCloseTime = closeTime ? parseISOLocal(new Date(closeTime).toISOString()) : null;
      const conditionStartingTime = parseISOLocal(new Date(startingTime).toISOString());
      //@ts-ignore
      const renderCalculateTime = formatDHMS(conditionStartingTime - conditionCloseTime);
      setTimeCalculator(renderCalculateTime);
    } else {
      setTimeCalculator(null);
    }
  }, [tournament, closeTime]);

  const notContainCurrentId = tournament?.participants?.every((e) => e.userId !== currentUser.id);
  let nonClickable =
    tournament?.state === TournamentStateEnum.scheduled ||
    tournament?.state === TournamentStateEnum.closed ||
    tournament?.state === TournamentStateEnum.terminated;
  nonClickable = nonClickable || tournament?.currentPlayerStatus == ParticipantStatusEnum.Eliminated
    || tournament?.participants?.some(item => item.userId == currentUser.id && item.status == ParticipantStatusEnum.Eliminated);
  // set back isSubscribed by participants
  const participantsLength = tournament?.participants?.length;
  const maxPlayers = tournament?.maxPlayers;

  const renderTitle = () => {
    const speed = getSpeedLabel(tournament?.speed);
    const winObj = getWinObject(tournament?.objective, tournament?.objectiveValue, t);

    const game = games?.find(g => g.id === tournament?.gameId);
    return `${game?.name} ${t(speed)} ${winObj} - ${tournament?.code || '123'}`;
  };

  const handlePlay = (id: number) => {
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getPlayerStatus(id);
        if (response) {
          if (response?.status === 2) {
            setContentDialog('Sei stato eliminato da questo torneo');
            setShowPassModal(true);
            return;
          }
          if (response?.status === 1 && response?.ongoingMatches?.length === 0) {
            setContentDialog('Sei passato al turno successivo, aspetta il tuo avversario');
            setShowPassModal(true);
            return;
          }
        }
        onAction('play', tournamentId);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  };

  useEffect(() => {

    if (!tournamentPassRound) return;
    
    setContentDialog('Sei passato al turno successivo, aspetta il tuo avversario');
    setShowPassModal(true);

    let timeout = setTimeout(() => {
      closePassModal();
      // dispatch(tournamentActions.resetAdvanceToNextRound({}));
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tournamentPassRound]);

  const closePassModal = () => {
    setShowPassModal(false);
    dispatch(tournamentActions.resetAdvanceToNextRound({}));
  }

  return (
    <div className="tournament-info">
      <div className="tournament-info-title">{renderTitle() || 'Scopa Lento 1 Partita - SCTM43808'}</div>
      <InfoList />
      <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />

      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: '5px' }}
        className="tournament-info-btn"
      >
        {tournament?.state !== TournamentStateEnum.end && (
          <>
            {isPlayButton && !notContainCurrentId && (
              <ButtonComponent
                title={t('Play')}
                variant="outlined"
                fullWidth
                disabled={nonClickable}
                size="large"
                rouned="large"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlay(tournamentId);
                }}
              />
            )}
            {!isSubscribed && tournament?.state !== TournamentStateEnum.inprogress && (
              <ButtonComponent
                variant="contained"
                title={t('Subscribe')}
                fullWidth
                color="success"
                size="large"
                rouned="large"
                disabled={nonClickable || balance === undefined || participantsLength == maxPlayers}
                onClick={(e) => {
                  e.stopPropagation();
                  onAction('subscribe', tournament as any);
                }}
              />
            )}

            {isSubscribed &&
              tournament?.state !== TournamentStateEnum.closed &&
              tournament?.state !== TournamentStateEnum.inprogress && (
                <ButtonComponent
                  variant="outlined"
                  color="error"
                  title={t('Unsubscribe')}
                  size="large"
                  rouned="large"
                  fullWidth
                  disabled={nonClickable}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAction('unsubscribe', tournamentId);
                  }}
                />
              )}
          </>
        )}

        {notContainCurrentId && tournament?.state === TournamentStateEnum.inprogress && (
          <ButtonComponent
            variant="outlined"
            title={t('Spectator')}
            size="large"
            rouned="large"
            color="error"
            fullWidth
            disabled={nonClickable}
            onClick={(e) => {
              e.stopPropagation();
              onAction('play', tournamentId);
            }}
          />
        )}
      </Stack>
    </div>
  );
};
