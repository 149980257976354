import { useEffect } from 'react'
import { ColpoGrosso } from 'games/colpo-grosso';
import "../../src/assets/styles/games/colpo-grosso.scss";
import { useSelector } from 'react-redux';
import { selectCasino } from '@quanticogames/gameclient-core';

export const Premio = () => {
    const casino: string = useSelector(selectCasino) || 'quantico';
    useEffect(() => {
        const crazyPrize = new ColpoGrosso({
            data: null,
            container: '.colpo-grosso-wrapper',
            onComplete: () => {
            }
        });
        crazyPrize.start();

        return () => {
            crazyPrize.unmount();
        }
    }, []);

    return (
        <div className={`colpo-grosso-container z-[10000] flex items-center justify-center ${casino}`}>
            <div className='colpo-grosso-wrapper'>
            </div>
        </div>
    )
}