import { TournamentStateEnum } from '@quanticogames/gameclient-core';

export const TOURNAMENT_FILTERS = {
    cost: {
        title: 'Cost',
        options: [
            { id: 1, label: 'Free', costRange: { costFrom: 0, costTo: 0 } },
            { id: 2, label: 'Micro (≤€1)', costRange: { costFrom: 1, costTo: 100 } },
            { id: 3, label: 'Low (€1.01-€5)', costRange: { costFrom: 101, costTo: 500 } },
            { id: 4, label: 'Medium (€5.01-€20)', costRange: { costFrom: 501, costTo: 2000 } },
            { id: 5, label: 'High (>€20.01)', costRange: { costFrom: 2001, costTo: 5000 } },
        ],
    },
    objective: {
        title: 'Objective',
        options: [
            { id: 1, label: 'In points', objective: 'p' },
            { id: 2, label: 'Smazzata', objective: 'm' },
        ],
    },
    matchType: {
        title: 'Match type',
        options: [
            { id: 1, label: 'Head to Head', matchType: 0 },
            { id: 2, label: '4 in 2', matchType: 1 },
            { id: 3, label: 'In pairs', matchType: 2 },
        ],
    },
    timeSpeed: {
        title: "Time speed",
        options: [
            { id: 1, label: 'Slow', timeSpeed: 0 },
            { id: 2, label: 'Normal', timeSpeed: 1 },
            { id: 3, label: 'Turbo', timeSpeed: 2 },
            { id: 4, label: 'Superturbo', timeSpeed: 3 },
        ],
    },
}

export const TOURNAMENT_ITALIAN_POKER_FILTERS = {
    cost: {
        title: 'Cost',
        options: [
            { id: 1, label: 'Free', costRange: { costFrom: 0, costTo: 0 } },
            { id: 2, label: 'Micro (≤€1)', costRange: { costFrom: 1, costTo: 100 } },
            { id: 3, label: 'Low (€1.01-€5)', costRange: { costFrom: 101, costTo: 500 } },
            { id: 4, label: 'Medium (€5.01-€20)', costRange: { costFrom: 501, costTo: 2000 } },
            { id: 5, label: 'High (>€20.01)', costRange: { costFrom: 2001, costTo: 5000 } },
        ],
    },
    maxPlayers: {
        title: 'POSTI AL TAVOLO',
        options: [
            { id: 1, label: '2 posti', maxPlayers: 2 },
            { id: 2, label: '4 posti', maxPlayers: 4 },
            { id: 3, label: '5 posti', maxPlayers: 5 },
            { id: 4, label: '6 posti', maxPlayers: 6 },
        ],
    },
    timeSpeed: {
        title: "Time speed",
        options: [
            { id: 1, label: 'Slow', timeSpeed: 0 },
            { id: 2, label: 'Normal', timeSpeed: 1 },
            { id: 3, label: 'Turbo', timeSpeed: 2 },
            { id: 4, label: 'Superturbo', timeSpeed: 3 },
        ],
    },
}

export const CASH_TABLE_FILTER = {
    cost : {
        title: "Invito",
        options: [
            { id: 1, label: 'Micro (≤€1)', costRange: { costFrom: 1, costTo: 100 } },
            { id: 2, label: 'Low (€1.01-€5)', costRange: { costFrom: 101, costTo: 500 } },
            { id: 3, label: 'Medium (€5.01-€20)', costRange: { costFrom: 501, costTo: 2000 } },
            { id: 4, label: 'High (>€20.01)', costRange: { costFrom: 2001, costTo: 5000 } },
        ]
    },
    maxPlayers: {
        title: "Numbero iscritti",
        options: [
            { id: 2, label: '4 posti', maxPlayers: 4 },
            { id: 3, label: '5 posti', maxPlayers: 5 },
            { id: 4, label: '6 posti', maxPlayers: 6 },
        ]
    }
}


export const TOURNAMENT_STATUS_FILTER = {
    status: {
        title: 'Status',
        options: [
            { id: 1, status: TournamentStateEnum.scheduled, label: 'Scheduled' },
            { id: 2, status: TournamentStateEnum.open, label: 'Open' },
            { id: 3, status: TournamentStateEnum.closed, label: 'Closed' },
            { id: 4, status: TournamentStateEnum.inprogress, label: 'Inprogress' },
            // { id: 5, status: TournamentStateEnum.end, label: 'End' },
            // { id: 6, status: TournamentStateEnum.canceled, label: 'Canceled' },
            { id: 7, status: TournamentStateEnum.terminated, label: 'Terminated' },]
    }

}